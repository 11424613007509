import { useParams, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { Button, Collapse, Form, Row, Spin, Card } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../../components/ContentCustom/ContentCustom';
import {
  drawerFormLayout,
  tailFormItemLayout
} from '../../../utils/constants/formLayout';
import { useGeneralInfosFields } from './GeneralInfos/generalInfosFields';
import { useGenerateFormItem } from '../../../utils/generateFormItem';
import { MaterialShapingForm } from './Material/MaterialShapingsForm';
import { ServicesForm } from './Services/ServicesForm';
import { AccessoriesForm } from './Accessories/AccessoriesForm';
import { SummaryForm } from './Summary/SummaryForm';
import { useQuotationContext } from '../../../contexts/QuotationContext';
import { quotationCalculations } from '../QuotationCalculations/quotationCalculations';
import { useMasterContext } from '../../../contexts/MasterContext';
import { QuotationDrawer } from './QuotationDrawer';
import { routes } from '../../../utils/constants/adminRoutes';
import { LoadingModal } from './LoadingModal';
import {
  formatedData,
  formatedDataCreate,
  formatedDataUpdate
} from '../QuotationCalculations/utils/formatQuotationsData';

const { Panel } = Collapse;

/**
 * CreateUpdateQuotation is a component for creating or updating a quotation.
 *
 * @component
 * @param {object} props - The component's props.
 * @param {string} props.purpose - The purpose of the quotation, either 'create' or 'edit'.
 * @returns {JSX.Element} - Rendered CreateUpdateQuotation component.
 */

export const CreateUpdateQuotation = ({ purpose }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const generateFields = useGenerateFormItem();
  const [initialValues, setInitialValues] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { generalInfosFields, isFieldsLoading, checkedList } =
    useGeneralInfosFields(form, isLoading);
  const {
    setTotals,
    VATRates,
    accessories,
    services,
    materials,
    shapings,
    materialProvidedByCustomer,
    customerCoefficient,
    fileList,
    setFileList,
    setGlobalMaterial,
    setCheckedList,
    setVolumesArray,
    setGlobalCommission,
    setDiscount,
    setCustomerCoefficient,
    setPickedTypeOfWork,
    setSelectedRate
  } = useQuotationContext();

  const [number, setNumber] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const { setPickedCustomer, setPickedProject } = useMasterContext();

  const showDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const config = {
    onGetResource: {
      setFields: (data) => {
        setDiscount(data.summary.price_conditions?.discount);
        setGlobalCommission(data.customer?.commission);
        setPickedCustomer(data?.customer);
        setCustomerCoefficient(data.customer?.coefficient);
        setPickedProject(data?.project);
        setGlobalMaterial(data.material_configuration?.principal_matter);
        setVolumesArray(data?.materials);
        return formatedData(data);
      }
    }
  };

  const updateQuotation = async (body) => {
    setIsSubmitting(true);
    const formData = new FormData();
    if (fileList.length) {
      fileList.forEach((file) => {
        formData.append('VAT_attestation', file);
      });
    }

    const newBody = formatedDataCreate(body);

    formData.append(
      'values',
      JSON.stringify({
        ...newBody,
        locale: moment.locale()
      })
    );
    try {
      const { data } = await dispatchAPI('PATCH', {
        url: `quotations/${id}`,
        body: formData
      });
      setFileList([]);
      navigate(`${routes.QUOTATIONS}/show/${data._id}`);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  useEffect(
    () => () => {
      setPickedCustomer();
      setPickedProject();
    },
    []
  );

  const calculateTotals = () => {
    const fieldsValues = form.getFieldsValue();
    const rawTotals = quotationCalculations(
      fieldsValues,
      accessories,
      VATRates,
      form,
      services,
      materials,
      shapings,
      materialProvidedByCustomer,
      customerCoefficient,
      initialValues
    );
    return setTotals(rawTotals);
  };

  const createQuotation = async (body) => {
    const newBody = formatedDataCreate(body);
    setIsSubmitting(true);

    const formData = new FormData();

    if (fileList.length) {
      fileList.forEach((file) => {
        formData.append('VAT_attestation', file);
      });
    }

    formData.append(
      'values',
      JSON.stringify({
        ...newBody,
        locale: moment.locale()
      })
    );
    try {
      const { data } = await dispatchAPI('POST', {
        url: `quotations`,
        body: formData
      });
      setFileList([]);
      navigate(`${routes.QUOTATIONS}/show/${data._id}`);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  const getQuotation = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `quotations/${id}?populate=customer,project,global_VAT_rate`
      });

      const dataFormated = formatedDataUpdate(data);

      form.setFieldsValue(config?.onGetResource?.setFields(dataFormated));
      setNumber(dataFormated?.number);
      setCheckedList(dataFormated?.type_of_quotation);
      setInitialValues(dataFormated);
      setPickedTypeOfWork(dataFormated?.type_of_work);
      setSelectedRate(dataFormated?.global_VAT_rate);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [purpose, id, isFieldsLoading]);

  useEffect(() => {
    if (purpose === 'edit' && id) {
      setIsLoading(true);

      (async () => {
        await getQuotation();
      })();
    }
  }, [getQuotation]);

  const handleSubmit = async (values) => {
    if (purpose === 'edit') await updateQuotation(values);
    if (purpose === 'create') await createQuotation(values);
  };

  const handleOnFieldsChange = () => {
    const formValues = form.getFieldValue();
    const volumesArrayToBeSet = formValues.materials;
    setVolumesArray(volumesArrayToBeSet);
    calculateTotals();
  };

  const title = () => {
    if (purpose === 'create') {
      return t(`quotations.form.title.create`);
    }
    return t(`quotations.form.title.edit`, { number });
  };

  return (
    <>
      <PageHeaderCustom title={title()} />
      <Spin spinning={isLoading}>
        <ContentCustom>
          <Form
            {...drawerFormLayout}
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            onFieldsChange={(_, allFields) => {
              handleOnFieldsChange(allFields);
            }}
          >
            <Card
              title={t('quotations.form.general_infos')}
              style={{ marginBottom: 8 }}
            >
              <Form.Item className="custom-form-item-quotations-general-infos">
                {generalInfosFields.map((field) =>
                  generateFields('quotations', field)
                )}
              </Form.Item>
            </Card>
            <Collapse>
              {checkedList?.includes('SHAPING-MATERIALS') && (
                <Panel key="1" header={t('quotations.form.materials.title')}>
                  <MaterialShapingForm form={form} />
                </Panel>
              )}
              {checkedList?.includes('SERVICES') && (
                <Panel key="2" header={t('quotations.form.services.title')}>
                  <ServicesForm form={form} calculateTotals={calculateTotals} />
                </Panel>
              )}
              {checkedList?.includes('ACCESSORIES') && (
                <Panel key="3" header={t('quotations.form.accessories.title')}>
                  <AccessoriesForm form={form} />
                </Panel>
              )}
            </Collapse>
            <Card
              title={t('quotations.form.summary.title')}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              <SummaryForm form={form} />
            </Card>
            <Form.Item
              {...tailFormItemLayout}
              style={{
                position: 'sticky',
                bottom: 0,
                zIndex: 1,
                backgroundColor: '#fff',
                padding: '20px',
                borderTop: '1px solid #e8e8e8'
              }}
            >
              <Row justify="end">
                <Button
                  style={{ margin: '0 10px' }}
                  type="link"
                  danger
                  onClick={() => navigate(-1)}
                >
                  {`${t('buttons.cancel')} `}
                  <CloseOutlined />
                </Button>
                <Button type="add" htmlType="submit" loading={isSubmitting}>
                  {`${t('buttons.save')} `}
                  <CheckOutlined />
                </Button>
              </Row>
            </Form.Item>
            <QuotationDrawer
              showDrawer={showDrawer}
              openDrawer={openDrawer}
              form={form}
            />
          </Form>
        </ContentCustom>
      </Spin>
      <LoadingModal open={isSubmitting} />
    </>
  );
};

CreateUpdateQuotation.propTypes = {
  purpose: PropTypes.string.isRequired
};
