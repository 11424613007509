import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Typography,
  message
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { outOfNavRoutes } from '../../utils/constants/adminRoutes';
import Logo from '../../assets/images/logomarbrerie.svg';
import { usePwdPattern } from '../../utils/pwdPattern';

const { Title } = Typography;

/**
 * @component
 * Component for creating a password via email.
 * @param {object} props - Component props.
 * @param {Function} props.switchForm - Callback to switch form.
 * @returns {JSX.Element} The JSX element representing the ForgotPwdForm component.
 */
const PostPwdForm = () => {
  const { t } = useTranslation();
  const { urlToken } = useParams();
  const { dispatchAPI } = useAuthContext();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const postPwd = async (body) => {
    setLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `users/post-pwd/${urlToken}`,
        body
      });
      message.success(t('login.post-pwd'));
      return navigate(outOfNavRoutes.LOGIN);
    } catch (e) {
      if (e) message.error(t('login.postPwdEmailError'));
      return setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    await postPwd(values);
  };

  return (
    <Layout className="login-layout">
      <Row>
        <Col
          xs={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 7 }}
          xxl={{ span: 7 }}
          className="login-form-col"
        >
          <img
            style={{
              width: 240,
              marginBottom: 56
            }}
            alt="Logo"
            src={Logo}
          />
          <Title className="login-title">{t(`login.title.post-pwd`)}</Title>
          <Form onFinish={handleSubmit}>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: t('login.pwdMissing') },
                ...usePwdPattern()
              ]}
              hasFeedback
            >
              <Input.Password
                prefix={
                  <LockOutlined
                    style={{ color: 'var(--textColorSecondary)' }}
                  />
                }
                placeholder={t('login.password')}
              />
            </Form.Item>
            <Form.Item
              dependencies={['password']}
              hasFeedback
              name="confirm"
              rules={[
                {
                  required: true,
                  message: t('login.pwdMissing')
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(t('login.pwdNotMatching'));
                  }
                })
              ]}
            >
              <Input.Password
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder={t('login.confirmPassword')}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: '100%' }}
                loading={isLoading}
              >
                {t('login.createPwd')}
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="link"
                block
                style={{ width: '100%' }}
                onClick={() => navigate(`${outOfNavRoutes.LOGIN}`)}
              >
                {t('buttons.back')}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Layout>
  );
};

export default PostPwdForm;
