import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { ListResource } from '../../components';
import { useTemplateContext } from '../../contexts/TemplatesContext';
import { useDownloadDocument } from '../../utils/downloadDoc';

const useTemplateColumns = () => {
  const { deleteResource } = useTemplateContext();
  const { downloadDocument } = useDownloadDocument();
  const { t } = useTranslation();
  return [
    {
      title: t('template-imports.column.name'),
      dataIndex: 'name',
      sorter: true,
      render: (name) => name || t('errors.form.not_specified')
    },
    {
      title: t('template-imports.column.format'),
      dataIndex: 'file',
      sorter: true,
      render: ({ contentType }) => contentType || t('errors.form.not_specified')
    },
    {
      key: 'action',
      align: 'right',
      render: (_, data) => (
        <>
          <Button type="link" onClick={() => downloadDocument(data?.file)}>
            <DownloadOutlined style={{ fontSize: 18 }} />
          </Button>
          <Button type="link" onClick={() => deleteResource(data)}>
            <DeleteOutlined style={{ fontSize: 18 }} />
          </Button>
        </>
      )
    }
  ];
};

export const Templates = () => {
  const columns = useTemplateColumns();
  const { refreshTemplate } = useTemplateContext();

  return (
    <ListResource
      columns={columns}
      forceRefresh={refreshTemplate}
      resourceName="template-imports"
      onDoubleClickAction={false}
      populate="file"
      customActionColumn
      withCreateButton
      withUploadButton={false}
    />
  );
};
