import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useDownloadDocument } from '../../utils/downloadDoc';
import { useErrorMessage } from '../../utils/errorMessage';

export const DownloadFileButtons = ({ file, name }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { downloadDocument } = useDownloadDocument();

  const handleDownload = async () => {
    setIsLoading(true);
    try {
      await downloadDocument(file);
    } catch (error) {
      message(error);
    }
    setIsLoading(false);
  };

  return (
    <Button
      loading={isLoading}
      icon={<DownloadOutlined style={{ fontSize: 18 }} />}
      type="link"
      onClick={() => handleDownload(file)}
    >
      {t(name || 'buttons.template')}
    </Button>
  );
};

DownloadFileButtons.propTypes = {
  file: PropTypes.shape({}).isRequired,
  name: PropTypes.string
};

DownloadFileButtons.defaultProps = {
  name: null
};
