import { Form, Button, Tabs, Row, Col, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CloseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { VolumeItem } from './VolumeItem';

const fontSize = 18;

export const VolumeForm = ({
  finishingProducts,
  projectTypes,
  roomTypes,
  materialProvidedByCustomer,
  form
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('0');

  return (
    <Form.List name={['materials']}>
      {(fields, { add, remove }) => (
        <Tabs
          type="card"
          activeKey={activeTab}
          className="custom-tabs-volume-form"
          onChange={(activeKey) => {
            setActiveTab(activeKey);
          }}
          tabBarExtraContent={
            <Button
              type="dashed"
              onClick={() => {
                add();
                setActiveTab(`${fields.length}`);
              }}
              icon={<PlusOutlined />}
            >
              {t('quotations.form.materials.add_volume')}
            </Button>
          }
        >
          {fields.map(({ key, name, ...restField }) => (
            <Tabs.TabPane
              tab={
                <Row>
                  <Col>{`${t('quotations.form.materials.volumes')} ${
                    name + 1
                  }`}</Col>
                  <Col
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: '8px'
                    }}
                  >
                    <Popconfirm
                      title={t('datatable.column.action.delete.title')}
                      okText={t('datatable.column.action.delete.ok')}
                      okButtonProps={{ type: 'danger' }}
                      cancelText={t('datatable.column.action.delete.cancel')}
                      onConfirm={() => remove(name)}
                      icon={<DeleteOutlined />}
                    >
                      <CloseOutlined
                        style={{ color: '#b51010', fontSize }}
                        type="delete"
                      />
                    </Popconfirm>
                  </Col>
                </Row>
              }
              key={key}
            >
              <VolumeItem
                key={key}
                name={name}
                materialProvidedByCustomer={materialProvidedByCustomer}
                restField={restField}
                finishingProducts={finishingProducts}
                projectTypes={projectTypes}
                roomTypes={roomTypes}
                form={form}
              />
            </Tabs.TabPane>
          ))}
        </Tabs>
      )}
    </Form.List>
  );
};

VolumeForm.propTypes = {
  finishingProducts: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      denomination: PropTypes.string
    })
  ),
  projectTypes: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      denomination: PropTypes.string
    })
  ),
  roomTypes: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      denomination: PropTypes.string
    })
  ),
  materialProvidedByCustomer: PropTypes.bool,
  form: PropTypes.shape({})
};

VolumeForm.defaultProps = {
  finishingProducts: null,
  projectTypes: null,
  roomTypes: null,
  materialProvidedByCustomer: undefined,
  form: null
};
