import { getItemById } from './utils/getItemById';
import { createItemsMap } from './utils/createItemsMap';
import { formatNumberWithSpaces } from '../../../utils/formatters/formatNumberWithSpaces';

/**
 * Calculate the total for accessories based on the given fields.
 *
 * @function
 * @param {Array} fields - The accessory fields to consider for calculation.
 * @param {Array} accessories - The list of available accessories.
 * @param {Array} rates - The available VAT rates.
 * @param {Object} form - The form object of the Form instance.

 * @returns {Object} - An object containing the totals for the base and option.
 */
export const calculateAccessoriesTotal = (fields, accessories, rates, form) => {
  /**
   * @property {number} total - The total accessory prices.
   * @property {number} VATtotal - The total VAT for accessories.
   * @property {number} totalWithVAT - The total with VAT for accessories.
   */
  const base = {
    total: 0,
    VATtotal: 0,
    totalWithVAT: 0
  };
  const option = {
    ...base
  };

  const baseVatSums = {};
  const optionVatSums = {};

  if (fields && fields.length > 0) {
    const accessoriesMap = createItemsMap(accessories);

    fields.forEach((currentAccessory, i) => {
      if (currentAccessory) {
        const target = currentAccessory.is_option ? option : base;
        const item = accessoriesMap[currentAccessory.accessory_catalog];

        if (item) {
          const quantity = currentAccessory.quantity || 0;
          const vatRate = getItemById(rates, currentAccessory.VAT_rate);
          const vat = vatRate?.percentage;

          const accessoryPrice = item.price || 0;
          const accessoryTotal = accessoryPrice * quantity;
          const accessoryVATAmount = accessoryTotal * (vat / 100);
          const accessoryTotalWithVAT = accessoryTotal + accessoryVATAmount;

          if (
            !Number.isNaN(accessoryVATAmount) &&
            accessoryVATAmount !== 0 &&
            vat !== 0
          ) {
            if (target === base) {
              if (
                !Object.prototype.hasOwnProperty.call(accessoryVATAmount, vat)
              ) {
                baseVatSums[vat] = 0;
              }

              baseVatSums[vat] += accessoryVATAmount;
            }
            if (target === option) {
              if (!Object.prototype.hasOwnProperty.call(optionVatSums, vat)) {
                optionVatSums[vat] = 0;
              }

              optionVatSums[vat] += accessoryVATAmount;
            }
          }

          form.setFields([
            {
              name: ['accessories', i, 'facial_value'],
              value: formatNumberWithSpaces(Number(accessoryTotal))
            },
            {
              name: ['accessories', i, 'all_included_value'],
              value: formatNumberWithSpaces(Number(accessoryTotalWithVAT))
            },
            {
              name: ['accessories', i, 'quantity'],
              value: formatNumberWithSpaces(Number(quantity))
            }
          ]);

          target.total += Number(Number(accessoryTotal).toFixed(2));
          target.VATtotal += Number(Number(accessoryVATAmount).toFixed(2));
          target.totalWithVAT += Number(
            Number(accessoryTotalWithVAT).toFixed(2)
          );
        }
      }
    });
  }

  return {
    base,
    option,
    baseVatSums,
    optionVatSums
  };
};
