import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Planning } from '../routes/planning/Planning';
import { OrderContextProvider } from '../contexts/OrderContext';

const PlanningRouter = () => (
  <OrderContextProvider>
    <Routes>
      <Route index element={<Planning />} />
    </Routes>
  </OrderContextProvider>
);

export default PlanningRouter;
