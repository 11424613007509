import { Input, Select } from 'antd';

const { Option } = Select;

/**
 * Creates an array of field objects used for edition forms.
 *
 * @param {Array} VATRates - An array of VAT rates for the Select input.
 * @returns {Array} An array of field objects.
 */
export const useEditionFields = (VATRates) => [
  {
    name: ['body_infos', 'label']
  },
  {
    name: ['totals', 'HT_total'],
    input: <Input type="number" />
  },
  {
    name: ['totals', 'VAT_rate'],
    input: (
      <Select>
        {(VATRates || []).map((rate) => (
          <Option
            key={rate._id}
            value={rate._id}
          >{`${rate.percentage}%`}</Option>
        ))}
      </Select>
    )
  },
  {
    name: ['totals', 'VAT_total'],
    input: <Input type="number" disabled />
  },
  {
    name: ['totals', 'all_included_total'],
    input: <Input type="number" disabled />
  }
];
