import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Popconfirm, Skeleton, Space, Tag, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  ContainerOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { ContentCustom } from '../../../../components/ContentCustom/ContentCustom';
import { routes, subRoutes } from '../../../../utils/constants/adminRoutes';
import { DescriptionList } from '../../../../components/DescriptionList/DescriptionList';
import { useListContent } from './listContent';
import { PageHeaderCustom } from '../../../../components/PageHeader/PageHeader';
import { colorTag } from '../../../../utils/constants/tagColors';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { PhotosManagement } from './PhotosManagement';
import { ReactivationButton } from '../../reactivationButton/ReactivationButton';
import { SlicesManagementTable } from './SlicesManagement/SlicesManagementTable';

const iconSize = 18;

/**
 * Component for displaying detailed information about a material.
 * Allows editing, archiving, reactivating, and managing photos.
 *
 * @component
 *
 * @returns {JSX.Element} The rendered ShowMaterial component.
 */
const ShowMaterial = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const navigate = useNavigate();

  const [material, setMaterials] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [photos, setPhotos] = useState([]);

  const fields = useListContent(material || {});

  const forceRefreshData = () => setForceRefresh(!forceRefresh);

  const fetchData = useCallback(async () => {
    setIsloading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/materials/${id}?populate=category,supplier,finishing_product,slices_management.unit_measure`
      });
      setMaterials(data);
      const photosArray = [];
      data.slices_management.map((slice) =>
        slice.photos.map((photo, index) =>
          photosArray.push({
            title: slice.nomenclature,
            photo,
            sliceID: slice._id,
            index
          })
        )
      );
      setPhotos(photosArray);
    } catch (e) {
      message(e);
    }
    setIsloading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [fetchData, forceRefresh]);

  const deletePrice = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/materials/${id}`,
        body: {
          status: 'ARCHIVED'
        }
      });
      navigate(-1);
    } catch (e) {
      message(e);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={`${t(`materials.show.title`)}`}
        extra={
          <div>
            {material.status !== 'ARCHIVED' ? (
              <>
                <Link
                  to={{
                    pathname: `${routes.CATALOG}${subRoutes.CATALOG.MATERIALS}/edit/${id}`
                  }}
                >
                  <Button type="primary">
                    <EditOutlined />
                    {`${t('buttons.edit')} `}
                  </Button>
                </Link>
                <Popconfirm
                  title={t('datatable.column.action.archive.title')}
                  okText={t('datatable.column.action.archive.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.archive.cancel')}
                  onConfirm={deletePrice}
                  icon={<WarningOutlined />}
                >
                  <Button danger style={{ marginLeft: 8 }}>
                    <ContainerOutlined
                      style={{ color: 'var(--errorColor)', fontSize: iconSize }}
                      type="delete"
                    />
                    {t('buttons.archive')}
                  </Button>
                </Popconfirm>
              </>
            ) : (
              <Space>
                <ReactivationButton
                  resourceName="materials"
                  forceRefresh={forceRefresh}
                  setForceRefresh={setForceRefresh}
                />
                <Tag color={colorTag.ARCHIVED}>
                  {t('customers.tags.archived')}
                </Tag>
              </Space>
            )}
          </div>
        }
      />
      <ContentCustom>
        <Row gutter={16}>
          <Col span={16}>
            <Skeleton loading={isLoading} active>
              <Card
                title={t('materials.show.infos')}
                style={{ marginBottom: 16 }}
                extra={
                  <Button
                    type="link"
                    onClick={() =>
                      navigate(
                        `${routes.CATALOG}${subRoutes.CATALOG.MATERIALS}/edit/${id}`
                      )
                    }
                    icon={<EditOutlined />}
                  />
                }
              >
                <DescriptionList data={fields} translate />
              </Card>
            </Skeleton>
          </Col>
          <Col span={8}>
            <Skeleton loading={isLoading} active>
              <Card
                title={t('materials.show.remarks')}
                style={{ marginBottom: 16 }}
              >
                {material?.remarks || ''}
              </Card>
            </Skeleton>
          </Col>
        </Row>

        <PhotosManagement
          photos={photos}
          forceRefresh={forceRefresh}
          setForceRefresh={setForceRefresh}
          id={id}
          slices={material.slices_management}
          setPhotos={setPhotos}
        />
        <Skeleton loading={isLoading} active>
          <SlicesManagementTable
            id={id}
            datas={material.slices_management || []}
            forceRefresh={forceRefreshData}
          />
        </Skeleton>
      </ContentCustom>
    </>
  );
};

export default ShowMaterial;
