import { Switch } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * A custom hook that provides column configurations for unit of measures.
 *
 * @hook
 * @returns {Array} An array containing column configurations for unit of measures.
 */
export const useUnitOfMeasuresColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('unit-of-measures.form.title'),
      dataIndex: 'title',
      editable: true,
      render: (title) => title,
      width: '25%'
    },
    {
      title: t('unit-of-measures.form.unit'),
      dataIndex: 'unit',
      editable: true,
      render: (unit) => unit,
      width: '25%'
    },
    {
      title: t('unit-of-measures.form.display'),
      dataIndex: ['display'],
      editable: true,
      width: '10%',
      render: (display) => <Switch disabled={false} checked={display} />
    }
  ];
};
