export const headers = [
  {
    label: 'nomenclature',
    key: 'nomenclature',
    display: true,
    required: true
  },
  {
    label: 'category',
    key: 'category.title',
    display: true,
    required: true
  },
  {
    label: 'price',
    key: 'price',
    display: true,
    required: true
  },
  {
    label: 'supplier',
    key: 'supplier.denomination',
    display: true,
    required: true
  }
];
