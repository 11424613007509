import React, { useEffect } from 'react';
import { Typography, Form, Select, message } from 'antd';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../../contexts/AuthContext';

const { Item } = Form;
const { Option } = Select;
const { Title } = Typography;

const WFLInputForm = ({
  title,
  collections,
  trigger,
  workflow,
  setOutputs
}) => {
  const collectionTitles = Object.keys(collections);
  const { dispatchAPI } = useAuthContext();

  // if trigger is PROCESS, get output from /workflows/id/input
  useEffect(() => {
    if (workflow) {
      if (trigger === 'PROCESS') {
        // get output from /workflows/id/input
        dispatchAPI('GET', {
          url: `/workflows/${workflow}/inputs`
        })
          .then((res) => {
            const { data } = res;

            setOutputs(data);
          })
          .catch((e) => {
            if (e.response) message(e.response.status);
          });
      }
    }
  }, [trigger]);

  return (
    <>
      <Title level={5}>{title}</Title>
      <Item label="Action" name="trigger" rules={[{ required: true }]}>
        <Select>
          <Option key="POST" value="POST">
            POST
          </Option>
          <Option key="DELETE" value="DELETE">
            DELETE
          </Option>
          <Option key="PATCH" value="PATCH">
            PATCH
          </Option>
          <Option key="GET" value="GET">
            GET
          </Option>
          <Option key="PROCESS" value="PROCESS">
            PROCESS (autre processus)
          </Option>
        </Select>
      </Item>
      {
        // required only if trigger is not PROCESS
        trigger !== 'PROCESS' && (
          <Item
            label="Collection"
            name="collection"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {collectionTitles !== [] &&
                collectionTitles.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
            </Select>
          </Item>
        )
      }
    </>
  );
};

WFLInputForm.propTypes = {
  title: PropTypes.string.isRequired,
  collections: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  trigger: PropTypes.oneOf(['POST', 'DELETE', 'PATCH', 'GET', 'PROCESS'])
    .isRequired,
  workflow: PropTypes.string.isRequired,
  setOutputs: PropTypes.func.isRequired
};
export default WFLInputForm;
