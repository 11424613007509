import React from 'react';
import PropTypes from 'prop-types';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';

/**
 * Component for creating or updating a service.
 *
 * @component
 *
 * @param {Object} props - Component props.
 * @param {string} props.purpose - The purpose of the component (create or update).
 * @returns {JSX.Element} JSX element representing the CreateUpdateService component.
 */
const CreateUpdateService = ({ purpose }) => {
  const { basicFields, isFieldsLoading } = useFields();

  return (
    <CreateUpdateContainer
      fields={basicFields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="services"
      resource="services"
    />
  );
};

CreateUpdateService.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateService;
