import moment from 'moment';
import { formatNumberWithSpaces } from '../../../../utils/formatters/formatNumberWithSpaces';

/**
 * The `formatQuotationsData` function is used to format the data of a quote.
 *
 * @function
 * @param {Object} data - The object containing the quote data.
 * @param {Object} data.customer - The object containing the customer information.
 * @param {Object} data.creation_date - The object containing the creation date of the quote.
 * @param {Object} data.validity_date - The object containing the validity date of the quote.
 * @param {Object} data.global_VAT_rate - The object containing the global VAT rate.
 * @param {Object} data.previsional_works_start_date - The object containing the provisional start date of the works.
 * @param {Object} data.summary - The object containing the totals of the quote.
 *
 * @returns {Object} Returns an object containing the formatted data of the quote.
 */
export const formatedData = (data) => {
  const {
    customer = {},
    creation_date = {},
    validity_date = {},
    global_VAT_rate = {},
    previsional_works_start_date = {},
    summary = {}
  } = data;
  return {
    ...data,
    customer: customer._id,
    customer_coefficient: customer.coefficient,
    project: data?.project?._id,
    creation_date: creation_date && moment(creation_date),
    validity_date: validity_date && moment(validity_date),
    global_VAT_rate: global_VAT_rate?._id,
    previsional_works_start_date:
      previsional_works_start_date && moment(previsional_works_start_date),
    summary: {
      ...summary,
      base_total: {
        ...summary?.base_total,
        all_included_total: formatNumberWithSpaces(
          Number(summary?.base_total?.all_included_total)
        ),
        VAT_total: formatNumberWithSpaces(
          Number(summary?.base_total?.VAT_total)
        ),
        accessories_total: formatNumberWithSpaces(
          Number(summary?.base_total?.accessories_total)
        ),
        commission_total: formatNumberWithSpaces(
          Number(summary?.base_total?.commission_total)
        ),
        customer_coefficient_total: formatNumberWithSpaces(
          Number(summary?.base_total?.customer_coefficient_total)
        ),
        discounted_total: formatNumberWithSpaces(
          Number(summary?.base_total?.discounted_total)
        ),
        facial_values_total: formatNumberWithSpaces(
          Number(summary?.base_total?.facial_values_total)
        ),
        materials_total: formatNumberWithSpaces(
          Number(summary?.base_total?.materials_total)
        ),
        services_total: formatNumberWithSpaces(
          Number(summary?.base_total?.services_total)
        ),
        shapings_total: formatNumberWithSpaces(
          Number(summary?.base_total?.shapings_total)
        ),
        total_excluding_VAT: formatNumberWithSpaces(
          Number(summary?.base_total?.total_excluding_VAT)
        ),
        unprocessed_values_total: formatNumberWithSpaces(
          Number(summary?.base_total?.unprocessed_values_total)
        )
      },
      option_total: {
        ...summary?.option_total,
        all_included_total: formatNumberWithSpaces(
          Number(summary?.option_total?.all_included_total)
        ),
        VAT_total: formatNumberWithSpaces(
          Number(summary?.option_total?.VAT_total)
        ),
        accessories_total: formatNumberWithSpaces(
          Number(summary?.option_total?.accessories_total)
        ),
        commission_total: formatNumberWithSpaces(
          Number(summary?.option_total?.commission_total)
        ),
        customer_coefficient_total: formatNumberWithSpaces(
          Number(summary?.option_total?.customer_coefficient_total)
        ),
        discounted_total: formatNumberWithSpaces(
          Number(summary?.option_total?.discounted_total)
        ),
        facial_values_total: formatNumberWithSpaces(
          Number(summary?.option_total?.facial_values_total)
        ),
        materials_total: formatNumberWithSpaces(
          Number(summary?.option_total?.materials_total)
        ),
        services_total: formatNumberWithSpaces(
          Number(summary?.option_total?.services_total)
        ),
        shapings_total: formatNumberWithSpaces(
          Number(summary?.option_total?.shapings_total)
        ),
        total_excluding_VAT: formatNumberWithSpaces(
          Number(summary?.option_total?.total_excluding_VAT)
        ),
        unprocessed_values_total: formatNumberWithSpaces(
          Number(summary?.option_total?.unprocessed_values_total)
        )
      }
    }
  };
};

/**
 * The `formatedDataCreate` function is used to format the data of a quote.
 *
 * @function
 * @param {Object} data - The object containing the quote data.
 * @param {Array} data.accessories - The array containing the accessories information.
 * @param {Array} data.materials - The array containing the materials information.
 * @param {Array} data.services - The array containing the services information.
 * @param {Array} data.shapings - The array containing the shapings information.
 * @param {Object} data.summary - The object containing the totals of the quote.
 *
 * @returns {Object} Returns an object containing the formatted data of the quote.
 */
export const formatedDataCreate = (data) => {
  const {
    accessories = [],
    materials = [],
    services = [],
    summary = {}
  } = data;

  return {
    ...data,
    accessories: accessories?.map((accessory) => ({
      ...accessory,
      all_included_value: Number(
        accessory?.all_included_value?.replace(/\s/g, '')
      ),
      facial_value: Number(accessory?.facial_value?.replace(/\s/g, '')),
      quantity: Number(accessory?.quantity?.replace(/\s/g, ''))
    })),
    materials: materials?.map((material) => ({
      ...material,
      all_included_value: Number(
        material?.all_included_value?.toString()?.replace(/\s/g, '')
      ),
      commission: Number(material?.commission?.toString()?.replace(/\s/g, '')),
      customer_coefficient_value: Number(
        material?.customer_coefficient_value?.toString()?.replace(/\s/g, '')
      ),
      facial_value: Number(
        material?.facial_value?.toString()?.replace(/\s/g, '')
      ),
      surface: Number(material?.surface?.toString()?.replace(/\s/g, '')),
      unprocessed_value: Number(
        material?.unprocessed_value?.toString()?.replace(/\s/g, '')
      ),
      shapings: material?.shapings?.map((shaping) => ({
        ...shaping,
        all_included_value: Number(
          shaping?.all_included_value?.toString()?.replace(/\s/g, '')
        ),
        customer_coefficient_value: Number(
          shaping?.customer_coefficient_value?.toString()?.replace(/\s/g, '')
        ),
        facial_value: Number(
          shaping?.facial_value?.toString()?.replace(/\s/g, '')
        ),
        quantity: Number(shaping?.quantity?.toString()?.replace(/\s/g, '')),
        unprocessed_value: Number(
          shaping?.unprocessed_value?.toString()?.replace(/\s/g, '')
        )
      }))
    })),
    services: services?.map((service) => ({
      ...service,
      all_included_value: Number(
        service?.all_included_value?.toString()?.replace(/\s/g, '')
      ),
      commission: Number(service?.commission),
      customer_coefficient_value: Number(
        service?.customer_coefficient_value?.toString()?.replace(/\s/g, '')
      ),
      facial_value: Number(
        service?.facial_value?.toString()?.replace(/\s/g, '')
      ),
      unprocessed_value: Number(
        service?.unprocessed_value?.toString()?.replace(/\s/g, '')
      )
    })),
    summary: {
      ...summary,
      base_total: {
        ...summary?.base_total,
        all_included_total: Number(
          summary?.base_total?.all_included_total
            ?.toString()
            ?.replace(/\s/g, '')
        ),
        VAT_total: Number(
          summary?.base_total?.VAT_total?.toString()?.replace(/\s/g, '')
        ),
        accessories_total: Number(
          summary?.base_total?.accessories_total?.toString()?.replace(/\s/g, '')
        ),
        commission_total: Number(
          summary?.base_total?.commission_total?.toString()?.replace(/\s/g, '')
        ),
        customer_coefficient_total: Number(
          summary?.base_total?.customer_coefficient_total
            ?.toString()
            ?.replace(/\s/g, '')
        ),
        discounted_total: Number(
          summary?.base_total?.discounted_total?.toString()?.replace(/\s/g, '')
        ),
        facial_values_total: Number(
          summary?.base_total?.facial_values_total
            ?.toString()
            ?.replace(/\s/g, '')
        ),
        materials_total: Number(
          summary?.base_total?.materials_total?.toString()?.replace(/\s/g, '')
        ),
        services_total: Number(
          summary?.base_total?.services_total?.toString()?.replace(/\s/g, '')
        ),
        shapings_total: Number(
          summary?.base_total?.shapings_total?.toString()?.replace(/\s/g, '')
        ),
        total_excluding_VAT: Number(
          summary?.base_total?.total_excluding_VAT
            ?.toString()
            ?.replace(/\s/g, '')
        ),
        unprocessed_values_total: Number(
          summary?.base_total?.unprocessed_values_total
            ?.toString()
            ?.replace(/\s/g, '')
        )
      },
      option_total: {
        ...summary?.option_total,
        all_included_total: Number(
          summary?.option_total?.all_included_total
            ?.toString()
            ?.replace(/\s/g, '')
        ),
        VAT_total: Number(
          summary?.option_total?.VAT_total?.toString()?.replace(/\s/g, '')
        ),
        accessories_total: Number(
          summary?.option_total?.accessories_total
            ?.toString()
            ?.replace(/\s/g, '')
        ),
        commission_total: Number(summary?.option_total?.commission_total),
        customer_coefficient_total: Number(
          summary?.option_total?.customer_coefficient_total
            ?.toString()
            ?.replace(/\s/g, '')
        ),
        discounted_total: Number(summary?.option_total?.discounted_total),
        facial_values_total: Number(
          summary?.option_total?.facial_values_total
            ?.toString()
            ?.replace(/\s/g, '')
        ),
        materials_total: Number(
          summary?.option_total?.materials_total?.toString()?.replace(/\s/g, '')
        ),
        services_total: Number(
          summary?.option_total?.services_total?.toString()?.replace(/\s/g, '')
        ),
        shapings_total: Number(
          summary?.option_total?.shapings_total?.toString()?.replace(/\s/g, '')
        ),
        total_excluding_VAT: Number(
          summary?.option_total?.total_excluding_VAT
            ?.toString()
            ?.replace(/\s/g, '')
        ),
        unprocessed_values_total: Number(
          summary?.option_total?.unprocessed_values_total
            ?.toString()
            ?.replace(/\s/g, '')
        )
      }
    }
  };
};

/**
 * The `formatedDataUpdate` function is used to format the data of a quote for updating.
 *
 * @function
 * @param {Object} data - The object containing the quote data.
 * @param {Array} data.accessories - The array containing the accessories information.
 * @param {Array} data.materials - The array containing the materials information.
 * @param {Array} data.services - The array containing the services information.
 * @param {Array} data.shapings - The array containing the shapings information.
 * @param {Object} data.summary - The object containing the totals of the quote.
 *
 * @returns {Object} Returns an object containing the formatted data of the quote for updating.
 */
export const formatedDataUpdate = (data) => {
  const {
    accessories = [],
    materials = [],
    services = [],
    summary = {}
  } = data;

  return {
    ...data,
    accessories: accessories?.map((accessory) => ({
      ...accessory,
      all_included_value: formatNumberWithSpaces(
        Number(accessory?.all_included_value)
      ),
      facial_value: formatNumberWithSpaces(Number(accessory?.facial_value)),
      quantity: formatNumberWithSpaces(Number(accessory?.quantity))
    })),
    materials: materials?.map((material) => ({
      ...material,
      all_included_value: formatNumberWithSpaces(
        Number(material?.all_included_value)
      ),
      commission: formatNumberWithSpaces(material?.commission),
      customer_coefficient_value: formatNumberWithSpaces(
        Number(material?.customer_coefficient_value)
      ),
      facial_value: formatNumberWithSpaces(Number(material?.facial_value)),
      surface: formatNumberWithSpaces(Number(material?.surface)),
      unprocessed_value: formatNumberWithSpaces(
        Number(material?.unprocessed_value)
      ),
      shapings: (material?.shapings || []).map((shaping) => ({
        ...shaping,
        all_included_value: formatNumberWithSpaces(shaping?.all_included_value),
        customer_coefficient_value: formatNumberWithSpaces(
          shaping?.customer_coefficient_value
        ),
        facial_value: formatNumberWithSpaces(shaping?.facial_value),
        quantity: formatNumberWithSpaces(shaping?.quantity),
        unprocessed_value: formatNumberWithSpaces(shaping?.unprocessed_value)
      }))
    })),
    services: services?.map((service) => ({
      ...service,
      all_included_value: formatNumberWithSpaces(
        Number(service?.all_included_value)
      ),
      commission: formatNumberWithSpaces(Number(service?.commission)),
      customer_coefficient_value: formatNumberWithSpaces(
        Number(service?.customer_coefficient_value)
      ),
      facial_value: formatNumberWithSpaces(Number(service?.facial_value)),
      unprocessed_value: formatNumberWithSpaces(
        Number(service?.unprocessed_value)
      )
    })),
    summary: {
      ...summary,
      base_total: {
        ...summary?.base_total,
        all_included_total: summary?.base_total?.all_included_total,
        VAT_total: summary?.base_total?.VAT_total,
        accessories_total: summary?.base_total?.accessories_total,
        commission_total: summary?.base_total?.commission_total,
        customer_coefficient_total:
          summary?.base_total?.customer_coefficient_total,
        discounted_total: summary?.base_total?.discounted_total,
        facial_values_total: summary?.base_total?.facial_values_total,
        materials_total: summary?.base_total?.materials_total,
        services_total: summary?.base_total?.services_total,
        shapings_total: summary?.base_total?.shapings_total,
        total_excluding_VAT: summary?.base_total?.total_excluding_VAT,
        unprocessed_values_total: summary?.base_total?.unprocessed_values_total
      },
      option_total: {
        ...summary?.option_total,
        all_included_total: summary?.option_total?.all_included_total,
        VAT_total: summary?.option_total?.VAT_total,
        accessories_total: summary?.option_total?.accessories_total,
        commission_total: summary?.option_total?.commission_total,
        customer_coefficient_total:
          summary?.option_total?.customer_coefficient_total,

        discounted_total: summary?.option_total?.discounted_total,
        facial_values_total: summary?.option_total?.facial_values_total,
        materials_total: summary?.option_total?.materials_total,
        services_total: summary?.option_total?.services_total,
        shapings_total: summary?.option_total?.shapings_total,
        total_excluding_VAT: summary?.option_total?.total_excluding_VAT,
        unprocessed_values_total:
          summary?.option_total?.unprocessed_values_total
      }
    }
  };
};
