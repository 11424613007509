import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';
import { Modal, Spin, Typography, Button, Dropdown } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ExportXLSX } from '../ExportXLSX/ExportXLSX';
import { ExportCSV } from '../ExportCSV/ExportCSV';

export const ExportButton = ({
  fileName,
  dataName,
  url,
  headers,
  formatter,
  populate,
  extraQuery,
  extraMenu
}) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [datas, setDatas] = useState([]);
  const [filteredHeaders, setFilteredHeaders] = useState([]);
  const { message } = useErrorMessage();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `${url}?${extraQuery ? `${extraQuery}&` : ''}${
          populate ? `populate=${populate}` : ''
        }`
      });
      if (data.length) setDatas(formatter ? data.map(formatter) : data);
      else setDatas(t('buttons.export.no-data'));
    } catch (e) {
      message(e);
      setDatas(t('buttons.export.no-data'));
    }
  }, [url, formatter, dataName, headers, populate]);

  useEffect(() => {
    if (headers) {
      const filtered = headers.filter((head) => head.display);
      setFilteredHeaders(filtered);
    }
  }, [headers]);

  const exportMenu = [
    {
      key: 'xlsx',
      label: (
        <ExportXLSX
          datas={datas}
          dataName={dataName}
          fileName={fileName}
          headers={filteredHeaders}
          fetchData={fetchData}
          setVisible={setVisible}
        />
      )
    },
    {
      key: 'csv',
      label: (
        <ExportCSV
          datas={datas}
          dataName={dataName}
          fileName={fileName}
          headers={filteredHeaders}
          fetchData={fetchData}
          setVisible={setVisible}
        />
      )
    }
  ];

  const menu = {
    items: [...extraMenu, ...(headers ? exportMenu : [])]
  };

  return (
    <>
      <Modal
        footer={false}
        open={visible}
        onCancel={() => setVisible(false)}
        bodyStyle={{ textAlign: 'center' }}
      >
        <Spin spinning size="large" style={{ margin: 16 }} />
        <br />
        <Typography.Text>{t('buttons.export.message-waiting')}</Typography.Text>
      </Modal>
      <Dropdown menu={menu}>
        <Button icon={<DownOutlined />} type="link">
          {t(`buttons.export.title`)}
        </Button>
      </Dropdown>
    </>
  );
};

ExportButton.propTypes = {
  fileName: PropTypes.string.isRequired,
  dataName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formatter: PropTypes.func,
  extraQuery: PropTypes.string,
  populate: PropTypes.string,
  extraMenu: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.element.isRequired
    })
  )
};

ExportButton.defaultProps = {
  formatter: null,
  extraQuery: null,
  populate: null,
  extraMenu: []
};
