import React from 'react';
import PropTypes from 'prop-types';
import { Result } from 'antd';

const ETLStepFinish = ({ description }) => (
  <Result status="success" title={description} />
);

ETLStepFinish.propTypes = {
  description: PropTypes.string.isRequired
};

export default ETLStepFinish;
