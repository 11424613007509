import { Row, Tag } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { customerTypes } from '../../utils/constants/tagColors';

/**
 * Custom hook to generate a list of customer details.
 *
 * This hook structures customer data in a format that's suitable for displaying in a list.
 * Each list item contains a label that describes the data and content that provides the data value.
 * If certain fields are missing or not specified, a default 'not specified' message is shown.
 *
 * @hook
 *
 * @param {Object} data - The customer data object.
 * @param {string} data.first_name - The customer's first name.
 * @param {string} data.last_name - The customer's last name.
 * @param {string} data.email - The customer's email.
 * @param {string} data.type - The type/category of the customer.
 * @param {Object} data.phone_number - The customer's phone number details.
 * @param {string} data.phone_number.number - Actual phone number.
 * @param {string} data.phone_number.country_code - The country code associated with the phone number.
 * @param {number} data.commission - Commission percentage (only for non-PRIVATE types).
 * @param {Object} data.billing_address - The customer's billing address.
 * @param {string} data.billing_address.street - Street of the billing address.
 * @param {string} data.billing_address.additional - Additional address details.
 * @param {string} data.billing_address.postal_code - Postal code of the address.
 * @param {string} data.billing_address.city - City of the address.
 * @param {string} data.business_provider - Business provider details.
 * @param {number} data.coefficient - Coefficient details.
 *
 * @returns {Array} - An array of objects with `label` and `content` properties to be used in a list display.
 */
export const useListContent = (data = {}) => {
  const { t } = useTranslation();
  const {
    first_name,
    last_name,
    email,
    type,
    phone_number,
    commission,
    billing_address,
    business_provider,
    coefficient,
    creation_date
  } = data;

  return [
    {
      label: 'customers.show.type',
      content:
        (type && (
          <Tag color={customerTypes[type]}>{t(`customers.form.${type}`)}</Tag>
        )) ||
        t('errors.form.not_specified')
    },
    {
      label: 'customers.show.last_name',
      content: last_name || t('errors.form.not_specified'),
      span: first_name ? 1 : 2
    },
    ...(first_name
      ? [
          {
            label: 'customers.show.first_name',
            content: first_name || t('errors.form.not_specified')
          }
        ]
      : []),
    {
      label: 'customers.show.phone_number',
      content:
        (phone_number?.number && (
          <div>
            {`${phone_number.country_code} ${phone_number.number.slice(1)}`}
          </div>
        )) ||
        t('errors.form.not_specified')
    },
    {
      label: 'customers.show.email',
      content: email || t('errors.form.not_specified')
    },
    {
      label: 'customers.show.business_provider',
      content: business_provider || t('errors.form.not_specified')
    },
    {
      label: 'customers.show.billing_address',
      span: 1,
      content:
        (billing_address && (
          <Row
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Row>{billing_address.street}</Row>
            {billing_address.additional && (
              <Row>{billing_address.additional}</Row>
            )}
            <Row>{`${billing_address.postal_code} ${billing_address.city}`}</Row>
          </Row>
        )) ||
        t('errors.form.not_specified')
    },
    {
      label: 'customers.show.coefficient',
      content: (coefficient && `${coefficient}%`) || '0%'
    },
    {
      label: 'customers.show.commission',
      content: (commission && `${commission}%`) || '0%'
    },
    {
      label: 'customers.show.creation_date',
      content:
        (creation_date && moment(creation_date).format('DD-MM-YYYY')) ||
        t('errors.form.not_specified')
    }
  ];
};
