import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Upload, Button, Select, Card, Space } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { useTemplateContext } from '../../contexts/TemplatesContext';

const { Dragger } = Upload;

export const CreateTemplate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { enumsTemplate } = useTemplateContext();
  const { message } = useErrorMessage();
  const [type, setType] = useState();
  const [category, setCategory] = useState();
  const [extension, setExtension] = useState('');
  const [fileData, setFileData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const postFile = async (body) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', body);
      const { data } = await dispatchAPI('POST', {
        url: 'files/',
        body: formData
      });

      if (data?.description?.fileId) {
        await dispatchAPI('POST', {
          url: 'template-import',
          body: {
            name: body?.name,
            type: category,
            file: data?.description?.fileId
          }
        });
      }
      navigate(-1);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  };

  const saveTemplate = () => {
    postFile(fileData);
  };

  const setRestriction = (value) => {
    switch (true) {
      case value === 'IMPORT_TEMPLATE':
        setExtension('.xlsx');
        break;
      case value === 'TEMPLATE':
        setExtension('.docx');
        break;

      default:
        setExtension('');
        break;
    }
  };

  useEffect(() => {
    if (type) setRestriction(type);
  }, [type]);

  const props = {
    name: 'file',
    multiple: false,
    disabled: !type,
    maxCount: 1,
    beforeUpload: (file) => {
      const fileExtension = file.name.split('.').pop();
      if (fileExtension === extension.split('.').pop()) {
        setFileData(file);
        return false;
      }
      message('templates.extension', 6);
      return true;
    },
    data: {
      type
    },
    showUploadList: {
      showRemoveIcon: false
    }
  };

  return (
    <>
      <PageHeaderCustom title={t('template-imports.create.title')} />
      <ContentCustom>
        <Card>
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Row justify="center" gutter={[16, 16]}>
              <Col>{t(`template-imports.create.category`)}</Col>
              <Col span={8}>
                <Space wrap style={{ width: '100%' }}>
                  <Select
                    options={(enumsTemplate?.types || []).map((el) => ({
                      label: t(`template-imports.enums.${el}`),
                      value: el
                    }))}
                    placeholder="Catégorie du fichier"
                    style={{ minWidth: 200 }}
                    onSelect={(value) => setType(value)}
                  />
                  {type === 'IMPORT_TEMPLATE' && (
                    <Select
                      options={(enumsTemplate?.category || [])
                        .slice(1)
                        .map((el) => ({
                          label: t(`template-imports.enums.${el}`),
                          value: el
                        }))}
                      allowClear
                      placeholder="Sous-catégorie"
                      style={{ minWidth: 200 }}
                      onSelect={(value) => setCategory(value)}
                      onClear={() => setCategory(null)}
                    />
                  )}
                </Space>
              </Col>
            </Row>

            <Row justify="center" gutter={[16, 16]}>
              <Col>{t(`template-imports.create.fileName`)}</Col>
              <Col span={8}>
                <Dragger accept={extension} {...props}>
                  <Row justify="center">
                    <InboxOutlined
                      style={{ fontSize: 50 }}
                      className="ant-upload-drag-icon"
                    />
                  </Row>
                  <Row justify="center">{t('files.create.action')}</Row>
                  <Row justify="center">
                    {t('files.create.formats', {
                      type:
                        extension &&
                        t('files.create.exemple', {
                          name: extension
                        })
                    })}
                  </Row>
                </Dragger>
              </Col>
            </Row>
          </Space>
        </Card>
        <Row justify="center" gutter={[16, 16]}>
          <Button type="link" onClick={() => navigate(-1)}>
            {t('buttons.cancel')}
          </Button>
          <Button
            loading={isLoading}
            disabled={!fileData}
            type="primary"
            onClick={saveTemplate}
          >
            {t('buttons.done')}
          </Button>
        </Row>
      </ContentCustom>
    </>
  );
};
