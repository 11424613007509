import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Tag, Tooltip, Modal, Button } from 'antd';
import { ListResource } from '../../../components';

const ListWorkflowExecution = () => {
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentLogs, setCurrentLogs] = useState([]);

  const showModal = (logs) => {
    setCurrentLogs(logs);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const headers = [
    {
      label: 'status',
      key: 'status'
    },
    {
      label: 'start_date',
      key: 'startTimestamp'
    },
    {
      label: 'end_date',
      key: 'endTimestamp'
    },
    {
      label: 'result',
      key: 'result'
    },
    {
      label: 'log',
      key: 'log'
    },
    {
      label: 'error',
      key: 'error'
    }
  ];

  const columns = [
    {
      title: t('workflow-executions.workflow'),
      dataIndex: 'workflow',
      key: 'workflow',
      render: (workflow) => workflow.title,
      sorter: (a, b) => a.workflow.title.localeCompare(b.workflow.title)
    },
    {
      title: t('workflow-executions.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === 'SUCCESS' ? 'green' : 'red'}>{status}</Tag>
      )
    },
    {
      title: t('workflow-executions.start_date'),
      dataIndex: 'startTimestamp',
      key: 'startTimestamp',
      sorter: (a, b) => a.startTimestamp - b.startTimestamp,
      render: (startTimestamp) => (
        <Tooltip title={moment(startTimestamp).format('LLL')}>
          {moment(startTimestamp).fromNow()}
        </Tooltip>
      )
    },
    {
      title: t('workflow-executions.end_date'),
      dataIndex: 'endTimestamp',
      key: 'endTimestamp',
      render: (endTimestamp) => (
        <Tooltip title={moment(endTimestamp).format('LLL')}>
          {moment(endTimestamp).fromNow()}
        </Tooltip>
      )
    },
    {
      title: t('workflow-executions.result'),
      dataIndex: 'result',
      key: 'result',
      render: (result) => (
        <Tooltip title={JSON.stringify(result)}>
          {t('workflow-executions.result')}
        </Tooltip>
      )
    },
    {
      title: t('workflow-executions.logs'),
      dataIndex: 'log',
      key: 'log',
      render: (logs) => {
        if (logs && logs.length > 0) {
          return (
            <Button type="link" onClick={() => showModal(logs)}>
              {t('workflow-executions.view_logs')}
            </Button>
          );
        }
        return t('workflow-executions.no_logs');
      }
    },
    {
      title: t('workflow-executions.error'),
      dataIndex: 'error',
      key: 'error',
      render: (error) => <Tooltip title={error}>{error}</Tooltip>
    }
  ];

  return (
    <div>
      <ListResource
        resourceName="workflow-executions"
        populate="workflow"
        columns={columns}
        headers={headers}
        resourceModelName="workflow-executions"
        showAction={false}
        editAction={false}
        deleteAction={false}
        onDoubleClickAction={false}
        withCreateButton={false}
      />

      <Modal
        title={t('workflow-executions.logs')}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}
        footer={[
          <Button key="back" onClick={handleOk}>
            {t('workflow-executions.close')}
          </Button>
        ]}
        width="80%"
      >
        <pre
          style={{
            overflowY: 'scroll',
            whiteSpace: 'pre-wrap',
            width: '100%',
            backgroundColor: '#f5f5f5'
          }}
        >
          {currentLogs.join('\n\n')}
        </pre>
      </Modal>
    </div>
  );
};

export default ListWorkflowExecution;
