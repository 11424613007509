import { createContext, useContext, useEffect, useState } from 'react';
import { useAuthContext } from './AuthContext';
import { useErrorMessage } from '../utils/errorMessage';

const TemplateContext = createContext({ isValid: false });

export const TemplateContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const [enumsTemplate, setEnumsTemplate] = useState([]);
  const [refreshTemplate, setRefreshTemplate] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [enumsTemplateResponse] = await Promise.all([
          dispatchAPI('GET', { url: 'template-imports/enums' })
        ]);

        setEnumsTemplate(enumsTemplateResponse.data);
      } catch (e) {
        message(e);
      }
    };

    fetchData();
  }, [refreshTemplate]);

  const deleteResource = async ({ _id, file }) => {
    try {
      await dispatchAPI('DELETE', {
        url: `template-imports/${_id}`
      });
      await dispatchAPI('DELETE', {
        url: `files/${file?._id}`
      });
      setRefreshTemplate(!refreshTemplate);
    } catch (e) {
      message(e);
    }
  };

  return (
    <TemplateContext.Provider
      value={{
        enumsTemplate,
        deleteResource,
        refreshTemplate,
        setRefreshTemplate
      }}
    >
      {children}
    </TemplateContext.Provider>
  );
};

export const useTemplateContext = () => {
  const context = useContext(TemplateContext);
  if (!context)
    throw new Error('Context must be used within a context provider');
  return context;
};
