/**
 * Generates an array of shaping items for a material item.
 *
 * @function
 *
 * @param {Array} shapings - Array of shaping items.
 * @param {function} t - Translation function.
 * @returns {Array} Array of generated shaping objects.
 */
const generateShapingsArrayForMaterialItem = (shapings, t) =>
  shapings.map((shaping) => {
    const unit_price = shaping.facial_value / shaping.quantity;
    const VAT_price =
      shaping.facial_value * (shaping.shaping_VAT_rate.percentage / 100);
    return {
      type: t('invoices.form.shapings'),
      quantity: shaping.quantity,
      denomination: shaping.nomenclature || 'Shaping',
      unit_price: Number(unit_price).toFixed(2),
      VAT_rate: shaping.shaping_VAT_rate,
      VAT_excluded_price: Number(shaping.facial_value).toFixed(2),
      all_included_value: Number(shaping.all_included_value).toFixed(2),
      VAT_price: Number(VAT_price).toFixed(2)
    };
  });

/**
 * Generates the data source for a table based on quotation data.
 *
 * @function
 *
 * @param {Object} quotation - Quotation object.
 * @param {function} t - Translation function.
 * @returns {Array} Array of generated table data objects.
 */
export const generateTableDataSource = (quotation, t) => {
  const resultArray = [];

  quotation.materials.forEach((material) => {
    const unit_price = material.facial_value / material.quantity;
    const VAT_price =
      material.facial_value * (material.VAT_rate.percentage / 100);
    const materialObject = {
      type: t('invoices.form.materials'),
      quantity: material.quantity,
      VAT_rate: material.VAT_rate,
      denomination: material.materials_catalog.denomination || 'Material',
      unit_price: Number(unit_price).toFixed(2),
      VAT_excluded_price: Number(material.facial_value).toFixed(2),
      VAT_price: Number(VAT_price).toFixed(2),
      all_included_value: Number(material.all_included_value).toFixed(2),
      shapings: generateShapingsArrayForMaterialItem(material?.shapings, t)
    };
    resultArray.push(materialObject);
  });

  quotation.accessories.forEach((accessory) => {
    const unit_price = accessory.facial_value / accessory.quantity;
    const VAT_price =
      accessory.facial_value * (accessory.VAT_rate.percentage / 100);

    const accessoryObject = {
      type: t('invoices.form.accessories'),
      quantity: accessory.quantity,
      VAT_excluded_price: Number(accessory.facial_value).toFixed(2),
      VAT_rate: accessory.VAT_rate,
      unit_price: Number(unit_price).toFixed(2),
      denomination: accessory.accessory_catalog.nomenclature,
      VAT_price: Number(VAT_price).toFixed(2),
      all_included_value: Number(accessory.all_included_value).toFixed(2)
    };
    resultArray.push(accessoryObject);
  });

  quotation.services.forEach((service) => {
    const unit_price = service.facial_value / service.quantity_surface;
    const VAT_price =
      service.facial_value * (service.VAT_rate.percentage / 100);
    const serviceObject = {
      type: t('invoices.form.services'),
      quantity: service.quantity_surface || service.quantity,
      denomination: service.services_catalog.nomenclature,
      VAT_rate: service.VAT_rate,
      VAT_excluded_price: Number(service.facial_value).toFixed(2),
      unit_price: Number(unit_price).toFixed(2),
      VAT_price: Number(VAT_price).toFixed(2),
      all_included_value: Number(service.all_included_value).toFixed(2)
    };
    resultArray.push(serviceObject);
  });
  return resultArray;
};
