import { useEffect, useState } from 'react';
import { Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

const { Option } = Select;

/**
 * A custom hook for generating fields used in the invoice item details form.
 * Manages fetching VAT rates and loading states.
 *
 * @hook
 *
 * @param {string} selectedType - Selected extra invoice type.
 * @param {function} setSelectedType - Function to set the selected type.
 * @returns {Object} fields - Array of field objects.
 * @returns {boolean} isFieldsLoading - Loading state for VAT rates fetching.
 */
export const useFields = (selectedType, setSelectedType) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [VATRates, setVATRates] = useState();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);

  const getVATRates = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'tvas'
      });
      setVATRates(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    setIsFieldsLoading(true);
    (async () => {
      await getVATRates();
    })();
    setIsFieldsLoading(false);
  }, []);

  const fields = [
    {
      label: 'details_type',
      name: ['type'],
      rules: [{ required: true }],
      input: (
        <Select onChange={(value) => setSelectedType(value)}>
          <Option value={t('invoices.form.extra_addition')}>
            {t('invoices.form.extra_addition')}
          </Option>
          <Option value={t('invoices.form.discount')}>
            {t('invoices.form.discount')}
          </Option>
        </Select>
      )
    },
    {
      label: 'quantity',
      name: ['quantity'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      label: 'denomination',
      name: ['denomination'],
      rules: [{ required: true }]
    },
    {
      label: 'unit_price',
      name: ['unit_price'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      title: 'VAT_excluded_price',
      name: ['VAT_excluded_price'],
      input: <Input type="number" disabled />
    },
    ...(selectedType === t('invoices.form.extra_addition')
      ? [
          {
            title: 'VAT_price',
            name: ['VAT_price'],
            input: (
              <Input.Group compact>
                <Form.Item noStyle name={['VAT_rate', 'percentage']}>
                  <Select style={{ width: '25%' }}>
                    {(VATRates || []).map((rate) => (
                      <Option
                        value={rate.percentage}
                      >{`${rate.percentage}%`}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item noStyle name={['VAT_rate', 'value']}>
                  <Input style={{ width: '75%' }} disabled />
                </Form.Item>
              </Input.Group>
            )
          },
          {
            title: 'all_included_value',
            name: ['all_included_value'],
            rules: [{ required: true }],
            input: <Input type="number" disabled />
          }
        ]
      : [])
  ];

  return {
    fields,
    isFieldsLoading
  };
};
