// Main nav menuItems
export const routes = {
  CUSTOMERS: '/customers',
  PROJECTS: '/projects',
  QUOTATIONS: '/quotations',
  ORDERS: '/orders',
  PLANNING: '/planning',
  CATALOG: '/catalog',
  INVOICES: '/invoices',
  PARAMETERS: '/parameters',
  WORKFLOWS: '/workflows'
};

// Main nav subMenuItems
export const subRoutes = {
  PARAMETERS: {
    USERS: '/users',
    CONFIGURATIONS: '/configurations',
    TEMPLATES: '/templates'
  },
  CATALOG: {
    MATERIALS: '/materials',
    SHAPINGS: '/shapings',
    SERVICES: '/services',
    ACCESSORIES: '/accessories'
  },
  PURCHASES: {
    SUPPLIERS: '/suppliers'
  }
};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings'
};

// url search parameters
export const pathSearches = {};
