/* eslint-disable react/no-array-index-key */
/* eslint-disable radix */
/* eslint-disable array-callback-return */
import React from 'react';
import { Button, Row, Col, Input, Form, Divider } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import CodeMirror from '@uiw/react-codemirror';
import { displayTagInMention } from '../../../utils';

const { Item } = Form;

const EmailFromTemplate = ({
  stepInputs,
  fromTemplateEmailFields,
  setFromTemplateEmailFields
}) => {
  const initialEmptyFields = {
    'new-param': ''
  };
  const addParam = () => {
    const newParams = {
      ...initialEmptyFields,
      ...fromTemplateEmailFields.params
    };
    setFromTemplateEmailFields({
      ...fromTemplateEmailFields,
      params: newParams
    });
  };

  const removeParam = (index) => {
    const prevParams = fromTemplateEmailFields.params;

    const newParams = Object.entries(prevParams).filter(
      (field, pos) => pos !== index
    );
    setFromTemplateEmailFields({
      ...fromTemplateEmailFields,
      params: newParams
    });
  };

  const tagDrag = (event, ElmLabel, field) => {
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.dropEffect = 'move';
    const data = `@{${ElmLabel}|${field}}`;
    event.dataTransfer.setData('text', data);
  };

  const updateInput = (type, value) => {
    const newValue = fromTemplateEmailFields;
    newValue[type] = value;
    setFromTemplateEmailFields({ ...newValue });
  };

  const updateParam = (index, type, value) => {
    const prevParams = fromTemplateEmailFields.params;
    // const newValue = Object.assign(fromTemplateEmailFields);
    const newParams = {};

    Object.entries(prevParams).forEach((param, paramIndex) => {
      const newParam = param;

      if (paramIndex === index) {
        if (type === 'title') newParam[0] = value;
        else newParam[1] = value;
        newParams[newParam[0]] = newParam[1];
      }

      newParams[newParam[0]] = newParam[1];
    });

    setFromTemplateEmailFields({
      ...fromTemplateEmailFields,
      params: newParams
    });
  };

  return (
    <>
      <Divider />
      <Row style={{ width: '100%' }}>
        <Col span={24}>
          <Row style={{ marginBottom: 15 }}>
            <Col span={12} offset={6}>
              <Item label="From">
                <Input
                  type="text"
                  name="sender"
                  value={fromTemplateEmailFields.sender}
                  onChange={(event) =>
                    updateInput('sender', event.target.value)
                  }
                  className="input-code"
                />
              </Item>
            </Col>
          </Row>
          <Row style={{ marginBottom: 15 }}>
            <Col span={12} offset={6}>
              <Item label="To">
                <Input
                  type="text"
                  name="targets"
                  value={fromTemplateEmailFields.targets}
                  onChange={(event) =>
                    updateInput('targets', event.target.value)
                  }
                  className="input-code"
                />
              </Item>
            </Col>
          </Row>
          <Row style={{ marginBottom: 15 }}>
            <Col span={12} offset={6}>
              <Item label="TemplateId">
                <Input
                  type="text"
                  name="templateId"
                  value={fromTemplateEmailFields.templateId}
                  onChange={(event) =>
                    updateInput('templateId', event.target.value)
                  }
                />
              </Item>
            </Col>
          </Row>
          <Divider />
          <Row style={{ width: '100%', marginBottom: 10 }}>
            <Col span={24} style={{ textAlign: 'center' }}>
              Params :
            </Col>
          </Row>
          {Object.entries(fromTemplateEmailFields.params).map(
            (param, index) => (
              <Row key={`row-${index}`} style={{ width: '100%' }}>
                <Col key={`col1-${index}`} span={6} offset={3}>
                  <Input
                    placeholder="Nom du param"
                    value={param[0]}
                    onChange={(event) =>
                      updateParam(index, 'title', event.target.value)
                    }
                    style={{ width: '100%' }}
                    key={`title-${index}`}
                  />
                </Col>
                <Col key={`col2-${index}`} span={12} style={{ marginLeft: 10 }}>
                  <CodeMirror
                    style={{ width: '100%' }}
                    value={param[1]}
                    onChange={(event) => updateParam(index, 'value', event)}
                    key={`value-${index}`}
                    options={{
                      mode: 'javascript',
                      theme: 'material',
                      lineNumbers: true
                    }}
                  >
                    {displayTagInMention(stepInputs, true, tagDrag)}
                  </CodeMirror>
                </Col>
                <Col>
                  <Col
                    key={`col4-${index}`}
                    span={2}
                    style={{ marginBottom: 10, minHeight: 30 }}
                  >
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => removeParam(index)}
                      style={{ marginLeft: 10, marginTop: 10, color: 'red' }}
                    />
                  </Col>
                </Col>
              </Row>
            )
          )}
          <Row>
            <Button
              type="dashed"
              onClick={() => addParam()}
              style={{ width: '100%', margin: '10px 0px' }}
              icon={<PlusOutlined />}
              block
            >
              Ajouter un param
            </Button>
          </Row>
        </Col>
      </Row>
    </>
  );
};

EmailFromTemplate.propTypes = {
  stepInputs: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  fromTemplateEmailFields: PropTypes.shape({
    sender: PropTypes.string,
    targets: PropTypes.string,
    templateId: PropTypes.string,
    params: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string
    })
  }).isRequired,
  setFromTemplateEmailFields: PropTypes.func.isRequired
};

export default EmailFromTemplate;
