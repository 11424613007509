import { createContext, useContext, useState } from 'react';

const MasterContext = createContext({ isValid: false });

export const MasterContextProvider = ({ children }) => {
  const [pickedProject, setPickedProject] = useState();
  const [pickedCustomer, setPickedCustomer] = useState();
  const [pickedOrder, setPickedOrder] = useState();

  const resetMasterContext = () => {
    setPickedCustomer();
    setPickedProject();
  };

  return (
    <MasterContext.Provider
      value={{
        pickedProject,
        setPickedProject,
        pickedCustomer,
        setPickedCustomer,
        resetMasterContext,
        pickedOrder,
        setPickedOrder
      }}
    >
      {children}
    </MasterContext.Provider>
  );
};

export const useMasterContext = () => {
  const context = useContext(MasterContext);
  if (!context)
    throw new Error('Context must be used within a context provider');
  return context;
};
