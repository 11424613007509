import { Button, Drawer, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFields } from './fields';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { calculateItemValues } from '../utils/calculateItemValues';

/**
 * A drawer component for adding item details, including VAT calculations.
 *
 * @component
 *
 * @param {Object} props - Component props.
 * @param {Function} props.setItemData - Function to set item data.
 * @param {Function} props.handleDrawer - Function to handle drawer visibility.
 * @param {boolean} props.open - Boolean to control drawer visibility.
 * @returns {JSX.Element} DetailsDrawer component.
 */
export const DetailsDrawer = ({
  setItemData,
  setDrawerValues,
  open,
  setOpen,
  drawerValues
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const generateFields = useGenerateFormItem();
  const [selectedType, setSelectedType] = useState();

  const { fields } = useFields(selectedType, setSelectedType);

  const handleDrawer = () => {
    form.resetFields();
    setOpen(!open);
    setDrawerValues();
  };

  const handleSubmit = (values) => {
    setItemData((prevList) => {
      const updatedList = [...prevList];
      updatedList.push({
        ...values,
        ...(selectedType === t('invoices.form.extra_addition')
          ? { VAT_price: values.VAT_rate.value }
          : {})
      });
      return updatedList;
    });
    handleDrawer();
  };

  useEffect(() => {
    if (drawerValues) {
      form.setFieldsValue(drawerValues);
    }
  }, [drawerValues]);

  return (
    <Drawer
      title={t('invoices.form.add_price')}
      placement="right"
      open={open}
      onClose={handleDrawer}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        onValuesChange={(_, allValues) =>
          calculateItemValues(allValues, selectedType, form, t)
        }
      >
        {fields.map((field) => generateFields('invoices', field))}
        <Button type="add" htmlType="submit">
          {t('buttons.save')}
          <CheckOutlined />
        </Button>
      </Form>
    </Drawer>
  );
};

DetailsDrawer.propTypes = {
  setItemData: PropTypes.func,
  setDrawerValues: PropTypes.func,
  drawerValues: PropTypes.shape({}),
  setOpen: PropTypes.func,
  open: PropTypes.bool
};

DetailsDrawer.defaultProps = {
  setItemData: null,
  setDrawerValues: null,
  open: false,
  drawerValues: null,
  setOpen: null
};
