import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Checkbox, DatePicker, Input } from 'antd';

const { TextArea } = Input;
const { RangePicker } = DatePicker;

/**
 * Hook that generates the content for displaying workshop information in a list.
 *
 * @hook
 * @param {object} props - Workshop information props.
 * @param {object} props.workshop_infos - Workshop information object.
 * @returns {Array} Array of objects containing type, label, content, and input for each workshop field.
 */
export const useWorkshopListContent = ({ workshop_infos }) => {
  const { t } = useTranslation();

  const dates = workshop_infos?.previsional_start_date?.map((date) =>
    moment(date).format('DD/MM/YYYY')
  );

  const rangeDates = workshop_infos?.previsional_start_date?.map((date) =>
    moment(date)
  );

  return [
    {
      type: 'checked',
      label: t('orders.show.awaiting_matters'),
      dataIndex: ['workshop_infos', 'awaiting_matters'],
      content:
        workshop_infos &&
        t(`orders.show.${workshop_infos?.awaiting_matters ? 'yes' : 'no'}`),
      input: <Checkbox data={workshop_infos?.awaiting_matters} />
    },
    {
      label: t('orders.show.previsional_start_date'),
      dataIndex: ['workshop_infos', 'previsional_start_date'],
      content:
        (workshop_infos?.previsional_start_date?.length &&
          t('orders.show.between_dates', { start: dates[0], end: dates[1] })) ||
        t('errors.form.not_specified'),
      input: <RangePicker data={rangeDates} format="DD/MM/YYYY" />
    },
    {
      label: t('orders.show.remarks'),
      dataIndex: ['workshop_infos', 'remarks'],
      content: workshop_infos?.remarks || t('errors.form.not_specified'),
      input: <TextArea data={workshop_infos?.remarks} />
    }
  ];
};
