import { Col, DatePicker, Space, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const { RangePicker } = DatePicker;

/**
 * Additional filters component for the invoices list.
 * This component provides UI controls to filter invoices based on payment status and date range.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.paidInvoices - Flag indicating whether to show paid invoices.
 * @param {function} props.setPaidInvoices - Function to toggle the paidInvoices state.
 * @param {function} props.setDatesFilter - Function to set the date range filter.
 * @returns {JSX.Element} - JSX element representing the extra filters component.
 */
export const extraFilters = ({
  paidInvoices,
  setPaidInvoices,
  setDatesFilter
}) => {
  const { t } = useTranslation();
  return (
    <Space>
      <Col>
        <span className={paidInvoices ? 'active-switch-label' : ''}>
          {t('invoices.form.is-payed')}
        </span>
        <Switch
          checked={paidInvoices}
          onChange={() => setPaidInvoices(!paidInvoices)}
        />
        <span className={!paidInvoices ? 'active-switch-label' : ''}>
          {t('invoices.form.not-payed')}
        </span>
      </Col>
      <Col>
        <RangePicker
          allowClear
          style={{ width: 300 }}
          format="DD/MM/YYYY"
          onChange={(values) => {
            if (values) setDatesFilter(values);
            else setDatesFilter([]);
          }}
        />
      </Col>
    </Space>
  );
};

extraFilters.propTypes = {
  paidInvoices: PropTypes.bool,
  setPaidInvoices: PropTypes.func,
  setDatesFilter: PropTypes.func
};

extraFilters.defaultProps = {
  paidInvoices: false,
  setPaidInvoices: null,
  setDatesFilter: null
};
