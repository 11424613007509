import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { ListResource } from '../../components/ListResource/ListResource';
import { useOrderColumns } from './columns';
import { OrderHeader } from './OrderHeader';
import { useDownloadDocument } from '../../utils/downloadDoc';
import { useOrderContext } from '../../contexts/OrderContext';
import { headers } from './formatter/headers';
import { formatOrders } from './formatter/formatterOrders';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * Represents a list of orders with filtering and display options.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isKanbanView - Indicates whether the current view is kanban view.
 * @param {function} props.setIsKanbanView - Function to set the kanban view mode.
 * @returns {JSX.Element} A JSX element representing the OrdersList component.
 */
export const OrdersList = ({ isKanbanView, setIsKanbanView }) => {
  const { t } = useTranslation();
  const { formatOrder } = formatOrders();
  const columns = useOrderColumns();
  const { masterRoles } = useAuthContext();
  const { importTemplate } = useOrderContext();
  const { downloadDocument } = useDownloadDocument();
  const { statusFilter, setStatusFilter, archiveState } = useOrderContext();
  const [customerFilter, setCustomerFilter] = useState();

  const extraQuery = `${customerFilter ? `customer=${customerFilter}` : ''}${
    statusFilter ? `${customerFilter ? `&` : ''}${statusFilter}` : ''
  }`;

  return (
    <ListResource
      columns={columns}
      populate="customer,kanban_status"
      extraQuery={extraQuery}
      resourceName="orders"
      resourceModelName="Order"
      deleteAction={masterRoles}
      withArchiveButton={false}
      withCreateButton={false}
      archiveState={archiveState}
      editAction={false}
      headers={headers}
      formatter={formatOrder}
      extraMenu={
        importTemplate[0]?.file && [
          {
            key: 'template',
            label: importTemplate[0]?.file && (
              <Button
                type="link"
                onClick={() => downloadDocument(importTemplate[0].file)}
              >
                <DownloadOutlined style={{ fontSize: 18 }} />
                {t('buttons.template')}
              </Button>
            )
          }
        ]
      }
      extraFilters={
        <OrderHeader
          isKanbanView={isKanbanView}
          setCustomerFilter={setCustomerFilter}
          setStatusFilter={setStatusFilter}
          setIsKanbanView={setIsKanbanView}
        />
      }
    />
  );
};

OrdersList.propTypes = {
  isKanbanView: PropTypes.bool,
  setIsKanbanView: PropTypes.func
};

OrdersList.defaultProps = {
  isKanbanView: false,
  setIsKanbanView: null
};
