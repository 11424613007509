import { useTranslation } from 'react-i18next';
import { formatNumberWithSpaces } from '../../../utils/formatters/formatNumberWithSpaces';

/**
 * @hook
 * Custom hook for generating the content list used to display shaping details.
 * @param {Object} props - The shaping details including nomenclature, category, unit, price, and coefficient_if_provided_by_customer.
 * @returns {Array} An array of objects representing the content list for the shaping details.
 */
export const useListContent = ({
  nomenclature,
  category,
  unit,
  price,
  coefficient_if_provided_by_customer
}) => {
  const { t } = useTranslation();

  return [
    {
      label: 'shapings.show.nomenclature',
      content: nomenclature
    },
    {
      label: 'shapings.show.category',
      content: (category && category.title) || t('errors.form.not_specified')
    },
    {
      label: 'shapings.show.unit',
      content: (unit && unit.title) || t('errors.form.not_specified')
    },
    {
      label: 'shapings.show.price',
      content: price
        ? `${formatNumberWithSpaces(Number(price))} €`
        : t('errors.form.not_specified')
    },
    ...(coefficient_if_provided_by_customer
      ? [
          {
            label: 'shapings.show.coefficient_if_provided_by_customer',
            content:
              coefficient_if_provided_by_customer ||
              t('errors.form.not_specified')
          }
        ]
      : [])
  ];
};
