import { useTranslation } from 'react-i18next';
import { formatNumberWithSpaces } from '../../../../utils/formatters/formatNumberWithSpaces';

/**
 * Generates a list of label-content pairs to display invoice totals information.
 *
 * @hook
 *
 * @param {Object} datas - The data of the invoice totals to display.
 * @param {Object} detailsInfoData - Detailed data of the invoice information.
 * @returns {Object} An object containing three arrays of label-content pairs: `basicTotals`, `advanceTotals`, and `creditTotals`.
 */
export const useTotalsListContent = (datas, detailsInfoData) => {
  const { t } = useTranslation();

  const basicTotals = datas && [
    {
      label: t('invoices.show.totals_infos.additional_discount'),
      span: 3,
      content:
        (datas?.extra_addition &&
          `${formatNumberWithSpaces(Number(datas.extra_addition))}€`) ||
        '0.00€'
    },
    {
      label: t('invoices.show.totals_infos.discountHT'),
      span: 2,
      content:
        (datas?.extra_discount &&
          `${formatNumberWithSpaces(Number(datas.extra_discount))}€`) ||
        '0.00€'
    },
    {
      label: t('invoices.show.totals_infos.totalHT'),
      span: 2,
      content:
        (datas?.order?.totals?.totalHT &&
          `${formatNumberWithSpaces(
            Number(datas?.order?.totals?.totalHT)
          )}€`) ||
        '0.00€'
    },
    {
      label: t('invoices.show.totals_infos.totalTva'),
      span: 2,
      content:
        (datas?.order?.totals?.totalTva &&
          `${formatNumberWithSpaces(
            Number(datas?.order?.totals?.totalTva)
          )}€`) ||
        '0.00€'
    },
    {
      label: t('invoices.show.totals_infos.totalTTC'),
      span: 2,
      content:
        (datas?.order?.totals?.totalTTC &&
          `${formatNumberWithSpaces(
            Number(datas?.order?.totals?.totalTTC)
          )}€`) ||
        '0.00€'
    },
    {
      label: t('invoices.show.totals_infos.payed'),
      span: 2,
      content:
        (datas?.order?.totals?.payed &&
          `${formatNumberWithSpaces(Number(datas?.order?.totals?.payed))}€`) ||
        '0€'
    },
    {
      label: t('invoices.show.totals_infos.remaining'),
      span: 2,
      content:
        (datas?.order?.totals?.remaining !== undefined &&
          `${formatNumberWithSpaces(
            Number(datas?.order?.totals?.remaining)
          )}€`) ||
        '0.00€'
    }
  ];

  const advanceTotals = [
    {
      label: t('invoices.show.totals_infos.totalTTC'),
      span: 2,
      content:
        (detailsInfoData &&
          `${formatNumberWithSpaces(
            detailsInfoData?.detailedInfo[0]?.TTC
          )}€`) ||
        '0.00€'
    },
    {
      label: t('invoices.show.totals_infos.totalHT'),
      span: 2,
      content:
        (detailsInfoData &&
          `${formatNumberWithSpaces(detailsInfoData?.detailedInfo[0]?.HT)}€`) ||
        '0.00€'
    },
    {
      label: t('invoices.show.totals_infos.totalTva'),
      span: 2,
      content:
        (detailsInfoData &&
          `${formatNumberWithSpaces(
            detailsInfoData?.detailedInfo[0]?.TVA
          )}€`) ||
        '0.00€'
    },
    {
      label: t('invoices.show.totals_infos.VAT_rate'),
      span: 2,
      content:
        (detailsInfoData &&
          detailsInfoData?.detailedInfo.length > 0 &&
          `${detailsInfoData?.detailedInfo[0]?.VATRate} %`) ||
        '0 %'
    }
  ];

  const creditTotals = [
    {
      label: t('invoices.show.totals_infos.totalHT'),
      span: 2,
      content:
        (datas?.order?.totals?.totalHT &&
          `-${formatNumberWithSpaces(
            Number(datas?.order?.totals?.totalHT)
          )}€`) ||
        '0.00€'
    },
    {
      label: t('invoices.show.totals_infos.VAT_rate'),
      span: 2,
      content: (datas?.VAT_rate && `${datas.VAT_rate.percentage}%`) || '0.00€'
    },
    {
      label: t('invoices.show.totals_infos.totalTva'),
      span: 2,
      content:
        (datas?.order?.totals?.totalTva &&
          `-${formatNumberWithSpaces(
            Number(datas?.order?.totals?.totalTva)
          )}€`) ||
        '0.00€'
    },
    {
      label: t('invoices.show.totals_infos.totalTTC'),
      span: 2,
      content:
        (datas?.order?.totals?.totalTTC &&
          `-${formatNumberWithSpaces(
            Number(datas?.order?.totals?.totalTTC)
          )}€`) ||
        '0.00€'
    }
  ];

  return {
    basicTotals,
    advanceTotals,
    creditTotals
  };
};
