import { createContext, useContext, useEffect, useState } from 'react';
import { useAuthContext } from './AuthContext';
import { useErrorMessage } from '../utils/errorMessage';

const MaterialContext = createContext({ isValid: false });

export const CatalogMaterialContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const [measureUnits, setMeasureUnits] = useState([]);
  const [editUnitMeasures, setEditUnitMeasures] = useState([]);
  const [dataPriceTables, setDataPriceTables] = useState([]);
  const [importTemplate, setImportTemplate] = useState([]);

  const [categories, setCategories] = useState([]);
  const [finishingProducts, setFinishingProducts] = useState([]);
  const [suppliers, setSuppliers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [measureUnitsResponse, importTemplateResponse] =
          await Promise.all([
            dispatchAPI('GET', {
              url: 'unit-of-measures?reserved=true'
            }),
            dispatchAPI('GET', {
              url: 'template-import?populate=file&type=MATTER'
            })
          ]);
        setMeasureUnits(measureUnitsResponse.data);
        setImportTemplate(importTemplateResponse.data);
      } catch (e) {
        message(e);
      }
    };

    fetchData();
  }, []);

  const getMaterialCategories = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'material-categories'
      });
      setCategories(data);
    } catch (e) {
      message(e);
    }
  };

  const getSuppliers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'suppliers'
      });
      setSuppliers(data);
    } catch (e) {
      message(e);
    }
  };

  const getFinishingProducts = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'finishing-products'
      });
      setFinishingProducts(data);
    } catch (e) {
      message(e);
    }
  };

  const deleteSlice = async (id, sliceId, callback, priceId) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/materials/slices/delete/${id}/${sliceId}/${priceId || 'null'}`
      });
      callback?.();
    } catch (e) {
      message(e);
    }
  };

  return (
    <MaterialContext.Provider
      value={{
        measureUnits,
        editUnitMeasures,
        setEditUnitMeasures,
        dataPriceTables,
        setDataPriceTables,
        importTemplate,
        setImportTemplate,
        categories,
        setCategories,
        finishingProducts,
        setFinishingProducts,
        suppliers,
        setSuppliers,
        getMaterialCategories,
        getSuppliers,
        getFinishingProducts,
        deleteSlice
      }}
    >
      {children}
    </MaterialContext.Provider>
  );
};

export const useCatalogMaterialContext = () => {
  const context = useContext(MaterialContext);
  if (!context)
    throw new Error('Context must be used within a context provider');
  return context;
};
