import React, { useEffect, useState, useCallback } from 'react';
import { Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';
import { useMasterContext } from '../../contexts/MasterContext';

const { Option } = Select;

const useFields = (form) => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { pickedCustomer } = useMasterContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [rooms, setRooms] = useState([]);

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'customers?status!=ARCHIVED'
      });
      setCustomers(data);
    } catch (e) {
      message(e);
    }
  };

  const getRooms = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'rooms'
      });
      setRooms(data);
    } catch (e) {
      message(e);
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'users'
      });
      setUsers(data);
    } catch (e) {
      message(e);
    }
  };

  const getProjectTypes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `project-types`
      });
      setProjectTypes(data);
    } catch (e) {
      message(e);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getCustomers();
    await getUsers();
    await getRooms();
    await getProjectTypes();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const handleCustomerPick = (id) => {
    const selectedCustomer = customers.find((customer) => customer._id === id);
    return form.setFieldsValue({
      site_address: {
        street: selectedCustomer?.billing_address?.street || '',
        postal_code: selectedCustomer?.billing_address?.postal_code || '',
        city: selectedCustomer?.billing_address?.city || '',
        streadditionalet: selectedCustomer?.billing_address?.additional || ''
      }
    });
  };

  useEffect(() => {
    if (pickedCustomer && !!customers.length) {
      handleCustomerPick(pickedCustomer._id);
    }
  }, [pickedCustomer, customers]);

  const basicFields = [
    {
      name: ['customer'],
      initialValue: pickedCustomer ? pickedCustomer._id : null,
      input: (
        <Select
          allowClear
          showSearch
          optionFilterProp="children"
          onChange={(customer) => handleCustomerPick(customer)}
        >
          {(customers || []).map((customer) => (
            <Option key={customer._id} value={customer._id}>
              {`${customer.last_name || ''} ${customer.first_name || ''}`}
            </Option>
          ))}
        </Select>
      ),
      rules: [{ required: true }]
    },
    {
      name: ['denomination']
    },
    {
      name: ['project_type'],
      input: (
        <Select allowClear showSearch optionFilterProp="children">
          {(projectTypes || []).map((type) => (
            <Option key={type._id} value={type._id}>
              {`${type.title}`}
            </Option>
          ))}
        </Select>
      ),
      rules: [{ required: true }]
    },
    {
      name: ['site_address', 'street']
    },
    {
      name: ['site_address', 'additional']
    },
    {
      name: ['site_address', 'postal_code']
    },
    {
      name: ['site_address', 'city']
    },
    {
      name: ['site_access']
    },
    {
      name: ['dwelling_type']
    },
    {
      name: ['entry_code']
    },
    {
      name: ['parking']
    },
    {
      name: ['floor_number']
    },
    {
      name: ['rooms'],
      input: (
        <Select
          allowClear
          showSearch
          optionFilterProp="children"
          mode="multiple"
        >
          {(rooms || []).map((room) => (
            <Option key={room._id} value={room._id}>
              {`${room.title}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['stairs'],
      input: (
        <Select>
          <Select.Option value>Oui</Select.Option>
          <Select.Option value={false}>Non</Select.Option>
        </Select>
      )
    },
    {
      name: ['housekeeper_and_others']
    },
    {
      name: ['MP_supervisor'],
      input: (
        <Select>
          {users.map((user) => (
            <Select.Option key={user._id} value={user._id}>
              {`${user.first_name} ${user.last_name}`}
            </Select.Option>
          ))}
        </Select>
      )
    },
    {
      name: ['remarks'],
      input: <TextArea />
    }
  ];

  return {
    basicFields,
    isFieldsLoading
  };
};

export default useFields;
