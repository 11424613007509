import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { KanbanColumn } from './KanbanColumn';

export const KanbanView = ({
  datas,
  resourceName,
  columnHeaders,
  refresh,
  setRefresh,
  children,
  defaultStatus,
  extraRoute
}) => (
  <DndProvider backend={HTML5Backend}>
    <Row
      wrap={false}
      style={{
        overflowX: 'scroll'
      }}
    >
      {columnHeaders.map((col) => (
        <KanbanColumn
          display={col.display}
          resourceName={resourceName}
          column={col}
          data={datas}
          refresh={refresh}
          setRefresh={setRefresh}
          defaultStatus={defaultStatus}
          extraRoute={extraRoute}
        >
          {children}
        </KanbanColumn>
      ))}
    </Row>
  </DndProvider>
);

KanbanView.propTypes = {
  resourceName: PropTypes.string.isRequired,
  datas: PropTypes.shape([]),
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
  columnHeaders: PropTypes.shape([]).isRequired,
  children: PropTypes.node.isRequired,
  defaultStatus: PropTypes.string,
  extraRoute: PropTypes.string
};

KanbanView.defaultProps = {
  defaultStatus: PropTypes.string,
  datas: [],
  extraRoute: undefined
};
