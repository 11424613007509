import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Customers } from '../routes/customers/Customers';
import CreateUpdateCustomer from '../routes/customers/CreateUpdateCustomer';
import ShowCustomer from '../routes/customers/ShowCustomer';
import { CustomerContextProvider } from '../contexts/CustomerContext';

const CustomerRouter = () => (
  <CustomerContextProvider>
    <Routes>
      <Route
        path="/create"
        element={<CreateUpdateCustomer purpose="create" />}
      />
      <Route
        path="/edit/:id"
        element={<CreateUpdateCustomer purpose="edit" />}
      />
      <Route path="/show/:id" element={<ShowCustomer />} />
      <Route index element={<Customers />} />
    </Routes>
  </CustomerContextProvider>
);

export default CustomerRouter;
