import { useTranslation } from 'react-i18next';
import { formatNumberWithSpaces } from '../../../../utils/formatters/formatNumberWithSpaces';

/**
 * Hook that generates the content for displaying order totals in a list.
 *
 * @hook
 * @param {object} datas - Totals data object.
 * @returns {Array} Array of objects containing label and content for each total field.
 */
export const useTotalsListContent = (datas = {}) => {
  const { t } = useTranslation();
  const { totalHT, totalTva, totalTTC, payed, remaining } = datas;

  return [
    {
      label: t('orders.show.totalHT'),
      content: (totalHT && `${formatNumberWithSpaces(totalHT)}€`) || '0.00€'
    },
    {
      label: t('orders.show.totalTva'),
      content: (totalTva && `${formatNumberWithSpaces(totalTva)}€`) || '0.00€'
    },
    {
      label: t('orders.show.totalTTC'),
      content: (totalTTC && `${formatNumberWithSpaces(totalTTC)}€`) || '0.00€'
    },
    {
      label: t('orders.show.payed'),
      content:
        (payed !== null &&
          payed !== undefined &&
          `${formatNumberWithSpaces(payed)}€`) ||
        '0.00€'
    },
    {
      label: t('orders.show.remaining'),
      content: (remaining && `${formatNumberWithSpaces(remaining)}€`) || '0.00€'
    }
  ];
};
