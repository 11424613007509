import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const formatOrders = () => {
  const { t } = useTranslation();

  const getCurrStatus = ({ status, kanban_status }) => {
    switch (true) {
      case status !== 'KANBAN_STATUS':
        return t(`orders.form.${status}`);
      case status === 'KANBAN_STATUS':
        return kanban_status?.title;
      default:
        return 'N/R';
    }
  };

  const getPaymentStatus = (status) => {
    if (status) return t(`orders.form.payment_status.${status}`);
    return 'N/R';
  };

  const formatRangeDates = (value, defaultvalue) => {
    if (value)
      return [
        moment(value[0]).format('DD/MM/YYYY'),
        moment(value[1]).format('DD/MM/YYYY')
      ];
    return defaultvalue || 'N/R';
  };

  const formatFullName = (customer, defaultvalue) => {
    if (customer) {
      return `${customer?.first_name || ''} ${customer?.last_name || ''}`;
    }
    return defaultvalue || 'N/R';
  };

  const formatOrder = (datas) => {
    const { construction_date, customer, payment_status } = datas;

    const result = {
      ...datas,
      status: getCurrStatus(datas),
      construction_date: formatRangeDates(construction_date),
      full_name: formatFullName(customer),
      payment_status: getPaymentStatus(payment_status)
    };

    return result;
  };

  return { formatOrder };
};
