import { createContext, useContext, useEffect, useState } from 'react';
import { useAuthContext } from './AuthContext';
import { useErrorMessage } from '../utils/errorMessage';

const OrderContext = createContext({ isValid: false });

export const OrderContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const [customers, setCustomers] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [kanbanStatus, setKanbanStatus] = useState([]);
  const [importTemplate, setImportTemplate] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [archiveState, setArchiveState] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          statusesResponse,
          kanbanStatusesResponse,
          importTemplateResponse
        ] = await Promise.all([
          dispatchAPI('GET', { url: 'orders/enums' }),
          dispatchAPI('GET', { url: 'kanbanview-actions' }),
          dispatchAPI('GET', {
            url: 'template-import?populate=file&type=ORDER'
          })
        ]);

        setStatuses(statusesResponse.data.status);
        setKanbanStatus(kanbanStatusesResponse.data);
        setImportTemplate(importTemplateResponse.data);
      } catch (e) {
        message(e);
      }
    };

    fetchData();
  }, []);

  const fetchCustomerFilters = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `orders?populate=customer${statusFilter ? `&${statusFilter}` : ''}`
      });
      const customersArray = data
        .map((order) => order.customer)
        .filter(Boolean);
      setCustomers(
        Array.from(new Set(customersArray.map(JSON.stringify))).map(JSON.parse)
      );
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    fetchCustomerFilters();
  }, [statusFilter]);

  return (
    <OrderContext.Provider
      value={{
        customers,
        setCustomers,
        statuses,
        setStatuses,
        kanbanStatus,
        setKanbanStatus,
        importTemplate,
        setImportTemplate,
        archiveState,
        setArchiveState,
        statusFilter,
        setStatusFilter
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export const useOrderContext = () => {
  const context = useContext(OrderContext);
  if (!context)
    throw new Error('Context must be used within a context provider');
  return context;
};
