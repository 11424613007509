import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, Card, Skeleton } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { SlicesTable } from './SlicesTable';
import { SlicesManagerDrawer } from './SlicesManagerDrawer';

export const SlicesManagementTable = ({ datas, forceRefresh }) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const [open, setOpen] = useState(false);
  const [isSliceManagement, setIsSliceManagement] = useState(true);

  const showDrawer = (parent, record, child) => {
    form.resetFields();
    setOpen(true);

    if (record && parent === 'slices') {
      setIsSliceManagement(true);
      form.setFieldsValue({
        sliceManage: {
          ...record,
          unit_measure: record.unit_measure._id
        }
      });
    } else if (parent === 'prices') {
      setIsSliceManagement(false);
      form.setFieldsValue({
        priceManage: {
          ...(child || []),
          application_date: moment(child?.application_date) || null,
          purchase_date: moment(child?.purchase_date) || null,
          sliceInfo: {
            _id: record?._id,
            nomenclature: record?.nomenclature
          }
        }
      });
    }
  };

  return (
    <Skeleton loading={!datas} active>
      <SlicesManagerDrawer
        form={form}
        forceRefresh={forceRefresh}
        open={open}
        setOpen={setOpen}
        switchFields={isSliceManagement}
      />
      <Card
        title={t('materials.show.prices_management')}
        style={{ marginBottom: 16 }}
      >
        <SlicesTable
          slices={datas}
          forceRefresh={forceRefresh}
          onEdit={showDrawer}
          setIsSliceManagement={setIsSliceManagement}
        />
        <Button
          type="dashed"
          style={{ marginTop: 16, width: '100%' }}
          onClick={() => {
            setIsSliceManagement(true);
            showDrawer('slices');
          }}
        >
          {t('materials.form.slices_management.add')}
        </Button>
      </Card>
    </Skeleton>
  );
};

SlicesManagementTable.propTypes = {
  datas: PropTypes.shape({}).isRequired,
  forceRefresh: PropTypes.func
};

SlicesManagementTable.defaultProps = {
  forceRefresh: () => {}
};
