import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Drawer, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { useGenerateFormItem } from '../../../../../utils/generateFormItem';
import { useFields } from '../../form/fields';
import { calculateSellPrice } from '../../form/calculateSellPrice';

export const SlicesManagerDrawer = ({
  form,
  forceRefresh,
  open,
  setOpen,
  switchFields
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { slicesFields, pricesFields } = useFields(form);
  const generateFields = useGenerateFormItem();

  const [isLoading, setIsloading] = useState(false);

  const handleSubmit = async (data) => {
    setIsloading(true);
    try {
      const priceHandler = data?.priceManage?.sliceInfo?._id;
      const sliceHandler = data?.sliceManage?._id;

      await dispatchAPI('PATCH', {
        url: `/materials/slices/${
          sliceHandler || priceHandler ? 'edit' : 'add'
        }/${id}/${sliceHandler || 'null'}/null`,
        body: data
      });
      setOpen(false);
      forceRefresh();
    } catch (e) {
      message(e);
    }
    setIsloading(false);
  };

  const calculateSellPriceForm = (e) => {
    if (e?.priceManage) {
      const prev = form.getFieldsValue();
      const result = calculateSellPrice(prev.priceManage);
      form.setFieldsValue({
        priceManage: { ...prev.priceManage, sell_price: result }
      });
    }
  };

  const renderDrawerTitle = () => {
    if (switchFields) return t('materials.form.add');
    return t('materials.form.add_price');
  };

  return (
    <Drawer
      title={renderDrawerTitle()}
      placement="right"
      onClose={() => {
        setOpen(false);
      }}
      open={open}
    >
      <Form
        onFinish={handleSubmit}
        onValuesChange={calculateSellPriceForm}
        form={form}
        layout="vertical"
      >
        {(switchFields ? slicesFields : pricesFields)?.map((field) =>
          generateFields('materials', field)
        )}
        <Button type="add" isLoading={isLoading} htmlType="submit">
          {`${t('buttons.save')} `}
          <CheckOutlined />
        </Button>
      </Form>
    </Drawer>
  );
};

SlicesManagerDrawer.propTypes = {
  datas: PropTypes.shape({}).isRequired,
  forceRefresh: PropTypes.func,
  form: PropTypes.shape({
    getFieldsValue: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  switchFields: PropTypes.bool.isRequired
};

SlicesManagerDrawer.defaultProps = {
  open: false,
  setOpen: () => {},
  forceRefresh: () => {}
};
