import React, { useState } from 'react';
import useColumns from './columns';
import { ListResource } from '../../../components/ListResource/ListResource';
import { ExtraFilters } from '../ExtraFilters';
import { useAccessoryContext } from '../../../contexts/AccessoryContext';
import { DownloadFileButtons } from '../../../components/DownloadFileButton/DownloadFileButton';
import { headers } from './formatter/headers';

/**
 * Renders a component for managing accessories with a list view.
 *
 * @component
 * @returns {JSX.Element} The rendered Accessories component.
 */
export const Accessories = () => {
  const columns = useColumns();
  const { importTemplate } = useAccessoryContext();
  const [categoryFilter, setCategoryFilter] = useState();
  const [supplierFilter, setSupplierFilter] = useState();
  const [archiveState, setArchiveState] = useState();

  const extraQuery = `${categoryFilter ? `category=${categoryFilter}` : ''}${
    supplierFilter
      ? `${categoryFilter ? '&' : ''}supplier=${supplierFilter}`
      : ''
  }`;

  return (
    <ListResource
      columns={columns}
      withArchiveButton
      withImportButton
      extraQuery={extraQuery}
      setArchiveState={setArchiveState}
      extraFilters={
        <ExtraFilters
          resourceModelName="accessory"
          translationName="accessories"
          extraAccessoriesFilters
          archiveState={archiveState}
          categoryFilter={categoryFilter}
          setSupplierFilter={setSupplierFilter}
          supplierFilter={supplierFilter}
          setCategoryFilter={setCategoryFilter}
        />
      }
      populate="category,supplier"
      resourceName="accessories"
      resourceModelName="Accessory"
      headers={headers}
      extraMenu={
        importTemplate &&
        importTemplate[0]?.file && [
          {
            key: 'template',
            label: importTemplate[0]?.file && (
              <DownloadFileButtons file={importTemplate[0]?.file} />
            )
          }
        ]
      }
    />
  );
};
