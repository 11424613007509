import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { outOfNavRoutes, routes } from '../utils/constants/adminRoutes';
import { Login } from '../routes/login';
import { Exception } from '../components';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { UserTheme } from '../assets/styles/Theme/UserTheme';
import PostPwdForm from '../routes/login/PostPwdForm';
import { RequireAuth } from './RequireAuth';
import PlanningRouter from './PlanningRouter';
import OrderRouter from './OrderRouter';

/**
 * The `UserRouter` component serves as the main router for the user section of the application.
 * It defines various routes and their respective components to render, including guarded routes
 * that require authentication. The component also applies a `user` class to the body element for
 * styling purposes when mounted.
 *
 * @component
 *
 * @returns {React.Element} A set of routes wrapped inside a BrowserRouter, which determines the components to render based on the current URL path.
 */
export const UserRouter = () => {
  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'user';
    }
  }, []);

  return (
    <BrowserRouter>
      <UserTheme />
      <Routes>
        <Route
          path={`${outOfNavRoutes.PASSWORD_CREATION}/:urlToken`}
          element={<PostPwdForm />}
        />
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route element={<RequireAuth />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route path={`${routes.PLANNING}/*`} element={<PlanningRouter />} />
          <Route path={`${routes.ORDERS}/*`} element={<OrderRouter />} />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
