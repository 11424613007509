import { Table } from 'antd';
import PropTypes from 'prop-types';
import { useServicesColumns } from '../Columns/servicesColumns';

/**
 * ServicesTable displays a table of services using the Ant Design Table component.
 *
 * @component
 *
 * @param {Array} services - An array of service objects.
 * @returns {JSX.Element} JSX element representing the services table.
 */
export const ServicesTable = ({ services }) => {
  const columns = useServicesColumns();

  return <Table columns={columns} dataSource={services} scroll={{ x: 1000 }} />;
};

ServicesTable.propTypes = {
  services: PropTypes.arrayOf(PropTypes.shape({}))
};

ServicesTable.defaultProps = {
  services: null
};
