import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { Link } from 'react-router-dom';
import { invoiceTypes } from '../../../../utils/constants/tagColors';
import { routes } from '../../../../utils/constants/adminRoutes';
import { formatNumberWithSpaces } from '../../../../utils/formatters/formatNumberWithSpaces';

/**
 * Generates a list of label and content pairs to display invoice information.
 *
 * @hook
 *
 * @param {Object} datas - The invoice data to display.
 * @returns {Array} An array of label and content pairs.
 */
export const useInvoiceInfosListContent = (datas = {}) => {
  const { t } = useTranslation();

  const activeQuotation = datas?.order?.quotations.find(
    (quotation) => quotation.status === 'ACTIVE'
  );

  return [
    {
      label: t('invoices.show.invoice_infos.type.title'),
      content:
        (datas?.invoice_type && (
          <Tag color={invoiceTypes[datas.invoice_type]}>
            {t(`invoices.show.invoice_infos.type.${datas.invoice_type}`)}
          </Tag>
        )) ||
        t('errors.form.not_specified')
    },
    {
      label: t('invoices.show.invoice_infos.order'),
      content:
        (datas?.order && (
          <Link to={`${routes.ORDERS}/show/${datas.order._id}`}>
            <Tag color="#EB5B25">{datas.order.number}</Tag>
          </Link>
        )) ||
        t('errors.form.not_specified')
    },
    {
      label: t('invoices.show.invoice_infos.quotation'),
      content:
        (activeQuotation && (
          <Link
            to={`${routes.QUOTATIONS}/show/${activeQuotation.quotation._id}`}
          >
            <Tag>{activeQuotation.quotation.number}</Tag>
          </Link>
        )) ||
        t('errors.form.not_specified')
    },
    ...(datas?.invoice_type !== 'BASIC'
      ? [
          {
            label: t('invoices.show.invoice_infos.due'),
            content:
              (datas?.totals?.due &&
                `${formatNumberWithSpaces(Number(datas.totals?.due))}€`) ||
              '0.00€'
          },
          {
            label: t('invoices.show.invoice_infos.paid'),
            content:
              (datas?.totals &&
                datas?.totals?.paid !== undefined &&
                `${formatNumberWithSpaces(Number(datas.totals?.paid))}€`) ||
              '0.00€'
          }
        ]
      : [
          {
            label: t('invoices.show.totals_infos.payed'),
            content:
              (datas?.totals?.paid &&
                `${formatNumberWithSpaces(Number(datas.totals.paid))}€`) ||
              '0.00€'
          },
          {
            label: t('invoices.show.totals_infos.remaining'),
            content:
              (datas?.totals &&
                `${formatNumberWithSpaces(Number(datas.totals.due))}€`) ||
              '0.00€'
          }
        ])
  ];
};
