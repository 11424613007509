import { Divider, Popconfirm, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import {
  ContainerOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useQuotationContext } from '../../contexts/QuotationContext';
import { routes } from '../../utils/constants/adminRoutes';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { formatNumberWithSpaces } from '../../utils/formatters/formatNumberWithSpaces';

const iconSize = 18;

/**
 * Generates table columns configuration for the quotations list.
 *
 * @hook
 *
 * @param {Object} colors - Color data for status tags.
 * @param {boolean} forceRefresh - Whether to force a data refresh.
 * @param {Function} setForceRefresh - Function to set the force refresh flag.
 * @returns {Array} An array of column configurations for the quotations table.
 */
export const useQuotationColumns = (colors, forceRefresh, setForceRefresh) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { customers } = useQuotationContext();

  const archiveResource = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/quotations/archived/${id}`
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  const colorStatus = (status, colorData) =>
    colorData.find(
      (color) => color.title === t(`quotations.statuses.${status}`)
    )?.color;

  const basicColumns = [
    {
      title: t('quotations.form.customer_arr'),
      dataIndex: 'customer',
      key: 'customer',
      sorter: true,
      render: (customer) =>
        (customer &&
          `${customer.first_name || ''} ${customer.last_name || ''}`) ||
        t('errors.form.not_specified'),
      filters: customers?.map((customer) => ({
        text: `${customer.first_name || ''} ${customer.last_name || ''}`,
        value: customer._id
      }))
    },
    {
      title: t('quotations.form.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (_, { status, kanban_status }) => {
        switch (true) {
          case status !== 'KANBAN_STATUS':
            return (
              <Tag color={colorStatus(status, colors)}>
                {t(`quotations.form.${status}`)}
              </Tag>
            );
          case status === 'KANBAN_STATUS':
            return <Tag color={kanban_status.color}>{kanban_status.title}</Tag>;
          default:
            return t('errors.form.not_specified');
        }
      }
    },
    {
      title: t('quotations.form.number'),
      dataIndex: 'number',
      key: 'number',
      sorter: true,
      render: (number) => <Tag>{number}</Tag> || t('errors.form.not_specified')
    },
    {
      title: t('quotations.form.created_at'),
      dataIndex: 'creation_date',
      key: 'creation_date',
      sorter: true,
      render: (date) =>
        (date && moment(date).format('DD-MM-YYYY')) ||
        t('errors.form.not_specified')
    },
    {
      title: t('quotations.form.amount'),
      dataIndex: 'summary',
      key: 'summary',
      sorter: true,
      render: (summary) => {
        const total = summary?.base_total?.all_included_total;
        if (total) {
          // Format the number with spaces as thousand separators
          const formattedTotal = formatNumberWithSpaces(total);
          return formattedTotal;
        }
        // Return a default message if the value is not specified
        return t('errors.form.not_specified');
      }
    },
    {
      title: t('quotations.form.validity_date_arr'),
      dataIndex: 'validity_date',
      key: 'validity_date',
      sorter: true,
      render: (date) =>
        (date && moment(date).format('DD-MM-YYYY')) ||
        t('errors.form.not_specified')
    }
  ];

  const actionColumn = [
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <>
          <Link to={`${routes.QUOTATIONS}/show/${record._id}`}>
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          {record?.kanban_status?.title !== 'DEVIS SIGNÉS' && (
            <>
              <Divider type="vertical" />
              <Link to={`${routes.QUOTATIONS}/edit/${record?._id}`}>
                <EditOutlined style={{ fontSize: iconSize }} />
              </Link>
            </>
          )}
          <>
            <Divider type="vertical" />
            <Popconfirm
              title={t('datatable.column.action.archive.title')}
              okText={t('datatable.column.action.archive.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.archive.cancel')}
              onConfirm={() => archiveResource(record?._id)}
              icon={<WarningOutlined />}
            >
              <ContainerOutlined
                style={{ color: '#b51010', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          </>
        </>
      )
    }
  ];

  const columns = [...basicColumns, ...actionColumn];

  return columns;
};
