import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Popconfirm } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';

export const ActionSliceManagement = ({ editAction, deleteAction }) => {
  const { t } = useTranslation();
  return (
    <>
      <Button type="link" onClick={editAction}>
        <EditOutlined style={{ fontSize: 18 }} />
      </Button>
      <Popconfirm
        title={t('datatable.column.action.delete.title')}
        okText={t('datatable.column.action.delete.ok')}
        okButtonProps={{ type: 'danger' }}
        cancelText={t('datatable.column.action.delete.cancel')}
        onConfirm={deleteAction}
        icon={<WarningOutlined />}
      >
        <Button type="link">
          <DeleteOutlined style={{ fontSize: 18 }} />
        </Button>
      </Popconfirm>
    </>
  );
};

ActionSliceManagement.propTypes = {
  editAction: PropTypes.func,
  deleteAction: PropTypes.func
};

ActionSliceManagement.defaultProps = {
  editAction: () => {},
  deleteAction: () => {}
};
