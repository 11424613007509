import React, { createContext, useContext } from 'react';
import LightTheme from '../assets/styles/Theme/LightTheme';
import DarkTheme from '../assets/styles/Theme/DarkTheme';
import { useStateWithLocalStorage } from '../utils';

const ThemeContext = createContext({});

export const ThemeContextProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useStateWithLocalStorage('darkTheme', false);
  const [theme, setTheme] = useStateWithLocalStorage('theme', 'user');

  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode, theme, setTheme }}>
      {darkMode ? <DarkTheme /> : <LightTheme />}
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
