import { useTranslation } from 'react-i18next';
import { Card, Table } from 'antd';
import { DescriptionList } from '../../../components';
import { useInvoiceInfosListContent } from './ListContent/InvoiceInfosListContent';
import { useCustomerListContent } from './ListContent/CustomerInfosListContent';
import { useBodyInfosListContent } from './ListContent/InfosBodyListContent';
import { useTotalsListContent } from './ListContent/TotalsListContent';
import { InvoiceTable } from '../InvoiceTable';
import { useAdvanceColumns } from './advanceColumns';
import { CreditEdition } from './CreditEdition';
import { renderTotalsListContent } from './utils/renderTotalsListContent';
import { formatAdvanceTableData } from './utils/formatAdvanceTableData';
import { useDetailColumns } from './detailColumns';

/**
 * Component that defines the structure of invoice detail information.
 *
 * @component
 *
 * @param {Object} datas - Data object containing invoice information.
 * @param {Array} quotationData - Array of quotation data to be displayed.
 * @param {boolean} creditEdition - Boolean to display credit Edition.
 * @param {function} setCreditEdition - Function to set creditEdition.
 * @param {boolean} refresh - Boolean to refresh page.
 * @param {function} setRefresh - Function to set refresh.
 * @param {Object} detailsInfoData - Additional details information data.
 * @returns {Array} - Array of objects defining rows of information to be displayed.
 */
export const InvoiceDetailLayer = (
  datas,
  quotationData,
  creditEdition,
  setCreditEdition,
  refresh,
  setRefresh,
  detailsInfoData
) => {
  const { t } = useTranslation();

  const associatedInvoice = () => {
    if (datas?.invoice_type === 'CREDIT_NOTE') {
      return datas.order.invoices.find(
        (invoice) => invoice.invoice_type === 'BASIC'
      );
    }
    return null;
  };

  const invoiceInfos = useInvoiceInfosListContent(datas);
  const customerInfos = useCustomerListContent(datas?.customer);
  const bodyInfos = useBodyInfosListContent(
    datas?.body_infos,
    datas?.invoice_type,
    associatedInvoice()
  );
  const advanceColumns = useAdvanceColumns();
  const detailColumns = useDetailColumns();
  const { basicTotals, advanceTotals, creditTotals } = useTotalsListContent(
    datas,
    detailsInfoData
  );

  return (
    datas && [
      {
        row: 1,
        render: () => (
          <Card title={t('invoices.show.invoice_infos.title')}>
            <DescriptionList data={invoiceInfos} />
          </Card>
        )
      },
      {
        row: 2,
        render: () => (
          <Card title={t('invoices.show.customer_infos.title')}>
            <DescriptionList data={customerInfos} />
          </Card>
        )
      },
      {
        row: 2,
        render: () => (
          <Card title={t('invoices.show.body_infos.title')}>
            <DescriptionList data={bodyInfos} />
          </Card>
        )
      },
      ...(datas.invoice_type === 'ADVANCE' ||
      datas.invoice_type === 'ADV_INITIAL'
        ? [
            {
              row: 3,
              render: () => (
                <Card title={t('invoices.show.totals_infos.title')}>
                  <Table
                    dataSource={detailsInfoData?.detailedInfo}
                    columns={detailColumns}
                  />
                </Card>
              )
            }
          ]
        : [
            {
              row: 2,
              render: () => (
                <Card title={t('invoices.show.totals_infos.title')}>
                  <DescriptionList
                    data={renderTotalsListContent(
                      datas,
                      advanceTotals,
                      creditTotals,
                      basicTotals
                    )}
                  />
                </Card>
              )
            }
          ]),
      ...(datas?.invoice_type !== 'CREDIT_NOTE'
        ? [
            {
              row: 4,
              render: () => (
                <Card title={t('invoices.show.detail.title')}>
                  {datas?.invoice_type === 'BASIC' && !creditEdition && (
                    <InvoiceTable purpose="show" itemData={datas?.details} />
                  )}
                  {datas?.invoice_type === 'BASIC' && creditEdition && (
                    <CreditEdition
                      creditEdition={creditEdition}
                      setCreditEdition={setCreditEdition}
                      datas={datas}
                      refresh={refresh}
                      setRefresh={setRefresh}
                    />
                  )}
                  {datas &&
                    ['ADVANCE', 'ADV_INITIAL'].includes(
                      datas?.invoice_type
                    ) && (
                      <Table
                        dataSource={formatAdvanceTableData(datas, t)}
                        columns={advanceColumns}
                      />
                    )}
                </Card>
              )
            }
          ]
        : []),
      ...(datas?.remarks
        ? [
            {
              row: 5,
              render: () => (
                <Card title={t('invoices.show.remarks')}>{datas?.remarks}</Card>
              )
            }
          ]
        : [])
    ]
  );
};
