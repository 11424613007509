import React from 'react';
import PropTypes from 'prop-types';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';

/**
 * Renders a component for creating or updating accessory information.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the component (e.g., 'create' or 'update').
 * @returns {JSX.Element} The rendered CreateUpdateAccessory component.
 */
const CreateUpdateAccessory = ({ purpose }) => {
  const { basicFields, isFieldsLoading } = useFields();

  return (
    <CreateUpdateContainer
      fields={basicFields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="accessories"
      resource="accessories"
    />
  );
};

CreateUpdateAccessory.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateAccessory;
