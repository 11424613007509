import { Col, Row, Space } from 'antd';
import PropTypes from 'prop-types';

/**
 * Component that displays grouped rows of information.
 *
 * @component
 *
 * @param {Object} props - Component props.
 * @param {Array} props.datas - Array of data objects to be displayed.
 * @returns {JSX.Element} - InfosDetail component.
 */
export const InfosDetail = ({ datas }) => {
  let groupedRows = datas;

  groupedRows = groupedRows.reduce((infosData, item, index) => {
    const sortedRows = infosData;

    if (!sortedRows[item.row]) sortedRows[item.row] = [];

    sortedRows[item.row].push({
      ...item,
      key: `${item.row}-${index}`
    });

    return sortedRows;
  }, {});

  const result = Object.entries(groupedRows).map(([rowKey, row]) => (
    <Row key={rowKey} gutter={[16, 16]}>
      {row.map((col) => (
        <Col key={col.key} span={24 / row.length}>
          {col.render()}
        </Col>
      ))}
    </Row>
  ));

  return (
    <Space direction="vertical" size="large">
      {result}
    </Space>
  );
};

InfosDetail.propTypes = {
  datas: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
