/**
 * Calculates VAT-related totals based on given values and updates the form fields.
 *
 * @function
 *
 * @param {Object} values - The form values containing HT_total and VAT_rate.
 * @param {Array} VATRates - An array of VAT rates for calculations.
 * @param {Object} form - The form instance to update the fields.
 *
 * @returns {void}
 */
export const calculateCreditTotals = (values, VATRates, form) => {
  if (values.totals.HT_total && values.totals.VAT_rate) {
    const VATRate = VATRates.find(
      (rate) => rate._id === values.totals.VAT_rate
    );
    const VAT_total = values.totals.HT_total * (VATRate.percentage / 100);
    const all_included_total =
      values.totals.HT_total * (1 + VATRate.percentage / 100);

    form.setFields([
      {
        name: ['totals', 'VAT_total'],
        value: Number(VAT_total).toFixed(2)
      },
      {
        name: ['totals', 'all_included_total'],
        value: Number(all_included_total).toFixed(2)
      }
    ]);
  }
};
