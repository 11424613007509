import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Divider, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { outOfNavRoutes } from '../../utils/constants/adminRoutes';
import { useAuthContext } from '../../contexts/AuthContext';
import listContent from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { useErrorMessage } from '../../utils/errorMessage';

const Profile = () => {
  const {
    dispatchAPI,
    user: { _id }
  } = useAuthContext();
  const { t } = useTranslation();
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();

  const fetchUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', { url: `/users/${_id}` });
      setUser(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await fetchUser();
    })();
  }, [fetchUser]);

  return (
    <>
      <PageHeaderCustom
        title={t('profile.title')}
        extra={
          <Link to={{ pathname: `${outOfNavRoutes.PROFILE}/edit/${_id}` }}>
            <Button type="primary">
              {`${t('buttons.edit')} `}
              <EditOutlined />
            </Button>
          </Link>
        }
      />
      <ContentCustom>
        <Skeleton loading={isLoading} active>
          <DescriptionList data={listContent(user, t)} translate />
        </Skeleton>
        <Divider orientation="left">{t('profile.settings.title')}</Divider>
        <div>
          <Link to={`${outOfNavRoutes.PROFILE}/change-pwd`}>
            <Button type="link">{t('profile.settings.changePwd')}</Button>
          </Link>
        </div>
        <div>
          <Button type="link">{t('profile.settings.others')}</Button>
        </div>
      </ContentCustom>
    </>
  );
};

export default Profile;
