import { getItemById } from './utils/getItemById';
import { formatNumberWithSpaces } from '../../../utils/formatters/formatNumberWithSpaces';

/**
 * Calculates the price of a shaping item based on various factors.
 *
 * @function
 * @param {Object} item - The shaping item.
 * @param {boolean} materialProvidedByCustomer - Indicates if material is provided by customer.
 * @param {number} quantity - The quantity of the shaping.
 * @param {number} shapingCoefficient - The coefficient for customer-provided material.
 * @param {number} formShapingCoefficient - The shaping coefficient from the form.
 * @returns {number} The calculated total price.
 */
const calculateShapingPrice = (
  item,
  materialProvidedByCustomer,
  quantity,
  shapingCoefficient
) => {
  let basePrice = item.price;
  if (materialProvidedByCustomer) {
    basePrice *= 1 + shapingCoefficient / 100;
  }
  return basePrice * quantity;
};

/**
 * Calculates the total values for shapings based on the provided fields and data.
 *
 * @function
 * @param {Object[]} fields - An array of shaping field objects.
 * @param {Array} shapings - An array of shaping objects.
 * @param {boolean} materialProvidedByCustomer - Indicates if material is provided by customer.
 * @param {number} customerCoefficient - The customer's coefficient value.
 * @param {Array} rates - An array of rate objects.
 * @param {Object} form - The form instance to update the fields.
 * @param {number} materialIndex - The index of the material in the main array.
 * @returns {Object} An object containing the calculated values for shapings.
 */
export const calculateShapingsTotal = (
  fields,
  shapings,
  materialProvidedByCustomer,
  customerCoefficient,
  rates,
  form,
  materialIndex
) => {
  let shapingsTotalWithoutVAT = 0;
  let shapingsTotalWithVAT = 0;
  let customerCoefficientTotal = 0;
  let shapingCommissionAmount = 0;
  let shapingVATAmount = 0;
  let priceWithoutCustomerCoefficient = 0;

  fields.forEach((field, i) => {
    if (field) {
      const shapingId = field.shaping_catalog;
      const item = getItemById(shapings, shapingId);

      if (item && field.shaping_VAT_rate) {
        if (materialProvidedByCustomer) {
          form.setFields([
            {
              name: [
                'materials',
                materialIndex,
                'shapings',
                i,
                'shaping_coefficient'
              ],
              value: Number(
                Number(item.coefficient_if_provided_by_customer).toFixed(2)
              )
            }
          ]);
        }
        item.VAT_rate = field.shaping_VAT_rate;
        const shapingCoefficient =
          item.coefficient_if_provided_by_customer || 1;

        const quantity = field.quantity || 0;
        const commission = Number(field.commission) || 0;
        const vatRate = getItemById(rates, item.VAT_rate).percentage;

        const itemRawTotal = calculateShapingPrice(
          item,
          materialProvidedByCustomer,
          quantity,
          shapingCoefficient
        );

        const itemPriceWithCoefficient =
          itemRawTotal * (1 + customerCoefficient / 100);
        const itemCoefficient = itemPriceWithCoefficient - itemRawTotal;
        const itemPriceWithCommission =
          itemPriceWithCoefficient * (1 + commission / 100);
        const itemCommission = itemPriceWithCoefficient * (commission / 100);
        const itemRawTotalWithVAT =
          itemPriceWithCommission * (1 + vatRate / 100);
        const itemRawTotalVAT = itemRawTotalWithVAT - itemPriceWithCommission;

        form.setFields([
          {
            name: [
              'materials',
              materialIndex,
              'shapings',
              i,
              'unprocessed_value'
            ],
            value: formatNumberWithSpaces(
              Number(Number(itemRawTotal).toFixed(2))
            )
          },
          {
            name: [
              'materials',
              materialIndex,
              'shapings',
              i,
              'customer_coefficient_value'
            ],
            value: formatNumberWithSpaces(
              Number(Number(itemPriceWithCoefficient).toFixed(2))
            )
          },
          {
            name: ['materials', materialIndex, 'shapings', i, 'facial_value'],
            value: formatNumberWithSpaces(
              Number(Number(itemPriceWithCommission).toFixed(2))
            )
          },
          {
            name: [
              'materials',
              materialIndex,
              'shapings',
              i,
              'all_included_value'
            ],
            value: formatNumberWithSpaces(
              Number(Number(itemRawTotalWithVAT.toFixed(2)))
            )
          },
          {
            name: ['materials', materialIndex, 'shapings', i, 'commission'],
            value: formatNumberWithSpaces(Number(Number(commission.toFixed(2))))
          }
        ]);

        // console.log('shapingcommission', item, shapingCommissionAmount);

        shapingsTotalWithoutVAT += itemPriceWithCommission;
        shapingsTotalWithVAT += itemRawTotalWithVAT;
        customerCoefficientTotal += itemCoefficient;
        shapingCommissionAmount += itemCommission;
        shapingVATAmount += itemRawTotalVAT;
        priceWithoutCustomerCoefficient += itemRawTotal;
      }
    }
  });

  return {
    shapingsTotalWithoutVAT,
    shapingsTotalWithVAT,
    customerCoefficientTotal,
    priceWithoutCustomerCoefficient,
    shapingCommissionAmount,
    shapingVATAmount
  };
};
