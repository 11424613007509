/**
 * Creates a map of items using their IDs as keys.
 *
 * @function
 * @param {Array} items - Array of items to be mapped
 * @returns {Object} - A map where items IDs are keys and items objects are values
 */
export const createItemsMap = (items) =>
  items.reduce((map, item) => {
    const newMap = map;
    newMap[item._id] = item;
    return map;
  }, {});
