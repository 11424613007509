export const headers = [
  {
    label: 'type',
    key: 'type.title',
    display: true,
    required: true
  },
  {
    label: 'denomination',
    key: 'denomination',
    display: true,
    required: true
  },
  {
    label: 'phone_number',
    key: 'phone_number.number',
    display: true,
    required: true
  },
  {
    label: 'email',
    key: 'email',
    display: true,
    required: true
  },
  {
    label: 'street',
    key: 'address.street',
    display: true,
    required: true
  },
  {
    label: 'street_number',
    key: 'address.number',
    display: true,
    required: true
  },
  {
    label: 'address_details',
    key: 'address.additional',
    display: true,
    required: false
  },
  {
    label: 'postal_code',
    key: 'address.postal_code',
    display: true,
    required: true
  },
  {
    label: 'city',
    key: 'address.city',
    display: true,
    required: true
  },
  {
    label: 'country',
    key: 'address.country',
    display: true,
    required: true
  },
  {
    label: 'siren',
    key: 'siren',
    display: false,
    required: false
  },
  {
    label: 'ape',
    key: 'ape',
    display: false,
    required: false
  }
];
