import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import {
  CopyOutlined,
  FileOutlined,
  ContainerOutlined,
  UploadOutlined
} from '@ant-design/icons';

import { OrderButtons } from './OrderButtons';
import { useMasterContext } from '../../../../contexts/MasterContext';
import { routes } from '../../../../utils/constants/adminRoutes';

/**
 * Component for rendering action buttons in the order header.
 *
 * @component
 * @param {object} datas - Order data object.
 * @param {function} update - Function to update order data.
 * @param {function} duplicateQuotation - Function to duplicate the active quotation.
 * @param {function} uploadDocument - Function to upload documents.
 * @returns {JSX.Element|null} JSX element containing action buttons or null if order data is not available.
 */
export const OrderHeaderButtons = (
  datas,
  update,
  duplicateQuotation,
  uploadDocument
) => {
  const order = datas;
  const { t } = useTranslation();

  const { setPickedCustomer, setPickedOrder } = useMasterContext();
  const navigate = useNavigate();

  const displayBasicInvoiceCreationButton = () => {
    const orderContainsBasicInvoice = order?.invoices?.some(
      (invoice) => invoice.invoice_type === 'BASIC'
    );
    return (
      order?.kanban_status?.number_place >= 5 && !orderContainsBasicInvoice
    );
  };

  const redirectToInvoiceCreation = (source) => {
    setPickedCustomer(order.customer);
    setPickedOrder(order);
    navigate(`${routes.INVOICES}/create/${source}`);
  };

  return (
    order && (
      <>
        {order?.kanban_status?.number_place < 5 && (
          <Button icon={<CopyOutlined />} onClick={duplicateQuotation}>
            {t('orders.buttons.duplicate_quotation')}
          </Button>
        )}
        {displayBasicInvoiceCreationButton() && (
          <Button
            icon={<FileOutlined />}
            onClick={() => redirectToInvoiceCreation('BASIC')}
          >
            {t('orders.buttons.create-basic')}
          </Button>
        )}
        <Button
          icon={<FileOutlined />}
          onClick={() => redirectToInvoiceCreation('ADVANCE')}
        >
          {t('orders.buttons.create-advance')}
        </Button>
        <OrderButtons
          display={order?.kanban_status?.number_place >= 5}
          resourceName="orders"
          action={() => uploadDocument('LAYSHEET')}
          name="laySheet"
          icon={<UploadOutlined />}
        />
        <OrderButtons
          display={order?.kanban_status?.number_place >= 5}
          resourceName="orders"
          action={() => uploadDocument('RECEIPT')}
          name="acceptance-report"
          icon={<UploadOutlined />}
        />
        <OrderButtons
          danger
          display={order?.status !== 'ARCHIVED'}
          resourceName="orders"
          action={() => update({ status: 'ARCHIVED', kanban_status: null })}
          name="archived"
          icon={<ContainerOutlined />}
        />
      </>
    )
  );
};
