import React, { useState, useRef } from 'react';
import { Row, Col, message, Tag, Alert } from 'antd';
import ReactFlow, {
  removeElements,
  MiniMap,
  Controls,
  Background,
  ReactFlowProvider,
  getOutgoers,
  getIncomers,
  getConnectedEdges
} from 'react-flow-renderer/nocss';
import 'react-flow-renderer/dist/style.css';
import 'react-flow-renderer/dist/theme-default.css';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
// import WFLToolbar from './WFLToolbar';
import Sidebar from './SideBar';
import {
  getflowCategory,
  SubProcessNode,
  DecisionNode,
  controlWFLIntegrity,
  isValidSmoothstep,
  hasOneInputNode,
  hasOneOutputNode
} from './utils';

import WFLDrawer from './WFLDrawer/WFLDrawer';
// import ButtonEdge from './ButtonEdge';

// let id = 0;
// const getId = () => `dndnode_${id++}`;

const WFLMap = ({ elements, setElements, workflowId, workflowData }) => {
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [selectedElement, setSelectedElement] = useState({});
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const { t } = useTranslation();

  const onLoad = (instance) => {
    setReactFlowInstance(instance);
    instance.fitView();
  };

  const getId = () => {
    let maxId = 0;
    elements.forEach((elm) => {
      const newId = parseInt(elm.id.replace('dndnode_', ''), 10);
      maxId = Math.max(newId, maxId);
    });
    maxId += 1;
    return maxId;
  };

  const showDrawer = () => setIsDrawerVisible(true);
  const closeDrawer = () => setIsDrawerVisible(false);

  const onDragOver = (event) => {
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.dropEffect = 'move';
  };

  const onDrop = (event) => {
    event.preventDefault();

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const nodeType = event.dataTransfer.getData('application/reactflow');
    const type = getflowCategory(nodeType);

    // if type is subprocess, get processId from dataTransfer
    const processId = event.dataTransfer.getData(
      'application/reactflow/processId'
    );

    const processName = event.dataTransfer.getData(
      'application/reactflow/processName'
    );

    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top
    });

    const newNode = {
      id: `dndnode_${getId()}`,
      type,
      position,
      data: { label: `${processName || nodeType}`, _id: processId }
    };

    const newElms = elements.concat(newNode);
    setElements(newElms);
  };

  const onElementsChange = (newElements) => {
    const elementsToSet = controlWFLIntegrity(selectedElement, newElements);

    let localElements = elementsToSet;
    // control integrity of each node
    elementsToSet.forEach((element) => {
      localElements = controlWFLIntegrity(element, localElements);
    });

    setElements(localElements);

    message.success("L'élément a été mis à jour");
  };

  const onConnect = (params) => {
    const response = isValidSmoothstep(params, elements);
    if (!response.status) {
      message.error(t(response.message));
      return false;
    }
    const newParams = {
      ...params,
      id: `dndnode_${getId()}`,
      type: 'smoothstep'
    };
    onElementsChange(elements.concat(newParams));
    return true;
  };

  const onNodeDragStop = (event, node) => {
    const newElements = elements.map((elm) => {
      if (elm.id === event.target.getAttribute('data-id')) return node;
      return elm;
    });
    setElements(newElements);
  };

  const onElementsRemove = (elementsToRemove) => {
    const newElements = [...removeElements(elementsToRemove, elements)];

    // close drawer if selected element is removed
    if (elementsToRemove.includes(selectedElement)) closeDrawer();

    onElementsChange(newElements);
  };

  const onElementClick = (event, element) => {
    setSelectedElement(element);
    if (element.type !== 'smoothstep') showDrawer();
  };

  const nodeTypes = {
    decision: DecisionNode,
    subprocess: SubProcessNode
  };

  // const edgeTypes = {
  //   buttonedge: ButtonEdge,
  // };

  const renderWFLNonConform = () => {
    const nonConformElms = [];
    if (workflowData && (!workflowData.title || !workflowData.status)) {
      return (
        <Row style={{ padding: 5 }}>
          <Alert
            style={{ width: '100%' }}
            description="Le titre et le statut du workflow sont obligatoires"
            type="warning"
            closable={false}
            showIcon
          />
        </Row>
      );
    }
    if (!(hasOneInputNode(elements) && hasOneOutputNode(elements))) {
      return (
        <Row style={{ padding: 5 }}>
          <Alert
            style={{ width: '100%' }}
            description="Il doit y avoir exactement 1 output et 1 input"
            type="warning"
            closable={false}
            showIcon
          />
        </Row>
      );
    }

    elements.forEach((element) => {
      const isRelevantNode =
        element.type !== 'smoothstep' && element.type !== 'output';
      const isConform = isRelevantNode && element.data.isConform === true;
      if (isRelevantNode && !isConform) nonConformElms.push(element);
    });

    const elmsToRender = nonConformElms.map((element) => {
      if (element.type === 'input')
        return (
          <Tag color="processing" key={`${element.id}`}>
            {element.data.label}
          </Tag>
        );
      if (element.type === 'decision')
        return (
          <Tag color="success" key={`${element.id}`}>
            {element.data.label}
          </Tag>
        );
      if (element.type === 'output')
        return (
          <Tag color="error" key={`${element.id}`}>
            {element.data.label}
          </Tag>
        );
      return (
        <Tag color="default" key={`${element.id}`}>
          {element.data.label}
        </Tag>
      );
    });

    if (nonConformElms.length > 0)
      return (
        <Row style={{ padding: 5 }}>
          <Alert
            style={{ width: '100%' }}
            // message="Elements non conformes :"
            description={
              <>Elements non conformes à corriger : {elmsToRender}</>
            }
            type="warning"
            closable={false}
            showIcon
          />
        </Row>
      );
    return (
      <Row style={{ padding: 5 }}>
        <Alert
          style={{ width: '100%' }}
          description={<>Tout est conforme, vous pouvez valider</>}
          type="success"
          showIcon
        />
      </Row>
    );
  };

  return (
    <Row style={{ minHeight: 600, backgroundColor: '#fff' }}>
      <Col xs={24} md={5}>
        <Sidebar />
      </Col>
      <Col xs={24} md={19}>
        {renderWFLNonConform()}
        <Row>
          <div className="dndflow">
            <ReactFlowProvider>
              {/* <Row>
                <Col span={24}>
                  <WFLToolbar />
                </Col>
              </Row> */}
              <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                <ReactFlow
                  elements={elements}
                  onConnect={onConnect}
                  onElementClick={onElementClick}
                  onElementsRemove={onElementsRemove}
                  onLoad={onLoad}
                  onNodeDragStop={onNodeDragStop}
                  onDrop={onDrop}
                  onDragOver={onDragOver}
                  snapToGrid
                  snapGrid={[25, 25]}
                  nodeTypes={nodeTypes}
                  // edgeTypes={edgeTypes}
                >
                  <MiniMap
                    nodeStrokeColor="#1a192b"
                    nodeColor={(n) => {
                      if (n.style?.background) return n.style.background;
                      return '#fff';
                    }}
                    nodeBorderRadius={2}
                  />
                  <Controls />
                  <Background color="#000" gap={16} />
                </ReactFlow>
              </div>
            </ReactFlowProvider>
          </div>
        </Row>
      </Col>
      <WFLDrawer
        visible={isDrawerVisible}
        workflowId={workflowId}
        close={closeDrawer}
        selectedElement={selectedElement}
        elements={elements}
        setElements={onElementsChange}
        getOutgoers={getOutgoers}
        getIncomers={getIncomers}
        getConnectedEdges={getConnectedEdges}
        onElementsRemove={onElementsRemove}
      />
    </Row>
  );
};

WFLMap.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      position: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired
      }),
      data: PropTypes.shape({
        label: PropTypes.string.isRequired
      }).isRequired
    })
  ),
  setElements: PropTypes.func.isRequired,
  workflowId: PropTypes.string,
  workflowData: PropTypes.shape({
    title: PropTypes.string,
    status: PropTypes.string,
    description: PropTypes.string
  }).isRequired
};

WFLMap.defaultProps = {
  elements: [],
  workflowId: undefined
};

export default WFLMap;
