import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Tag } from 'antd';
import { Link } from 'react-router-dom';
import { routes } from '../../../../utils/constants/adminRoutes';

/**
 * Generates a list of label and content pairs to display body information.
 *
 * @hook
 *
 * @param {Object} bodyInfos - The body information data to display.
 * @param {string} type - Type of the invoice
 * @param {Object} associatedInvoice - Associated Invoice.
 * @returns {Array} An array of label and content pairs.
 */
export const useBodyInfosListContent = (bodyInfos, type, associatedInvoice) => {
  const { t } = useTranslation();
  const formatDate = 'DD/MM/YYYY';

  return (
    bodyInfos && [
      {
        label: t('invoices.show.body_infos.label'),
        content: bodyInfos.label || t('errors.form.not_specified'),
        span: 3
      },
      {
        label: t('invoices.show.body_infos.creator'),
        content: bodyInfos.creator || t('errors.form.not_specified'),
        span: 2
      },
      {
        label: t('invoices.show.body_infos.emission_date'),
        content:
          moment(bodyInfos.emission_date).format(formatDate) ||
          t('errors.form.not_specified'),
        span: 1
      },
      {
        label: t('invoices.show.body_infos.due_date'),
        content:
          moment(bodyInfos.due_date).format(formatDate) ||
          t('errors.form.not_specified'),
        span: 2
      },
      {
        label: t('invoices.show.body_infos.payment_choice.title'),
        content:
          t(
            `invoices.show.body_infos.payment_choice.${bodyInfos.payment_choice}`
          ) || t('errors.form.not_specified'),
        span: 1
      },
      ...(type !== 'CREDIT_NOTE'
        ? [
            {
              label: t('invoices.show.body_infos.deadline_payment.title'),
              content:
                t(
                  `invoices.show.body_infos.deadline_payment.${bodyInfos.deadline_payment}`
                ) || t('errors.form.not_specified'),
              span: 2
            },
            {
              label: t('invoices.show.body_infos.penalty.title'),
              content:
                t('invoices.show.body_infos.penalty.value', {
                  value: bodyInfos.penalty
                }) || t('errors.form.not_specified'),
              span: 1
            }
          ]
        : [
            {
              label: t('invoices.show.body_infos.associated_invoice'),
              content: (
                <Link to={`${routes.INVOICES}/show/${associatedInvoice._id}`}>
                  <Tag>{associatedInvoice?.number}</Tag>
                </Link>
              )
            }
          ])
    ]
  );
};
