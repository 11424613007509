import { DatePicker, Tag } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;

/**
 * Hook that generates the content for displaying order information in a list.
 *
 * @hook
 * @param {object} order - Order data object.
 * @returns {Array} Array of objects containing label, dataIndex, content, and input for each order information field.
 */
export const useOrderListContent = (order) => {
  const { t } = useTranslation();

  const getFullAdress = ({ number, street, additional, postal_code, city }) => (
    <>
      {number} {street}
      <br />
      {additional && (
        <>
          {additional} <br />
        </>
      )}
      {postal_code} {city}
    </>
  );

  const constructionDates = order?.construction_date?.map((date) =>
    moment(date).format('DD/MM/YYYY')
  );
  const measurementDates = order?.measurement_date?.map((date) =>
    moment(date).format('DD/MM/YYYY')
  );

  const rangeDates = order?.construction_date?.map((date) => moment(date));
  const rangeMeasurementDates = order?.measurement_date?.map((date) =>
    moment(date)
  );

  const activeQuotation = order?.quotations?.find(
    (quotation) => quotation.status === 'ACTIVE'
  );

  return [
    {
      label: t('orders.show.quotations'),
      content:
        (activeQuotation && (
          <Link
            to={{
              pathname: `/quotations/show/${activeQuotation?.quotation?._id}`
            }}
          >
            <Tag>{activeQuotation?.quotation?.number}</Tag>
          </Link>
        )) ||
        t('errors.form.not_specified')
    },
    {
      label: t('orders.show.project_type'),
      content:
        order?.project?.project_type?.title || t('errors.form.not_specified')
    },
    {
      label: t('orders.show.worksite_address'),
      content:
        (order?.worksite_address && getFullAdress(order?.worksite_address)) ||
        t('errors.form.not_specified')
    },
    {
      label: t('orders.show.construction_date'),
      dataIndex: ['construction_date'],
      content:
        (order?.construction_date &&
          t('orders.show.between_dates', {
            start: constructionDates[0],
            end: constructionDates[1]
          })) ||
        t('errors.form.not_specified'),
      input: <RangePicker defaultValue={rangeDates} format="DD/MM/YYYY" />
    },
    {
      label: t('orders.show.measurement_date'),
      dataIndex: ['measurement_date'],
      content:
        (order?.measurement_date?.length &&
          t('orders.show.between_dates', {
            start: measurementDates[0],
            end: measurementDates[1]
          })) ||
        t('errors.form.not_specified'),
      input: (
        <RangePicker defaultValue={rangeMeasurementDates} format="DD/MM/YYYY" />
      )
    }
  ];
};
