import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CreateTemplate } from '../../routes/templates/CreateTemplate';
import { Templates } from '../../routes/templates/Templates';
import { Exception } from '../../components';
import { TemplateContextProvider } from '../../contexts/TemplatesContext';

export const TemplateRouter = () => (
  <TemplateContextProvider>
    <Routes>
      <Route path="/create" element={<CreateTemplate />} />
      <Route index element={<Templates />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </TemplateContextProvider>
);
