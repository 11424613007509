import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuotationContext } from '../../../../contexts/QuotationContext';

const { TextArea } = Input;

export const AccessoriesForm = () => {
  const { t } = useTranslation();
  const { VATRates, accessories } = useQuotationContext();
  const VAT_Accessory = VATRates.find((rate) => rate.percentage === 20);

  const booleanOptions = [
    {
      label: t('quotations.form.accessories.isBase'),
      boolean: false
    },
    {
      label: t('quotations.form.accessories.isOption'),
      boolean: true
    }
  ];

  return (
    <Form.Item className="quotation-accessories-title">
      <Form.List name={['accessories']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Row
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column'
                }}
                key={key}
              >
                <Row style={{ justifyContent: 'space-between' }}>
                  <Col>
                    <span>{`${t('quotations.form.accessories.item')} ${
                      key + 1
                    }`}</span>
                  </Col>
                  <Col>
                    <Form.Item
                      name={[name, 'is_option']}
                      valuePropName="select"
                    >
                      <Select
                        placeholder={t('quotations.form.accessories.isOption')}
                        style={{ width: '150px' }}
                      >
                        {booleanOptions.map((option) => (
                          <Select.Option
                            value={option.boolean}
                            key={option.boolean}
                          >
                            {option.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <CloseOutlined onClick={() => remove(name)} />
                  </Col>
                </Row>
                <Col>
                  <Row style={{ flexDirection: 'column' }}>
                    <Form.Item
                      name={[name, 'accessory_catalog']}
                      rules={[
                        {
                          required: true
                        }
                      ]}
                      label={t(
                        'quotations.form.accessories.accessories_catalog'
                      )}
                      {...restField}
                    >
                      <Select showSearch optionFilterProp="children">
                        {(accessories || []).map((accessory) => (
                          <Select.Option
                            key={accessory._id}
                            value={accessory._id}
                          >
                            {`${accessory.nomenclature}`}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={[name, 'quantity']}
                      rules={[
                        {
                          required: true
                        }
                      ]}
                      label={t('quotations.form.services.quantity')}
                      {...restField}
                    >
                      <Input type="number" />
                    </Form.Item>
                    <Form.Item
                      name={[name, 'facial_value']}
                      label={t('quotations.form.materials.facial_value')}
                    >
                      <Input type="text" disabled />
                    </Form.Item>
                    <Form.Item
                      name={[name, 'VAT_rate']}
                      rules={[
                        {
                          required: true
                        }
                      ]}
                      label={t('quotations.form.accessories.VAT_rate')}
                      initialValue={VAT_Accessory?._id}
                    >
                      <Select>
                        <Select.Option
                          key={VAT_Accessory._id}
                          value={VAT_Accessory._id}
                        >
                          {`${VAT_Accessory.percentage}%`}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={[name, 'all_included_value']}
                      label={t('quotations.form.materials.all_included_value')}
                    >
                      <Input type="text" disabled />
                    </Form.Item>
                    <Form.Item
                      name={[name, 'remarks']}
                      label={t('quotations.form.accessories.remarks')}
                      {...restField}
                    >
                      <TextArea />
                    </Form.Item>
                  </Row>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                {t('quotations.form.accessories.add_accessory')}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form.Item>
  );
};
