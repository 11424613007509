// Main nav menuItems
export const routes = {
  PLANNING: '/planning',
  ORDERS: '/orders'
};

// Main nav subMenuItems
export const subRoutes = {};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  PASSWORD_CREATION: '/post-pwd'
};

// url search parameters
export const pathSearches = {};
