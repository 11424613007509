import { useCallback } from 'react';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Row, Select, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useQuotationContext } from '../../../../contexts/QuotationContext';

export const ShapingForm = ({ fieldName, shapingFilter }) => {
  const { t } = useTranslation();
  const {
    measureUnits,
    shapings,
    selectedRate,
    globalCommission,
    VATRates,
    materialProvidedByCustomer
  } = useQuotationContext();

  const customSelect = useCallback(() => {
    let source = shapings;
    if (shapingFilter) {
      const filteredShapings = shapings.filter(
        (shaping) =>
          shaping.category &&
          shaping.category.title === shapingFilter &&
          shaping.status === 'ACTIVE'
      );
      source = filteredShapings;
    }
    return (
      <Select allowClear showSearch optionFilterProp="children">
        {(source || []).map((shaping) => (
          <Select.Option key={shaping._id} value={shaping._id}>
            {`${shaping.nomenclature}`}
          </Select.Option>
        ))}
      </Select>
    );
  }, [shapingFilter]);

  return (
    <Form.List name={[fieldName, 'shapings']}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Row key={key}>
              <Card
                title={`${t('quotations.form.materials.shapings')} ${key + 1}`}
                extra={<CloseOutlined onClick={() => remove(name)} />}
              >
                <Col>
                  <Form.Item
                    name={[name, 'shaping_catalog']}
                    rules={[
                      {
                        required: true
                      }
                    ]}
                    label={t('quotations.form.materials.shaping_catalog')}
                    {...restField}
                  >
                    {customSelect(name)}
                  </Form.Item>
                  <Form.Item
                    name={[name, 'quantity']}
                    rules={[
                      {
                        required: true
                      }
                    ]}
                    label={t('quotations.form.materials.quantity')}
                    {...restField}
                  >
                    <InputNumber min={0} />
                  </Form.Item>
                  {materialProvidedByCustomer && (
                    <Form.Item
                      name={[name, 'shaping_coefficient']}
                      label={t('quotations.form.materials.shaping_coefficient')}
                      {...restField}
                    >
                      <Input type="text" disabled />
                    </Form.Item>
                  )}
                  <Form.Item
                    name={[name, 'unit']}
                    rules={[
                      {
                        required: true
                      }
                    ]}
                    label={t('quotations.form.materials.unit')}
                    {...restField}
                  >
                    <Select>
                      {(measureUnits || []).map((unit) => (
                        <Select.Option key={unit._id} value={unit._id}>
                          {`${unit.title ? unit.title : ''}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={[name, 'unprocessed_value']}
                    label={t('quotations.form.materials.unprocessed_value')}
                  >
                    <Input type="text" disabled />
                  </Form.Item>
                  <Form.Item
                    name={[name, 'customer_coefficient_value']}
                    label={t(
                      'quotations.form.materials.customer_coefficient_value'
                    )}
                  >
                    <Input type="text" disabled />
                  </Form.Item>
                  <Form.Item
                    name={[name, 'commission']}
                    label={t('quotations.form.services.commission')}
                    initialValue={globalCommission}
                  >
                    <Input type="text" initialValue={globalCommission} />
                  </Form.Item>
                  <Form.Item
                    name={[name, 'facial_value']}
                    label={t('quotations.form.materials.facial_value')}
                  >
                    <Input type="text" disabled />
                  </Form.Item>
                  <Form.Item
                    name={[name, 'shaping_VAT_rate']}
                    rules={[
                      {
                        required: true
                      }
                    ]}
                    label={t('quotations.form.materials.VAT_rate')}
                    initialValue={selectedRate?._id}
                  >
                    <Select>
                      {(VATRates || []).map((rate) => (
                        <Select.Option key={rate._id} value={rate._id}>
                          {`${rate.percentage}%`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={[name, 'all_included_value']}
                    label={t('quotations.form.materials.all_included_value')}
                  >
                    <Input type="text" disabled />
                  </Form.Item>
                </Col>
              </Card>
            </Row>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              {t('quotations.form.materials.add_shaping')}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

ShapingForm.propTypes = {
  fieldName: PropTypes.number,
  shapingFilter: PropTypes.string
};

ShapingForm.defaultProps = {
  fieldName: undefined,
  shapingFilter: undefined
};
