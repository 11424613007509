import React, { useCallback, useEffect, useState } from 'react';
import { Tabs, Spin, Row, Col } from 'antd';
import { ContentCustom } from '../../components/ContentCustom/ContentCustom';
import WFLNode from './WFLNode';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const SideBar = () => {
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [workflows, setWorkflows] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const getWorkflows = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/workflows/?status=VALIDATED`
      });
      setWorkflows(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    } finally {
      setIsFieldsLoading(false);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getWorkflows();
  }, []);

  useEffect(() => {
    getSelectOptions();
  }, []);

  const tabContent = () => [
    {
      label: 'Outils',
      key: '1',
      children: (
        <ContentCustom style={{ height: '100%' }}>
          <Row align="middle">
            <Col xs={6} md={24}>
              <WFLNode type="start" />
            </Col>
            <Col xs={6} md={24}>
              <WFLNode type="end" />
            </Col>
            <Col xs={6} md={24}>
              <WFLNode type="decision" />
            </Col>
            <Col xs={6} md={24}>
              <WFLNode type="task" />
            </Col>
            <Col xs={6} md={24}>
              <WFLNode type="log" />
            </Col>
          </Row>
          {/* <WFLNode type="subprocess" /> */}
        </ContentCustom>
      )
    },
    {
      label: 'Sous-processus',
      key: '2',
      children: !isFieldsLoading ? (
        workflows.map((wfl) => (
          <WFLNode
            key={wfl._id}
            type="subprocess"
            title={wfl.title}
            processId={wfl._id}
            processName={wfl.title}
          />
        ))
      ) : (
        <Spin />
      )
    }
  ];

  return (
    <aside style={{ padding: 0, paddingRight: 40, height: '100%' }}>
      <Tabs defaultActiveKey="1" items={tabContent()} />
    </aside>
  );
};

export default SideBar;
