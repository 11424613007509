/**
 * Calculate and set total fields for a quotation form.
 *
 * @param {object} quotation - The quotation data.
 * @param {array} itemData - The array of item data.
 * @param {number} paid - The paid amount.
 * @param {Function} t - The translation function.
 * @param {object} form - The form object to set fields.
 *
 * @returns {void}
 */
export const setTotalFields = (quotation, itemData, paid, t, form) => {
  let extraAdditionHTValue = 0;
  let extraAdditionTotalAllIncluded = 0;

  let extraDiscountHTValue = 0;

  const paidTotal = paid || 0;

  const vatSums = {};

  itemData.forEach((item) => {
    if (item.type !== t('invoices.form.discount')) {
      const vatPercentage = item.VAT_rate.percentage;
      const vatPrice = parseFloat(item.VAT_price);

      if (!Object.prototype.hasOwnProperty.call(vatSums, vatPercentage)) {
        vatSums[vatPercentage] = 0;
      }

      vatSums[vatPercentage] += vatPrice;

      if (item.shapings && Array.isArray(item.shapings)) {
        item.shapings.forEach((shaping) => {
          const shapingVatPercentage = shaping.VAT_rate.percentage;
          const shapingVatPrice = parseFloat(shaping.VAT_price);

          if (
            !Object.prototype.hasOwnProperty.call(vatSums, shapingVatPercentage)
          ) {
            vatSums[shapingVatPercentage] = 0;
          }

          vatSums[shapingVatPercentage] += shapingVatPrice;
        });
      }
    }
  });

  itemData.map((item) => {
    if (item.type === t('invoices.form.extra_addition')) {
      extraAdditionHTValue += Number(item.VAT_excluded_price);
      extraAdditionTotalAllIncluded += Number(item.all_included_value);
    }
    if (item.type === t('invoices.form.discount')) {
      extraDiscountHTValue += Number(item.VAT_excluded_price);
    }
    return false;
  });

  const baseTotal =
    Number(quotation.summary.base_total.total_excluding_VAT) +
    extraAdditionHTValue;
  const updatedHTTotal =
    Number(quotation.summary.base_total.total_excluding_VAT) +
    extraAdditionHTValue -
    extraDiscountHTValue;

  const updatedValues = Object.entries(vatSums).map(([percentage, sum]) => {
    const updatedValue = (updatedHTTotal * sum) / baseTotal;
    return {
      VAT_rate: parseInt(percentage, 10),
      updatedValue: Number(updatedValue).toFixed(2)
    };
  });

  const updatedVATTotal = () => {
    let VATTotal = 0;
    for (let i = 0; i < updatedValues.length; i += 1) {
      VATTotal += Number(updatedValues[i].updatedValue);
    }

    return Number(VATTotal);
  };

  const AITotal = Number(updatedHTTotal) + updatedVATTotal();
  const dueTotal = AITotal - paidTotal;

  form.setFields([
    {
      name: ['totals', 'extra_addition'],
      value: Number(extraAdditionTotalAllIncluded).toFixed(2)
    },
    {
      name: ['totals', 'extra_discount'],
      value: Number(extraDiscountHTValue).toFixed(2)
    },
    {
      name: ['totals', 'HT_total'],
      value: Number(updatedHTTotal).toFixed(2)
    },
    {
      name: ['totals', 'VAT_total'],
      value: Number(updatedVATTotal()).toFixed(2)
    },
    {
      name: ['totals', 'all_included_total'],
      value: Number(AITotal).toFixed(2)
    },
    {
      name: ['totals', 'due'],
      value: Number(dueTotal).toFixed(2)
    },
    {
      name: ['totals', 'paid'],
      value: Number(paidTotal).toFixed(2)
    }
  ]);
};
