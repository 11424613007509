import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ConfigurationTable from './ConfigurationTable';
import useConfigurationContext from './ConfigurationContext';

/**
 * A component for managing configurations for a specific resource.
 *
 * @component
 * @param {object} props - The component props.
 * @param {string} props.resourceName - The name of the resource.
 * @param {array} props.resourceColumns - The columns configuration for the resource table.
 * @returns {JSX.Element} The rendered Configuration component.
 */
export const Configuration = ({
  resourceName,
  resourceColumns,
  addItemButton
}) => {
  const { forceRefresh, fetchItems, addItem } = useConfigurationContext();
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [newItem] = useState({});

  useEffect(() => {
    fetchItems(resourceName, setItems);
  }, [forceRefresh]);

  return (
    <>
      {items.length > 0 && (
        <ConfigurationTable
          resourceColumns={resourceColumns}
          resourceName={resourceName}
          items={items}
        />
      )}
      {addItemButton && (
        <Button
          type="dashed"
          style={{ width: '100%' }}
          onClick={() => addItem(newItem, resourceName)}
        >
          {t(`${resourceName}.form.add`)}
        </Button>
      )}
    </>
  );
};

Configuration.propTypes = {
  resourceName: PropTypes.string.isRequired,
  resourceColumns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addItemButton: PropTypes.bool
};

Configuration.defaultProps = {
  addItemButton: true
};
