import React from 'react';
import {
  FundOutlined,
  SettingOutlined,
  SnippetsOutlined,
  SolutionOutlined,
  FolderOutlined,
  UserOutlined,
  ProfileOutlined,
  ShoppingOutlined,
  AuditOutlined,
  CalculatorOutlined,
  CalendarOutlined,
  ThunderboltOutlined
} from '@ant-design/icons';

const navMenuLogos = {
  HOME: <FundOutlined />,
  USERS: <UserOutlined />,
  CUSTOMERS: <SolutionOutlined />,
  PROJECTS: <FolderOutlined />,
  CATALOG: <SnippetsOutlined />,
  QUOTATIONS: <AuditOutlined />,
  INVOICES: <CalculatorOutlined />,
  ORDERS: <ProfileOutlined />,
  PLANNING: <CalendarOutlined />,
  PURCHASES: <ShoppingOutlined />,
  PARAMETERS: <SettingOutlined />,
  WORKFLOWS: <ThunderboltOutlined />
};

export default navMenuLogos;
