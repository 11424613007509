import { useTranslation } from 'react-i18next';

export const usePwdPattern = () => {
  const { t } = useTranslation();

  return [
    {
      pattern: /(?=.*[0-9])/,
      message: t('login.pwdDigit')
    },
    {
      pattern: /(?=.*[a-z])/,
      message: t('login.pwdLowerCaseChar')
    },
    {
      pattern: /(?=.*[A-Z])/,
      message: t('login.pwdUpperCaseChar')
    },
    {
      pattern: /(?=.*[!@#$%^&*])/,
      message: t('login.pwdSpecialChar')
    },
    {
      pattern: /.{8,32}/,
      message: t('login.pwdLength')
    }
  ];
};
