import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, Tag, Button, Popconfirm } from 'antd';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../../utils/errorMessage';
import WFLElementForm from './WFLElementForm';

const WFLDrawer = ({
  visible,
  close,
  selectedElement,
  elements,
  setElements,
  onElementsRemove,
  workflow
}) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const getLinkTagColor = (elm) => {
    switch (elm.type) {
      case 'input':
        return 'processing';
      case 'decision':
        return 'success';
      case 'output':
        return 'error';
      default:
        return 'default';
    }
  };

  const setTitle = () => {
    if (
      Object.keys(selectedElement).length === 0 ||
      selectedElement.type === 'smoothstep'
    )
      return 'Gestion du workflow';
    return (
      <>
        {`Gestion de `}
        <Tag color={getLinkTagColor(selectedElement)}>
          {selectedElement.data.label}
        </Tag>
      </>
    );
  };

  const title = setTitle();

  const deleteElement = () => {
    try {
      onElementsRemove([selectedElement]);
      close();
    } catch (e) {
      message('delete_fail');
    }
  };
  // console.log('WFLMap', elements);

  return (
    <Drawer
      title={title}
      extra={
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={deleteElement}
          icon={<WarningOutlined />}
          placement="bottomRight"
        >
          <Button type="danger">
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      }
      width="50%"
      placement="right"
      maskClosable={false}
      onClose={close}
      destroyOnClose
      open={visible}
      key="drawer"
    >
      <WFLElementForm
        selectedElement={selectedElement}
        elements={elements}
        setElements={setElements}
        closeDrawer={close}
        workflow={workflow}
      />
    </Drawer>
  );
};

WFLDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  selectedElement: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    data: PropTypes.shape({
      label: PropTypes.string
    })
  }).isRequired,
  elements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setElements: PropTypes.func.isRequired,
  onElementsRemove: PropTypes.func.isRequired,
  workflow: PropTypes.shape({}).isRequired
};

export default WFLDrawer;
