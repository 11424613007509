import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { userRoles } from '../../utils/constants/tagColors';

export const useListContent = (data = {}) => {
  const { t } = useTranslation();
  const {
    first_name,
    last_name,
    role,
    address,
    date_of_birth,
    email,
    phone_number
  } = data;

  return [
    {
      label: 'users.form.role',
      span: 3,
      content:
        (role && (
          <Tag color={userRoles[role.split(':')[1]]}>
            {t(`users.tags.${role.split(':')[1]}`)}
          </Tag>
        )) ||
        '-'
    },
    {
      label: 'users.form.last_name',
      span: 1,
      content: last_name || '-'
    },
    {
      label: 'users.form.first_name',
      span: 2,
      content: first_name || '-'
    },
    {
      label: 'users.form.email',
      span: 1,
      content: email || '-'
    },
    {
      label: 'users.form.phone_number_full',
      span: 2,
      content:
        phone_number && phone_number.number ? (
          <div>
            {`${phone_number.country_code} ${phone_number.number.slice(
              0,
              1
            )} ${phone_number.number.slice(1).replace(/(.{2})/g, '$1 ')}
                    `}
          </div>
        ) : (
          '-'
        )
    },
    {
      label: 'users.form.address_full',
      span: 3,
      content:
        address && address.number !== '' ? (
          <>
            {address.number} {address.street}
            <br />
            {address.additional}
            {address.additional && <br />}
            {address.postal_code} {address.city}
          </>
        ) : (
          '-'
        )
    },
    {
      label: 'users.form.date_of_birth',
      span: 1,
      content: date_of_birth ? moment(date_of_birth).format('ll') : '-'
    }
  ];
};
