import { createGlobalStyle } from 'styled-components';
import '../SCSS/user/index.scss';

export const UserTheme = createGlobalStyle`
body {
  --clientColor: #fff;
  --primaryColor: #EB5B25;
  --addColor: #EB5B25;
  --itemHoverColor: var(--primaryColor);
  --componentBackground: #fff;
  --secondaryHover: var(--componentBackground);
  --subMenuBackground: var(--componentBackground);
  --menuDropdownBackground: var(--componentBackground);
  --pickerDropdown: var(--componentBackground);
  --primaryHover: rgba(246, 246, 246, 0.36);
  --secondaryColor: #AA1D1F;
  --disabledColor: #53514F;
  --bodyBackground: #f0f2f5;
  --textColor: #464038;
  --textColorSecondary: #727274;
  --itemActiveBackground: #e6f7ff;
  --itemHoverBackground: #f0f0f0;
  --borderColor: #C7C7CE;
  --contrastBackground: #f5f5f5;
  --captionGantt_construction_date: #73DF78;
  --captionGantt_previsional_start_date: #CCC;
  --captionGantt_measurement_date: #3D66CC;
  --opacitySVG: 1;
  --primaryColorA04
  --primaryColorA07
  --primaryContrast
  --tabsGray
  --textColorInvert
  --textColorHover
  --textColorA015
  --textColorA075
  --borderColorA06
  --disabledColorA04
  --disabledColorA06
  --itemActiveColor
  --logo
  --logoSmall
  --errorColor: #AA1D1F;
  --error100
  --errorA08
}
`;
