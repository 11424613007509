import { useTranslation } from 'react-i18next';
import { Input } from 'antd';

const { TextArea } = Input;

/**
 * Hook that generates the content for displaying lay information in a list.
 *
 * @hook
 * @param {object} datas - Lay data object.
 * @returns {Array} Array of objects containing label, dataIndex, content, and input for each lay information field.
 */
export const useLayListContent = (datas) => {
  const { t } = useTranslation();

  return [
    {
      label: t('orders.show.remarks'),
      dataIndex: ['remarks'],
      content:
        (datas && (
          <TextArea
            value={datas?.remarks}
            autoSize={{
              minRows: 5,
              maxRows: 5
            }}
          />
        )) ||
        t('errors.form.not_specified'),
      input: <TextArea defaultValue={datas?.remarks} />
    }
  ];
};
