import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CreateUpdateAccessory from '../../routes/catalog/accessories/CreateUpdateAccessory';
import ShowAccesory from '../../routes/catalog/accessories/ShowAccessory';
import { Accessories } from '../../routes/catalog/accessories/Accessories';
import { AccessoryContextProvider } from '../../contexts/AccessoryContext';

const AccessoriesRouter = () => (
  <AccessoryContextProvider>
    <Routes>
      <Route
        path="/create"
        element={<CreateUpdateAccessory purpose="create" />}
      />
      <Route
        path="/edit/:id"
        element={<CreateUpdateAccessory purpose="edit" />}
      />
      <Route path="/show/:id" element={<ShowAccesory />} />
      <Route index element={<Accessories />} />
    </Routes>
  </AccessoryContextProvider>
);

export default AccessoriesRouter;
