/**
 * An array containing column header information for a table or data representation.
 * Each object in the array represents a column with various properties.
 *
 * @typedef {Object} ColumnHeader
 * @property {string} label - The label or display name of the column.
 * @property {string} key - The key or data field associated with the column.
 * @property {boolean} display - Indicates whether the column should be displayed (true) or hidden (false).
 * @property {boolean} required - Indicates whether the column is required for display (true) or optional (false).
 */

/**
 * An array of column headers used to define the columns in a table or data representation.
 *
 * @type {Array<ColumnHeader>}
 */

export const headers = [
  {
    label: 'number',
    key: 'number',
    display: true,
    required: true
  },
  {
    label: 'type',
    key: 'invoice_type',
    display: true,
    required: true
  },
  {
    label: 'customer',
    key: 'full_name',
    display: true,
    required: true
  },
  {
    label: 'order',
    key: 'order.number',
    display: true,
    required: true
  },
  {
    label: 'emission_date',
    key: 'body_infos.emission_date',
    display: true,
    required: true
  },
  {
    label: 'totalTTC',
    key: 'totals.totalTTC',
    display: true,
    required: true
  },
  {
    label: 'payed',
    key: 'totals.payed',
    display: true,
    required: true
  }
];
