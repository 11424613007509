import { createContext, useContext, useEffect, useState } from 'react';
import { useAuthContext } from './AuthContext';
import { useErrorMessage } from '../utils/errorMessage';

const QuotationContext = createContext({ isValid: false });

export const QuotationContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const [VATRates, setVATRates] = useState([]);
  const [selectedRate, setSelectedRate] = useState();
  const [globalCommission, setGlobalCommission] = useState();
  const [checkedList, setCheckedList] = useState([
    'SHAPING-MATERIALS',
    'SERVICES',
    'ACCESSORIES'
  ]);
  const [statuses, setStatuses] = useState([]);
  const [kanbanStatus, setKanbanStatus] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [accessories, setAccessories] = useState([]);
  const [totals, setTotals] = useState([]);
  const [services, setServices] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [shapings, setShapings] = useState([]);
  const [globalMaterial, setGlobalMaterial] = useState();
  const [materialProvidedByCustomer, setMaterialProvidedByCustomer] =
    useState(false);
  const [typeOfWorks, setTypeOfWorks] = useState([]);
  const [pickedTypeOfWork, setPickedTypeOfWork] = useState();
  const [measureUnits, setMeasureUnits] = useState();
  const [customerCoefficient, setCustomerCoefficient] = useState();
  const [fileList, setFileList] = useState([]);
  const [importTemplate, setImportTemplate] = useState([]);
  const [volumesArray, setVolumesArray] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [customerOptions, setCustomerOptions] = useState();
  const [statusFilter, setStatusFilter] = useState();
  const [archiveState, setArchiveState] = useState(false);

  const handleVATrate = (value) => {
    const item = VATRates.find((rate) => value === rate._id);
    return setSelectedRate(item);
  };

  const handleMaterialChoice = (value) => {
    const item = materials.find((material) => value === material._id);
    return setGlobalMaterial(item._id);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          vatRatesResponse,
          accessoriesResponse,
          customersResponse,
          servicesResponse,
          shapingsResponse,
          materialsResponse,
          statusesResponse,
          kanbanStatusesResponse,
          typeOfWorksResponse,
          measureUnitsResponse,
          importTemplateResponse
        ] = await Promise.all([
          dispatchAPI('GET', { url: 'Tvas' }),
          dispatchAPI('GET', { url: 'accessories' }),
          dispatchAPI('GET', { url: 'customers' }),
          dispatchAPI('GET', { url: 'services' }),
          dispatchAPI('GET', { url: 'shapings?populate=category' }),
          dispatchAPI('GET', {
            url: 'materials?populate=finishing_product,category'
          }),
          dispatchAPI('GET', { url: 'quotations/enums' }),
          dispatchAPI('GET', { url: 'kanbanview-quotes' }),
          dispatchAPI('GET', { url: 'type-of-works' }),
          dispatchAPI('GET', { url: 'unit-of-measures?display=true' }),
          dispatchAPI('GET', {
            url: 'template-imports?populate=file&type=QUOTE'
          })
        ]);

        setVATRates(vatRatesResponse.data);
        setAccessories(accessoriesResponse.data);
        setCustomers(customersResponse.data);
        setServices(servicesResponse.data);
        setShapings(shapingsResponse.data);
        setMaterials(materialsResponse.data);
        setStatuses(statusesResponse.data.status);
        setKanbanStatus(kanbanStatusesResponse.data);
        setTypeOfWorks(typeOfWorksResponse.data);
        setMeasureUnits(measureUnitsResponse.data);
        setImportTemplate(importTemplateResponse.data);
      } catch (e) {
        message(e);
      }
    };

    fetchData();
  }, []);

  const fetchCustomerFilters = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `quotations?populate=customer${
          statusFilter ? `&${statusFilter}` : ''
        }`
      });
      const customersArray = data
        .map((order) => order.customer)
        .filter(Boolean);
      setCustomerOptions(
        Array.from(new Set(customersArray.map(JSON.stringify))).map(JSON.parse)
      );
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    fetchCustomerFilters();
  }, [statusFilter]);

  return (
    <QuotationContext.Provider
      value={{
        VATRates,
        handleVATrate,
        selectedRate,
        checkedList,
        setCheckedList,
        globalCommission,
        setGlobalCommission,
        accessories,
        totals,
        setTotals,
        services,
        globalMaterial,
        setGlobalMaterial,
        materials,
        setMaterials,
        shapings,
        customers,
        setCustomers,
        setShapings,
        handleMaterialChoice,
        materialProvidedByCustomer,
        setMaterialProvidedByCustomer,
        statuses,
        setStatuses,
        setSelectedRate,
        kanbanStatus,
        setKanbanStatus,
        pickedTypeOfWork,
        setPickedTypeOfWork,
        typeOfWorks,
        setTypeOfWorks,
        measureUnits,
        setMeasureUnits,
        customerCoefficient,
        setCustomerCoefficient,
        fileList,
        setFileList,
        importTemplate,
        setImportTemplate,
        volumesArray,
        setVolumesArray,
        discount,
        setDiscount,
        statusFilter,
        setStatusFilter,
        archiveState,
        setArchiveState,
        customerOptions,
        setCustomerOptions
      }}
    >
      {children}
    </QuotationContext.Provider>
  );
};

export const useQuotationContext = () => {
  const context = useContext(QuotationContext);
  if (!context)
    throw new Error('Context must be used within a context provider');
  return context;
};
