export const headers = [
  {
    label: 'last_name',
    key: 'last_name',
    display: true,
    required: true
  },
  {
    label: 'first_name',
    key: 'first_name',
    display: true,
    required: true
  },
  {
    label: 'email',
    key: 'email',
    display: true,
    required: true
  },
  {
    label: 'role',
    key: 'role',
    display: true,
    required: true
  }
];
