import { Table } from 'antd';
import PropTypes from 'prop-types';
import { useMaterialsColumns } from '../Columns/materialColumns';
import { useShapingsColumns } from '../Columns/shapingsColumns';
import { ShapingsTable } from './ShapingsTable';

/**
 * MaterialsShapingsTable displays a table of materials with expandable rows showing related shapings.
 *
 * @component
 *
 * @param {Object[]} materialsShapings - Array of materials with associated shapings.
 * @returns {JSX.Element} JSX element representing the materials and shapings table.
 */
export const MaterialsShapingsTable = ({ materialsShapings }) => {
  const columns = useMaterialsColumns();
  const shapingsColumns = useShapingsColumns();

  return (
    <Table
      columns={columns}
      dataSource={materialsShapings}
      scroll={{ x: 1000 }}
      expandable={{
        expandedRowRender: (record) => {
          const shapingsWithIndex =
            record.shapings &&
            record.shapings.map((shaping, index) => ({
              ...shaping,
              index
            }));
          return ShapingsTable(shapingsWithIndex, shapingsColumns);
        },
        rowExpandable: (record) => record.shapings.length
      }}
    />
  );
};

MaterialsShapingsTable.propTypes = {
  materialsShapings: PropTypes.arrayOf(
    PropTypes.shape({
      shapings: PropTypes.arrayOf(PropTypes.shape({}))
    })
  )
};

MaterialsShapingsTable.defaultProps = {
  materialsShapings: null
};
