import { Row } from 'antd';
import { useTranslation } from 'react-i18next';

export const useListContent = ({
  type,
  denomination,
  address,
  siren,
  ape,
  email,
  phone_number
}) => {
  const { t } = useTranslation();

  return [
    {
      label: 'suppliers.show.type',
      content: (type && type.title) || t('errors.form.not_specified')
    },
    {
      label: 'suppliers.show.denomination',
      content: denomination || t('errors.form.not_specified')
    },
    {
      label: 'suppliers.show.address',
      content:
        (address && (
          <Row
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Row>{address.street}</Row>
            {address.additional && <Row>{address.additional}</Row>}
            <Row>{`${address.postal_code} ${address.city}`}</Row>
            {address.country && <Row>{address.country}</Row>}
          </Row>
        )) ||
        t('errors.form.not_specified')
    },
    {
      label: 'suppliers.show.siren',
      content: siren || t('errors.form.not_specified')
    },
    {
      label: 'suppliers.show.ape',
      content: ape || t('errors.form.not_specified')
    },
    {
      label: 'suppliers.show.email',
      content: email || t('errors.form.not_specified')
    },
    {
      label: 'suppliers.show.phone_number',
      content:
        (phone_number?.number && (
          <div>
            {`${phone_number.country_code} ${phone_number.number.slice(1)}`}
          </div>
        )) ||
        t('errors.form.not_specified')
    }
  ];
};
