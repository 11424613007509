import React, { useEffect, useState } from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { customerTypes } from '../../utils/constants/tagColors';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * Custom hook for defining table columns for displaying customer data.
 *
 * @hook
 *
 * @returns {Array} An array of column objects.
 */
const useColumns = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [enums, setEnums] = useState([]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/customers/enums' });
      setEnums(data.type);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return [
    {
      title: t('customers.table.type'),
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      filters: enums.map((type) => ({
        text: t(`customers.form.${type}`),
        value: type
      })),
      render: (type) =>
        (type && (
          <Tag color={customerTypes[type]}>{t(`customers.form.${type}`)}</Tag>
        )) ||
        t('errors.form.not_specified')
    },
    {
      title: t('customers.table.last_name'),
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: true
    },
    {
      title: t('customers.table.first_name'),
      dataIndex: 'first_name',
      key: 'first_name'
    },
    {
      title: t('customers.table.business_provider'),
      dataIndex: 'business_provider',
      key: 'business_provider',
      sorter: true
    },
    {
      title: t('customers.table.phone'),
      dataIndex: 'phone_number',
      render: (phone_number) => {
        if (phone_number?.number && phone_number.number.startsWith('0')) {
          const numberWithoutZero = phone_number.number.substring(1);
          return `${phone_number.country_code || ''} ${numberWithoutZero}`;
        }
        if (phone_number?.number) {
          return `${
            phone_number.country_code || ''
          } ${phone_number.number.slice(1)}`;
        }
        return '';
      }
    },
    {
      title: t('customers.table.email'),
      dataIndex: 'email'
    }
  ];
};

export default useColumns;
