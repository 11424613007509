import { Collapse } from 'antd';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { usePanels } from './Panels';
import { ConfigurationContextProvider } from './ConfigurationContext';

const { Panel } = Collapse;

/**
 * A component that renders configuration panels using Ant Design's Collapse component.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the Configurations component.
 */
export const Configurations = () => {
  const panels = usePanels();

  return (
    <ConfigurationContextProvider>
      <PageHeaderCustom />
      <ContentCustom>
        <Collapse>
          {panels.map((panel) => (
            <Panel key={panel.title} header={panel.title}>
              {panel.element}
            </Panel>
          ))}
        </Collapse>
      </ContentCustom>
    </ConfigurationContextProvider>
  );
};
