export const headers = [
  {
    label: 'nomenclature',
    key: 'nomenclature',
    display: true,
    required: true
  },
  {
    label: 'category',
    key: 'category.title',
    display: true,
    required: true
  },
  {
    label: 'unit',
    key: 'unit',
    display: true,
    required: true
  },
  {
    label: 'price',
    key: 'price',
    display: true,
    required: true
  },
  {
    label: 'coefficient_if_provided_by_customer',
    key: 'coefficient_if_provided_by_customer',
    display: true
  }
];
