import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useQuotationContext } from '../../../../contexts/QuotationContext';

export const BaseOptionTotalForm = ({ purpose }) => {
  const { t } = useTranslation();
  const { checkedList } = useQuotationContext();

  const purposeRelatedName =
    purpose === 'option'
      ? ['summary', 'option_total']
      : ['summary', 'base_total'];

  return (
    <Form.Item
      className="quotation-price_conditions-title"
      label={t(`quotations.form.summary.total.title.${purpose}`)}
    >
      {checkedList.includes('SHAPING-MATERIALS') && (
        <>
          <Form.Item
            name={[...purposeRelatedName, 'materials_total']}
            label={t('quotations.form.summary.total.materials_total')}
          >
            <Input type="text" disabled />
          </Form.Item>
          <Form.Item
            name={[...purposeRelatedName, 'shapings_total']}
            label={t('quotations.form.summary.total.shapings_total')}
          >
            <Input type="text" disabled />
          </Form.Item>
        </>
      )}

      {checkedList.includes('SERVICES') && (
        <Form.Item
          name={[...purposeRelatedName, 'services_total']}
          label={t('quotations.form.summary.total.services_total')}
        >
          <Input type="text" disabled />
        </Form.Item>
      )}

      {checkedList.includes('ACCESSORIES') && (
        <Form.Item
          name={[...purposeRelatedName, 'accessories_total']}
          label={t('quotations.form.summary.total.accessories_total')}
        >
          <Input type="text" disabled />
        </Form.Item>
      )}
      <Form.Item
        name={[...purposeRelatedName, 'unprocessed_values_total']}
        label={t('quotations.form.summary.total.unprocessed_values_total')}
      >
        <Input type="text" disabled />
      </Form.Item>
      <Form.Item
        name={[...purposeRelatedName, 'customer_coefficient_total']}
        label={t('quotations.form.summary.total.customer_coefficient_total')}
      >
        <Input type="text" disabled />
      </Form.Item>
      <Form.Item
        name={[...purposeRelatedName, 'commission_total']}
        label={t('quotations.form.summary.total.commission_total')}
      >
        <Input type="text" disabled />
      </Form.Item>
      <Form.Item
        name={[...purposeRelatedName, 'facial_values_total']}
        label={t('quotations.form.summary.total.facial_values_total')}
      >
        <Input type="text" disabled />
      </Form.Item>
      <Form.Item
        name={[...purposeRelatedName, 'discounted_total']}
        label={t('quotations.form.summary.total.discounted_total')}
        className="discount-label"
      >
        <Input type="text" disabled className="discount-input" />
      </Form.Item>
      <Form.Item
        name={[...purposeRelatedName, 'total_excluding_VAT']}
        label={t('quotations.form.summary.total.total_excluding_VAT')}
      >
        <Input type="text" disabled />
      </Form.Item>
      <Form.Item
        name={[...purposeRelatedName, 'VAT_total']}
        label={t('quotations.form.summary.total.VAT_total')}
      >
        <Input type="text" disabled />
      </Form.Item>
      <Form.Item
        name={[...purposeRelatedName, 'all_included_total']}
        label={t('quotations.form.summary.total.all_included_total')}
      >
        <Input type="text" disabled />
      </Form.Item>
    </Form.Item>
  );
};

BaseOptionTotalForm.propTypes = {
  purpose: PropTypes.string
};

BaseOptionTotalForm.defaultProps = {
  purpose: undefined
};
