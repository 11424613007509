import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import useColumns from './columns';
import { ListResource } from '../../../components/ListResource/ListResource';
import { ExtraFilters } from '../ExtraFilters';
import { useDownloadDocument } from '../../../utils/downloadDoc';
import { headers } from './formatter/headers';
import { useCatalogMaterialContext } from '../../../contexts/CatalogMaterialContext';
import { formatMatters } from './formatter/formatterMatter';

/**
 * Renders the Materials list page.
 * @component
 * @returns {JSX.Element} The Materials component.
 */
export const Materials = () => {
  const { t } = useTranslation();
  const { basicColumns } = useColumns();
  const { importTemplate } = useCatalogMaterialContext();
  const { formatMatter } = formatMatters();
  const { downloadDocument } = useDownloadDocument();
  const [categoryFilter, setCategoryFilter] = useState();
  const [denominationFilter, setDenominationFilter] = useState();
  const [finishingProductFilter, setFinishingProductFilter] = useState();
  const [archiveState, setArchiveState] = useState();

  const extraQuery = `${categoryFilter ? `category=${categoryFilter}` : ''}${
    denominationFilter
      ? `${categoryFilter ? '&' : ''}denomination=${denominationFilter}`
      : ''
  }${
    finishingProductFilter
      ? `${
          categoryFilter || denominationFilter ? '&' : ''
        }finishing_product=${finishingProductFilter}`
      : ''
  }`;

  return (
    <ListResource
      columns={basicColumns}
      withArchiveButton
      extraQuery={extraQuery}
      withImportButton
      populate="category,finishing_product"
      resourceName="materials"
      setArchiveState={setArchiveState}
      extraFilters={
        <ExtraFilters
          resourceModelName="material"
          translationName="materials"
          extraMaterialFilters
          extraFilters
          categoryFilter={categoryFilter}
          setCategoryFilter={setCategoryFilter}
          denominationFilter={denominationFilter}
          setDenominationFilter={setDenominationFilter}
          finishingProductFilter={finishingProductFilter}
          archiveState={archiveState}
          setFinishingProductFilter={setFinishingProductFilter}
        />
      }
      resourceModelName="Material"
      headers={headers}
      extraMenu={
        importTemplate &&
        importTemplate[0]?.file && [
          {
            key: 'template',
            label: (
              <Button
                type="link"
                onClick={() => downloadDocument(importTemplate[0]?.file)}
              >
                <DownloadOutlined style={{ fontSize: 18 }} />
                {t('buttons.template')}
              </Button>
            )
          }
        ]
      }
      formatter={formatMatter}
    />
  );
};
