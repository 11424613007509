import { useTranslation } from 'react-i18next';

export const formatMatters = () => {
  const { t } = useTranslation();

  const renderPriceThickness = (slices, thickness) => {
    if (!slices) return t('materials.form.no_thickness_found');
    const sliceThickness = slices.find(
      (slice) => slice.thickness === thickness
    );

    if (sliceThickness) {
      const sortedPrices = sliceThickness.prices_management.sort(
        (a, b) => new Date(b.application_date) - new Date(a.application_date)
      );
      return sortedPrices[0].sell_price;
    }

    return t('materials.form.no_thickness_found');
  };

  const formatMatter = (datas) => {
    const { slices_management } = datas;

    const result = {
      ...datas,
      slices_management_20: renderPriceThickness(slices_management, 20),
      slices_management_30: renderPriceThickness(slices_management, 30)
    };

    return result;
  };

  return { formatMatter, renderPriceThickness };
};
