import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import { outOfNavRoutes } from '../utils/constants/adminRoutes';
import { AdminLayout } from '../components/layouts/AdminLayout';
import { DeveloperLayout } from '../components/layouts/DeveloperLayout';
import { UserLayout } from '../components/layouts/UserLayout';

/**
 * Higher-order component that handles authentication and user role-based rendering of layouts.
 * @component
 * @returns {JSX.Element} Rendered layout component based on user role or redirects to login if not authenticated.
 */
export const RequireAuth = () => {
  const location = useLocation();
  const { isValid, user } = useAuthContext();
  const { '*': urlToken } = useParams();

  const isPostRoute = urlToken?.includes('post-pwd');

  if (!isValid && !isPostRoute) {
    return Navigate({
      to: {
        pathname: outOfNavRoutes.LOGIN
      },
      state: { from: location }
    });
  }

  const renderLayout = () => {
    switch (user?.role) {
      case 'admins:ADMIN':
      case 'admins:SUPER-ADMIN':
        return <AdminLayout />;
      case 'users:USER':
      case 'developer:DEVELOPER':
        return <DeveloperLayout />;
      case 'users:SUPER-USER':
      default:
        return <UserLayout />;
    }
  };

  return renderLayout();
};
