export const headers = [
  {
    label: 'customer_arr',
    key: 'full_name',
    display: true,
    required: true
  },
  {
    label: 'status',
    key: 'status',
    display: true,
    required: true
  },
  {
    label: 'number',
    key: 'number',
    display: true,
    required: true
  },
  {
    label: 'construction_date',
    key: 'construction_date.0',
    display: true,
    required: true
  },
  {
    label: 'worksite_address',
    key: 'worksite_address.city',
    display: true,
    required: true
  },
  {
    label: 'totalTTC',
    key: 'totals.totalTTC',
    display: true,
    required: true
  },
  {
    label: 'payment_status.title',
    key: 'payment_status',
    display: true,
    required: true
  }
];
