import moment from 'moment';
import { OrderTemplate } from './OrderTemplate';

/**
 * Get the range of dates based on the start and end dates of tasks.
 *
 * @function
 * @param {Array} datas - An array of task data.
 * @returns {Array} An array containing the start and end dates of the date range.
 */
const getRangeDates = (datas) => {
  let curr_start_date = datas[0]?.start_date || new Date();
  let curr_date_end = datas[0]?.end_date || new Date();

  datas.forEach(({ start_date, end_date }) => {
    if (start_date < curr_start_date) curr_start_date = start_date;
    if (end_date > curr_date_end) curr_date_end = end_date;
  });

  const addMonth = new Date(curr_date_end);
  const removeMonth = new Date(curr_start_date);

  addMonth.setMonth(addMonth.getMonth() + 1);
  removeMonth.setMonth(removeMonth.getMonth() - 1);

  return [removeMonth, addMonth];
};

/**
 * Format a date in a specific index of an array.
 *
 * @function
 * @param {Array} dates - An array of dates.
 * @param {number} index - The index of the date to format.
 * @param {string} purpose - Purpose of the function either "end" of "start".
 * @returns {string} The formatted date string.
 */
export const formatOrderDates = (dates, index, purpose) => {
  let date;
  if (purpose === 'start') {
    date = moment(dates[index]).startOf('day');
  } else {
    date = moment(dates[index]).add(1, 'day').startOf('day');
  }
  return date.format('YYYY-MM-DD');
};

/**
 * Check if task dates exist.
 *
 * @function
 * @param {Array} dates - An array of dates.
 * @returns {boolean} Returns true if dates exist, otherwise false.
 */
export const haveLayDates = (dates) => {
  if (dates) return true;
  return false;
};

/**
 * Update the order dates with additional information.
 *
 * @function
 * @param {Array} orderDates - An array of order date objects.
 * @returns {Array} An array of updated order date objects.
 */
export const updateOrderDates = (orderDates) =>
  orderDates.map((el, index) => {
    const data = el;
    if (index !== 0) {
      data.parent = el.id;
      data.id += index;
    } else {
      data.open = true;
    }
    return data;
  });

/**
 * Initialize the Gantt chart with specified configurations.
 *
 * @function
 * @param {object} ganttRef - The reference to the Gantt container element.
 * @param {object} tasks - The tasks data for the Gantt chart.
 * @param {object} currGantt - The Gantt object from dhtmlx-gantt.
 * @returns {void}
 */
export const initializeGantt = (ganttRef, tasks, currGantt) => {
  const gantt = currGantt;
  const { config, templates } = currGantt;
  const rangeDates = getRangeDates(tasks.data);

  config.layout.month_cell_class = 'custom-month-cell';

  config.container_autoresize = true;
  config.columns = [
    {
      name: 'order',
      align: 'center',
      width: '*',
      task_class: 'custom-task-cell',
      template: (data) => OrderTemplate(data, tasks.data || [])
    }
  ];

  config.details_on_create = false;
  config.details_on_dblclick = false;
  config.select_task = false;

  config.drag_resize = false;
  config.drag_links = false;
  config.drag_progress = false;
  config.drag_move = false;

  config.xml_date = '%Y-%m-%d %H:%i';
  gantt.locale.labels.section_month = 'Mois';
  gantt.locale.date.month_full = moment
    .months()
    .map((month) => month.charAt(0).toUpperCase() + month.slice(1));
  gantt.locale.date.month_short = moment.monthsShort();
  config.start_date = new Date(rangeDates[0]);
  config.end_date = new Date(rangeDates[1]);

  gantt.init(ganttRef.current);

  gantt.ext.zoom.init({
    levels: [
      {
        name: 'Months',
        scale_height: 80,
        min_column_width: 50,
        scales: [
          { unit: 'month', step: 1, format: '%F %Y' },
          { unit: 'day', step: 1, format: '%d' }
        ]
      }
    ]
  });
  gantt.clearAll();
  gantt.parse(tasks);
  templates.task_text = () => '';
  gantt.showDate(new Date());
};
