import { Table } from 'antd';
import PropTypes from 'prop-types';
import { useAccessoriesColumns } from '../Columns/accessoriesColumns';

/**
 * AccessoriesTable displays a table of accessories using the Ant Design Table component.
 *
 * @component
 *
 * @param {Array} accessories - An array of accessory objects.
 * @returns {JSX.Element} JSX element representing the accessories table.
 */
export const AccessoriesTable = ({ accessories }) => {
  const columns = useAccessoriesColumns();

  return (
    <Table columns={columns} dataSource={accessories} scroll={{ x: 1000 }} />
  );
};

AccessoriesTable.propTypes = {
  accessories: PropTypes.arrayOf(PropTypes.shape({}))
};

AccessoriesTable.defaultProps = {
  accessories: null
};
