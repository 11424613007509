import { useTranslation } from 'react-i18next';

export const formatProjects = () => {
  const { t } = useTranslation();

  const getCurrStatus = (status) => {
    if (status) return t(`projects.form.${status}`);
    return 'N/R';
  };

  const getAllRooms = (rooms) => {
    const result = [];
    if (!rooms || rooms.length === 0) return [];
    rooms.forEach((el) => result.push(el.title));
    return result.join(',');
  };

  const formatFullName = (customer, defaultvalue) => {
    if (customer) {
      return `${customer?.first_name || ''} ${customer?.last_name || ''}`;
    }
    return defaultvalue || 'N/R';
  };

  const formatProject = (datas) => {
    const { customer, MP_supervisor, status, rooms } = datas;

    const result = {
      ...datas,
      status: getCurrStatus(status),
      full_name: formatFullName(customer),
      MP_supervisor_full_name: formatFullName(MP_supervisor),
      all_rooms: getAllRooms(rooms)
    };
    return result;
  };

  return { formatProject };
};
