import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { UserRouter } from '../routes/users/UserRouter';
import { subRoutes } from '../utils/constants/adminRoutes';
import { Configurations } from '../routes/parameters/configurations/Configurations';
import { TemplateRouter } from './Parameters/TemplateRouter';

const ParametersRouter = () => (
  <Routes>
    <Route path={`${subRoutes.PARAMETERS.USERS}/*`} element={<UserRouter />} />
    <Route
      path={subRoutes.PARAMETERS.CONFIGURATIONS}
      element={<Configurations />}
    />
    <Route
      path={`${subRoutes.PARAMETERS.TEMPLATES}/*`}
      element={<TemplateRouter />}
    />
  </Routes>
);

export default ParametersRouter;
