/**
 * Converts a string according to custom capitalization rules.
 * Uppercases the first character, and lowercases the rest,
 * except if there's a '/', the following character is uppercased.
 *
 * @param {string} inputString - The input string to be converted.
 * @returns {string} The converted string.
 */
export const customCaseConversion = (inputString) => {
  let output = '';
  let capitalizeNext = true;

  for (let i = 0; i < inputString.length; i += 1) {
    const char = inputString[i];

    if (char === '/') {
      output += '/';
      capitalizeNext = true;
    } else if (capitalizeNext) {
      output += char.toUpperCase();
      capitalizeNext = false;
    } else {
      output += char.toLowerCase();
    }
  }

  return output;
};
