import { Input } from 'antd';

const { TextArea } = Input;

/**
 * Custom hook to generate an array of field objects based on the source value.
 *
 * @hook
 *
 * @param {string} source - The source value to determine the field structure.
 * @returns {Array} An array of field objects.
 */
export const useTotalFields = (source) => {
  const totalFields = [
    ...(source === 'BASIC'
      ? [
          {
            name: ['totals', 'extra_addition'],
            input: <Input type="number" disabled />
          },
          {
            name: ['totals', 'extra_discount'],
            input: <Input type="number" disabled />
          },
          {
            name: ['totals', 'HT_total'],
            input: <Input type="number" disabled />
          },
          {
            name: ['totals', 'VAT_total'],
            input: <Input type="number" disabled />
          },
          {
            name: ['totals', 'all_included_total'],
            input: <Input type="number" disabled />
          },
          {
            name: ['totals', 'paid'],
            input: <Input type="number" disabled />
          },
          {
            name: ['totals', 'due'],
            input: <Input type="number" disabled />
          }
        ]
      : [
          {
            name: ['totals', 'advance_total'],
            input: <Input type="number" />
          }
        ]),
    {
      name: ['remarks'],
      input: <TextArea />
    }
  ];

  return totalFields;
};
