import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { customerTypes } from '../../../utils/constants/tagColors';

/**
 * useCustomerListContent is a custom hook that generates an array of customer information
 * for rendering in a list.
 *
 * @hook
 *
 * @param {object} data - Customer data object.
 * @returns {Array} Array of objects containing customer information.
 */
export const useCustomerListContent = (data = {}) => {
  const { t } = useTranslation();
  const { customer } = data;

  return [
    {
      label: t('quotations.show.type'),
      span: 1,
      content:
        (
          <Tag color={customerTypes[customer?.type]}>
            {t(`quotations.show.${customer?.type}`)}
          </Tag>
        ) || t('errors.form.not_specified')
    },
    {
      label: t('quotations.show.last_name'),
      content: customer?.last_name || t('errors.form.not_specified'),
      span: 1
    },
    {
      label: t('quotations.show.first_name'),
      content: customer?.first_name || t('errors.form.not_specified')
    },
    {
      label: t('quotations.show.phone_number'),
      content:
        customer?.phone_number && customer.phone_number.number ? (
          <div>
            {`${
              customer.phone_number.country_code
            } ${customer.phone_number.number.slice(
              0,
              1
            )} ${customer.phone_number.number
              .slice(1)
              .replace(/(.{2})/g, '$1 ')}
                    `}
          </div>
        ) : (
          '-'
        )
    },
    {
      label: t('quotations.show.email'),
      content: customer?.email || t('errors.form.not_specified')
    },
    {
      label: t('quotations.show.business_provider'),
      content:
        (customer && customer.business_provider) ||
        t('errors.form.not_specified'),
      span: 1
    },
    {
      label: t('quotations.show.billing_address'),
      span: 1,
      content:
        customer?.billing_address && customer.billing_address.number !== '' ? (
          <>
            {customer.billing_address.number} {customer.billing_address.street}
            <br />
            {customer.billing_address.additional}
            {customer.billing_address.additional && <br />}
            {customer.billing_address.postal_code}{' '}
            {customer.billing_address.city}
          </>
        ) : (
          '-'
        )
    },

    {
      label: t('quotations.show.customer_coefficient'),
      content:
        (customer && customer?.coefficient && `${customer.coefficient}%`) ||
        '0%',
      span: 1
    },
    {
      label: t('quotations.show.commission'),
      content:
        (customer && customer?.commission && `${customer.commission}%`) || '0%',
      span: 1
    }
  ];
};
