import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Projects } from '../routes/projects/Projects';
import CreateUpdateProject from '../routes/projects/CreateUpdateProject';
import { ShowProject } from '../routes/projects/ShowProject';
import { ProjectContextProvider } from '../contexts/ProjectContext';

const ProjectRouter = () => (
  <ProjectContextProvider>
    <Routes>
      <Route
        path="/create"
        element={<CreateUpdateProject purpose="create" />}
      />
      <Route
        path="/edit/:id"
        element={<CreateUpdateProject purpose="edit" />}
      />
      <Route path="/show/:id" element={<ShowProject />} />
      <Route index element={<Projects />} />
    </Routes>
  </ProjectContextProvider>
);

export default ProjectRouter;
