import React, { useCallback, useEffect, useState } from 'react';
import { InputNumber, Select, Input, DatePicker } from 'antd';
import { useCatalogMaterialContext } from '../../../../contexts/CatalogMaterialContext';

const { TextArea } = Input;

const formatDate = 'DD-MM-YYYY';

/**
 * Custom hook for managing form fields related to material catalog.
 *
 * @param {Object} form - The Ant Design form instance used to manage form state.
 * @returns {Object} An object containing field configurations, loading state, and helper functions.
 */
export const useFields = (form) => {
  const {
    measureUnits,
    getMaterialCategories,
    getSuppliers,
    categories,
    getFinishingProducts,
    finishingProducts,
    suppliers
  } = useCatalogMaterialContext();

  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [isMl, setIsMl] = useState(false);
  const [quotationHeight, setQuotationHeight] = useState();

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getMaterialCategories();
    await getFinishingProducts();
    await getSuppliers();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const handleHeight = (value) => {
    setQuotationHeight(value);
    form.setFields([
      {
        name: ['sliceManage', 'quotation_height'],
        value: value <= 150 ? 150 : value
      }
    ]);
  };

  const basicFields = [
    {
      label: 'denomination',
      name: ['denomination'],
      rules: [{ required: true }]
    },
    {
      label: 'category',
      name: ['category'],
      input: (
        <Select allowClear showSearch optionFilterProp="children">
          {(categories || []).map((category) => (
            <Select.Option key={category._id} value={category._id}>
              {category.title}
            </Select.Option>
          ))}
        </Select>
      ),
      rules: [{ required: true }]
    },
    {
      label: 'finishing_product',
      name: ['finishing_product'],
      input: (
        <Select allowClear showSearch optionFilterProp="children">
          {(finishingProducts || []).map((finishingProduct) => (
            <Select.Option
              key={finishingProduct._id}
              value={finishingProduct._id}
            >
              {finishingProduct.title}
            </Select.Option>
          ))}
        </Select>
      ),
      rules: [{ required: true }]
    },
    {
      label: 'supplier',
      name: ['supplier'],
      input: (
        <Select allowClear showSearch optionFilterProp="children">
          {(suppliers || []).map((supplier) => (
            <Select.Option key={supplier._id} value={supplier._id}>
              {supplier.denomination}
            </Select.Option>
          ))}
        </Select>
      ),
      rules: [{ required: true }]
    },
    {
      label: 'devaluation',
      name: ['devaluation'],
      input: <InputNumber min={0} />
    },
    {
      label: 'remarks',
      name: ['remarks'],
      input: <TextArea />
    }
  ];

  const slicesFields = [
    {
      name: ['sliceManage', '_id'],
      hidden: true
    },
    {
      label: 'slices_management.nomenclature',
      name: ['sliceManage', 'nomenclature'],
      rules: [{ required: true }]
    },
    {
      name: ['sliceManage', 'prices_management'],
      hidden: true
    },
    {
      label: 'slices_management.unit_measure',
      name: ['sliceManage', 'unit_measure'],
      input: (
        <Select
          onClear={() => setIsMl(false)}
          onChange={(e) => {
            const currentUnit = measureUnits.find((el) => el._id === e);
            if (currentUnit) setIsMl(currentUnit.unit === 'ml');
          }}
          optionFilterProp="children"
        >
          {(measureUnits || []).map((unit) => (
            <Select.Option key={unit._id} value={unit._id}>
              {unit.unit}
            </Select.Option>
          ))}
        </Select>
      ),
      rules: [{ required: true }]
    },
    {
      label: 'slices_management.length',
      name: ['sliceManage', 'length'],
      input: <InputNumber min={0} />,
      rules: [{ required: true }]
    },
    ...(isMl
      ? [
          {
            label: 'slices_management.height',
            name: ['sliceManage', 'height'],
            input: (
              <InputNumber min={0} onChange={(value) => handleHeight(value)} />
            ),
            rules: [{ required: true }]
          },
          {
            label: 'slices_management.quotation_height',
            name: ['sliceManage', 'quotation_height'],
            input: <InputNumber disabled value={quotationHeight} />,
            rules: [{ required: true }]
          }
        ]
      : [
          {
            label: 'slices_management.width',
            name: ['sliceManage', 'width'],
            input: <InputNumber min={0} />,
            rules: [{ required: true }]
          }
        ]),
    {
      label: 'slices_management.quantity',
      name: ['sliceManage', 'quantity'],
      input: <InputNumber min={0} />,
      rules: [{ required: true }]
    },
    {
      label: 'slices_management.thickness',
      name: ['sliceManage', 'thickness'],
      input: <InputNumber min={0} />,
      rules: [{ required: true }]
    }
  ];

  const pricesFields = [
    {
      name: ['priceManage', '_id'],
      hidden: true
    },
    {
      name: ['priceManage', 'sliceInfo', '_id'],
      hidden: true
    },
    {
      name: ['priceManage', 'sliceInfo', 'nomenclature'],
      hidden: true
    },
    {
      label: 'prices_management.application_date',
      name: ['priceManage', 'application_date'],
      input: <DatePicker format={formatDate} />,
      rules: [{ required: true }]
    },
    {
      label: 'prices_management.purchase_date',
      name: ['priceManage', 'purchase_date'],
      input: <DatePicker format={formatDate} />,
      rules: [{ required: true }]
    },
    {
      label: 'prices_management.purchase_price',
      name: ['priceManage', 'purchase_price'],
      input: <InputNumber min={0} />,
      rules: [{ required: true }]
    },
    {
      label: 'prices_management.material_coefficient',
      name: ['priceManage', 'material_coefficient'],
      input: <InputNumber min={0} />,
      rules: [{ required: true }]
    },
    {
      label: 'prices_management.transport_cost',
      name: ['priceManage', 'transport_cost'],
      input: <InputNumber min={0} />,
      rules: [{ required: true }]
    },
    {
      label: 'prices_management.sell_price',
      name: ['priceManage', 'sell_price'],
      input: <InputNumber disabled min={0} />
    }
  ];

  return {
    basicFields,
    slicesFields,
    pricesFields,
    isFieldsLoading
  };
};
