import { Button, Card, Col, Row, Select, Upload, Space, Image } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { InboxOutlined, DeleteOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

const { Dragger } = Upload;

/**
 * Component for managing and displaying photos related to a material.
 *
 * @component
 *
 * @param {Object} props - Component props.
 * @param {string} props.id - The ID of the material.
 * @param {boolean} [props.isLoading=false] - Indicates if the component is in a loading state.
 * @param {boolean} [props.forceRefresh] - A trigger to force the component to refresh.
 * @param {Function} [props.setForceRefresh] - A function to set the forceRefresh state.
 * @param {Array} props.photos - An array of photo objects.
 * @param {Array} props.slices - An array of slice objects.
 * @returns {JSX.Element} The rendered PhotosManagement component.
 */
export const PhotosManagement = ({
  photos,
  forceRefresh,
  setForceRefresh,
  id,
  slices,
  setPhotos
}) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [fileList, setFileList] = useState([]);
  const [slice, setSlice] = useState();

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });

  const postPicture = async () => {
    try {
      const base64Picture = await fileToBase64(...fileList);
      await dispatchAPI('PATCH', {
        url: `materials/photos/add/${id}/${slice}`,
        body: {
          photo: base64Picture
        }
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  const draggerProps = {
    multiple: true,
    name: 'file',
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([...fileList, file]);
        setPhotos([...photos, file]);
        return true;
      }
      message('Not a JPG file.');
      return false;
    },
    fileList
  };

  const deletePicture = async (sliceID, index) => {
    try {
      await dispatchAPI('PATCH', {
        url: `materials/photos/remove/${id}/${sliceID}/${index}`
      });
      setForceRefresh(!forceRefresh);
    } catch (error) {
      message(error);
    }
  };

  return (
    <Card
      title={t('materials.show.photos')}
      style={{
        marginBottom: 16
      }}
    >
      <Row gutter={[8, 0]}>
        <Col xs={24} xl={8} style={{ marginBottom: '16px' }}>
          <Row>
            <Col className="select__files">
              {fileList.length > 0 && (
                <>
                  <Select
                    onChange={(value) => setSlice(value)}
                    className="select__files--selector"
                  >
                    {slices.map((sliceUnit) => (
                      <Select.Option value={sliceUnit._id} key={sliceUnit._id}>
                        {sliceUnit.nomenclature}
                      </Select.Option>
                    ))}
                  </Select>
                  {slice && (
                    <Button onClick={postPicture}>
                      {t('materials.show.save')}
                    </Button>
                  )}
                </>
              )}
            </Col>
          </Row>

          <Col>
            <Dragger {...draggerProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                {t('materials.show.drag_or_drop')}
              </p>
            </Dragger>
          </Col>
        </Col>
        <Col xs={24} xl={16}>
          {photos.length > 0 && (
            <Card
              title={t('materials.show.details')}
              style={{
                width: '100%'
              }}
            >
              <Space wrap>
                {photos.map(({ photo, sliceID, index }) => (
                  <Space key={index} direction="horizontal" size={8}>
                    <Image src={photo} width={100} height={100} />
                    <Button onClick={() => deletePicture(sliceID, index)}>
                      <DeleteOutlined />
                    </Button>
                  </Space>
                ))}
              </Space>
            </Card>
          )}
        </Col>
      </Row>
    </Card>
  );
};

PhotosManagement.propTypes = {
  id: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.func,
  photos: PropTypes.arrayOf(PropTypes.shape({})),
  slices: PropTypes.arrayOf(PropTypes.shape({})),
  setPhotos: PropTypes.func
};

PhotosManagement.defaultProps = {
  isLoading: false,
  forceRefresh: undefined,
  setForceRefresh: null,
  photos: null,
  slices: null,
  setPhotos: null
};
