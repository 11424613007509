import { useTranslation } from 'react-i18next';
import { formatNumberWithSpaces } from '../../../utils/formatters/formatNumberWithSpaces';

/**
 * Custom hook for generating a list of content items for displaying service details.
 *
 * @hook
 *
 * @param {Object} params - The parameters containing nomenclature, category, price, and unit.
 * @param {string} params.nomenclature - The nomenclature of the service.
 * @param {Object} params.category - The category of the service.
 * @param {string} params.price - The price of the service.
 * @param {Object} params.unit - The unit of measure of the service.
 * @returns {Array} An array of objects representing label-content pairs for display.
 */
export const useListContent = ({ nomenclature, category, price, unit }) => {
  const { t } = useTranslation();

  return [
    {
      label: 'services.show.nomenclature',
      content: nomenclature || t('errors.form.not_specified')
    },
    {
      label: 'services.show.category',
      content: (category && category.title) || t('errors.form.not_specified')
    },
    {
      label: 'services.show.unit',
      content: (unit && unit.title) || t('errors.form.not_specified')
    },
    {
      label: 'services.show.price',
      content: price
        ? `${formatNumberWithSpaces(Number(price))}€`
        : t('errors.form.not_specified')
    }
  ];
};
