import { createContext, useContext, useEffect, useState } from 'react';
import { useAuthContext } from './AuthContext';
import { useErrorMessage } from '../utils/errorMessage';

const CustomerContext = createContext({ isValid: false });

export const CustomerContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const [importTemplate, setImportTemplate] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [importTemplateResponse] = await Promise.all([
          dispatchAPI('GET', {
            url: 'template-import?populate=file&type=CUSTOMER'
          })
        ]);
        setImportTemplate(importTemplateResponse.data);
      } catch (e) {
        message(e);
      }
    };

    fetchData();
  }, []);

  return (
    <CustomerContext.Provider
      value={{
        importTemplate,
        setImportTemplate
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomerContext = () => {
  const context = useContext(CustomerContext);
  if (!context)
    throw new Error('Context must be used within a context provider');
  return context;
};
