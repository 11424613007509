import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { ServiceItem } from './ServiceItem';

export const ServicesForm = ({ form, calculateTotals }) => {
  const { t } = useTranslation();

  return (
    <Form.Item className="quotation-services-title">
      <Form.List name={['services']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <ServiceItem
                form={form}
                key={key}
                name={name}
                restField={restField}
                remove={remove}
                calculateTotals={calculateTotals}
              />
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                {t('quotations.form.services.add_service')}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form.Item>
  );
};

ServicesForm.propTypes = {
  form: PropTypes.shape({}).isRequired,
  calculateTotals: PropTypes.func
};

ServicesForm.defaultProps = {
  calculateTotals: null
};
