export const generateInitialFinalInput = (type, key) => {
  const initialCrudField = {
    key,
    outputName: 'nouvel-input',
    formula: '',
    outputType: '',
    required: false
  };
  const initialETLField = {
    key,
    outputName: 'nouvel-input',
    formula: '',
    outputType: '',
    required: false
  };
  const basicField = {
    key: 'nouvel-input',
    value: ''
  };

  switch (type) {
    case 'email':
      return basicField;
    case 'CRUD':
      return initialCrudField;
    case 'ETL':
      return initialETLField;
    default:
      return {};
  }
};

export const generateInitialFinalInputs = (type) => {
  const initialConfirmEmailFields = [
    { key: 'email', value: '' },
    { key: 'first_name', value: '' },
    { key: 'last_name', value: '' },
    { key: '_id', value: '' }
  ];
  const initialPwdEmailFields = [
    { key: 'email', value: '' },
    { key: 'first_name', value: '' },
    { key: 'last_name', value: '' },
    { key: '_id', value: '' },
    { key: 'password', value: '' }
  ];
  const initialTemplateEmailFields = {
    sender: '',
    targets: '',
    templateId: '',
    params: { 'new-param': '' }
  };

  switch (type) {
    case 'emailFromTemplate':
      return initialTemplateEmailFields;
    case 'emailConfirmation':
      return initialConfirmEmailFields;
    case 'emailResetPwd':
      return initialPwdEmailFields;
    case 'CRUD':
      return [generateInitialFinalInput('CRUD', 0)];
    case 'ETL':
      return [generateInitialFinalInput('ETL', 0)];
    default:
      return [];
  }
};
