import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import { ListResource } from '../../components';
import { useInvoiceColumns } from './columns';
import { useInvoiceContext } from '../../contexts/InvoiceContext';
import { useDownloadDocument } from '../../utils/downloadDoc';
import { headers } from './formatter/headers';
import { formatInvoices } from './formatter/formatterInvoice';
import { extraFilters as ExtraFilters } from './extraFilters';

/**
 * Component that renders a list of invoices with additional features.
 *
 * @component
 *
 * @returns {JSX.Element} JSX element representing the Invoices component.
 */
export const Invoices = () => {
  const { t } = useTranslation();
  const { importTemplate } = useInvoiceContext();
  const columns = useInvoiceColumns();
  const { downloadDocument } = useDownloadDocument();
  const { formatInvoice } = formatInvoices();
  const [paidInvoices, setPaidInvoices] = useState(false);
  const [datesFilter, setDatesFilter] = useState([]);

  return (
    <ListResource
      columns={columns}
      headers={headers}
      extraQuery={`${paidInvoices ? 'status=PAID' : 'status!=PAID'}${
        datesFilter[0]
          ? `&body_infos.emission_date>=${moment(datesFilter[0]).startOf(
              'day'
            )}`
          : ''
      }${
        datesFilter[1]
          ? `&body_infos.emission_date<=${moment(datesFilter[1]).endOf('day')}`
          : ''
      }`}
      resourceName="invoices"
      resourceModelName="Invoice"
      populate="order,customer"
      withArchiveButton
      withCreateButton={false}
      extraFilters={
        <ExtraFilters
          paidInvoices={paidInvoices}
          setPaidInvoices={setPaidInvoices}
          setDatesFilter={setDatesFilter}
        />
      }
      extraMenu={
        importTemplate[0]?.file && [
          {
            key: 'template',
            label: (
              <Button
                type="link"
                onClick={() => downloadDocument(importTemplate[0]?.file)}
              >
                <DownloadOutlined style={{ fontSize: 18 }} />
                {t('buttons.template')}
              </Button>
            )
          }
        ]
      }
      formatter={formatInvoice}
      customActionColumn
    />
  );
};
