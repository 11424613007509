import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, Form, Input, Select, Tag, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { CameraOutlined } from '@ant-design/icons/lib';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { userRoles } from '../../utils/constants/tagColors';

const { Option } = Select;
const { Dragger } = Upload;

/**
 * Converts a file to a base64 string.
 *
 * @function
 * @param {File} file - The file to convert.
 * @returns {Promise<string>} A promise that resolves with the base64 string.
 */
export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (e) => reject(e);
  });

/**
 * A custom hook to generate a set of form fields with various configurations
 * and controls file uploading functionality.
 *
 * @function
 *
 * @returns {object} The fields configuration object, a loading state boolean and a base64 string of the uploaded file.
 */
export const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [fileList, setFileList] = useState([]);
  const [base64, setBase64] = useState('');

  const draggerProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: async (file) => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([...fileList, file]);
        const base = await fileToBase64(file);
        setBase64(base);
        return false;
      }
      message('Not a PNG or JPG file.');
      return true;
    },
    fileList
  };

  const fields = [
    {
      name: ['photo'],
      input: (
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['role'],
      required: true,
      input: (
        <Select loading={isFieldsLoading}>
          {(enums?.roles || []).map(
            (role) =>
              role !== 'developer:DEVELOPER' &&
              role !== 'admins:ADMIN' && (
                <Option key={role} value={role}>
                  <Tag color={userRoles[role.split(':')[1]]}>
                    {t(`users.tags.${role.split(':')[1]}`)}
                  </Tag>
                </Option>
              )
          )}
        </Select>
      )
    },
    {
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }]
    },
    {
      name: ['phone_number'],
      label: 'phone_number.number',
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['date_of_birth'],
      input: <DatePicker style={{ width: '100%' }} />
    },
    {
      label: 'address.number',
      name: ['address', 'number'],
      input: <Input type="number" />
    },
    {
      label: 'address.street',
      name: ['address', 'street']
    },
    {
      label: 'address.additional',
      name: ['address', 'additional']
    },
    {
      label: 'address.postcode',
      name: ['address', 'postal_code'],
      input: <Input type="number" />
    },
    {
      label: 'address.city',
      name: ['address', 'city']
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading,
    base64
  };
};
