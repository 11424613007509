import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ShowMaterial from '../../routes/catalog/materials/show/ShowMaterial';
import { Materials } from '../../routes/catalog/materials/Materials';
import CreateUpdateMaterial from '../../routes/catalog/materials/form/CreateUpdateMaterial';
import { CatalogMaterialContextProvider } from '../../contexts/CatalogMaterialContext';

const MaterialsRouter = () => (
  <CatalogMaterialContextProvider>
    <Routes>
      <Route
        path="/create"
        element={<CreateUpdateMaterial purpose="create" />}
      />
      <Route
        path="/edit/:id"
        element={<CreateUpdateMaterial purpose="edit" />}
      />
      <Route path="/show/:id" element={<ShowMaterial />} />
      <Route index element={<Materials />} />
    </Routes>
  </CatalogMaterialContextProvider>
);

export default MaterialsRouter;
