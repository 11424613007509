import { Col, Modal, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/**
 * A loading modal component that displays a spinner and a loading message.
 * This component is useful for indicating to the user that some operation
 * (like file creation) is in progress.
 *
 * @component
 * @param {object} props The props for the component.
 * @param {boolean} props.open Specifies whether the modal is open or not.
 *
 * @returns {JSX.Element} The LoadingModal component.
 */

export const LoadingModal = ({ open }) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} footer={null} closable={false}>
      <Row
        justify="center"
        align="center"
        style={{ flexDirection: 'column' }}
        gutter={[16, 16]}
      >
        <Col style={{ textAlign: 'center' }}>
          <Spin />
        </Col>
        <Col style={{ textAlign: 'center' }}>
          {t('quotations.form.files_being_created')}
        </Col>
      </Row>
    </Modal>
  );
};

LoadingModal.propTypes = {
  open: PropTypes.bool.isRequired
};
