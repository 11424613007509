import React from 'react';
import {
  BellOutlined,
  HourglassOutlined,
  QuestionOutlined,
  CheckOutlined,
  LoginOutlined,
  CloseOutlined,
  DiffOutlined,
  UnorderedListOutlined,
  HeatMapOutlined,
  WechatOutlined,
  AlertOutlined,
  BulbOutlined
} from '@ant-design/icons';

/**
 * Returns an array of icon objects for use in configurations.
 *
 * @hook
 * @returns {Array} An array of icon objects.
 */
export const useIconArray = () => [
  {
    label: 'bell',
    value: <BellOutlined />
  },
  {
    label: 'check',
    value: <CheckOutlined />
  },
  {
    label: 'plus',
    value: <HourglassOutlined />
  },
  {
    label: 'question',
    value: <QuestionOutlined />
  },
  {
    label: 'login',
    value: <LoginOutlined />
  },
  {
    label: 'close',
    value: <CloseOutlined />
  },
  {
    label: 'diff',
    value: <DiffOutlined />
  },
  {
    label: 'list',
    value: <UnorderedListOutlined />
  },
  {
    label: 'heatmap',
    value: <HeatMapOutlined />
  },
  {
    label: 'wechat',
    value: <WechatOutlined />
  },
  {
    label: 'alert',
    value: <AlertOutlined />
  },
  {
    label: 'bulb',
    value: <BulbOutlined />
  }
];
