import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentCustom, PageHeaderCustom } from '../../components';

export const Settings = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeaderCustom title={t('settings.title')} />
      <ContentCustom />
    </>
  );
};
