import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CreateUpdateService from '../../routes/catalog/services/CreateUpdateService';
import ShowService from '../../routes/catalog/services/ShowService';
import { Services } from '../../routes/catalog/services/Services';
import { ServiceContextProvider } from '../../contexts/ServiceContext';

const ServicesRouter = () => (
  <ServiceContextProvider>
    <Routes>
      <Route
        path="/create"
        element={<CreateUpdateService purpose="create" />}
      />
      <Route
        path="/edit/:id"
        element={<CreateUpdateService purpose="edit" />}
      />
      <Route path="/show/:id" element={<ShowService />} />
      <Route index element={<Services />} />
    </Routes>
  </ServiceContextProvider>
);

export default ServicesRouter;
