import { useTranslation } from 'react-i18next';

export const useSuppliersColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('suppliers-columns.form.denomination'),
      dataIndex: 'denomination',
      editable: true,
      render: (title) => title,
      width: '15%'
    },
    {
      title: t('suppliers-columns.form.contact'),
      dataIndex: 'contact',
      editable: true,
      render: (contact) => contact,
      width: '15%'
    },
    {
      title: t('suppliers-columns.form.email'),
      dataIndex: ['email'],
      editable: true,
      width: '10%',
      render: (email) => email
    },
    {
      title: t('suppliers-columns.form.phone_number'),
      dataIndex: ['phone_number'],
      editable: true,
      width: '10%',
      render: (phone_number) => phone_number
    }
  ];
};
