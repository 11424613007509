import { useTranslation } from 'react-i18next';
import { formatNumberWithSpaces } from '../../../utils/formatters/formatNumberWithSpaces';

/**
 * useDetailColumns is a custom hook that returns an array of column configurations for displaying invoice-related data.
 *
 * @hook
 *
 * @returns {Object[]} Array of column configurations.
 */
export const useDetailColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('invoices.show.totals_infos.totalHT'),
      dataIndex: 'HT',
      key: 'HT',
      render: (HT) => HT && `${formatNumberWithSpaces(Number(HT))}€`
    },
    {
      title: t('invoices.show.totals_infos.totalTTC'),
      dataIndex: 'TTC',
      key: 'TTC',
      render: (TTC) => TTC && `${formatNumberWithSpaces(Number(TTC))}€`
    },
    {
      title: t('invoices.show.totals_infos.totalTva'),
      dataIndex: 'TVA',
      key: 'TVA',
      render: (TVA) => TVA && `${formatNumberWithSpaces(Number(TVA))}€`
    },
    {
      title: t('invoices.show.totals_infos.VAT_rate'),
      dataIndex: 'VATRate',
      key: 'VATRate',
      render: (VATRate) => VATRate && `${VATRate} %`
    }
  ];
};
