import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { ThunderboltOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Renders a reactivation button component that allows reactivating a resource.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.resourceName - The name of the resource to reactivate.
 * @param {boolean} [props.forceRefresh=false] - A flag indicating whether to trigger a refresh after reactivation.
 * @param {Function} [props.setForceRefresh] - A function to set the refresh flag.
 * @returns {JSX.Element} The ReactivationButton component.
 */
export const ReactivationButton = ({
  resourceName,
  forceRefresh,
  setForceRefresh
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { id } = useParams();

  const patchResourceToActiveStatus = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `${resourceName}/${id}`,
        body: {
          status: 'ACTIVE'
        }
      });
      setForceRefresh(!forceRefresh);
    } catch (error) {
      message(error);
    }
  };

  return (
    <Button onClick={patchResourceToActiveStatus} type="primary">
      <ThunderboltOutlined />
      {t('buttons.reactivate')}
    </Button>
  );
};

ReactivationButton.propTypes = {
  resourceName: PropTypes.string.isRequired,
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.func
};

ReactivationButton.defaultProps = {
  forceRefresh: false,
  setForceRefresh: null
};
