export const headers = [
  {
    label: 'customer',
    key: 'customer.full_name',
    display: true,
    required: false
  },
  {
    label: 'denomination',
    key: 'denomination',
    display: true,
    required: false
  },
  {
    label: 'project_type',
    key: 'project_type.title',
    display: true,
    required: false
  },
  {
    label: 'site_address.street',
    key: 'site_address.street',
    display: true,
    required: false
  },
  {
    label: 'site_address.additional',
    key: 'site_address.additional',
    display: true,
    required: false
  },
  {
    label: 'site_address.postal_code',
    key: 'site_address.postal_code',
    display: true,
    required: false
  },
  {
    label: 'site_address.city',
    key: 'site_address.city',
    display: true,
    required: false
  },
  {
    label: 'rooms',
    key: 'all_rooms',
    display: true,
    required: false
  },
  {
    label: 'site_access',
    key: 'site_access',
    display: true,
    required: false
  },
  {
    label: 'dwelling_type',
    key: 'dwelling_type',
    display: true,
    required: false
  },
  {
    label: 'entry_code',
    key: 'entry_code',
    display: true,
    required: false
  },
  {
    label: 'parking',
    key: 'parking',
    display: true,
    required: false
  },
  {
    label: 'rooms',
    key: 'rooms',
    display: true,
    required: false
  },
  {
    label: 'floor_number',
    key: 'floor_number',
    display: true,
    required: false
  },
  {
    label: 'stairs',
    key: 'stairs',
    display: true,
    required: false
  },
  {
    label: 'housekeeper_and_others',
    key: 'housekeeper_and_others',
    display: true,
    required: false
  },
  {
    label: 'MP_supervisor',
    key: 'MP_supervisor_full_name',
    display: true,
    required: false
  },
  {
    label: 'remarks',
    key: 'remarks',
    display: true,
    required: false
  }
];
