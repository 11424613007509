import React from 'react';
import useColumns from './columns';
import { ListResource } from '../../components/ListResource/ListResource';
import { headers } from './formatter/headers';
import { useCustomerContext } from '../../contexts/CustomerContext';
import { DownloadFileButtons } from '../../components/DownloadFileButton/DownloadFileButton';

/**
 * Customers Component
 *
 * The `Customers` component renders a list of customers utilizing the `ListResource` component.
 * This component is responsible for determining the structure of the columns to be displayed,
 * the headers for the columns, and also enables the archive button functionality.
 *
 * @component
 *
 * @returns {React.Component} ShowCustomer component.
 *
 */
export const Customers = () => {
  const columns = useColumns();
  const { importTemplate } = useCustomerContext();

  return (
    <ListResource
      columns={columns}
      withArchiveButton
      withImportButton
      resourceName="customers"
      resourceModelName="Customer"
      headers={headers}
      extraMenu={
        importTemplate &&
        importTemplate[0]?.file && [
          {
            key: 'template',
            label: importTemplate[0]?.file && (
              <DownloadFileButtons file={importTemplate[0]?.file} />
            )
          }
        ]
      }
    />
  );
};
