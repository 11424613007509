import React, { useEffect, useState } from 'react';
import { Row, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  projectsStatus,
  quotationStatus
} from '../../utils/constants/tagColors';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { formatNumberWithSpaces } from '../../utils/formatters/formatNumberWithSpaces';

/**
 * Custom hook for generating columns configuration for projects and quotations tables.
 *
 * @hook
 *
 * @param {boolean} filterState - State of the ARCHIVED filter.
 *
 * @returns {Object} - An object containing the column configurations for projects and quotations tables.
 */
const useColumns = (filterState) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [customers, setCustomers] = useState([]);
  const [businessProviders, setBusinessProviders] = useState([]);

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `projects?populate=customer,MP_supervisor${
          !filterState ? '&status!=ARCHIVED' : '&status=ARCHIVED'
        }`
      });
      const projectsArray = data
        .map((project) => project.customer && project.customer)
        .filter(Boolean);
      const businessProvidersArray = data
        .map(
          (project) => project.customer && project.customer.business_provider
        )
        .filter(Boolean);
      setCustomers(
        Array.from(new Set(projectsArray.map(JSON.stringify))).map(JSON.parse)
      );
      setBusinessProviders(
        Array.from(new Set(businessProvidersArray.map(JSON.stringify))).map(
          JSON.parse
        )
      );
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getCustomers();
    })();
  }, [filterState]);

  const basicColumns = [
    {
      title: t('projects.table.customer'),
      dataIndex: 'customer',
      key: 'customer',
      sorter: (a, b) => {
        const nameA = `${a?.customer?.first_name || ''} ${
          a?.customer?.last_name || ''
        }`.toLowerCase();
        const nameB = `${b?.customer?.first_name || ''} ${
          b?.customer?.last_name || ''
        }`.toLowerCase();
        return nameA.localeCompare(nameB);
      },
      filters: customers.map((customer) => ({
        text: `${
          `${customer.first_name || ''} ${customer.last_name || ''}` || ''
        }`,
        value: customer._id
      })),
      render: (customer) =>
        (customer &&
          `${customer.first_name || ''} ${customer.last_name || ''}`) ||
        t('errors.form.not_specified')
    },
    {
      title: t('projects.table.address'),
      dataIndex: 'site_address',
      key: 'site_address',
      render: (address) =>
        address && (
          <Row style={{ display: 'flex', flexDirection: 'column' }}>
            <Row>{address.street}</Row>
            <Row>{`${address.postal_code} ${address.city}`}</Row>
            {address.additional ? <Row> {address.additional}</Row> : null}
          </Row>
        )
    },
    {
      title: t('projects.table.dwelling_type'),
      dataIndex: 'dwelling_type',
      key: 'dwelling_type',
      sorter: true
    },
    {
      title: t('projects.table.project_type'),
      dataIndex: 'project_type',
      key: 'project_type',
      sorter: true,
      render: (type) => type && type.title
    },
    {
      title: t('projects.table.business_provider'),
      dataIndex: 'customer',
      key: 'customer.business_provider',
      filters: businessProviders.map((businessProvider) => ({
        text: businessProvider,
        value: businessProvider
      })),
      render: (customer) => customer && customer?.business_provider
    },
    {
      title: t('projects.table.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={projectsStatus[status]}>{t(`projects.form.${status}`)}</Tag>
      )
    }
  ];

  const quotationsColumns = [
    {
      title: t('projects.table.number'),
      key: 'number',
      dataIndex: 'number',
      sorter: true,
      render: (number) => <Tag>{number}</Tag> || t('errors.form.not_specified')
    },
    {
      title: t('projects.table.status'),
      render: (record) => (
        <Tag
          color={
            quotationStatus[
              record.kanban_status ? record.kanban_status.title : record.status
            ]
          }
        >
          {record.kanban_status
            ? record.kanban_status.title
            : t(`quotations.form.${record.status}`)}
        </Tag>
      )
    },
    {
      title: t('projects.table.creation_date'),
      dataIndex: 'creation_date',
      key: 'creation_date',
      sorter: true,
      render: (date) =>
        (date && moment(date).format('DD-MM-YYYY')) ||
        t('errors.form.not_specified')
    },
    {
      title: t('projects.table.amount'),
      dataIndex: 'summary',
      sorter: true,
      render: (summary) =>
        (summary &&
          `${formatNumberWithSpaces(
            summary?.base_total?.all_included_total
          )}€`) ||
        t('errors.form.not_specified')
    }
  ];

  return {
    basicColumns,
    quotationsColumns
  };
};

export default useColumns;
