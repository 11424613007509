/**
 * Calculates the square meter price based on the provided thickness item.
 *
 * @function
 * @param {Object} thicknessItem - The thickness item containing price management information
 * @returns {number} - The calculated square meter price, or 0 if not available
 */
export const calculateSquareMeterPrice = (thicknessItem) => {
  const hasPrices = thicknessItem?.prices_management?.length > 0;
  return hasPrices ? thicknessItem.prices_management[0].sell_price : 0;
};
