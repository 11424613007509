import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { customerTypes } from '../../../../utils/constants/tagColors';

/**
 * Hook that generates the content for displaying customer information in a list.
 * @hook
 *
 * @param {object} customer - Customer data object.
 * @returns {Array} Array of objects containing label and content for each customer information field.
 */
export const useCustomerListContent = (customer) => {
  const { t } = useTranslation();
  return [
    {
      label: t('orders.show.type'),
      content:
        (
          <Tag color={customerTypes[customer?.type]}>
            {t(`orders.show.${customer?.type}`)}
          </Tag>
        ) || t('errors.form.not_specified')
    },
    {
      label: t('orders.show.last_name'),
      content: customer?.last_name || t('errors.form.not_specified')
    },
    ...(customer?.first_name
      ? [
          {
            label: t('orders.show.last_name'),
            content: customer?.first_name || t('errors.form.not_specified')
          }
        ]
      : []),
    {
      label: t('orders.show.phone_number'),
      content:
        customer?.phone_number && customer.phone_number.number ? (
          <div>
            {`${
              customer.phone_number.country_code
            } ${customer.phone_number.number.slice(
              0,
              1
            )} ${customer.phone_number.number
              .slice(1)
              .replace(/(.{2})/g, '$1 ')}
                    `}
          </div>
        ) : (
          '-'
        )
    },
    {
      label: t('orders.show.email'),
      content: customer?.email || t('errors.form.not_specified')
    },
    {
      label: t('orders.show.business_provider'),
      content: customer?.business_provider || t('errors.form.not_specified')
    },
    {
      label: t('orders.show.billing_address'),
      span: 3,
      content:
        customer?.billing_address && customer.billing_address.number !== '' ? (
          <>
            {customer.billing_address.number} {customer.billing_address.street}
            <br />
            {customer.billing_address.additional}
            {customer.billing_address.additional && <br />}
            {customer.billing_address.postal_code}{' '}
            {customer.billing_address.city}
          </>
        ) : (
          '-'
        )
    }
  ];
};
