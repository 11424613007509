import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * Component for rendering action buttons related to orders.
 *
 * @component
 * @param {object} props - Component props.
 * @param {Function} props.action - Action to be executed on button click.
 * @param {ReactNode} props.icon - Icon to be displayed on the button.
 * @param {string} props.name - Name of the button.
 * @param {string} props.resourceName - Name of the resource for translation keys.
 * @param {string} [props.type] - Type of the button (e.g., 'primary', 'default').
 * @param {boolean} [props.display=true] - Whether to display the button.
 * @param {boolean} [props.disabled=false] - Whether the button is disabled.
 * @returns {JSX.Element|null} Action button JSX or null if `display` is false.
 */
export const OrderButtons = ({
  action,
  icon,
  name,
  resourceName,
  type,
  display,
  disabled,
  danger
}) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();

  const [isLoading, setIsLoading] = useState(false);

  const onClick = async () => {
    setIsLoading(true);
    try {
      await action();
    } catch (error) {
      message(error);
    }
    setIsLoading(false);
  };

  return (
    display && (
      <Button
        icon={icon}
        type={type || 'default'}
        onClick={onClick}
        loading={isLoading}
        disabled={disabled}
        danger={danger}
      >
        {resourceName && name && t(`${resourceName}.buttons.${name}`)}
      </Button>
    )
  );
};

OrderButtons.propTypes = {
  action: PropTypes.func,
  icon: PropTypes.node,
  name: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  type: PropTypes.string,
  display: PropTypes.bool,
  disabled: PropTypes.bool,
  danger: PropTypes.bool
};

OrderButtons.defaultProps = {
  action: () => {},
  icon: null,
  type: null,
  display: true,
  disabled: false,
  danger: false
};
