import { useTranslation } from 'react-i18next';
import { formatNumberWithSpaces } from '../../../utils/formatters/formatNumberWithSpaces';

/**
 * Custom hook to generate columns configuration for services table.
 *
 * @hook
 *
 * @returns {Array} An array of column configuration objects.
 */
const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('services.form.nomenclature'),
      dataIndex: 'nomenclature',
      key: 'nomenclature',
      sorter: true
    },
    {
      title: t('services.form.category'),
      dataIndex: ['category', 'title'],
      key: 'category',
      render: (category) => category && category,
      sorter: true
    },
    {
      title: t('services.form.unit'),
      dataIndex: ['unit', 'title'],
      key: 'unit',
      render: (unit) => unit && unit,
      sorter: true
    },
    {
      title: t('services.form.price'),
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => (a.price < b.price ? -1 : 1),
      render: (price) => {
        if (price) {
          return formatNumberWithSpaces(Number(price));
        }
        return t('errors.not_specified');
      }
    }
  ];
};

export default useColumns;
