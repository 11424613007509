import { Switch, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * A custom hook that provides columns configuration for color-related tables.
 *
 * @hook
 * @returns {Array} An array of column configurations for colors.
 */
export const useColorColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('color-columns.form.title'),
      dataIndex: 'title',
      editable: true,
      render: (title) => title,
      width: '25%'
    },
    {
      title: t('color-columns.form.color'),
      dataIndex: ['color'],
      editable: true,
      width: '10%',
      render: (color) => color && <Tag color={color}>{color}</Tag>
    },
    {
      title: t('kanbanview-actions.form.display'),
      dataIndex: ['display'],
      editable: true,
      width: '10%',
      render: (display) => <Switch disabled={false} checked={display} />
    }
  ];
};
