import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Popover, Select, Switch, Table, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { CirclePicker } from 'react-color';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useIconArray } from './IconArray';
import usePanelContext from './ConfigurationContext';
import ButtonPanel from './ButtonPanel';

/**
 * Represents an editable cell within the ConfigurationTable.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.editing - Indicates if the cell is in edit mode.
 * @param {string} props.dataIndex - The dataIndex of the cell.
 * @param {string} props.title - The title of the column.
 * @param {string} props.inputType - The type of input to display in the cell.
 * @param {string} props.checkType - The type of checking rules for the form item.
 * @param {Object} props.record - The record associated with the cell.
 * @param {number} props.index - The index of the cell.
 * @param {React.ReactNode} props.children - The children elements of the cell.
 * @param {string} props.colorPicked - The selected color value.
 * @param {function} props.setColorPicked - The function to set the selected color.
 * @param {boolean} props.isDisplayed - Indicates if a switch is displayed.
 * @param {function} props.setIsDisplayed - The function to set the switch status.
 * @param {string} props.selectType - The type of select input.
 * @param {string} props.iconValue - The selected icon value.
 * @param {function} props.setIconValue - The function to set the selected icon.
 * @returns {JSX.Element} The JSX element representing the EditableCell.
 */
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  checkType,
  record,
  index,
  children,
  colorPicked,
  setColorPicked,
  isDisplayed,
  setIsDisplayed,
  selectType,
  iconValue,
  setIconValue,
  ...restProps
}) => {
  const { t } = useTranslation();
  const iconArray = useIconArray();
  let inputNode;

  const popoverContent = (
    <CirclePicker
      colors={[
        '#C1CCFF',
        '#C478F2',
        '#3D66CC',
        '#ABABAB',
        '#73DF78',
        '#F57F80'
      ]}
      color={colorPicked}
      onChangeComplete={(color) => setColorPicked(color.hex)}
    />
  );

  switch (inputType) {
    case 'color':
      inputNode = (
        <Popover content={popoverContent} title={t('configurations.color')}>
          <Tag color={colorPicked}>{colorPicked}</Tag>
        </Popover>
      );
      break;
    case 'icon':
      inputNode = (
        <Select>
          {iconArray.map((icon) => (
            <Select.Option value={icon.label}>{icon.value}</Select.Option>
          ))}
        </Select>
      );

      break;
    case 'display':
      inputNode = (
        <Switch
          defaultChecked={record.display || isDisplayed}
          onChange={() => setIsDisplayed(!isDisplayed)}
        />
      );
      break;
    default:
      inputNode = <Input />;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

/**
 * Represents a table for displaying and editing configurations.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.items - The array of items to be displayed in the table.
 * @param {Array} props.resourceColumns - The configuration columns definition.
 * @param {string} props.resourceName - The name of the resource associated with the configurations.
 * @returns {JSX.Element} The JSX element representing the ConfigurationTable.
 */
const ConfigurationTable = ({ items, resourceColumns, resourceName }) => {
  const { t } = useTranslation();
  const { editItem, deleteItem, cancel, editingKey, setEditingKey, isEditing } =
    usePanelContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();
  const [colorPicked, setColorPicked] = useState('#ababab');
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [iconValue, setIconValue] = useState('');

  const edit = (record) => {
    form.setFieldsValue({
      label: '',
      ...record
    });
    setEditingKey(record._id);
    if (record?.color) setColorPicked(record.color);
  };

  const setValues = ({ color, icon }) => {
    form.setFieldsValue({
      color: colorPicked || color,
      display: isDisplayed,
      iconValue: iconValue || icon
    });
  };

  const save = async (record) => {
    setValues(record);
    try {
      const row = await form.validateFields();
      editItem(row, record._id, resourceName);
      setEditingKey('');
    } catch (e) {
      message(e);
    }
  };

  const columns = [
    ...resourceColumns,

    {
      title: t(''),
      dataIndex: 'operation',
      width: '15%',
      align: 'right',
      render: (_, record) => {
        const editable = isEditing(record);
        if (record.reserved !== true) {
          return (
            <ButtonPanel
              editingKey={editingKey}
              editable={editable}
              saveItem={() => save(record)}
              edit={() => edit(record)}
              cancel={cancel}
              deleteItem={() => deleteItem(record, resourceName)}
            />
          );
        }
        return false;
      }
    }
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    const createInputType = (dataIndex) => {
      switch (true) {
        case dataIndex[0] === 'icon':
          return 'icon';
        case dataIndex[0] === 'color':
          return 'color';
        case dataIndex[0] === 'display':
          return 'display';
        default:
          return 'text';
      }
    };

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: createInputType(col.dataIndex),
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        colorPicked,
        setColorPicked,
        isDisplayed,
        setIsDisplayed,
        iconValue,
        setIconValue
      })
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered={false}
        dataSource={items}
        columns={mergedColumns}
        style={{ overflowX: 'auto', overflowY: 'visible' }}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};

EditableCell.propTypes = {
  editing: PropTypes.bool.isRequired,
  dataIndex: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  checkType: PropTypes.string.isRequired,
  record: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  setColorPicked: PropTypes.string.isRequired,
  colorPicked: PropTypes.string.isRequired,
  isDisplayed: PropTypes.bool.isRequired,
  setIsDisplayed: PropTypes.bool.isRequired,
  iconValue: PropTypes.string.isRequired,
  setIconValue: PropTypes.string.isRequired,
  selectType: PropTypes.string.isRequired
};

ConfigurationTable.propTypes = {
  items: PropTypes.string.isRequired,
  resourceColumns: PropTypes.shape([{}]).isRequired,
  resourceName: PropTypes.string.isRequired
};

export default ConfigurationTable;
