import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input, Select } from 'antd';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';

export const useFields = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();

  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [types, setTypes] = useState([]);

  const getSupplierTypes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'supplier-types'
      });
      setTypes(data);
    } catch (e) {
      message(e);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getSupplierTypes();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const basicFields = [
    {
      label: 'type',
      name: ['type'],
      input: (
        <Select>
          {types.map((category) => (
            <Select.Option key={category._id} value={category._id}>
              {category.title}
            </Select.Option>
          ))}
        </Select>
      ),
      rules: [{ required: true }]
    },
    {
      label: 'denomination',
      name: ['denomination'],
      rules: [{ required: true }]
    },
    {
      label: 'street_number',
      name: ['address', 'number'],
      rules: [{ required: true }]
    },
    {
      label: 'street',
      name: ['address', 'street'],
      rules: [{ required: true }]
    },
    {
      label: 'address_details',
      name: ['address', 'additional']
    },
    {
      label: 'postal_code',
      name: ['address', 'postal_code'],
      rules: [{ required: true }]
    },
    {
      label: 'city',
      name: ['address', 'city'],
      rules: [{ required: true }]
    },
    {
      label: 'country',
      name: ['address', 'country'],
      rules: [{ required: true }]
    },
    {
      label: 'siren',
      name: ['siren']
    },
    {
      label: 'ape',
      name: ['ape']
    },
    {
      label: 'email',
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }]
    },
    {
      label: 'phone_number',
      name: ['phone_number'],
      rules: [{ required: true }],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Select.Option value="+33">+33</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    }
  ];

  return {
    basicFields,
    isFieldsLoading
  };
};
