import moment from 'moment';
import { useTranslation } from 'react-i18next';

/**
 * Utility function for formatting invoice data.
 * It provides methods to format various fields in invoice data.
 *
 * @function
 *
 * @returns {object} - An object containing the `formatInvoice` method.
 */
export const formatInvoices = () => {
  const { t } = useTranslation();

  const getInvoiceType = (type) => {
    if (type) return t(`invoices.show.invoice_infos.type.${type}`);
    return 'N/R';
  };

  const formatDate = (value, defaultvalue) => {
    if (value) return moment(value[0]).format('DD/MM/YYYY');
    return defaultvalue || 'N/R';
  };

  const formatFullName = (customer, defaultvalue) => {
    if (customer) {
      return `${customer?.first_name || ''} ${customer?.last_name || ''}`;
    }
    return defaultvalue || 'N/R';
  };

  const formatInvoice = (datas) => {
    const { customer, invoice_type, body_infos } = datas;

    const result = {
      ...datas,
      invoice_type: getInvoiceType(invoice_type),
      full_name: formatFullName(customer),
      body_infos: {
        ...body_infos,
        emission_date: formatDate(body_infos.emission_date)
      }
    };

    return result;
  };

  return { formatInvoice };
};
