import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import { useMasterContext } from '../../../../contexts/MasterContext';

/**
 * Hook that generates an array of customer fields for display in a form.
 * Each field includes a name, initial value, and disabled input component.
 *
 * @hook
 *
 * @returns {Array} An array of customer fields.
 */
const useCustomerFields = () => {
  const { t } = useTranslation();
  const { pickedCustomer } = useMasterContext();

  const customerFields = [
    {
      name: ['customer'],
      initialValue: pickedCustomer
        ? `${pickedCustomer.first_name || ''} ${pickedCustomer.last_name || ''}`
        : null,
      input: <Input disabled />
    },
    {
      name: ['customer_type'],
      initialValue: pickedCustomer
        ? t(`customers.show.${pickedCustomer.type}`)
        : null,
      input: <Input disabled />
    },
    {
      name: ['billing_address'],
      initialValue:
        pickedCustomer && pickedCustomer?.billing_address
          ? `${pickedCustomer.billing_address.number || ''}${
              pickedCustomer.billing_address.street || ''
            }`
          : null,
      input: <Input disabled />
    },
    {
      name: ['postal_code'],
      initialValue:
        pickedCustomer && pickedCustomer?.billing_address
          ? pickedCustomer.billing_address.postal_code
          : null,
      input: <Input disabled />
    },
    {
      name: ['city'],
      initialValue:
        pickedCustomer && pickedCustomer?.billing_address
          ? pickedCustomer.billing_address.city
          : null,
      input: <Input disabled />
    },
    {
      name: ['email'],
      initialValue: pickedCustomer ? pickedCustomer?.email : null,
      input: <Input disabled />
    },
    {
      name: ['phone_number'],
      initialValue:
        pickedCustomer && pickedCustomer?.phone_number
          ? `${pickedCustomer.phone_number.country_code || ''}${
              pickedCustomer.phone_number.number || ''
            }`
          : null,
      input: <Input disabled />
    }
  ];

  return customerFields;
};

export default useCustomerFields;
