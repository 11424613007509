import { Row, Tag } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { customerTypes } from '../../utils/constants/tagColors';

export const useListContent = (data = {}) => {
  const { t } = useTranslation();
  const {
    denomination,
    customer,
    site_address,
    dwelling_type,
    entry_code,
    parking,
    floor_number,
    project_type,
    rooms,
    MP_supervisor,
    site_access,
    stairs,
    housekeeper_and_others
  } = data;

  const projectListContent = [
    ...(denomination
      ? [
          {
            label: 'projects.show.denomination',
            content: denomination
          }
        ]
      : []),
    ...(project_type
      ? [
          {
            label: 'projects.show.project_type',
            content:
              (project_type && project_type.title) ||
              t('errors.form.not_specified')
          }
        ]
      : []),
    ...(site_address
      ? [
          {
            label: 'projects.show.site_address',
            content:
              (site_address && (
                <Row
                  style={{
                    flexDirection: 'column'
                  }}
                >
                  <Row>{site_address.street}</Row>
                  <Row>{`${site_address.postal_code} ${site_address.city}`}</Row>
                </Row>
              )) ||
              t('errors.form.not_specified')
          }
        ]
      : []),
    ...(dwelling_type
      ? [
          {
            label: 'projects.show.dwelling_type',
            content: dwelling_type || t('errors.form.not_specified')
          }
        ]
      : []),
    ...(entry_code
      ? [
          {
            label: 'projects.show.entry_code',
            content: entry_code || t('errors.form.not_specified')
          }
        ]
      : []),
    ...(parking
      ? [
          {
            label: 'projects.show.parking',
            content: parking || t('errors.form.not_specified')
          }
        ]
      : []),
    ...(floor_number
      ? [
          {
            label: 'projects.show.floor_number',
            content: floor_number || t('errors.form.not_specified')
          }
        ]
      : []),
    ...(rooms?.length > 0
      ? [
          {
            label: 'projects.show.rooms',
            content:
              (rooms && rooms.map((room) => `${room.title} `)) ||
              t('errors.form.not_specified')
          }
        ]
      : []),
    ...(MP_supervisor
      ? [
          {
            label: 'projects.show.MP_supervisor',
            content:
              (MP_supervisor &&
                `${MP_supervisor.first_name} ${MP_supervisor.last_name}`) ||
              t('errors.form.not_specified')
          }
        ]
      : []),
    ...(site_access
      ? [
          {
            label: 'projects.show.site_access',
            content: site_access
          }
        ]
      : []),
    {
      label: 'projects.show.stairs',
      content:
        stairs === true
          ? t('projects.show.yes')
          : t('errors.form.not_specified')
    },
    ...(housekeeper_and_others
      ? [
          {
            label: 'projects.show.housekeeper_and_others',
            content: housekeeper_and_others || t('errors.form.not_specified')
          }
        ]
      : [])
  ];

  const customerListContent = [
    {
      label: 'customers.show.type',
      content:
        (customer && customer.type && (
          <Tag color={customerTypes[customer.type]}>
            {t(`customers.form.${customer.type}`)}
          </Tag>
        )) ||
        t('errors.form.not_specified')
    },
    {
      label: 'customers.show.last_name',
      content: (customer && customer.last_name) || ''
    },
    {
      label: 'customers.show.first_name',
      content: (customer && customer.first_name) || ''
    },
    {
      label: 'customers.show.phone_number',
      content:
        (customer && customer.phone_number?.number && (
          <div>
            {`${
              customer.phone_number.country_code
            } ${customer.phone_number.number.slice(1)}`}
          </div>
        )) ||
        t('errors.form.not_specified')
    },
    {
      label: 'customers.show.email',
      content: (customer && customer.email) || t('errors.form.not_specified')
    },
    {
      label: 'customers.show.business_provider',
      content:
        (customer && customer.business_provider) ||
        t('errors.form.not_specified')
    },
    {
      label: 'customers.show.billing_address',
      content:
        (customer && customer.billing_address && (
          <Row
            style={{
              flexDirection: 'column'
            }}
          >
            <Row>{customer.billing_address.street}</Row>
            {customer.billing_address.additional && (
              <Row>{customer.billing_address.additional}</Row>
            )}
            <Row>{`${customer.billing_address.postal_code} ${customer.billing_address.city}`}</Row>
          </Row>
        )) ||
        t('errors.form.not_specified')
    },
    {
      label: 'customers.show.coefficient',
      content:
        (customer && customer.coefficient && `${customer.coefficient}%`) || `0%`
    },
    {
      label: 'customers.show.commission',
      content: (customer?.commission && `${customer.commission}%`) || `0%`
    }
  ];

  return {
    projectListContent,
    customerListContent
  };
};
