import React, { useState } from 'react';
import useColumns from './columns';
import { ListResource } from '../../components/ListResource/ListResource';
import { formatProjects } from './formatter/formatterProjects';
import { headers } from './formatter/headers';
import { DownloadFileButtons } from '../../components/DownloadFileButton/DownloadFileButton';
import { useProjectContext } from '../../contexts/ProjectContext';

export const Projects = () => {
  const { importTemplate } = useProjectContext();
  const { formatProject } = formatProjects();
  const [filterState, setFilterState] = useState();

  const { basicColumns } = useColumns(filterState);

  return (
    <ListResource
      columns={basicColumns}
      withArchiveButton
      tradKey="projects"
      resourceName="projects/table"
      resourceModelName="Project"
      headers={headers}
      withImportButton
      setArchiveState={setFilterState}
      extraMenu={
        importTemplate[0]?.file && [
          {
            key: 'template',
            label: importTemplate[0]?.file && (
              <DownloadFileButtons file={importTemplate[0]?.file} />
            )
          }
        ]
      }
      formatter={formatProject}
    />
  );
};
