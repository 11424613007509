/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Steps } from 'antd';
import { ContentCustom } from '../../../../../components/ContentCustom/ContentCustom';
import ETLStepInputs from './ETLStepInputs';
import ETLStepTransform from '../../CommonSteps/StepTransform';
import ETLStepFinish from '../../CommonSteps/StepFinish';
import { generateInitialFinalInputs } from '../../../../../utils/generateFinalInputs';

const ETLModale = ({
  isModalVisible,
  setIsModalVisible,
  handleOk,
  handleCancel,
  sourceElements,
  operations,
  setOperations,
  setOutputs
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [stepInputs, setStepInputs] = useState(operations.stepInputs || []);
  const [finalInputs, setFinalInputs] = useState(
    (JSON.stringify(operations.finalInputs) !== '{}' &&
      operations.finalInputs) ||
      generateInitialFinalInputs('ETL')
  );

  const finishModale = () => {
    const newOutputs = {};
    finalInputs.map((operation) => {
      newOutputs[operation.outputName] = { type: operation.outputType };
    });

    setOperations({ stepInputs, finalInputs });
    setOutputs(newOutputs);
    setIsModalVisible(false);
    handleOk();
  };

  const stepToShow = () => {
    switch (currentStep) {
      case 0:
        return (
          <ETLStepInputs
            inputs={sourceElements}
            stepInputs={stepInputs}
            setStepInputs={setStepInputs}
            nextStep={() => setCurrentStep(currentStep + 1)}
          />
        );
      case 1:
        return (
          <ETLStepTransform
            inputs={sourceElements}
            stepInputs={stepInputs}
            finalInputs={finalInputs}
            setFinalInputs={setFinalInputs}
            nextStep={() => setCurrentStep(currentStep + 1)}
          />
        );
      case 2:
        return (
          <ETLStepFinish description="Génération des nouvelles formules terminée" />
        );
      default:
        return (
          <ETLStepInputs
            inputs={sourceElements}
            stepInputs={stepInputs}
            setStepInputs={setStepInputs}
            nextStep={() => setCurrentStep(currentStep + 1)}
          />
        );
    }
  };

  return (
    <Modal
      title="Système ETL"
      open={isModalVisible}
      maskClosable={false}
      width="90%"
      onOk={finishModale}
      onCancel={handleCancel}
    >
      <Steps
        current={currentStep}
        items={[
          {
            title: 'Selection des inputs à considérer',
            onClick: () => setCurrentStep(0)
          },
          {
            title: 'Transformation des données',
            onClick: () => setCurrentStep(1)
          },
          {
            title: 'Validation',
            onClick: () => setCurrentStep(2)
          }
        ]}
      />
      <ContentCustom>{stepToShow()}</ContentCustom>
    </Modal>
  );
};

ETLModale.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  sourceElements: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  operations: PropTypes.shape({
    stepInputs: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    finalInputs: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  }).isRequired,
  setOperations: PropTypes.func.isRequired,
  setOutputs: PropTypes.func.isRequired
};
export default ETLModale;
