/**
 * Calculates the sell price based on purchase price, transport cost, and material coefficient.
 *
 * @function
 * @param {Object} values - The input values including purchase_price, transport_cost, and material_coefficient.
 * @param {number} values.purchase_price - The purchase price.
 * @param {number} values.transport_cost - The transport cost.
 * @param {number} values.material_coefficient - The material coefficient.
 * @param {Form} form - The Ant Design Form instance.
 *
 * @returns {void}
 */
export const calculateSellPrice = ({
  purchase_price,
  transport_cost,
  material_coefficient
}) => {
  const purchasePrice = purchase_price || 0;
  const transportCost = transport_cost || 0;
  const materialCoefficient = material_coefficient || 1;

  return (purchasePrice + transportCost) * materialCoefficient;
};
