import React, { useEffect, useState } from 'react';
import { QuotationsList } from './QuotationsList';
import { QuotationsKanbanView } from './QuotationsKanbanView';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * Quotations component serves as a container for rendering quotations either in a list or kanban view.
 *
 * @component
 * @returns {JSX.Element} Rendered JSX element representing the Quotations component.
 */
export const Quotations = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isKanbanView, setIsKanbanView] = useState(false);
  const [statusesColors, setStatusesColors] = useState([]);

  const [refresh, setRefresh] = useState(false);

  const getStatusesColors = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'quotation-statuses-colors'
      });
      setStatusesColors(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    getStatusesColors();
  }, [refresh]);

  return !isKanbanView ? (
    <QuotationsList
      isKanbanView={isKanbanView}
      setIsKanbanView={setIsKanbanView}
      statusesColors={statusesColors}
    />
  ) : (
    <QuotationsKanbanView
      isKanbanView={isKanbanView}
      setIsKanbanView={setIsKanbanView}
      refresh={refresh}
      setRefresh={setRefresh}
    />
  );
};
