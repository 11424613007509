export const userRoles = {
  GUEST: 'green',
  USER: 'blue',
  'SUPER-USER': 'geekblue',
  ADMIN: 'red',
  'SUPER-ADMIN': 'magenta'
};

export const pendingTasksCat = {
  POST: 'green',
  PATCH: 'blue',
  DELETE: 'red'
};

export const customerTypes = {
  PRIVATE: '#C1CCFF',
  PROFESSIONAL: '#3D66CC',
  PROSPECT: '#C478F2'
};

export const quotationStatus = {
  QUOTATION_BEING_CREATED: '#ABABAB',
  'DEVIS FINAUX': '#C1CCFF',
  'DEVIS REMIS': '#3D66CC',
  'DEVIS SIGNÉS': '#73DF78',
  'DEVIS REFUSÉS': '#F57F80'
};

export const colorTag = {
  ARCHIVED: 'grey'
};

export const invoiceTypes = {
  ADV_INITIAL: '#f57f80',
  ADVANCE: '#c478f2',
  CREDIT_NOTE: '#EB5B25',
  BASIC: '#3d66cc'
};

export const projectsStatus = {
  OPEN: '#73DF78',
  DONE: '#ABABAB',
  ARCHIVED: '#EB5B25'
};

export const WFLStatus = {
  INITIATED: 'grey',
  PENDING: 'orange',
  VALIDATED: 'green'
};
