import { Table } from 'antd';

/**
 * ShapingsTable displays a table using Ant Design Table component.
 *
 * @component
 *
 * @param {Array} data - Data to be displayed in the table.
 * @param {Array} columns - Table columns configuration.
 * @returns {JSX.Element} JSX element representing the table.
 */
export const ShapingsTable = (data, columns) => (
  <Table
    dataSource={data}
    columns={columns}
    pagination={false}
    rowKey={(record) => record._id}
  />
);
