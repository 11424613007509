import moment from 'moment';
import { formatNumberWithSpaces } from '../../../../utils/formatters/formatNumberWithSpaces';

/**
 * Formats the advance table data number by finding the index of a specific invoice number.
 *
 * @param {Object} datas - The data object containing order and invoices information.
 * @param {string} numberInvoice - The invoice number to find.
 * @returns {number} The index of the invoice number incremented by 1, or 0 if not found.
 */
export const formatAdvanceTableDataNumber = (datas, numberInvoice) => {
  const index = datas.order.invoices.findIndex(
    (invoice) => invoice.number === numberInvoice
  );
  return index !== -1 ? index + 1 : 0;
};

/**
 * Calculates the percentage of the advance total with respect to the total TTC.
 *
 * @param {number} advance_total - The advance total amount.
 * @param {number} totalTTC - The total amount including all taxes.
 * @returns {number} The percentage of the advance total with respect to the total TTC, rounded to the nearest whole number.
 */
export const percentageInvoice = (advance_total, totalTTC) => {
  const percentage = ((advance_total * 100) / totalTTC).toFixed(0);

  return percentage;
};

/**
 * Formats invoice data for the advance table.
 *
 * @param {Object} datas - The invoice data to be formatted.
 * @param {function} t - The translation function for localization.
 * @returns {Array} An array of objects containing formatted invoice data.
 */
export const formatAdvanceTableData = (datas, t) => [
  {
    label: `${
      datas?.invoice_type === 'ADVANCE'
        ? t('invoices.show.advance_of')
        : t('invoices.show.init_advance_of')
    } n°${formatAdvanceTableDataNumber(datas, datas.number)} ${t(
      'invoices.show.of.of_1'
    )} ${percentageInvoice(
      datas.totals.advance_total,
      datas.order.totals.totalTTC
    )} % ${t('invoices.show.value_of')} ${datas.totals.advance_total}€ TTC ${t(
      'invoices.show.on_order'
    )} ${datas?.order?.number} ${t('invoices.show.of.of_2')} ${moment(
      datas.body_infos.emission_date
    ).format('DD-MM-YYYY')} ${t(
      'invoices.show.total'
    )} ${formatNumberWithSpaces(datas.order.totals.totalTTC)}€ TTC.`,
    total: datas.totals.advance_total
  }
];
