import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Invoices } from '../routes/invoices/Invoices';
import { ShowInvoice } from '../routes/invoices/Show/ShowInvoice';
import { InvoiceContextProvider } from '../contexts/InvoiceContext';
import CreateInvoice from '../routes/invoices/form/CreateInvoice';

const InvoiceRouter = () => (
  <InvoiceContextProvider>
    <Routes>
      <Route path="/show/:id" element={<ShowInvoice />} />
      <Route path="/create/:source" element={<CreateInvoice />} />
      <Route index element={<Invoices />} />
    </Routes>
  </InvoiceContextProvider>
);

export default InvoiceRouter;
