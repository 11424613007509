/**
 * Renders the appropriate totals content based on the invoice type.
 *
 * @param {Object} datas - The invoice data.
 * @param {JSX.Element} advanceTotals - JSX content for advance totals.
 * @param {JSX.Element} creditTotals - JSX content for credit note totals.
 * @param {JSX.Element} basicTotals - JSX content for basic totals.
 * @returns {JSX.Element} JSX content for the appropriate totals based on invoice type.
 */
export const renderTotalsListContent = (
  datas,
  advanceTotals,
  creditTotals,
  basicTotals
) => {
  switch (datas.invoice_type) {
    case 'ADVANCE':
      return advanceTotals;
    case 'ADV_INITIAL':
      return advanceTotals;
    case 'CREDIT_NOTE':
      return creditTotals;
    default:
      return basicTotals;
  }
};
