import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CreateUpdateSupplier from '../../routes/purchases/suppliers/CreateUpdateSupplier';
import ShowSupplier from '../../routes/purchases/suppliers/ShowSupplier';
import { Suppliers } from '../../routes/purchases/suppliers/Suppliers';
import { SupplierContextProvider } from '../../contexts/SupplierContext';

const SuppliersRouter = () => (
  <SupplierContextProvider>
    <Routes>
      <Route
        path="/create"
        element={<CreateUpdateSupplier purpose="create" />}
      />
      <Route
        path="/edit/:id"
        element={<CreateUpdateSupplier purpose="edit" />}
      />
      <Route path="/show/:id" element={<ShowSupplier />} />
      <Route index element={<Suppliers />} />
    </Routes>
  </SupplierContextProvider>
);

export default SuppliersRouter;
