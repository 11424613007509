import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';

export const ConfigurationContext = createContext({});

/**
 * Provider component that wraps its children with the ConfigurationContext.
 * Provides functions and state related to fetching, adding, editing, and deleting configurations.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {React.ReactNode} props.children - Child components to be wrapped by the context provider.
 * @returns {void}
 */
export const ConfigurationContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record) => record._id === editingKey;

  const cancel = () => setEditingKey('');

  const fetchItems = async (resourceName, setData) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${resourceName}`
      });
      setData(data);
    } catch (e) {
      message(e);
    }
  };

  const addItem = async (newItem, resourceName) => {
    try {
      await dispatchAPI('POST', { url: `/${resourceName}`, body: newItem });
    } catch (e) {
      message(e);
    }
    setForceRefresh(!forceRefresh);
  };

  const editItem = async (item, id, resourceName) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/${resourceName}/${id}`,
        body: item
      });
    } catch (e) {
      message(e);
    }
    setForceRefresh(!forceRefresh);
  };

  const deleteItem = async (item, resourceName) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/${resourceName}/${item._id}`
      });
    } catch (e) {
      message(e);
    }
    setForceRefresh(!forceRefresh);
  };

  return (
    <ConfigurationContext.Provider
      value={{
        fetchItems,
        addItem,
        editItem,
        deleteItem,
        editingKey,
        setEditingKey,
        forceRefresh,
        setForceRefresh,
        isEditing,
        cancel
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};

ConfigurationContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(ConfigurationContext);
