import { useTranslation } from 'react-i18next';

/**
 * A custom hook for generating a list of label-content pairs for displaying information.
 *
 * @hook
 *
 * @param {Object} options - Options for generating the list content.
 * @param {Object} options.category - The category information.
 * @param {string} options.denomination - The denomination information.
 * @param {number} options.devaluation - The devaluation percentage.
 * @param {Object} options.supplier - The supplier information.
 * @param {Object} options.finishing_product - The finishing product information.
 * @returns {Array} An array of label-content pairs.
 */
export const useListContent = ({
  category,
  denomination,
  devaluation,
  supplier,
  finishing_product
}) => {
  const { t } = useTranslation();

  return [
    {
      label: 'materials.show.denomination',
      content: denomination || t('errors.form.not_specified')
    },
    {
      label: 'materials.show.category',
      content: (category && category.title) || t('errors.form.not_specified')
    },
    {
      label: 'materials.show.supplier',
      content:
        (supplier && supplier.denomination) || t('errors.form.not_specified')
    },
    {
      label: 'materials.show.finishing_product',
      content:
        (finishing_product && finishing_product.title) ||
        t('errors.form.not_specified')
    },
    ...(devaluation
      ? [
          {
            label: 'materials.show.devaluation',
            content:
              (devaluation && `${devaluation}%`) ||
              t('errors.form.not_specified')
          }
        ]
      : [])
  ];
};
