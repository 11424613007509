import { createContext, useContext, useEffect, useState } from 'react';
import { useAuthContext } from './AuthContext';
import { useErrorMessage } from '../utils/errorMessage';

const ProjectContext = createContext({ isValid: false });

export const ProjectContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const [importTemplate, setImportTemplate] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [importTemplateResponse] = await Promise.all([
          dispatchAPI('GET', {
            url: 'template-import?populate=file&type=PROJECT'
          })
        ]);
        setImportTemplate(importTemplateResponse.data);
      } catch (e) {
        message(e);
      }
    };

    fetchData();
  }, []);

  return (
    <ProjectContext.Provider
      value={{
        importTemplate,
        setImportTemplate
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjectContext = () => {
  const context = useContext(ProjectContext);
  if (!context)
    throw new Error('Context must be used within a context provider');
  return context;
};
