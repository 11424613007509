import { useTranslation } from 'react-i18next';

/**
 * A custom hook that provides a column configuration for displaying only the title.
 *
 * @hook
 * @param {string} titleHeader - The header for the title column. If not provided, a default value will be used.
 * @returns {Array} An array containing a column configuration for displaying titles.
 */
export const useOnlyTitleColumn = (titleHeader) => {
  const { t } = useTranslation();
  const titleRender = titleHeader || t('configurations.form.title_column');

  return [
    {
      title: titleRender,
      dataIndex: 'title',
      editable: true,
      render: (title) => title,
      width: '25%'
    }
  ];
};
