import { useTranslation } from 'react-i18next';
import { formatNumberWithSpaces } from '../../../../utils/formatters/formatNumberWithSpaces';

/**
 * useServicesColumns is a custom hook that returns an array of column configurations for displaying service-related data.
 *
 * @hook
 *
 * @returns {Object[]} Array of column configurations.
 */
export const useServicesColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('quotations.table.volume'),
      key: 'is_option',
      dataIndex: 'is_option',
      render: (boolean) =>
        boolean ? t('quotations.table.option') : t('quotations.table.base')
    },
    {
      title: t('quotations.table.services_catalog'),
      key: 'services_catalog',
      dataIndex: 'services_catalog',
      render: (service) => service && service.nomenclature
    },
    {
      title: t('quotations.table.linked_volumes'),
      key: 'linked_volumes',
      dataIndex: 'linked_volumes',
      render: (volumes) => {
        const string = volumes
          .map((volume) => `${t('quotations.table.volume')} ${volume + 1}`)
          .join(' ');

        return string;
      }
    },
    {
      title: t('quotations.table.quantity_surface'),
      render: ({ quantity_surface_type, quantity_surface }) => {
        if (quantity_surface_type === 'surface') {
          return `${quantity_surface} m2`;
        }
        return quantity_surface;
      }
    },
    {
      title: t('quotations.table.unprocessed_value'),
      render: ({ services_catalog, quantity_surface_type }) => {
        if (quantity_surface_type === 'surface') {
          return (
            services_catalog &&
            `${formatNumberWithSpaces(services_catalog.price)}€/m2`
          );
        }
        return (
          services_catalog &&
          `${formatNumberWithSpaces(services_catalog.price)}€`
        );
      }
    },
    {
      title: t('quotations.table.customer_coefficient_value'),
      key: 'customer_coefficient_value',
      dataIndex: 'customer_coefficient_value',
      render: (value) => value && `${formatNumberWithSpaces(value)}€`
    },
    {
      title: t('quotations.table.commission'),
      key: 'commission',
      dataIndex: 'commission',
      render: (rate) => rate && `${rate}%`
    },
    {
      title: t('quotations.table.facial_value'),
      key: 'facial_value',
      dataIndex: 'facial_value',
      render: (value) => value && `${formatNumberWithSpaces(value)}€`
    },
    {
      title: t('quotations.table.VAT_rate'),
      key: 'VAT_rate',
      dataIndex: 'VAT_rate',
      render: (rate) => rate && `${rate.percentage}%`
    },
    {
      title: t('quotations.table.all_included_value'),
      key: 'all_included_value',
      dataIndex: 'all_included_value',
      render: (value) => value && `${formatNumberWithSpaces(value)}€`
    }
  ];
};
