import React, { useCallback, useEffect, useState, forwardRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Spin } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { PageHeaderCustom } from '../../../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../../../components/ContentCustom/ContentCustom';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../../../utils/constants/formLayout';
import { useCatalogMaterialContext } from '../../../../contexts/CatalogMaterialContext';

/**
 * CreateUpdateMaterialContainer Component
 *
 * @component
 *
 * @typedef {Object} FormField
 * @property {string} label - Label for the form field.
 * @property {string} name - Name of the form field.
 * @property {string} [type] - Type of the form field.
 * @property {React.ReactNode} [input] - Custom input component.
 * @property {Array} [options] - Options for select fields.
 *
 * @typedef {Object} ResourceConfig
 * @property {Object} onGetResource - Configuration for getting resource data.
 * @property {function} onGetResource.setFields - Function to set resource data fields.
 * @property {Object} onCreateResource - Configuration for creating a resource.
 * @property {function} onCreateResource.setBody - Function to set request body for creation.
 * @property {Object} onUpdateResource - Configuration for updating a resource.
 * @property {function} onUpdateResource.setBody - Function to set request body for update.
 *
 * @typedef {Object} CreateUpdateMaterialProps
 * @property {string} purpose - Purpose of the form (create or edit).
 * @property {Array<FormField>} fields - List of form fields.
 * @property {boolean} loadingFields - Flag indicating if fields are loading.
 * @property {string} resource - Name of the resource.
 * @property {string} baseUrl - Base URL for API requests.
 * @property {ResourceConfig} config - Configuration for resource operations.
 * @property {React.ReactNode} formExtra - Additional form elements.
 * @property {string} [tradKey] - Key for translation of form elements.
 * @property {string} [submitLabel] - Label for submit button.
 * @property {Function} [customSubmit] - Custom submit function.
 * @property {boolean} isParentLoading - Flag indicating if parent component is loading.
 * @property {Function} ref - Ref for the form.
 *
 * @param {CreateUpdateMaterialProps} props - Component props.
 * @returns {React.ReactNode} - CreateUpdateMaterialContainer component.
 */
export const CreateUpdateMaterialContainer = forwardRef(
  (
    {
      purpose,
      fields,
      loadingFields,
      resource,
      baseUrl,
      config,
      formExtra,
      tradKey,
      submitLabel,
      customSubmit,
      isParentLoading
    },
    ref
  ) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { message } = useErrorMessage();
    const { dispatchAPI } = useAuthContext();
    const { setDataPriceTables } = useCatalogMaterialContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const generateFields = useGenerateFormItem();
    const [form] = Form.useForm();
    const { onGetResource, onCreateResource, onUpdateResource } = config;

    const updateResource = async (body) => {
      setIsSubmitting(true);
      try {
        await dispatchAPI('PATCH', {
          url: `${baseUrl}/${id}`,
          body:
            onUpdateResource && onUpdateResource.setBody
              ? onUpdateResource.setBody(body)
              : body
        });
        setDataPriceTables();
        navigate(-1);
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
    };

    const createResource = async (body) => {
      setIsSubmitting(true);
      try {
        await dispatchAPI('POST', {
          url: `${baseUrl}`,
          body:
            onCreateResource && onCreateResource.setBody
              ? onCreateResource.setBody(body)
              : body
        });
        setDataPriceTables();
        navigate(-1);
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
    };

    const getResource = useCallback(async () => {
      setIsLoading(true);
      try {
        const { data } = await dispatchAPI('GET', { url: `${baseUrl}/${id}` });
        form.setFieldsValue(
          onGetResource && onGetResource.setFields
            ? onGetResource.setFields(data)
            : data
        );
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    }, [purpose, id, loadingFields, baseUrl]);

    useEffect(() => {
      if (purpose === 'edit' && id) {
        setIsLoading(true);
        if (!loadingFields)
          (async () => {
            await getResource();
          })();
      }
    }, [getResource]);

    const handleSubmit = async (values) => {
      if (customSubmit) customSubmit(values);
      else {
        if (purpose === 'edit') await updateResource(values);
        if (purpose === 'create') await createResource(values);
      }
    };

    return (
      <>
        <PageHeaderCustom title={t(`${resource}.form.title.${purpose}`)} />
        <ContentCustom>
          <Spin spinning={isLoading || isParentLoading}>
            <Form
              ref={ref}
              {...formItemLayout}
              onFinish={handleSubmit}
              form={form}
              layout="vertical"
            >
              {fields.map((field) =>
                generateFields(tradKey || resource, field)
              )}
              {formExtra}
              <Form.Item {...tailFormItemLayout}>
                <Row justify="end">
                  <Button
                    style={{ margin: '0 10px' }}
                    type="link"
                    danger
                    onClick={() => navigate(-1)}
                  >
                    {`${t('buttons.cancel')} `}
                    <CloseOutlined />
                  </Button>
                  <Button type="add" htmlType="submit" loading={isSubmitting}>
                    {`${t(submitLabel || 'buttons.save')} `}
                    <CheckOutlined />
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          </Spin>
        </ContentCustom>
      </>
    );
  }
);

CreateUpdateMaterialContainer.propTypes = {
  purpose: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseUrl: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  loadingFields: PropTypes.bool,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    }),
    onCreateResource: PropTypes.shape({
      setBody: PropTypes.func
    }),
    onUpdateResource: PropTypes.shape({
      setBody: PropTypes.func
    })
  }),
  formExtra: PropTypes.element,
  tradKey: PropTypes.string,
  submitLabel: PropTypes.string,
  customSubmit: PropTypes.func,
  isParentLoading: PropTypes.bool,
  form: PropTypes.func
};

CreateUpdateMaterialContainer.defaultProps = {
  config: {},
  loadingFields: false,
  formExtra: null,
  tradKey: null,
  submitLabel: null,
  customSubmit: null,
  isParentLoading: false,
  form: null
};
