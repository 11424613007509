import PropTypes from 'prop-types';
import { Col, Form, Row } from 'antd';
import { PriceConditionsForm } from './PriceConditionsForm';
import { BaseOptionTotalForm } from './BaseOptionTotalForm';

export const SummaryForm = ({ form }) => (
  <Form.Item className="quotation-summary-title">
    <Row style={{ marginLeft: 65 }}>
      <PriceConditionsForm form={form} />
    </Row>
    <Row style={{ justifyContent: 'space-between' }}>
      <Col style={{ width: '50%' }}>
        <BaseOptionTotalForm purpose="base" />
      </Col>
      <Col style={{ width: '50%' }}>
        <BaseOptionTotalForm purpose="option" />
      </Col>
    </Row>
  </Form.Item>
);

SummaryForm.propTypes = {
  form: PropTypes.shape({})
};

SummaryForm.defaultProps = {
  form: {}
};
