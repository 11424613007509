import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { outOfNavRoutes, routes } from '../utils/constants/developerRoutes';
import { Login } from '../routes/login';
import { Home } from '../routes/home/Home';
import { Exception } from '../components';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { Settings } from '../routes/settings/settings';
import { AdminTheme } from '../assets/styles/Theme/AdminTheme';
import ParametersRouter from './ParametersRouter';
import CustomerRouter from './CustomerRouter';
import ProjectRouter from './ProjectRouter';
import CatalogRouter from './Catalog/CatatogRouter';
import PurchasesRouter from './Purchases/PurchasesRouter';
import QuotationRouter from './QuotationRouter';
import OrderRouter from './OrderRouter';
import InvoiceRouter from './InvoiceRouter';
import PlanningRouter from './PlanningRouter';
import { WorkflowRouter } from '../routes/workflows/WorkflowRouter';
import { MasterContextProvider } from '../contexts/MasterContext';
import { RequireAuth } from './RequireAuth';

export const DeveloperRouter = () => {
  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'admin';
    }
  });

  return (
    <BrowserRouter>
      <AdminTheme />
      <MasterContextProvider>
        <Routes>
          <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
          <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
          <Route
            path={`${outOfNavRoutes.CONFIRMATION}/:token`}
            element={<Confirmation />}
          />
          <Route element={<RequireAuth />}>
            <Route
              path={`${outOfNavRoutes.PROFILE}/*`}
              element={<ProfileRouter />}
            />
            <Route
              path={`${routes.PARAMETERS}/*`}
              element={<ParametersRouter />}
            />
            <Route path={outOfNavRoutes.SETTINGS} element={<Settings />} />
            <Route path={routes.HOME} element={<Home />} />
            <Route
              path={`${routes.CUSTOMERS}/*`}
              element={<CustomerRouter />}
            />
            <Route path={`${routes.PROJECTS}/*`} element={<ProjectRouter />} />
            <Route path={`${routes.CATALOG}/*`} element={<CatalogRouter />} />
            <Route
              path={`${routes.PURCHASES}/*`}
              element={<PurchasesRouter />}
            />
            <Route
              path={`${routes.QUOTATIONS}/*`}
              element={<QuotationRouter />}
            />
            <Route path={`${routes.ORDERS}/*`} element={<OrderRouter />} />
            <Route path={`${routes.INVOICES}/*`} element={<InvoiceRouter />} />
            <Route path={`${routes.PLANNING}/*`} element={<PlanningRouter />} />
            <Route
              path={`${routes.WORKFLOWS}/*`}
              element={<WorkflowRouter />}
            />
            <Route path="*" element={<Exception />} />
          </Route>
        </Routes>
      </MasterContextProvider>
    </BrowserRouter>
  );
};
