import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ListUsers } from './ListUsers';
import { CreateUpdateUser } from './CreateUpdateUser';
import { ShowUser } from './ShowUser';
import { Exception } from '../../components';

export const UserRouter = () => (
  <Routes>
    <Route path="/create" element={<CreateUpdateUser purpose="create" />} />
    <Route path="/edit/:id" element={<CreateUpdateUser purpose="edit" />} />
    <Route path="/show/:id" element={<ShowUser />} />
    <Route index element={<ListUsers />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
