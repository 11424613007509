/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Steps } from 'antd';
import { ContentCustom } from '../../../../../components/ContentCustom/ContentCustom';
import ETLStepInputs from '../ETL/ETLStepInputs';
import EmailGeneration from './EmailGeneration';
import ETLStepFinish from '../../CommonSteps/StepFinish';

const EmailModale = ({
  isModalVisible,
  setIsModalVisible,
  handleOk,
  handleCancel,
  sourceElements,
  operations,
  setOperations,
  setOutputs
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [stepInputs, setStepInputs] = useState(operations.stepInputs || []);
  const [finalInputs, setFinalInputs] = useState(operations.finalInputs || {});
  const [emailType, setEmailType] = useState(operations.emailType || '');

  const finishModale = () => {
    let newOutputs = {};
    stepInputs.map((stepInput) => {
      newOutputs = { ...newOutputs, ...stepInput.data.outputs };
    });

    setOperations({ stepInputs, finalInputs, emailType });
    setOutputs(newOutputs);
    setIsModalVisible(false);
    handleOk();
  };

  const stepToShow = () => {
    switch (currentStep) {
      case 0:
        return (
          <ETLStepInputs
            inputs={sourceElements}
            stepInputs={stepInputs}
            setStepInputs={setStepInputs}
            nextStep={() => setCurrentStep(currentStep + 1)}
          />
        );
      case 1:
        return (
          <EmailGeneration
            inputs={sourceElements}
            emailType={emailType}
            setEmailType={setEmailType}
            stepInputs={stepInputs}
            finalInputs={finalInputs}
            setFinalInputs={setFinalInputs}
            nextStep={() => setCurrentStep(currentStep + 1)}
          />
        );
      case 2:
        return <ETLStepFinish description="Génération de l'email terminé" />;
      default:
        return (
          <ETLStepInputs
            inputs={sourceElements}
            stepInputs={stepInputs}
            setStepInputs={setStepInputs}
            nextStep={() => setCurrentStep(currentStep + 1)}
          />
        );
    }
  };

  // console.log('stepInputs', stepInputs);
  // console.log('operations', operations);
  // console.log('finalInputs', finalInputs);
  // console.log('emailType', emailType);

  return (
    <Modal
      title="Email transactionnels"
      open={isModalVisible}
      maskClosable={false}
      width="90%"
      onOk={finishModale}
      onCancel={handleCancel}
    >
      <Steps
        current={currentStep}
        items={[
          {
            title: 'Selection des inputs à considérer',
            onClick: () => setCurrentStep(0)
          },
          {
            title: "Génération de l'email",
            onClick: () => setCurrentStep(1)
          },
          {
            title: 'Validation',
            onClick: () => setCurrentStep(2)
          }
        ]}
      />
      <ContentCustom>{stepToShow()}</ContentCustom>
    </Modal>
  );
};

EmailModale.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  sourceElements: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  operations: PropTypes.shape({
    emailType: PropTypes.string,
    stepInputs: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    finalInputs: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  }).isRequired,
  setOperations: PropTypes.func.isRequired,
  setOutputs: PropTypes.func.isRequired
};
export default EmailModale;
