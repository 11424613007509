import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { ListResource } from '../../components/ListResource/ListResource';
import { useQuotationColumns } from './columns';
import { QuotationHeader } from './QuotationHeader';
import { useDownloadDocument } from '../../utils/downloadDoc';
import { useQuotationContext } from '../../contexts/QuotationContext';
import { headers } from './formatter/headers';
import { formatQuotations } from './formatter/formatQuotations';

/**
 * QuotationsList component displays a list of quotations with various filters and options.
 *
 * @component
 * @param {boolean} isKanbanView - Flag to indicate if the kanban view is active.
 * @param {Function} setIsKanbanView - Function to set the kanban view flag.
 * @param {Array} statusesColors - Array of status color information.
 * @returns {JSX.Element} Rendered JSX element representing the QuotationsList.
 */
export const QuotationsList = ({
  isKanbanView,
  setIsKanbanView,
  statusesColors
}) => {
  const { t } = useTranslation();
  const { importTemplate, archiveState, statusFilter } = useQuotationContext();
  const { downloadDocument } = useDownloadDocument();
  const { formatQuotation } = formatQuotations();
  const [forceRefresh, setForceRefresh] = useState(false);
  const columns = useQuotationColumns(
    statusesColors,
    forceRefresh,
    setForceRefresh
  );

  const [customerFilter, setCustomerFilter] = useState();

  const extraQuery = `${customerFilter ? `customer=${customerFilter}` : ''}${
    statusFilter ? `${customerFilter ? `&` : ''}${statusFilter}` : ''
  }`;

  return (
    <ListResource
      columns={columns}
      populate="customer,kanban_status"
      extraQuery={extraQuery}
      resourceName="quotations"
      resourceModelName="Quotation"
      withArchiveButton={false}
      formatter={formatQuotation}
      headers={headers}
      forceRefresh={forceRefresh}
      setForceRefresh={setForceRefresh}
      archiveState={archiveState}
      customActionColumn
      extraMenu={
        importTemplate && importTemplate.length > 0
          ? [
              {
                key: 'template',
                label: importTemplate[0]?.file && (
                  <Button
                    type="link"
                    onClick={() => downloadDocument(importTemplate[0].file)}
                  >
                    <DownloadOutlined style={{ fontSize: 18 }} />
                    {t('buttons.template')}
                  </Button>
                )
              }
            ]
          : []
      }
      extraFilters={
        <QuotationHeader
          isKanbanView={isKanbanView}
          setCustomerFilter={setCustomerFilter}
          setIsKanbanView={setIsKanbanView}
        />
      }
    />
  );
};

QuotationsList.propTypes = {
  isKanbanView: PropTypes.bool,
  setIsKanbanView: PropTypes.func,
  statusesColors: PropTypes.arrayOf(PropTypes.shape({}))
};

QuotationsList.defaultProps = {
  isKanbanView: false,
  setIsKanbanView: null,
  statusesColors: null
};
