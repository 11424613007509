import { useEffect, useState } from 'react';
import { Col, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { CategoryFilter } from './CategoryFilter';

/**
 * @component
 * Component for rendering extra filters for resource listings.
 * @param {object} props - The component props.
 * @param {string} props.resourceModelName - The name of the resource model for fetching filters.
 * @param {string} props.translationName - The name used for translation keys.
 * @param {boolean} [props.extraMaterialFilters=false] - Flag indicating if extra material filters should be displayed.
 * @param {boolean} [props.extraAccessoriesFilters=false] - Flag indicating if extra accessories filters should be displayed.
 * @returns {JSX.Element} The JSX element representing the ExtraFilters component.
 */
export const ExtraFilters = ({
  translationName,
  extraMaterialFilters,
  extraAccessoriesFilters,
  setCategoryFilter,
  categoryFilter,
  denominationFilter,
  finishingProductFilter,
  setDenominationFilter,
  setFinishingProductFilter,
  setSupplierFilter,
  supplierFilter,
  archiveState
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [materials, setMaterials] = useState([]);
  const [finishingProducts, setFinishingProducts] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [categories, setCategories] = useState([]);

  const fetchMaterials = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `materials?${
          archiveState ? 'status=ARCHIVED' : 'status=ACTIVE'
        }&populate=finishing_product,category${
          categoryFilter ? `&category=${categoryFilter}` : ''
        }${denominationFilter ? `&denomination=${denominationFilter}` : ''}${
          finishingProductFilter
            ? `&finishing_product=${finishingProductFilter}`
            : ''
        }`
      });
      const finishingProductsArray = data
        .map(
          (material) => material.finishing_product && material.finishing_product
        )
        .filter(Boolean);
      const categoriesArray = data
        .map((material) => material.category && material.category)
        .filter(Boolean);
      setMaterials(data);
      setFinishingProducts(
        Array.from(new Set(finishingProductsArray.map(JSON.stringify))).map(
          JSON.parse
        )
      );
      setCategories(
        Array.from(new Set(categoriesArray.map(JSON.stringify))).map(JSON.parse)
      );
    } catch (e) {
      message(e);
    }
  };

  const fetchShapingCategories = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `shapings?${
          archiveState ? 'status=ARCHIVED' : 'status=ACTIVE'
        }&populate=category`
      });
      const categoriesArray = data
        .map((shaping) => shaping.category && shaping.category)
        .filter(Boolean);
      setCategories(
        Array.from(new Set(categoriesArray.map(JSON.stringify))).map(JSON.parse)
      );
    } catch (e) {
      message(e);
    }
  };

  const fetchServicesCategories = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `services?${
          archiveState ? 'status=ARCHIVED' : 'status=ACTIVE'
        }&populate=category`
      });
      const categoriesArray = data
        .map((shaping) => shaping.category && shaping.category)
        .filter(Boolean);
      setCategories(
        Array.from(new Set(categoriesArray.map(JSON.stringify))).map(JSON.parse)
      );
    } catch (e) {
      message(e);
    }
  };

  const fetchAccessoriesFilters = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `accessories?${
          archiveState ? 'status=ARCHIVED' : 'status=ACTIVE'
        }&populate=category,supplier&${
          supplierFilter ? `supplier=${supplierFilter}` : ''
        }`
      });
      const categoriesArray = data
        .map((accessory) => accessory.category && accessory.category)
        .filter(Boolean);
      const suppliersArray = data
        .map((accessory) => accessory.supplier)
        .filter(Boolean);
      setCategories(
        Array.from(new Set(categoriesArray.map(JSON.stringify))).map(JSON.parse)
      );
      setSuppliers(
        Array.from(new Set(suppliersArray.map(JSON.stringify))).map(JSON.parse)
      );
    } catch (e) {
      message(e);
    }
  };

  const fetchSelectOptions = async () => {
    if (translationName === 'materials') {
      await fetchMaterials();
    }
    if (translationName === 'accessories') {
      await fetchAccessoriesFilters();
    }
    if (translationName === 'shapings') {
      await fetchShapingCategories();
    }
    if (translationName === 'services') {
      await fetchServicesCategories();
    }
  };

  useEffect(() => {
    (async () => {
      await fetchSelectOptions();
    })();
  }, [
    archiveState,
    supplierFilter,
    categoryFilter,
    denominationFilter,
    finishingProductFilter
  ]);

  return (
    <Col
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Col>{t('materials.filters.filters')}</Col>
      <Col>
        <CategoryFilter
          categories={categories}
          translationName={translationName}
          setCategoryFilter={setCategoryFilter}
        />
      </Col>
      {extraMaterialFilters && (
        <>
          <Col>
            <Select
              allowClear
              placeholder={t('materials.filters.denomination')}
              style={{ width: 150 }}
              onSelect={(value) => setDenominationFilter(value)}
              optionFilterProp="children"
              showSearch
              onClear={() => setDenominationFilter()}
            >
              {materials
                .sort((a, b) => a?.denomination.localeCompare(b?.denomination))
                .map((material) => (
                  <Select.Option
                    key={material._id}
                    value={material.denomination}
                  >
                    {material.denomination}
                  </Select.Option>
                ))}
            </Select>
          </Col>
          <Col>
            <Select
              allowClear
              placeholder={t('materials.filters.finishing_product')}
              style={{ width: 150 }}
              onSelect={(value) => setFinishingProductFilter(value)}
              optionFilterProp="children"
              showSearch
              onClear={() => setFinishingProductFilter()}
            >
              {finishingProducts
                .sort((a, b) => a.title.localeCompare(b.title))
                .map((finishingProduct) => (
                  <Select.Option
                    key={finishingProduct._id}
                    value={finishingProduct._id}
                  >
                    {finishingProduct.title}
                  </Select.Option>
                ))}
            </Select>
          </Col>
        </>
      )}
      {extraAccessoriesFilters && (
        <Col>
          <Select
            allowClear
            placeholder={t('accessories.filters.suppliers')}
            style={{ width: 150 }}
            onSelect={(value) => setSupplierFilter(value)}
            optionFilterProp="children"
            showSearch
            onClear={() => setSupplierFilter()}
          >
            {suppliers.map((supplier) => (
              <Select.Option key={supplier._id} value={supplier._id}>
                {supplier.denomination}
              </Select.Option>
            ))}
          </Select>
        </Col>
      )}
    </Col>
  );
};

ExtraFilters.propTypes = {
  translationName: PropTypes.string.isRequired,
  extraMaterialFilters: PropTypes.bool,
  extraAccessoriesFilters: PropTypes.bool,
  setCategoryFilter: PropTypes.func,
  setDenominationFilter: PropTypes.func,
  setFinishingProductFilter: PropTypes.func,
  supplierFilter: PropTypes.string,
  setSupplierFilter: PropTypes.func,
  archiveState: PropTypes.bool,
  categoryFilter: PropTypes.string,
  denominationFilter: PropTypes.string,
  finishingProductFilter: PropTypes.string
};

ExtraFilters.defaultProps = {
  extraMaterialFilters: false,
  extraAccessoriesFilters: false,
  setCategoryFilter: null,
  setDenominationFilter: null,
  setFinishingProductFilter: null,
  setSupplierFilter: null,
  supplierFilter: undefined,
  archiveState: undefined,
  categoryFilter: undefined,
  denominationFilter: undefined,
  finishingProductFilter: undefined
};
