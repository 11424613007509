import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Quotations } from '../routes/quotations/Quotations';
import { QuotationContextProvider } from '../contexts/QuotationContext';
import { CreateUpdateQuotation } from '../routes/quotations/form/CreateUpdateQuotation';
import { ShowQuotation } from '../routes/quotations/show/ShowQuotation';

const QuotationRouter = () => (
  <QuotationContextProvider>
    <Routes>
      <Route
        path="/create"
        element={<CreateUpdateQuotation purpose="create" />}
      />
      <Route
        path="/edit/:id"
        element={<CreateUpdateQuotation purpose="edit" />}
      />
      <Route path="/show/:id" element={<ShowQuotation />} />
      <Route index element={<Quotations />} />
    </Routes>
  </QuotationContextProvider>
);

export default QuotationRouter;
