/**
 * Formats a number by inserting spaces as thousand separators to enhance readability.
 *
 * @function
 *
 * @param {number} num - The number to format.
 * @returns {string} The formatted number with spaces as thousand separators.
 */
export const formatNumberWithSpaces = (num) => {
  if (typeof num === 'undefined' || !num) {
    return 0;
  }
  if (num !== 0) {
    return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  return num;
};
