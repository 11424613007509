import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Button, Table, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import ButtonPanel from './ButtonPanel';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';
import { ContentCustom } from '../../../components';
import useConfigurationContext from './ConfigurationContext';

const { Panel } = Collapse;

/**
 * A component that displays child configurations within a collapsible panel.
 *
 * @component
 * @param {object} props - The component props.
 * @param {string} props.childName - The name of the child configuration.
 * @param {string} props.resourceName - The name of the parent resource.
 * @returns {JSX.Element} The rendered ChildrenConfiguration component.
 */
const ChildrenConfiguration = ({ childName, resourceName }) => {
  const {
    forceRefresh,
    setForceRefresh,
    fetchItems,
    addItem,
    editItem,
    deleteItem,
    cancel,
    editingKey,
    setEditingKey,
    isEditing
  } = useConfigurationContext();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [items, setItems] = useState([]);
  const [newItem] = useState({});

  const edit = (record) => {
    form.setFieldsValue({
      ...record
    });
    setEditingKey(record._id);
  };

  const save = (record) => {
    const row = form.getFieldValue();
    try {
      editItem(row, record._id, resourceName);
      setEditingKey('');
    } catch (e) {
      message(e);
    }
    setForceRefresh(!forceRefresh);
  };

  const saveChildItem = (record, child) => {
    const row = form.getFieldValue();
    const body = {
      ...record,
      [child]: record?.[child].map((el) => {
        if (el._id === row._id) {
          return { ...el, ...row };
        }
        return el;
      })
    };
    try {
      editItem(body, record._id, resourceName);
      setEditingKey('');
    } catch (e) {
      message(e);
    }
    setForceRefresh(!forceRefresh);
  };

  const removeItemChild = async (item, parent) => {
    const newBody = parent?.[childName].filter((el) => el._id !== item._id);
    try {
      await dispatchAPI('PATCH', {
        url: `/${resourceName}/${parent._id}`,
        body: { [childName]: newBody }
      });
    } catch (e) {
      message(e);
    }
    setForceRefresh(!forceRefresh);
  };

  useEffect(() => {
    fetchItems(resourceName, setItems);
  }, [forceRefresh]);

  const childColumn = (parentData) => [
    {
      title: t(`typeOfWork.form.${childName}`),
      dataIndex: 'title',
      editable: true,
      render: (title, data) =>
        editingKey === data._id ? (
          <Form.Item name="title" style={{ width: '50%' }}>
            <Input
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
          </Form.Item>
        ) : (
          title
        ),
      width: '25%'
    },
    {
      align: 'right',
      width: '50%',
      render: (record) => (
        <ButtonPanel
          editable={isEditing(record)}
          editingKey={editingKey}
          saveItem={() => saveChildItem(parentData, childName)}
          edit={() => edit(record)}
          cancel={cancel}
          deleteItem={() => removeItemChild(record, parentData)}
        />
      )
    }
  ];

  const generatePanels = (datas) =>
    datas.map((item) => {
      const dataSource = item?.[childName];
      const id = item._id;
      const property = Object.hasOwn(item, childName) ? (
        <ContentCustom>
          <Table
            dataSource={dataSource}
            columns={childColumn(item)}
            pagination={false}
            showHeader={false}
          />
          <Button
            type="dashed"
            style={{ width: '100%' }}
            disabled={editingKey !== ''}
            onClick={() => {
              item?.[childName].push({});
              editItem(item, id, resourceName);
            }}
          >
            {t(`${childName}.form.add`)}
          </Button>
        </ContentCustom>
      ) : null;

      return (
        property && (
          <Panel
            key={item._id}
            header={
              editingKey === item._id ? (
                <Form.Item style={{ width: '50%' }} id={item._id} name="title">
                  <Input
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    id={item._id}
                  />
                </Form.Item>
              ) : (
                item.title
              )
            }
            extra={
              <ButtonPanel
                editable={isEditing(item)}
                editingKey={editingKey}
                saveItem={() => save(item)}
                edit={() => edit(item)}
                cancel={cancel}
                deleteItem={() => deleteItem(item, resourceName)}
              />
            }
          >
            {property}
          </Panel>
        )
      );
    });

  return (
    <Form form={form}>
      {items.length > 0 && <Collapse>{generatePanels(items)}</Collapse>}
      <Button
        type="dashed"
        style={{ width: '100%' }}
        disabled={editingKey !== ''}
        onClick={() => addItem(newItem, resourceName)}
      >
        {t(`${resourceName}.form.add`)}
      </Button>
    </Form>
  );
};

export default ChildrenConfiguration;

ChildrenConfiguration.propTypes = {
  childName: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired
};
