import React from 'react';
import PropTypes from 'prop-types';

const WFLNode = ({ type, title, processId, processName }) => {
  const classname = `dndnode ${type}`;

  const onDragStart = (event) => {
    event.dataTransfer.setData('application/reactflow', type);
    event.dataTransfer.setData('application/reactflow/processId', processId);
    event.dataTransfer.setData(
      'application/reactflow/processName',
      processName
    );
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <div
      className={classname}
      style={{ marginLeft: 'auto', marginRight: 'auto' }}
      onDragStart={(event) => onDragStart(event, type)}
      draggable
    >
      {`${title || type}`}
    </div>
  );
};

WFLNode.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  processId: PropTypes.string,
  processName: PropTypes.string
};

WFLNode.defaultProps = {
  title: '',
  processId: '',
  processName: ''
};

export default WFLNode;
