import { useTranslation } from 'react-i18next';
import { formatNumberWithSpaces } from '../../../../utils/formatters/formatNumberWithSpaces';

/**
 * useTotalColumns is a custom hook that returns an array of column configurations for displaying total values.
 *
 * @hook
 *
 * @returns {Object[]} Array of column configurations.
 */
export const useTotalColumns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('quotations.table.total_type'),
      key: 'total_type',
      dataIndex: 'total_type',
      sorter: true
    },
    {
      title: t('quotations.table.materials_total'),
      dataIndex: 'materials_total',
      key: 'materials_total',
      render: (total) => formatNumberWithSpaces(total) || 0
    },
    {
      title: t('quotations.table.shapings_total'),
      dataIndex: 'shapings_total',
      key: 'shapings_total',
      sorter: true,
      render: (total) => formatNumberWithSpaces(total) || 0
    },
    {
      title: t('quotations.table.services_total'),
      dataIndex: 'services_total',
      sorter: true,
      render: (total) => formatNumberWithSpaces(total) || 0
    },
    {
      title: t('quotations.table.accessories_total'),
      dataIndex: 'accessories_total',
      sorter: true,
      render: (total) => formatNumberWithSpaces(total) || 0
    },
    {
      title: t('quotations.table.unprocessed_values_total'),
      dataIndex: 'unprocessed_values_total',
      sorter: true,
      render: (total) => formatNumberWithSpaces(total) || 0
    },
    {
      title: t('quotations.table.customer_coefficient_total'),
      dataIndex: 'customer_coefficient_total',
      sorter: true,
      render: (total) => formatNumberWithSpaces(total) || 0
    },
    {
      title: t('quotations.table.commission_total'),
      dataIndex: 'commission_total',
      sorter: true,
      render: (total) => formatNumberWithSpaces(total) || 0
    },
    {
      title: t('quotations.table.facial_values_total'),
      dataIndex: 'facial_values_total',
      sorter: true,
      render: (total) => formatNumberWithSpaces(total) || 0
    },
    {
      title: t('quotations.table.discount'),
      dataIndex: 'discounted_total',
      sorter: true,
      render: (discounted_total) =>
        (discounted_total && formatNumberWithSpaces(discounted_total)) || 0
    },
    {
      title: t('quotations.table.total_excluding_VAT'),
      dataIndex: 'total_excluding_VAT',
      sorter: true,
      render: (total) => formatNumberWithSpaces(total) || 0
    },
    {
      title: t('quotations.table.VAT_total'),
      dataIndex: 'VAT_total',
      sorter: true,
      render: (total) => formatNumberWithSpaces(total) || 0
    },
    {
      title: t('quotations.table.VAT_rate'),
      dataIndex: 'VAT',
      sorter: true,
      render: (total) => `${total.percentage} %` || '0 %'
    },
    {
      title: t('quotations.table.all_included_total'),
      dataIndex: 'all_included_total',
      sorter: true,
      render: (total) => formatNumberWithSpaces(total) || 0
    }
  ];
};
