import { DatePicker, Input, Select } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';

const { Option } = Select;

/**
 * Hook that generates an array of invoice body information fields for display in a form.
 * Each field includes a name, input component, and additional properties.
 *
 * @hook
 *
 * @returns {Object} An object containing `invoiceBodyFields` array and `isLoading` state.
 */
const useInvoiceBodyFields = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const [enums, setEnums] = useState([]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'invoices/enums'
      });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await getEnums();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const invoiceBodyFields = [
    {
      name: ['body_infos', 'label'],
      rules: [{ required: true }]
    },
    {
      name: ['body_infos', 'creator'],
      rules: [{ required: true }]
    },
    {
      name: ['body_infos', 'emission_date'],
      initialValue: moment(),
      input: <DatePicker format="DD-MM-YYYY" defaultValue={moment()} />,
      rules: [{ required: true }]
    },
    {
      name: ['body_infos', 'due_date'],
      input: <DatePicker format="DD-MM-YYYY" />,
      rules: [{ required: true }]
    },
    {
      name: ['body_infos', 'payment_choice'],
      rules: [{ required: true }],
      input: (
        <Select>
          {(enums.payment_choice || []).map((enumItem) => (
            <Option key={enumItem} value={enumItem}>
              {t(`invoices.form.${enumItem}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['body_infos', 'deadline_payment'],
      rules: [{ required: true }],
      input: (
        <Select>
          {(enums.deadline_payment || []).map((enumItem) => (
            <Option key={enumItem} value={enumItem}>
              {t(`invoices.form.${enumItem}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['body_infos', 'penalty'],
      input: <Input type="number" />
    }
  ];

  return {
    invoiceBodyFields,
    isLoading
  };
};

export default useInvoiceBodyFields;
