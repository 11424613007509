/**
 * useDocuments custom hook generates an array of documents related to a quotation.
 *
 * @hook
 *
 * @param {Object} quotation - Quotation data containing document information.
 * @param {Function} t - Translation function for internationalization.
 * @returns {Array} An array of document objects containing title, file, and type.
 */
export const useDocuments = (quotation, t) => [
  {
    title: t('quotations.files.calculation_sheet'),
    file: quotation.calculation_sheet,
    type: 'calculation_sheet'
  },
  {
    title: t('quotations.files.VAT_attestation'),
    file: quotation.summary.price_conditions.VAT_attestation,
    type: 'VAT_attestation'
  },

  ...(quotation.files || []).map((document) => ({
    title: t('quotations.files.extraDocuments'),
    file: document,
    type: 'extraDocuments'
  }))
];
