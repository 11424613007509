import { useTranslation } from 'react-i18next';
import { formatNumberWithSpaces } from '../../../utils/formatters/formatNumberWithSpaces';

/**
 * Custom hook for defining table columns to display accessory data.
 *
 * @hook
 * @returns {Array} An array of column objects.
 */
const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('accessories.form.nomenclature'),
      dataIndex: 'nomenclature',
      key: 'nomenclature',
      sorter: true
    },
    {
      title: t('accessories.form.category'),
      dataIndex: ['category', 'title'],
      key: 'category',
      render: (category) => category,
      sorter: true
    },
    {
      title: t('accessories.form.price'),
      dataIndex: 'price',
      key: 'price',
      render: (price) => {
        if (price) {
          return formatNumberWithSpaces(Number(price));
        }
        return t('errors.form.not_specified');
      },
      sorter: (a, b) => (a.price < b.price ? -1 : 1)
    },
    {
      title: t('accessories.form.supplier'),
      dataIndex: ['supplier', 'denomination'],
      key: 'supplier',
      render: (supplier) => supplier,
      sorter: true
    }
  ];
};

export default useColumns;
