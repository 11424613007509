import React, { useCallback, useEffect, useState } from 'react';
import { InputNumber, Select } from 'antd';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';

/**
 * Custom hook for managing fields and loading state related to creating or updating accessories.
 *
 * @hook
 * @returns {Object} An object containing basicFields configuration and loading state.
 */
export const useFields = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();

  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [suppliers, setSuppliers] = useState([]);

  const getAccessoriesCategories = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'accessory-categories'
      });
      setCategories(data);
    } catch (e) {
      message(e);
    }
  };

  const getSuppliers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'suppliers'
      });
      setSuppliers(data);
    } catch (e) {
      message(e);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getAccessoriesCategories();
    await getSuppliers();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const basicFields = [
    {
      label: 'nomenclature',
      name: ['nomenclature'],
      rules: [{ required: true }]
    },
    {
      label: 'category',
      name: ['category'],
      input: (
        <Select>
          {categories.map((category) => (
            <Select.Option key={category._id} value={category._id}>
              {category.title}
            </Select.Option>
          ))}
        </Select>
      ),
      rules: [{ required: true }]
    },
    {
      label: 'price',
      name: ['price'],
      input: <InputNumber min={0} />,
      rules: [{ required: true }]
    },
    {
      label: 'supplier',
      name: ['supplier'],
      input: (
        <Select>
          {suppliers.map((supplier) => (
            <Select.Option key={supplier._id} value={supplier._id}>
              {supplier.denomination}
            </Select.Option>
          ))}
        </Select>
      ),
      rules: [{ required: true }]
    }
  ];

  return {
    basicFields,
    isFieldsLoading
  };
};
