import React, { useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { UserRouter } from './UserRouter';
import { AdminRouter } from './AdminRouter';
import { DeveloperRouter } from './DeveloperRouter';
import { useThemeContext } from '../contexts/ThemeContext';

/**
 * A router dispatch component that decides which router (UserRouter, AdminRouter, DeveloperRouter)
 * should be used based on the user's role. It also updates the body class and theme context based
 * on the user status.
 *
 * @component
 *
 * @returns {JSX.Element} The appropriate router component based on the user's role.
 */
export const RouterDispatch = () => {
  const { user } = useAuthContext();
  const { setTheme } = useThemeContext();

  useEffect(() => {
    const body = document.body;
    if (body && !user) {
      body.className = 'user';
      setTheme('user');
    }
  }, [user]);

  const dispatchRouter = () => {
    switch (user?.role) {
      case 'admins:ADMIN':
      case 'admins:SUPER-ADMIN':
        return <AdminRouter />;
      case 'developer:DEVELOPER':
        return <DeveloperRouter />;
      case 'production:PRODUCTION':
        return <UserRouter />;
      default:
        return <UserRouter />;
    }
  };
  return dispatchRouter();
};
