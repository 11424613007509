import React, { useCallback, useEffect, useState } from 'react';
import { Input, Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { WFLStatus } from '../../utils/constants/tagColors';

const { Option } = Select;
const { TextArea } = Input;

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState([]);

  const fields = [
    {
      name: 'title',
      rules: [{ required: true }]
    },
    {
      name: 'status',
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums || []).map((status) => (
            <Option key={status} value={status}>
              <Tag color={WFLStatus[status]}>{t(status)}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: 'description',
      input: <TextArea rows={5} />
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/workflows/enums' });
      setEnums(data.status);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
