import { useTranslation } from 'react-i18next';
import { Button, Form, Table } from 'antd';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useColumns } from './form/table/columns';
import { DetailsDrawer } from './form/table/DetailsDrawer';
import { ShapingsTable } from './form/table/ShapingsTable';

/**
 * A component that displays an invoice table with item details and pricing information.
 * Manages fetching data, handling drawer for adding item details, and displaying subtables for shapings.
 *
 * @component
 *
 * @param {Array} itemData - Quotation data.
 * @param {function} setItemData - Function to set itemData.
 * @param {string} purpose - Purpose of the component - either show or form.
 * @returns {JSX.Element} InvoiceTable component.
 */
export const InvoiceTable = ({ itemData, setItemData, purpose }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [drawerValues, setDrawerValues] = useState();

  const handleRemove = (record) => {
    setItemData((prevList) => {
      const updatedList = [...prevList];

      const findIndex = updatedList.findIndex((item) => item === record);

      updatedList.splice(findIndex, 1);

      return updatedList;
    });
  };

  const handleEdit = (record) => {
    setDrawerValues(record);
    setOpen(!open);
  };

  const columns = useColumns(handleEdit, handleRemove, purpose);

  return (
    <div>
      {purpose === 'form' && (
        <DetailsDrawer
          setItemData={setItemData}
          setDrawerValues={setDrawerValues}
          open={open}
          setOpen={setOpen}
          drawerValues={drawerValues}
        />
      )}
      {itemData && (
        <Form form={form}>
          <Table
            bordered={false}
            dataSource={itemData}
            columns={columns}
            style={{ overflowX: 'auto', overflowY: 'visible' }}
            rowClassName="editable-row"
            pagination={false}
            expandable={{
              expandedRowRender: (record, rowIndex) => {
                const shapingsWithIndex =
                  record.shapings &&
                  record.shapings.map((shaping, index) => ({
                    ...shaping,
                    index
                  }));
                return ShapingsTable(shapingsWithIndex, columns, rowIndex);
              },
              rowExpandable: (record) =>
                record.type === t('invoices.form.materials') &&
                record.shapings.length &&
                record.shapings.map((r) => r.key)
            }}
          />
          {purpose === 'form' && (
            <Button
              type="dashed"
              style={{ width: '100%', marginBottom: 16 }}
              onClick={() => setOpen(!open)}
            >
              {t('invoices.form.add_price')}
            </Button>
          )}
        </Form>
      )}
    </div>
  );
};

InvoiceTable.propTypes = {
  itemData: PropTypes.arrayOf(PropTypes.shape({})),
  setItemData: PropTypes.func,
  purpose: PropTypes.string
};

InvoiceTable.defaultProps = {
  itemData: null,
  setItemData: null,
  purpose: undefined
};
