/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import DecisionConditionsBuilder from './DecisionConditionsBuilder';

const DecisionModale = ({
  isModalVisible,
  setIsModalVisible,
  handleCancel,
  sourceElements,
  decisionConditions,
  setDecisionConditions
}) => {
  const initialEmptyFields = [
    {
      key: 0,
      input1: null,
      condition: '===',
      input2: null,
      afterCondition: ''
    }
  ];

  const [fields, setFields] = useState(
    decisionConditions || initialEmptyFields
  );

  const finishModale = () => {
    setDecisionConditions(fields);
    setIsModalVisible(false);
  };

  const checkBtnEnable = () => {
    let isNoEmptyInputs = true;
    fields.map((field) => {
      if (field.input1 === null || field.input2 === null)
        isNoEmptyInputs = false;
    });
    return isNoEmptyInputs;
  };

  return (
    <Modal
      title="Gestion des conditions de décision"
      open={isModalVisible}
      maskClosable={false}
      width="90%"
      onOk={finishModale}
      onCancel={handleCancel}
      okButtonProps={{ disabled: !checkBtnEnable() }}
    >
      <DecisionConditionsBuilder
        sourceElements={sourceElements}
        decisionConditions={decisionConditions}
        fields={fields}
        setFields={setFields}
      />
    </Modal>
  );
};

DecisionModale.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  sourceElements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  decisionConditions: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  setDecisionConditions: PropTypes.func.isRequired
};
export default DecisionModale;
