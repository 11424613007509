import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

export const useErrorMessage = () => {
  const { t } = useTranslation();
  return {
    message: (
      status = '404',
      duration = 4.5,
      key = undefined,
      notificationType = 'error'
    ) => {
      const s = status?.response?.status || status;
      const d = status?.response?.data?.description;
      const m = status?.response?.data?.message;
      if (s !== 498)
        notification[notificationType]({
          message: t(`errors.message.${d || s || m}`),
          key: key || 'network_feedback',
          duration
        });
    }
  };
};
