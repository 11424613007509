import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Modal, Spin, Tag } from 'antd';
import { PageHeaderCustom, ContentCustom } from '../../../components';
import { InfosDetail } from './InfosDetail';
import { InvoiceDetailLayer } from './InvoiceDetailLayer';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ExtraButtons } from './ExtraButtons';
import { generateTableDataSource } from '../form/utils/generateTableDataSource';

/**
 * Component that displays detailed information about an invoice.
 * It fetches the invoice data and renders it along with additional controls.
 *
 * @component
 *
 * @returns {JSX.Element} - The rendered component.
 */
export const ShowInvoice = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const [invoice, setInvoice] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [itemData, setItemData] = useState();
  const [creditEdition, setCreditEdition] = useState(false);
  const [detailsData, setDetailsData] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const invoiceData = InvoiceDetailLayer(
    invoice,
    itemData,
    creditEdition,
    setCreditEdition,
    refresh,
    setRefresh,
    detailsData
  );

  const titleDisplay = () => {
    switch (invoice?.invoice_type) {
      case 'ADV_INITIAL':
        return t(`invoices.show.title.advance_initial`, {
          number: invoice?.number
        });
      case 'ADVANCE':
        return t(`invoices.show.title.advance_normal`, {
          number: invoice?.number
        });
      case 'CREDIT_NOTE':
        return t(`invoices.show.title.credit_note`, {
          number: invoice?.number
        });
      default:
        return t(`invoices.show.title.basic`, { number: invoice?.number });
    }
  };

  const getInvoice = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `invoices/show/${id}?populate=totals.VAT_rate`
      });
      setInvoice(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  };

  const patchInvoiceStatus = async (status) => {
    setIsSubmitting(true);
    try {
      await dispatchAPI('PATCH', {
        url: `invoices/status/${id}`,
        body: {
          status,
          totals: {
            paid: invoice.totals.due
          }
        }
      });
      setOpen(!open);
      setRefresh(!refresh);
      setRefresh(refresh);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  const getQuotation = async () => {
    const activeQuotation =
      invoice &&
      invoice?.order.quotations.find(
        (quotationItem) => quotationItem.status === 'ACTIVE'
      ).quotation;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `quotations/show/${activeQuotation._id}?populate=global_VAT_rate`
      });
      const dataSource = generateTableDataSource(data, t);
      setItemData(dataSource);
    } catch (e) {
      message(e);
    }
  };

  const getDetailsInfos = async () => {
    try {
      const activeQuotation =
        invoice &&
        invoice?.order.quotations.find(
          (quotationItem) => quotationItem.status === 'ACTIVE'
        ).quotation;
      const { data } = await dispatchAPI('GET', {
        url: `quotations/infos/${activeQuotation._id}`
      });
      setDetailsData(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getInvoice();
    })();
  }, [refresh, isSubmitting]);

  useEffect(() => {
    if (invoice && invoice.invoice_type === 'BASIC')
      (async () => {
        await getQuotation();
      })();
    if (
      invoice &&
      (invoice.invoice_type === 'ADV_INITIAL' ||
        invoice.invoice_type === 'ADVANCE')
    ) {
      (async () => {
        await getDetailsInfos();
      })();
    }
  }, [invoice]);

  return (
    <>
      <PageHeaderCustom
        title={titleDisplay()}
        extra={
          invoice?.status !== 'ARCHIVED' ? (
            <ExtraButtons
              patchInvoiceStatus={patchInvoiceStatus}
              open={open}
              setOpen={setOpen}
              invoiceType={invoice?.invoice_type}
              creditEdition={creditEdition}
              setCreditEdition={setCreditEdition}
              invoiceStatus={invoice?.status}
              invoiceFile={invoice?.file}
            />
          ) : (
            <Tag>{t('invoices.show.archived')}</Tag>
          )
        }
      />
      <ContentCustom>
        <Spin spinning={invoice && isLoading}>
          {invoice && <InfosDetail datas={invoiceData} />}
        </Spin>
      </ContentCustom>
      <Modal
        open={open}
        title={t('invoices.show.modal_title')}
        onOk={() => patchInvoiceStatus('PAID')}
        onCancel={() => setOpen(!open)}
        okText={t('invoices.show.ok_text')}
        confirmLoading={isSubmitting}
      >
        {t('invoices.show.modal_confirmation')}
      </Modal>
    </>
  );
};
