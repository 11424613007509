import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/**
 * @component
 * Component for rendering a category filter dropdown.
 * @param {object} props - The component props.
 * @param {string} props.categories - Array of categories.
 * @param {string} props.translationName - The name used for translation keys.
 * @param {Function} props.filteredData - The callback function to handle filtered data.
 * @returns {JSX.Element} The JSX element representing the CategoryFilter component.
 */
export const CategoryFilter = ({
  categories,
  translationName,
  setCategoryFilter
}) => {
  const { t } = useTranslation();

  return (
    <Select
      allowClear
      placeholder={t(`${translationName}.filters.category`)}
      style={{ width: 150 }}
      onSelect={(value) => setCategoryFilter(value)}
      optionFilterProp="children"
      showSearch
      onClear={() => setCategoryFilter()}
    >
      {categories
        .sort((a, b) => a?.title.localeCompare(b?.title))
        .map((category) => (
          <Select.Option key={category._id} value={category._id}>
            {category.title}
          </Select.Option>
        ))}
    </Select>
  );
};

CategoryFilter.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  translationName: PropTypes.string.isRequired,
  setCategoryFilter: PropTypes.func
};

CategoryFilter.defaultProps = {
  categories: null,
  setCategoryFilter: null
};
