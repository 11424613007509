import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  ExportOutlined
} from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from '../../../utils/constants/adminRoutes';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';

/**
 * renderStatusButton function generates and returns JSX buttons based on the quotation status and kanban status.
 *
 * @function
 *
 * @param {Object} quotation - Quotation data.
 * @param {Function} patchQuotationStatus - Function to update the quotation status.
 * @returns {JSX.Element|null} Rendered JSX element representing the generated buttons or null if no matching condition.
 */
export const renderStatusButton = (quotation, patchQuotationStatus) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const navigate = useNavigate();
  const { id } = useParams();

  const findOrder = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `orders?quotations.quotation=${id}&fields=_id`
      });
      return data[0];
    } catch (e) {
      return message(e);
    }
  };

  const handleOrderRedirection = async () => {
    const { _id } = await findOrder();
    return navigate(`${routes.ORDERS}/show/${_id}`);
  };

  const renderedButton = () => {
    switch (true) {
      case quotation?.status === 'QUOTATION_BEING_CREATED':
        return (
          <>
            <Button onClick={() => navigate(`${routes.QUOTATIONS}/edit/${id}`)}>
              <EditOutlined />
              {t('buttons.edit')}
            </Button>
            <Button
              onClick={() =>
                navigate(`${routes.CUSTOMERS}/show/${quotation.customer._id}`)
              }
            >
              <ExportOutlined />
              {t('quotations.show.customer_redirection')}
            </Button>
            <Button onClick={() => patchQuotationStatus('DEVIS FINAUX')}>
              <CheckOutlined />
              {t('quotations.show.final_quotation')}
            </Button>
          </>
        );
      case quotation?.status === 'KANBAN_STATUS' &&
        quotation?.kanban_status?.title === 'DEVIS FINAUX':
        return (
          <>
            <Button onClick={() => navigate(`${routes.QUOTATIONS}/edit/${id}`)}>
              <EditOutlined />
              {t('buttons.edit')}
            </Button>
            <Button onClick={() => patchQuotationStatus('DEVIS REMIS')}>
              <CheckOutlined />
              {t('quotations.show.quotation_delivered')}
            </Button>
          </>
        );
      case quotation?.status === 'KANBAN_STATUS' &&
        quotation?.kanban_status?.title === 'DEVIS REMIS':
        return (
          <>
            <Button onClick={() => patchQuotationStatus('DEVIS SIGNÉS')}>
              <CheckOutlined />
              {t('quotations.show.quotation_signed')}
            </Button>
            <Button onClick={() => patchQuotationStatus('DEVIS REFUSÉS')}>
              <CloseOutlined />
              {t('quotations.show.quotation_rejected')}
            </Button>
          </>
        );
      case quotation?.status === 'KANBAN_STATUS' &&
        quotation?.kanban_status?.title === 'DEVIS SIGNÉS':
        return (
          <Button onClick={handleOrderRedirection}>
            <ExportOutlined />
            {t('quotations.show.order_redirection')}
          </Button>
        );
      default:
        return null;
    }
  };

  return renderedButton();
};
