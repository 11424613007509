import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { customerTypes } from '../../../../utils/constants/tagColors';

/**
 * Generates a list of label and content pairs to display customer information.
 *
 * @hook
 *
 * @param {Object} customer - The customer data to display.
 * @returns {Array} An array of label and content pairs.
 */
export const useCustomerListContent = (customer) => {
  const { t } = useTranslation();

  return (
    customer && [
      {
        label: t('invoices.show.customer'),
        content:
          (customer &&
            `${customer?.first_name || ''} ${customer?.last_name || ''}`) ||
          t('errors.form.not_specified'),
        span: 2
      },
      {
        label: t('invoices.show.type'),
        content:
          (
            <Tag color={customerTypes[customer?.type]}>
              {t(`orders.show.${customer?.type}`)}
            </Tag>
          ) || t('errors.form.not_specified'),
        span: 2
      },
      {
        label: t('invoices.show.billing_address'),
        content:
          customer?.billing_address &&
          customer.billing_address.number !== '' ? (
            <>
              {customer.billing_address.number}{' '}
              {customer.billing_address.street}
              <br />
              {customer.billing_address.additional}
              {customer.billing_address.additional && <br />}
              {customer.billing_address.postal_code}{' '}
              {customer.billing_address.city}
            </>
          ) : (
            '-'
          ),
        span: 2
      },
      {
        label: t('invoices.show.phone_number'),
        content:
          customer?.phone_number && customer.phone_number.number ? (
            <div>
              {`${
                customer.phone_number.country_code
              } ${customer.phone_number.number.slice(
                0,
                1
              )} ${customer.phone_number.number
                .slice(1)
                .replace(/(.{2})/g, '$1 ')}
                    `}
            </div>
          ) : (
            '-'
          ),
        span: 1
      },
      {
        label: t('invoices.show.email'),
        content: customer?.email || t('errors.form.not_specified')
      }
    ]
  );
};
