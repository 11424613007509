import { Input } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

/**
 * useGeneralInfosListContent is a custom hook that generates an array of objects containing general information content for displaying in a list.
 *
 * @hook
 *
 * @param {Object} data - Quotation data containing general information.
 * @returns {Array} Array of objects defining label, content, and span for each general information field.
 */
export const useGeneralInfosListContent = (data = {}) => {
  const { t } = useTranslation();
  const {
    type_of_quotation,
    validity_date,
    previsional_works_start_date,
    remarks
  } = data;

  return [
    {
      label: t('quotations.show.quotation_type'),
      content:
        (type_of_quotation &&
          type_of_quotation.map(
            (type, index) =>
              `${t(`quotations.show.${type}`)}${
                index !== type_of_quotation.length - 1 ? '/' : ''
              }`
          )) ||
        t('errors.form.not_specified'),
      span: 3
    },
    {
      label: t('quotations.show.validity_date'),
      span: 1,
      content:
        (validity_date && moment(validity_date).format('DD-MM-YYYY')) ||
        t('errors.form.not_specified')
    },
    {
      label: t('quotations.show.previsional_works_start_date'),
      span: 2,
      content:
        (previsional_works_start_date?.length &&
          `Entre le ${moment(previsional_works_start_date[0]).format(
            'DD-MM-YYYY'
          )} et le ${moment(previsional_works_start_date[1]).format(
            'DD-MM-YYYY'
          )}`) ||
        t('errors.form.not_specified')
    },
    {
      label: t('quotations.show.remarks'),
      span: 3,
      content:
        (remarks && <TextArea placeholder={remarks} disabled />) ||
        t('errors.form.not_specified')
    }
  ];
};
