import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { KanbanView } from '../../components/KanbanView/KanbanView';
import { PageHeaderCustom } from '../../components';
import { QuotationHeader } from './QuotationHeader';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { KanbanCardQuote } from './KanbanCardQuote';
import { HeaderFilter } from '../../components/HeaderFilter/HeaderFilter';

/**
 * QuotationsKanbanView component displays quotations in a kanban view with various filters and options.
 *
 * @component
 * @param {boolean} isKanbanView - Flag to indicate if the kanban view is active.
 * @param {boolean} refresh - Flag to trigger a refresh of the kanban view.
 * @param {Function} setIsKanbanView - Function to set the kanban view flag.
 * @param {Function} setRefresh - Function to set the refresh flag.
 * @returns {JSX.Element} Rendered JSX element representing the QuotationsKanbanView.
 */
export const QuotationsKanbanView = ({
  refresh,
  setRefresh,
  isKanbanView,
  setIsKanbanView
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [kanbanViewHeaders, setKanbanViewHeaders] = useState([]);
  const [archiveFilter, setArchiveFilter] = useState(false);
  const [customerFilter, setCustomerFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  const extraQuery = `${customerFilter ? `customer=${customerFilter}` : ''}${
    statusFilter ? `${customerFilter ? `&` : ''}${statusFilter}` : ''
  }`;

  const getKanbanViewHeaders = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'kanbanview-quotes'
      });
      const archivedColumn = {
        color: '#ababab',
        display: archiveFilter,
        title: t('quotations.statuses.ARCHIVED'),
        _id: 'ARCHIVED'
      };
      const beginColumn = {
        color: '#c1ccff',
        display: true,
        title: t('quotations.statuses.QUOTATION_BEING_CREATED'),
        _id: 'QUOTATION_BEING_CREATED'
      };
      data.push(archivedColumn);
      data.unshift(beginColumn);
      setKanbanViewHeaders(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    getKanbanViewHeaders();
  }, [refresh]);

  return (
    <>
      <PageHeaderCustom title="Devis" />
      <HeaderFilter
        archiveFilter={archiveFilter}
        setArchiveFilter={setArchiveFilter}
        resourceName="quotations"
        extraQuery={extraQuery}
        populate="customer,project.project_type,kanban_status"
        setRefresh={setRefresh}
        refresh={refresh}
        withArchiveButton
        extraFilters={
          <QuotationHeader
            isKanbanView={isKanbanView}
            setCustomerFilter={setCustomerFilter}
            setStatusFilter={setStatusFilter}
            setIsKanbanView={setIsKanbanView}
          />
        }
      >
        <KanbanView
          resourceName="quotations"
          refresh={refresh}
          setRefresh={setRefresh}
          columnHeaders={kanbanViewHeaders}
          defaultStatus="QUOTATION_BEING_CREATED"
          extraRoute="status"
        >
          <KanbanCardQuote />
        </KanbanView>
      </HeaderFilter>
    </>
  );
};

QuotationsKanbanView.propTypes = {
  isKanbanView: PropTypes.bool.isRequired,
  refresh: PropTypes.bool.isRequired,
  setIsKanbanView: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired
};
