import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Tag } from 'antd';
import moment from 'moment/moment';
import { EyeOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { invoiceTypes } from '../../utils/constants/tagColors';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { formatNumberWithSpaces } from '../../utils/formatters/formatNumberWithSpaces';

/**
 * useInvoiceColumns is a custom hook that returns an array of column configurations for displaying invoice-related data.
 *
 * @hook
 *
 * @returns {Object[]} Array of column configurations.
 */
export const useInvoiceColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { pathname } = useLocation();
  const [options, setOptions] = useState([]);

  const getOptions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'invoices/enums'
      });
      setOptions(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getOptions();
    })();
  }, []);

  return [
    {
      title: t('invoices.form.number'),
      dataIndex: 'number',
      key: 'number',
      sorter: true,
      render: (number) =>
        (number && <Tag>{number}</Tag>) || t('errors.form.not_specified')
    },
    {
      title: t('invoices.form.type'),
      dataIndex: 'invoice_type',
      key: 'invoice_type',
      sorter: true,
      render: (type) =>
        (type && (
          <Tag color={invoiceTypes[type]}>
            {t(`invoices.show.invoice_infos.type.${type}`)}
          </Tag>
        )) ||
        t('errors.form.not_specified'),
      filters: (options.type || []).map((type) => ({
        text: t(`invoices.show.invoice_infos.type.${type}`),
        value: type
      }))
    },
    {
      title: t('invoices.form.customer'),
      dataIndex: 'customer',
      key: 'customer',
      sorter: true,
      render: (customer) =>
        (customer &&
          `${customer.first_name || ''} ${customer.last_name || ''}`) ||
        t('errors.form.not_specified')
    },
    {
      title: t('invoices.form.order'),
      dataIndex: 'order',
      key: 'order',
      sorter: true,
      render: (order) =>
        (order && (
          <Tag
            style={{
              color: 'var(--primaryColor)'
            }}
          >
            {order.number}
          </Tag>
        )) ||
        t('errors.form.not_specified')
    },
    {
      title: t('invoices.form.emission_date'),
      dataIndex: 'body_infos',
      key: 'body_infos',
      sorter: true,
      render: ({ emission_date }) =>
        (emission_date && moment(emission_date).format('DD/MM/YYYY')) ||
        t('errors.form.not_specified')
    },
    {
      title: t('invoices.form.totalTTC'),
      dataIndex: 'details',
      key: 'details',
      sorter: true,
      render: (record) => {
        if (!['ADVANCE', 'ADV_INITIAL'].includes(record.invoice_type)) {
          return (
            (record.totals?.all_included_total &&
              `${formatNumberWithSpaces(
                record?.totals?.all_included_total
              )}€`) ||
            t('errors.form.not_specified')
          );
        }
        return (
          (record.totals?.advance_total &&
            `${formatNumberWithSpaces(record?.totals?.advance_total)}€`) ||
          t('errors.form.not_specified')
        );
      }
    },
    {
      title: t('invoices.form.payed'),
      dataIndex: 'totals',
      key: 'totals',
      sorter: true,

      render: (totals) => {
        if (totals && typeof totals.paid !== 'undefined') {
          return `${formatNumberWithSpaces(totals.paid)}€`;
        }
        return t('errors.form.not_specified');
      }
    },
    {
      key: 'action',
      align: 'right',
      render: ({ _id }) => (
        <Link to={`${pathname}/show/${_id}`}>
          <EyeOutlined style={{ fontSize: 18 }} />
        </Link>
      )
    }
  ];
};
