import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  message,
  Popconfirm,
  Row,
  Skeleton,
  Space,
  Switch,
  Tag
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  ContainerOutlined,
  EditOutlined,
  PlusOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { routes } from '../../utils/constants/adminRoutes';
import { DescriptionList } from '../../components/DescriptionList/DescriptionList';
import { useListContent } from './listContent';
import { PageHeaderCustom } from '../../components/PageHeader/PageHeader';
import { Datatable } from '../../components/DataTable/Datatable';
import { colorTag } from '../../utils/constants/tagColors';
import useColumns from './CustomerProjectsColumns';
import { useMasterContext } from '../../contexts/MasterContext';
import { ReactivationButton } from '../catalog/reactivationButton/ReactivationButton';
import { ContentCustom } from '../../components';

const iconSize = 18;

/**
 * Component to display the details of a specific customer.
 *
 * @component
 *
 * @returns {React.Component} ShowCustomer component.
 */
const ShowCustomer = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState({});
  const { dispatchAPI } = useAuthContext();
  const { setPickedCustomer } = useMasterContext();
  const [isLoading, setIsloading] = useState(false);
  const fields = useListContent(customer || {});
  const [forceRefresh, setForceRefresh] = useState();
  const [archiveFilter, setArchiveFilter] = useState(false);
  const { basicColumns, actionColumn } = useColumns(
    forceRefresh,
    setForceRefresh
  );

  const fetchData = useCallback(async () => {
    setIsloading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customers/${id}`
      });

      setCustomer(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsloading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [fetchData, forceRefresh]);

  const deleteCustomer = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/customers/${id}`,
        body: {
          status: 'ARCHIVED'
        }
      });
      navigate(`${routes.CUSTOMERS}`);
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={`${customer?.last_name || ''} - ${t(`customers.show.title`)}`}
        extra={
          <Space>
            {customer?.status !== 'ARCHIVED' ? (
              <>
                <Button
                  onClick={() => {
                    setPickedCustomer(customer);
                    return navigate(`${routes.PROJECTS}/create`);
                  }}
                >
                  <PlusOutlined />
                  {t('buttons.create_project')}
                </Button>

                <Link
                  to={{
                    pathname: `${routes.CUSTOMERS}/edit/${id}`
                  }}
                >
                  <Button type="primary">
                    <EditOutlined />
                    {`${t('buttons.edit')} `}
                  </Button>
                </Link>
                <Popconfirm
                  title={t('datatable.column.action.archive.title')}
                  okText={t('datatable.column.action.archive.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.archive.cancel')}
                  onConfirm={deleteCustomer}
                  icon={<WarningOutlined />}
                >
                  <Button danger>
                    <ContainerOutlined
                      style={{ color: 'var(--errorColor)', fontSize: iconSize }}
                    />
                    {t('buttons.archive')}
                  </Button>
                </Popconfirm>
              </>
            ) : (
              <Space>
                <ReactivationButton
                  resourceName="customers"
                  forceRefresh={forceRefresh}
                  setForceRefresh={setForceRefresh}
                />
                <Tag color={colorTag.ARCHIVED}>
                  {t('customers.tags.archived')}
                </Tag>
              </Space>
            )}
          </Space>
        }
      />
      <ContentCustom>
        <Skeleton loading={isLoading} active>
          <Row gutter={16} style={{ marginBottom: 16 }}>
            <Col span={16}>
              <Card title={t('customers.show.infos')}>
                <DescriptionList data={fields} translate />
              </Card>
            </Col>
            <Col span={8}>
              <Card title={t('customers.show.remarks')}>
                {customer?.remark || t('customers.show.no_remark')}
              </Card>
            </Col>
          </Row>

          <Card
            title={t('customers.show.project')}
            extra={
              <Space>
                <Switch
                  onChange={() => setArchiveFilter(!archiveFilter)}
                  checkedChildren={t('buttons.archived')}
                  unCheckedChildren={t('buttons.not_archived')}
                />
              </Space>
            }
          >
            <Datatable
              resourceName="projects"
              extraQuery={`customer=${id}`}
              showAction={false}
              editAction={false}
              archiveFilter={archiveFilter}
              onDoubleClickAction={{
                action: (record) =>
                  navigate(`${routes.PROJECTS}/show/${record._id}`)
              }}
              deleteAction={false}
              columns={[...basicColumns, ...actionColumn]}
              populate="MP_supervisor,project_type"
            />
          </Card>
        </Skeleton>
      </ContentCustom>
    </>
  );
};

export default ShowCustomer;
