import moment from 'moment';
import { useTranslation } from 'react-i18next';

/**
 * A utility function to format quotation data.
 *
 * @function
 *
 * @returns {{ formatQuotation: Function }} An object containing the `formatQuotation` function.
 */
export const formatQuotations = () => {
  const { t } = useTranslation();

  const getCurrStatus = ({ status, kanban_status }) => {
    switch (true) {
      case status !== 'KANBAN_STATUS':
        return t(`quotations.form.${status}`);
      case status === 'KANBAN_STATUS':
        return kanban_status?.title;
      default:
        return 'N/R';
    }
  };

  const formatDates = (value, defaultvalue) => {
    if (value) return moment(value).format('DD/MM/YYYY');
    return defaultvalue || 'N/R';
  };

  const formatFullName = (customer, defaultvalue) => {
    if (customer) {
      return `${customer?.first_name || ''} ${customer?.last_name || ''}`;
    }
    return defaultvalue || 'N/R';
  };

  const formatQuotation = (datas) => {
    const {
      creation_date,
      created_at,
      validity_date,
      previsional_works_start_date,
      customer
    } = datas;

    const result = {
      ...datas,
      status: getCurrStatus(datas),
      creation_date: formatDates(creation_date || created_at),
      validity_date: formatDates(validity_date),
      previsional_works_start_date: formatDates(previsional_works_start_date),
      full_name: formatFullName(customer)
    };

    return result;
  };

  return { formatQuotation };
};
