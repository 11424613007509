import { ContainerOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const iconSize = 18;

const useColumns = (forceRefresh, setForceRefresh, supplierID) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const removeContact = async (contactID) => {
    try {
      await dispatchAPI('PATCH', {
        url: `suppliers/${supplierID}/${contactID}`
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  const basicColumns = [
    {
      title: t('suppliers.form.denomination'),
      dataIndex: 'denomination',
      sorter: true
    },
    {
      title: t('suppliers.form.type'),
      dataIndex: 'type',
      sorter: true,
      render: (type) => type && type.title
    },
    {
      title: t('suppliers.form.address'),
      dataIndex: 'address',
      render: (address) => (
        <Row
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Row>{address.street}</Row>
          {address.additional && <Row>{address.additional}</Row>}
          <Row>{`${address.postal_code} ${address.city}`}</Row>
        </Row>
      )
    },
    {
      title: t('suppliers.form.email'),
      dataIndex: 'email',
      sorter: true
    },
    {
      title: t('suppliers.form.phone_number'),
      dataIndex: 'phone_number',
      render: (phone_number) =>
        phone_number.number &&
        `${phone_number.country_code} ${phone_number.number.slice(1)}`
    }
  ];

  const contactColumns = [
    {
      title: t('suppliers.form.contact.first_name'),
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('suppliers.form.contact.last_name'),
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('suppliers.form.contact.role'),
      dataIndex: 'role',
      sorter: true
    },
    {
      title: t('suppliers.form.contact.email'),
      dataIndex: 'email',
      sorter: true
    },
    {
      title: t('suppliers.form.contact.phone_number'),
      dataIndex: 'phone_number',
      sorter: true,
      render: (phone_number) =>
        phone_number.number &&
        `${phone_number.country_code} ${phone_number.number.slice(1)}`
    },
    {
      render: (record) => (
        <Button onClick={() => removeContact(record._id)}>
          <ContainerOutlined
            style={{ color: 'var(--errorColor)', fontSize: iconSize }}
            type="delete"
          />
        </Button>
      )
    }
  ];

  return {
    basicColumns,
    contactColumns
  };
};

export default useColumns;
