import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Card, Row, Modal, Button, Form } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { DescriptionList } from '../../../components';

/**
 * A component that displays a description list with editable fields inside a modal.
 * Users can edit and save changes to the fields.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the editable description list.
 * @param {string} props.resourceName - The name of the resource for API requests.
 * @param {boolean} props.refresh - Flag to trigger data refresh after editing.
 * @param {function} props.setRefresh - Function to set the refresh flag.
 * @param {Array} props.fields - An array of field objects for the description list.
 * @param {boolean} props.triggerModal - Flag to control the visibility of the modal.
 * @returns {JSX.Element} - The rendered component.
 */
export const DescriptionListEditable = ({
  fields,
  title,
  resourceName,
  refresh,
  setRefresh,
  triggerModal,
  editable
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const dataFields = fields || [];

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      await dispatchAPI('PATCH', {
        url: `${resourceName}/${id}`,
        body: values
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    } finally {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    if (triggerModal) setIsModalOpen(true);
  }, [triggerModal]);

  return (
    <>
      <Modal
        title={title}
        open={isModalOpen}
        centered
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          <>
            <Button onClick={handleCancel} type="link">
              {t('buttons.cancel')}
            </Button>
            <Button onClick={handleOk} type="primary">
              {t('buttons.save')}
            </Button>
          </>
        }
      >
        <Form form={form}>
          <Row>
            {dataFields.map(
              (field) =>
                field.input && (
                  <Form.Item
                    label={field.label}
                    name={field.dataIndex || field.label}
                    valuePropName={field.type || 'value'}
                    initialValue={field.input.props.data}
                    key={field.label}
                  >
                    {field.input}
                  </Form.Item>
                )
            )}
          </Row>
        </Form>
      </Modal>
      <Card
        style={{ height: '100%' }}
        title={title}
        extra={
          editable && (
            <Button
              type="link"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              <EditOutlined />
            </Button>
          )
        }
      >
        <DescriptionList data={fields} />
      </Card>
    </>
  );
};

DescriptionListEditable.propTypes = {
  title: PropTypes.string,
  resourceName: PropTypes.string.isRequired,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      dataIndex: PropTypes.arrayOf(PropTypes.string),
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      input: PropTypes.element
    })
  ),
  triggerModal: PropTypes.bool,
  editable: PropTypes.bool
};

DescriptionListEditable.defaultProps = {
  title: '',
  refresh: false,
  setRefresh: () => {},
  triggerModal: false,
  fields: null,
  editable: true
};
