import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select, DatePicker, Row, Col, Space, Avatar } from 'antd';
import { useOrderContext } from '../../../contexts/OrderContext';

const { RangePicker } = DatePicker;

/**
 * A component that displays filter buttons for planning orders.
 *
 * @component
 *
 * @param {object} props - The component props.
 * @param {boolean} props.refresh - A boolean indicating whether to refresh data.
 * @param {function} props.setRefresh - Function to set the refresh state.
 * @param {function} props.setCustomerFilter - Function to set the customer filter.
 * @param {function} props.setStatusFilter - Function to set the status filter.
 * @param {function} props.setDatesFilter - Function to set the dates filter.
 * @returns {JSX.Element} The rendered component.
 */
export const FilterPlanningButtons = ({
  refresh,
  setRefresh,
  setCustomerFilter,
  setStatusFilter,
  setDatesFilter
}) => {
  const { t } = useTranslation();
  const { customers, statuses, kanbanStatus } = useOrderContext();

  const allStatuses = statuses
    .filter((item) => item !== 'KANBAN_STATUS')
    .map((item) => ({
      label: t(`orders.form.${item}`),
      value: item,
      key: item,
      type: 'status'
    }));

  const allKanbanStatuses = kanbanStatus.map(({ _id, title }) => ({
    label: title,
    value: _id,
    key: _id,
    type: 'kanban_status'
  }));

  const statusOptions = [...allStatuses, ...allKanbanStatuses];

  return (
    <Row gutter={16}>
      <Col style={{ marginBottom: 8 }}>
        <Space>
          {t('planning.filters.filter.title')}
          <Select
            allowClear
            onSelect={(_, { type, value }) => {
              setStatusFilter(`${type}=${value}`);
              setRefresh(!refresh);
            }}
            onClear={() => {
              setStatusFilter('');
              setRefresh(!refresh);
            }}
            style={{ width: 150 }}
            placeholder={t('planning.filters.filter.status')}
            options={statusOptions}
          />
          <Select
            allowClear
            onSelect={(value) => {
              setCustomerFilter(value);
              setRefresh(!refresh);
            }}
            onClear={() => {
              setCustomerFilter('');
              setRefresh(!refresh);
            }}
            style={{ width: 150 }}
            placeholder={t('planning.filters.filter.customer')}
            showSearch
            optionFilterProp="children"
          >
            {customers
              .sort(
                (a, b) =>
                  a?.last_name.localeCompare(b?.last_name) ||
                  a?.first_name.localeCompare(b?.first_name)
              )
              .map((customer) => (
                <Select.Option key={customer} value={customer._id}>
                  {`${customer.first_name || ''} ${customer.last_name || ''}`}
                </Select.Option>
              ))}
          </Select>
        </Space>
      </Col>
      <Col style={{ marginBottom: 8 }}>
        <Space>
          {t('planning.filters.date')}
          <RangePicker
            allowClear
            style={{ width: 300 }}
            format="DD/MM/YYYY"
            onChange={(values) => {
              if (values) setDatesFilter(values);
              else setDatesFilter([]);
            }}
          />
        </Space>
      </Col>
      <Col style={{ marginTop: 8 }}>
        <Space size="middle">
          {t('planning.filters.caption.title')}
          <Col>
            <Space size="small">
              <Avatar
                style={{
                  backgroundColor: 'var(--captionGantt_previsional_start_date)'
                }}
              />
              {t('planning.filters.caption.workshop')}
            </Space>
          </Col>
          <Col>
            <Space size="small">
              <Avatar
                style={{
                  backgroundColor: 'var(--captionGantt_construction_date)'
                }}
              />
              {t('planning.filters.caption.lay')}
            </Space>
          </Col>
          <Col>
            <Space size="small">
              <Avatar
                style={{
                  backgroundColor: 'var(--captionGantt_measurement_date)'
                }}
              />
              {t('planning.filters.caption.measurement')}
            </Space>
          </Col>
        </Space>
      </Col>
    </Row>
  );
};

FilterPlanningButtons.propTypes = {
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
  setCustomerFilter: PropTypes.func.isRequired,
  setStatusFilter: PropTypes.func.isRequired,
  setDatesFilter: PropTypes.func.isRequired
};
