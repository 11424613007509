import { createItemsMap } from './utils/createItemsMap';
import { getItemById } from './utils/getItemById';
import { formatNumberWithSpaces } from '../../../utils/formatters/formatNumberWithSpaces';

/**
 * Calculates the total values for services based on the provided fields and data.
 *
 * @function
 * @param {Object[]} fields - An array of service field objects.
 * @param {Array} services - An array of service objects.
 * @param {Array} rates - An array of rate objects.
 * @param {number} customerCoefficient - The customer's coefficient value.
 * @param {Object} form - The form instance to update the fields.

 * @returns {Object} An object containing the calculated values for base and option.
 */
export const calculateServicesTotal = (
  fields,
  services,
  rates,
  customerCoefficient,
  form
) => {
  const base = {
    total: 0,
    VATtotal: 0,
    totalWithVAT: 0,
    commissionTotal: 0,
    totalWithCommission: 0,
    withCustomerCoefficient: 0,
    customerCoefficientTotal: 0,
    rawTotal: 0
  };
  const option = {
    ...base
  };
  const baseVatSums = {};
  const optionVatSums = {};

  if (fields && fields.length > 0) {
    const servicesMap = createItemsMap(services);

    fields.forEach((currentService, i) => {
      if (currentService) {
        const {
          services_catalog,
          quantity_surface,
          commission,
          VAT_rate,
          is_option
        } = currentService;
        const item = servicesMap[services_catalog];
        const target = is_option ? option : base;

        if (item) {
          const serviceCommissionRate = commission || 0;
          const serviceRawPrice = item.price || 0;
          const vatRate = getItemById(rates, VAT_rate)?.percentage;

          const servicePriceWithCustomerCoefficient =
            serviceRawPrice * (1 + customerCoefficient / 100);

          const servicePriceWithCommission =
            servicePriceWithCustomerCoefficient *
            (1 + serviceCommissionRate / 100);
          const serviceCommission =
            servicePriceWithCustomerCoefficient *
            (serviceCommissionRate / 100) *
            quantity_surface;

          const serviceRawTotal = serviceRawPrice * quantity_surface;
          const serviceTotalWithCustomerCoefficient =
            servicePriceWithCustomerCoefficient * quantity_surface;
          const serviceTotal = servicePriceWithCommission * quantity_surface;
          const serviceVatAmount = serviceTotal * (vatRate / 100);
          const serviceTotalWithVat = serviceTotal + serviceVatAmount;
          const serviceCoefficient =
            serviceRawTotal * (customerCoefficient / 100);

          if (
            !Number.isNaN(serviceVatAmount) &&
            serviceVatAmount !== 0 &&
            vatRate !== 0
          ) {
            if (target === base) {
              if (!Object.prototype.hasOwnProperty.call(baseVatSums, vatRate)) {
                baseVatSums[vatRate] = 0;
              }

              baseVatSums[vatRate] += serviceVatAmount;
            }
            if (target === option) {
              if (
                !Object.prototype.hasOwnProperty.call(optionVatSums, vatRate)
              ) {
                optionVatSums[vatRate] = 0;
              }

              optionVatSums[vatRate] += serviceVatAmount;
            }
          }

          form.setFields([
            {
              name: ['services', i, 'unprocessed_value'],
              value: formatNumberWithSpaces(
                Number(Number(serviceRawTotal).toFixed(2))
              )
            },
            {
              name: ['services', i, 'customer_coefficient_value'],
              value: formatNumberWithSpaces(
                Number(Number(serviceTotalWithCustomerCoefficient).toFixed(2))
              )
            },
            {
              name: ['services', i, 'facial_value'],
              value: formatNumberWithSpaces(
                Number(Number(serviceTotal).toFixed(2))
              )
            },
            {
              name: ['services', i, 'all_included_value'],
              value: formatNumberWithSpaces(
                Number(Number(serviceTotalWithVat).toFixed(2))
              )
            },
            {
              name: ['services', i, 'commission'],
              value: formatNumberWithSpaces(
                Number(Number(serviceCommissionRate).toFixed(2))
              )
            }
          ]);

          target.total += serviceTotal;
          target.VATtotal += serviceVatAmount;
          target.withCustomerCoefficient += serviceTotalWithCustomerCoefficient;
          target.totalWithCommission += serviceTotal;
          target.totalWithVAT += serviceTotalWithVat;
          target.customerCoefficientTotal += serviceCoefficient;
          target.commissionTotal += serviceCommission;
          target.rawTotal += serviceRawTotal;
        }
      }
    });
  }

  const formatResult = (result) => ({
    total: Number(Number(result.total).toFixed(2)),
    VATtotal: Number(Number(result.VATtotal).toFixed(2)),
    totalWithVAT: Number(Number(result.totalWithVAT).toFixed(2)),
    withCustomerCoefficient: Number(
      Number(result.withCustomerCoefficient).toFixed(2)
    ),
    totalWithCommission: Number(Number(result.totalWithCommission).toFixed(2)),
    customerCoefficientTotal: Number(
      Number(result.customerCoefficientTotal).toFixed(2)
    ),
    commissionTotal: Number(Number(result.commissionTotal).toFixed(2)),
    rawTotal: Number(Number(result.rawTotal).toFixed(2))
  });

  return {
    base: formatResult(base),
    option: formatResult(option),
    baseVatSums,
    optionVatSums
  };
};
