import React from 'react';
import PropTypes from 'prop-types';
import { useFields } from './fields';
import { CreateUpdateMaterialContainer } from './CreateUpdateMaterialContainer';

/**
 * Component for creating or updating material information.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the component (e.g., 'create' or 'update').
 * @returns {JSX.Element} The rendered CreateUpdateMaterial component.
 */
const CreateUpdateMaterial = ({ purpose }) => {
  const { basicFields, isFieldsLoading } = useFields();

  return (
    <CreateUpdateMaterialContainer
      fields={basicFields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="materials"
      resource="materials"
    />
  );
};

CreateUpdateMaterial.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateMaterial;
