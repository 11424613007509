import { Route, Routes } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/adminRoutes';
import ShapingRouter from './ShapingRouter';
import ServicesRouter from './ServicesRouter';
import AccessoriesRouter from './AccessoriesRouter';
import MaterialsRouter from './MaterialsRouter';

const CatalogRouter = () => (
  <Routes>
    <Route
      path={`${subRoutes.CATALOG.SHAPINGS}/*`}
      element={<ShapingRouter />}
    />
    <Route
      path={`${subRoutes.CATALOG.SERVICES}/*`}
      element={<ServicesRouter />}
    />
    <Route
      path={`${subRoutes.CATALOG.ACCESSORIES}/*`}
      element={<AccessoriesRouter />}
    />
    <Route
      path={`${subRoutes.CATALOG.MATERIALS}/*`}
      element={<MaterialsRouter />}
    />
  </Routes>
);

export default CatalogRouter;
