import { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { PageHeaderCustom, HeaderFilter } from '../../components';
import { FilterPlanningButtons } from './Gantt/FilterPlanningButtons';
import { Gantts } from './Gantt/Gantt';

/**
 * Component for the planning page.
 *
 * @component
 * @returns {JSX.Element} The rendered planning page.
 */
export const Planning = () => {
  const { t } = useTranslation();

  const [customerFilter, setCustomerFilter] = useState();
  const [statusFilter, setStatusFilter] = useState();
  const [datesFilter, setDatesFilter] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const extraQuery = `${customerFilter ? `customer=${customerFilter}` : ''}${
    statusFilter ? `${customerFilter ? `&` : ''}${statusFilter}` : ''
  }${
    datesFilter[0] ? `&dateStart=${moment(datesFilter[0]).startOf('day')}` : ''
  }${datesFilter[1] ? `&dateEnd=${moment(datesFilter[1]).endOf('day')}` : ''}`;

  return (
    <>
      <PageHeaderCustom title={t('planning.title')} />
      <HeaderFilter
        resourceName="orders"
        extraQuery={extraQuery}
        populate="customer,kanban_status,project,type_of_work,invoices"
        withCreateButton={false}
        withSearchButton={false}
        refresh={refresh}
        setRefresh={setRefresh}
        extraFilters={
          <FilterPlanningButtons
            refresh={refresh}
            setRefresh={setRefresh}
            setCustomerFilter={setCustomerFilter}
            setStatusFilter={setStatusFilter}
            setDatesFilter={setDatesFilter}
          />
        }
        resizeExtra={16}
      >
        <Gantts />
      </HeaderFilter>
    </>
  );
};
