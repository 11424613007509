import React, { useState } from 'react';
import { parse } from 'papaparse';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Spin, Typography, Upload, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { formatterFile } from '../../utils/formatters/importFile';
import { useErrorMessage } from '../../utils/errorMessage';

const FileConverter = ({ setVisible, resourceName, fileType, headers }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { xlsxFormatter, unflattenObject, checkFileType } = formatterFile;
  const [fileData, setFileData] = useState();

  const convertFileToJSON = async (file) => {
    if (file.name.endsWith('.xlsx')) {
      return xlsxFormatter(file, headers, resourceName, t);
    }
    if (file.name.endsWith('.csv')) {
      const text = await file.text();
      const textWithoutTitle = text.split('\n').slice(1).join('\n');
      const results = parse(textWithoutTitle, { header: true });
      const jsonData = results.data;

      const filteredData = jsonData.filter((row) =>
        Object.keys(row).every((key) => {
          const value = row[key];
          return typeof value === 'string' && value.trim() !== '';
        })
      );
      return filteredData;
    }
    return notification.error('Invalid file format.');
  };

  const handleFileLoad = async () => {
    try {
      const jsonData = await convertFileToJSON(fileData);
      if (jsonData) {
        setVisible(true);
        const body = jsonData.map((data) => unflattenObject(data));
        await dispatchAPI('POST', {
          url: `/${resourceName}/import`,
          body
        });

        notification.success({ message: t('export.messages.success') });
        setVisible(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      message(error);
    }
    setVisible(false);
  };

  const props = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      if (checkFileType(file, fileType, t)) {
        setFileData(file);
      } else {
        message('templates.extension', 6);
        setFileData(null);
        setVisible(false);
      }
    },
    onChange: () => {
      if (fileData) {
        handleFileLoad();
        setFileData(null);
      }
    },
    showUploadList: {
      showRemoveIcon: false
    }
  };

  return (
    <Upload accept=".csv, .xlsx" {...props}>
      <UploadOutlined />
      {t('buttons.upload')}
    </Upload>
  );
};

export const ImportFile = ({ resourceName, headers }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Modal
        closable={false}
        footer={false}
        open={visible}
        maskClosable={false}
        bodyStyle={{ textAlign: 'center' }}
      >
        <Spin spinning={loading} size="large" style={{ margin: 16 }} />
        <br />
        <Typography.Text>
          {`${t(`import.messages.process-request`)} `}
        </Typography.Text>
      </Modal>
      <FileConverter
        resourceName={resourceName}
        setVisible={setVisible}
        fileType={['csv', 'xlsx']}
        headers={headers}
        loading={loading}
        setLoading={setLoading}
      />
    </>
  );
};

ImportFile.propTypes = {
  resourceName: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

ImportFile.defaultProps = {};

FileConverter.propTypes = {
  resourceName: PropTypes.string.isRequired,
  setVisible: PropTypes.func.isRequired,
  fileType: PropTypes.arrayOf(PropTypes.string),
  headers: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
FileConverter.defaultProps = {
  fileType: []
};
