export const headers = [
  {
    label: 'denomination',
    key: 'denomination',
    display: true,
    required: true
  },
  {
    label: 'category',
    key: 'category.title',
    display: true,
    required: true
  },
  {
    label: 'finishing_product',
    key: 'finishing_product.title',
    display: true,
    required: true
  },
  {
    label: 'supplier',
    key: 'supplier.denomination',
    display: false,
    required: true
  },
  {
    label: 'devaluation',
    key: 'devaluation',
    display: false
  },
  {
    label: 'remarks',
    key: 'remarks',
    display: false
  },
  {
    label: 'slices_management_20',
    key: 'slices_management_20',
    display: true
  },
  {
    label: 'slices_management_30',
    key: 'slices_management_30',
    display: true
  }
];
