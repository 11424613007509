import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Steps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { customCaseConversion } from './customCaseConversion';

/**
 * Displays a series of steps indicating the status of a resource.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {string} props.resourceName - Name of the resource being tracked.
 * @param {Object} props.data - Data representing the resource's current status.
 * @param {Object[]} props.status - List of possible statuses for the resource.
 * @param {string} props.defaultStatus - Default status of the resource.
 * @param {boolean} props.refresh - Indicates whether a refresh is needed.
 * @param {Function} props.setRefresh - Function to toggle the refresh state.
 * @returns {JSX.Element} - The rendered component.
 */
export const StepsStatus = ({
  resourceName,
  data,
  status,
  defaultStatus,
  refresh,
  setRefresh
}) => {
  const { dispatchAPI, masterRoles } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [current, setCurrent] = useState();
  const [loading, setLoading] = useState(false);
  let allStatus = status;

  const updateResource = async (body, id) => {
    try {
      setLoading(true);
      const bodyStatus = { status: defaultStatus, kanban_status: null };
      const { _id } = body;
      if (_id !== defaultStatus) {
        bodyStatus.status = 'KANBAN_STATUS';
        bodyStatus.kanban_status = _id;
      }
      await dispatchAPI('PATCH', {
        url: `${resourceName}/${id}`,
        body: bodyStatus
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
    setLoading(false);
  };

  for (let i = 0; i < allStatus.length; i += 1) {
    allStatus[i].title = customCaseConversion(allStatus[i].title);
  }

  allStatus = [
    {
      _id: defaultStatus,
      title: t(`${resourceName}.steps.${defaultStatus}`)
    },
    ...allStatus.sort((a, b) => {
      if (a.number_place && b.number_place)
        return a.number_place - b.number_place;
      return null;
    })
  ];

  useEffect(() => {
    if (status && data) {
      allStatus.map(({ _id }, index) => {
        if (data.status !== 'KANBAN_STATUS' && _id === defaultStatus)
          setCurrent(index);
        if (data.status === 'KANBAN_STATUS' && _id === data.kanban_status._id)
          setCurrent(index);
        return null;
      });
    }
  }, [status, data]);

  const onStepChange = async (value) => {
    if (!masterRoles) {
      return;
    }
    try {
      const [body] = allStatus.filter((_, index) => index === value);
      await updateResource(body, data._id);
    } catch (e) {
      message(e);
    }
  };

  const loadingDot = (dot) => {
    if (loading) return <LoadingOutlined style={{ fontSize: 10 }} />;
    return dot;
  };

  return (
    <Steps
      status="process"
      progressDot={loadingDot}
      onChange={onStepChange}
      current={current}
      items={allStatus}
      size="small"
    />
  );
};

StepsStatus.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.string,
    kanban_status: PropTypes.shape({ _id: PropTypes.string })
  }).isRequired,
  status: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultStatus: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired
};
