import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Table } from 'antd';
import { useCustomerListContent } from './ListContents/customerListContent';
import { useOrderListContent } from './ListContents/orderListContent';
import { useLayListContent } from './ListContents/layListContent';
import { useWorkshopListContent } from './ListContents/workshopListContent';
import { useTotalsListContent } from './ListContents/totalsListContent';
import { useOrderInvoicesColumns } from './OrderInvoices/columns';
import { DescriptionListEditable } from './DescriptionListEditable';
import { DescriptionList } from '../../../components';
import { useAuthContext } from '../../../contexts/AuthContext';

/**
 * A custom hook that generates an array of objects, each representing a section in the order details view.
 * Each object contains information about the section's rendering and content.
 *
 * @hook
 * @param {Object} order - The order data.
 * @param {boolean} refresh - Flag to trigger data refresh.
 * @param {function} setRefresh - Function to set the refresh flag.
 * @returns {Array} An array of section objects for rendering in the order details view.
 */
export const useShowOrderColumns = (order, refresh, setRefresh) => {
  const { t } = useTranslation();
  const { masterRoles } = useAuthContext();

  const [numberPlace, setNumberPlace] = useState(0);

  const customerFields = useCustomerListContent(order?.customer);
  const ordersFields = useOrderListContent(order);
  const layFields = useLayListContent(order);
  const workshopFields = useWorkshopListContent(order || []);
  const totalsFields = useTotalsListContent(order?.totals);
  const invoicesColumns = useOrderInvoicesColumns();
  const orderInvoices = order?.invoices || [];

  useEffect(() => {
    if (order?.kanban_status?.number_place)
      setNumberPlace(order?.kanban_status?.number_place);
    else setNumberPlace(0);
  }, [order]);

  return [
    {
      row: 1,
      render: () => (
        <Card
          style={{ height: '100%' }}
          title={t('orders.show.customer_infos')}
        >
          <DescriptionList data={customerFields} />
        </Card>
      )
    },
    {
      row: 1,
      render: () => (
        <DescriptionListEditable
          title={t('orders.show.order_infos')}
          resourceName="orders"
          fields={ordersFields}
          refresh={refresh}
          setRefresh={setRefresh}
          triggerModal={numberPlace === 1}
          editable={masterRoles}
        />
      )
    },
    {
      row: 2,
      render: () => (
        <DescriptionListEditable
          title={t('orders.show.lay_infos')}
          resourceName="orders"
          fields={layFields}
          refresh={refresh}
          setRefresh={setRefresh}
          triggerModal={numberPlace === 5}
        />
      )
    },
    {
      row: 2,
      render: () => (
        <DescriptionListEditable
          title={t('orders.show.workshop_infos')}
          resourceName="orders"
          fields={workshopFields}
          refresh={refresh}
          setRefresh={setRefresh}
          triggerModal={numberPlace === 4}
        />
      )
    },
    ...(masterRoles
      ? [
          {
            row: 3,
            render: () => (
              <Card
                style={{ height: '100%' }}
                title={t('orders.show.totals_infos')}
              >
                <DescriptionList data={totalsFields} />
              </Card>
            )
          },
          {
            row: 4,
            render: () => (
              <Card
                style={{ height: '100%' }}
                title={t('orders.show.invoices')}
              >
                <Table
                  dataSource={orderInvoices}
                  columns={invoicesColumns}
                  pagination={false}
                  loading={!order}
                />
              </Card>
            )
          }
        ]
      : [])
  ];
};
