import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import useFields from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useMasterContext } from '../../contexts/MasterContext';

const CreateUpdateProject = ({ purpose }) => {
  const [form] = Form.useForm();
  const { basicFields, isFieldsLoading } = useFields(form);
  const { setPickedCustomer } = useMasterContext();

  useEffect(
    () => () => {
      setPickedCustomer();
    },
    []
  );

  return (
    <CreateUpdateContainer
      customForm={form}
      fields={basicFields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="projects"
      resource="projects"
    />
  );
};

CreateUpdateProject.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateProject;
