import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Popconfirm, Skeleton, Space, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  ContainerOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { ContentCustom } from '../../../components/ContentCustom/ContentCustom';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';
import { DescriptionList } from '../../../components/DescriptionList/DescriptionList';
import { useListContent } from './listContent';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { colorTag } from '../../../utils/constants/tagColors';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ReactivationButton } from '../reactivationButton/ReactivationButton';

const iconSize = 18;

/**
 * @component
 *
 * Component for displaying details of a service.
 *
 * @returns {JSX.Element} The rendered ShowService component.
 */
const ShowService = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [service, setService] = useState({});
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsloading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const fields = useListContent(service || {});

  const fetchData = useCallback(async () => {
    setIsloading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/services/${id}?populate=category,unit`
      });

      setService(data);
    } catch (e) {
      message(e);
    }
    setIsloading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [fetchData, forceRefresh]);

  const deleteService = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/services/${id}`,
        body: {
          status: 'ARCHIVED'
        }
      });
      navigate(-1);
    } catch (e) {
      message(e);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={`${t(`services.show.title`)}`}
        extra={
          <div>
            {service.status !== 'ARCHIVED' ? (
              <>
                <Link
                  to={{
                    pathname: `${routes.CATALOG}${subRoutes.CATALOG.SERVICES}/edit/${id}`
                  }}
                >
                  <Button type="primary">
                    <EditOutlined />
                    {`${t('buttons.edit')} `}
                  </Button>
                </Link>
                <Popconfirm
                  title={t('datatable.column.action.archive.title')}
                  okText={t('datatable.column.action.archive.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.archive.cancel')}
                  onConfirm={deleteService}
                  icon={<WarningOutlined />}
                >
                  <Button danger style={{ marginLeft: 8 }}>
                    <ContainerOutlined
                      style={{ color: 'var(--errorColor)', fontSize: iconSize }}
                      type="delete"
                    />
                    {t('buttons.archive')}
                  </Button>
                </Popconfirm>
              </>
            ) : (
              <Space>
                <ReactivationButton
                  resourceName="services"
                  forceRefresh={forceRefresh}
                  setForceRefresh={setForceRefresh}
                />
                <Tag color={colorTag.ARCHIVED}>
                  {t('customers.tags.archived')}
                </Tag>
              </Space>
            )}
          </div>
        }
      />

      <ContentCustom>
        <Skeleton loading={isLoading} active>
          <Card title={t('services.show.infos')}>
            <DescriptionList data={fields} translate />
          </Card>
        </Skeleton>
      </ContentCustom>
    </>
  );
};

export default ShowService;
