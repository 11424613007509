import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { Tag } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { invoiceTypes } from '../../../../utils/constants/tagColors';
import { formatNumberWithSpaces } from '../../../../utils/formatters/formatNumberWithSpaces';

/**
 * Hook that generates columns configuration for displaying invoices associated with an order.
 *
 * @hook
 * @returns {Array} Array of column objects for rendering invoice information in a table.
 */
export const useOrderInvoicesColumns = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return [
    {
      title: t('orders.invoices.column.number'),
      dataIndex: 'number',
      key: 'number',
      render: (number) => <Tag>{number}</Tag> || t('errors.form.not_specified')
    },
    {
      title: t('orders.invoices.column.type'),
      dataIndex: 'invoice_type',
      key: 'invoice_type',
      render: (type) =>
        (
          <Tag color={invoiceTypes[type]}>
            {t(`invoices.show.invoice_infos.type.${type}`)}
          </Tag>
        ) || t('errors.form.not_specified')
    },
    {
      title: t('orders.invoices.column.emission_date'),
      dataIndex: 'body_infos',
      key: 'body_infos',
      render: ({ emission_date }) =>
        (emission_date && moment(emission_date).format('DD/MM/YYYY')) ||
        t('errors.form.not_specified')
    },
    {
      title: t('orders.invoices.column.totalTTC'),

      render: (record) => {
        if (['BASIC', 'CREDIT_NOTE'].includes(record.invoice_type)) {
          return (
            (record.totals &&
              record.totals?.all_included_total &&
              `${formatNumberWithSpaces(record.totals.all_included_total)}€`) ||
            t('errors.form.not_specified')
          );
        }
        return (
          (record.totals &&
            record.totals?.advance_total &&
            `${formatNumberWithSpaces(record.totals.advance_total)}€`) ||
          t('errors.form.not_specified')
        );
      }
    },
    {
      title: t('orders.invoices.column.payed'),
      dataIndex: 'totals',
      key: 'totals',
      render: (totals) =>
        (totals &&
          totals.paid !== undefined &&
          `${formatNumberWithSpaces(totals.paid)}€`) ||
        t('errors.form.not_specified')
    },
    {
      key: 'action',
      align: 'right',
      render: ({ _id }) => (
        <Link
          to={`invoices/show/${_id}`}
          onClick={(e) => {
            e.preventDefault();
            navigate(`/invoices/show/${_id}`, { replace: true });
          }}
        >
          <EyeOutlined style={{ fontSize: 18 }} />
        </Link>
      )
    }
  ];
};
