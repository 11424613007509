import React from 'react';
import styled from 'styled-components';
import { Badge, Col, Card } from 'antd';
import { useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';

const StyledCol = styled.div`
  height: 600px;
  position: relative;
  border-radius: 2px;
  min-width: 250px;
  margin: 5px;
  padding-right: 5px;
  overflow-y: auto;
`;

const Title = styled.div`
  position: absolute;
  display: flex;
  justify-content: left;
  align-items: center;
  top: 20px;
  background-color: #464038;
  box-shadow: 0px 9px 16px -6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 100%;
  text-align: left;
  padding: 10px 15px;
  min-height: 65px;
`;

export const KanbanColumn = ({
  resourceName,
  column,
  data,
  display,
  setRefresh,
  refresh,
  children,
  defaultStatus,
  extraRoute
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const updateCard = async (id, statusId) => {
    try {
      await dispatchAPI('PATCH', {
        url: `${resourceName}${extraRoute ? `/${extraRoute}` : ''}/${id}`,
        body: {
          status: statusId,
          kanban_status: null
        }
      });
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
  };

  const updateOtherCard = async (id, statusId) => {
    try {
      await dispatchAPI('PATCH', {
        url: `${resourceName}${extraRoute ? `/${extraRoute}` : ''}/${id}`,
        body: {
          status: 'KANBAN_STATUS',
          kanban_status: statusId
        }
      });
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
  };

  const [, drop] = useDrop({
    accept: 'card',
    drop: (item) => {
      const quotation = data.find((dataitem) => dataitem._id === item.id);
      const quotationIsSigned =
        quotation?.kanban_status?.title === 'DEVIS SIGNÉS';
      switch (true) {
        case column._id === 'ARCHIVED':
          updateCard(item.id, column._id);
          break;
        case quotationIsSigned && column._id !== 'ARCHIVED':
          return message('quotation_signed');
        case column._id === defaultStatus:
          updateCard(item.id, column._id);
          break;
        default:
          updateOtherCard(item.id, column._id);
          break;
      }
      return {};
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  return (
    display && (
      <StyledCol ref={drop}>
        <Title>
          <Badge
            color={column.color}
            text={column.title}
            style={{ color: '#fff' }}
          />
        </Title>
        <Col style={{ position: 'absolute', top: '100px', width: '100%' }}>
          {data ? (
            data
              .filter(
                ({ status, kanban_status }) =>
                  status === column._id || kanban_status?._id === column._id
              )
              .map((item) => (
                <>
                  {React.Children.map(children, (child) =>
                    React.cloneElement(child, { data: item, column })
                  )}
                </>
              ))
          ) : (
            <Card loading />
          )}
        </Col>
      </StyledCol>
    )
  );
};

KanbanColumn.propTypes = {
  resourceName: PropTypes.string.isRequired,
  column: PropTypes.shape({
    _id: PropTypes.string,
    color: PropTypes.string,
    title: PropTypes.string
  }).isRequired,
  data: PropTypes.shape([]).isRequired,
  display: PropTypes.bool,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  defaultStatus: PropTypes.string,
  extraRoute: PropTypes.string
};

KanbanColumn.defaultProps = {
  display: true,
  defaultStatus: null,
  extraRoute: undefined
};
