import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import { MaterialsShapingsTable } from './MaterialsShapingsTable';
import { ServicesTable } from './ServicesTable';
import { AccessoriesTable } from './AccessoriesTable';

/**
 * QuotationDetailsTables displays tabbed tables for materials, services, and accessories of a quotation.
 *
 * @component
 *
 * @param {Object} quotation - Quotation object containing materials, services, and accessories data.
 * @returns {JSX.Element} JSX element representing the tabbed tables.
 */
export const QuotationDetailsTables = ({ quotation }) => {
  const { t } = useTranslation();

  const items = [
    {
      key: '1',
      label: t('quotations.table.materialShapings'),
      children: (
        <MaterialsShapingsTable materialsShapings={quotation?.materials} />
      )
    },
    {
      key: '2',
      label: t('quotations.table.services'),
      children: <ServicesTable services={quotation?.services} />
    },
    {
      key: '3',
      label: t('quotations.table.accessories'),
      children: <AccessoriesTable accessories={quotation?.accessories} />
    }
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
};

QuotationDetailsTables.propTypes = {
  quotation: PropTypes.shape({
    materials: PropTypes.arrayOf(PropTypes.shape({})),
    services: PropTypes.arrayOf(PropTypes.shape({})),
    accessories: PropTypes.arrayOf(PropTypes.shape({}))
  })
};

QuotationDetailsTables.defaultProps = {
  quotation: null
};
