import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Space, Table } from 'antd';
import { gantt } from 'dhtmlx-gantt';
import { ScrollButtons } from './ScrollButtons';
import {
  initializeGantt,
  formatOrderDates,
  updateOrderDates
} from './ganttConfig';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';

/**
 * A component that displays Gantt charts for orders.
 *
 * @component
 * @param {object} props - The component props.
 * @param {Array} props.datas - An array of order data to be displayed in Gantt charts.
 * @returns {JSX.Element} The rendered component.
 */
export const Gantts = ({ datas }) => {
  const ganttContainerRef = useRef(null);
  const dataProcessorRef = useRef(null);
  const [orders, setOrders] = useState(null);

  const formatData = (datasToFormat) => {
    let filteredDatas = datasToFormat.filter(
      ({ construction_date, workshop_infos, measurement_date }) =>
        construction_date ||
        workshop_infos.previsional_start_date ||
        measurement_date
    );

    if (filteredDatas) {
      filteredDatas = filteredDatas.map(
        ({
          _id,
          number,
          customer,
          type_of_work,
          construction_date,
          measurement_date,
          workshop_infos
        }) => {
          const { first_name, last_name } = customer;
          const { previsional_start_date } = workshop_infos;
          const allOrderDates = [];

          const constructOrderDate = (date, color) => ({
            id: _id,
            order: number,
            full_name: `${last_name || ''} ${first_name || ''}`,
            project_type: type_of_work?.title,
            start_date: formatOrderDates(date, 0, 'start'),
            end_date: formatOrderDates(date, 1, 'end'),
            title: '',
            color
          });

          const existingDates = {};

          if (construction_date?.length > 0) {
            existingDates.construction_date = construction_date;
          }
          if (previsional_start_date?.length > 0) {
            existingDates.previsional_start_date =
              workshop_infos.previsional_start_date;
          }
          if (measurement_date?.length > 0) {
            existingDates.measurement_date = measurement_date;
          }

          Object.entries(existingDates).forEach(([key, values]) => {
            if (values)
              allOrderDates.push(
                constructOrderDate(
                  existingDates[key],
                  `var(--captionGantt_${key})`
                )
              );
          });
          return updateOrderDates(allOrderDates);
        }
      );
    }
    setOrders(filteredDatas.flat());
  };

  useEffect(() => {
    if (datas[0]) formatData(datas);
    else setOrders(null);
  }, [datas]);

  useEffect(() => {
    if (orders) initializeGantt(ganttContainerRef, { data: orders }, gantt);
    return () => {
      if (dataProcessorRef.current) {
        dataProcessorRef.current.destructor();
        dataProcessorRef.current = null;
      }
    };
  }, [orders]);

  return (
    <Space direction="vertical" style={{ width: '100%' }} className="gantt">
      <ScrollButtons currGantt={gantt} />
      {orders?.length ? (
        <Col
          ref={ganttContainerRef}
          style={{ minWidth: '100%', minHeight: 600 }}
        />
      ) : (
        <Table showHeader={false} />
      )}
    </Space>
  );
};

Gantts.propTypes = {
  datas: PropTypes.arrayOf(PropTypes.shape({}))
};

Gantts.defaultProps = {
  datas: null
};
