import { Input, Select } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useMasterContext } from '../../../../contexts/MasterContext';

const { Option } = Select;

/**
 * Hook that generates an array of information fields for display in a form.
 * Each field includes a name, initial value, and input component.
 *
 * @hook
 *
 * @returns {Object} An object containing `infosFields` array and `isLoading` state.
 */
const useInfosFields = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { pickedOrder } = useMasterContext();
  const { source } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [enums, setEnums] = useState([]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'invoices/enums'
      });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await getEnums();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const activeQuotation = pickedOrder?.quotations.find(
    (quotation) => quotation.status === 'ACTIVE'
  );

  const infosFields = [
    {
      name: ['invoice_type'],
      initialValue: source,
      input: (
        <Select>
          {(enums.type || []).map((enumItem) => (
            <Option key={enumItem} value={enumItem}>
              {t(`invoices.form.${enumItem}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['order_number'],
      initialValue: pickedOrder ? pickedOrder?.number : null,
      input: <Input disabled />
    },
    {
      name: ['quotation_number'],
      initialValue: activeQuotation ? activeQuotation.quotation.number : null,
      input: <Input disabled />
    }
  ];

  return {
    infosFields,
    isLoading
  };
};

export default useInfosFields;
