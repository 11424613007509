import { Route, Routes } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/adminRoutes';
import SuppliersRouter from './SuppliersRouter';

const PurchasesRouter = () => (
  <Routes>
    <Route
      path={`${subRoutes.PURCHASES.SUPPLIERS}/*`}
      element={<SuppliersRouter />}
    />
  </Routes>
);

export default PurchasesRouter;
