import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popconfirm } from 'antd';
import {
  EditOutlined,
  ContainerOutlined,
  CheckOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

/**
 * A component that displays buttons for actions in an editable panel.
 *
 * @component
 * @param {object} props - The component props.
 * @param {boolean} props.editable - Indicates whether the panel is in editable mode.
 * @param {string} props.editingKey - The key of the item being edited.
 * @param {Function} props.saveItem - The function to save the edited item.
 * @param {Function} props.edit - The function to switch to edit mode.
 * @param {Function} props.deleteItem - The function to delete the item.
 * @param {Function} props.cancel - The function to cancel editing.
 * @returns {JSX.Element} The rendered ButtonPanel component.
 */
const ButtonPanel = ({
  editingKey,
  editable,
  saveItem,
  edit,
  cancel,
  deleteItem
}) => {
  const { t } = useTranslation();
  return editable ? (
    <>
      <Button
        onClick={(event) => {
          event.stopPropagation();
          saveItem();
        }}
        type="add"
        icon={<CheckOutlined />}
      >
        {`${t('buttons.save')}`}
      </Button>
      <Button
        onClick={(event) => {
          event.stopPropagation();
        }}
        type="link"
      >
        <Popconfirm
          title={t('settings.messages.confirm-deletion')}
          onConfirm={cancel}
          placement="topRight"
        >
          {`${t('buttons.cancel')}`}
        </Popconfirm>
      </Button>
    </>
  ) : (
    <>
      <Button
        type="link"
        disabled={editingKey !== ''}
        onClick={(event) => {
          event.stopPropagation();
          edit();
        }}
      >
        <EditOutlined />
      </Button>
      <Button
        type="link"
        disabled={editingKey !== ''}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Popconfirm
          title={t('settings.messages.archive-line-confirm')}
          onConfirm={deleteItem}
          placement="topRight"
        >
          <ContainerOutlined
            type="delete"
            style={{ color: 'var(--errorColor)' }}
          />
        </Popconfirm>
      </Button>
    </>
  );
};

export default ButtonPanel;

ButtonPanel.propTypes = {
  editable: PropTypes.bool.isRequired,
  editingKey: PropTypes.string.isRequired,
  saveItem: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired
};
