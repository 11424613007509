export const formatUsers = () => {
  const formatRoleName = (role) => {
    if (role) return role.split(':').pop();
    return 'N/R';
  };

  const formatUser = (datas) => {
    const { role } = datas;

    const result = {
      ...datas,
      role: formatRoleName(role)
    };

    return result;
  };

  return { formatUser };
};
