import { Steps } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * QuotationsSteps component displays a series of steps based on the quotation's status and kanban statuses.
 *
 * @component
 *
 * @param {Object} quotation - Quotation data.
 * @param {Array} kanbanStatuses - Array of kanban statuses.
 * @param {Function} patchQuotationStatus - Function to update the quotation status.
 * @returns {JSX.Element|null} Rendered JSX element representing the series of steps or null if no matching condition.
 */
export const QuotationsSteps = ({
  quotation,
  kanbanStatuses,
  patchQuotationStatus
}) => {
  const { t } = useTranslation();
  const [current, setCurrent] = useState(1);
  const { message } = useErrorMessage();
  const [loading] = useState(false);

  const items = kanbanStatuses && [
    {
      title: t('quotations.show.QUOTATION_BEING_CREATED')
    },
    ...kanbanStatuses.map((status) => ({
      title:
        status.title.charAt(0).toUpperCase() +
        status.title.slice(1).toLowerCase()
    })),
    {
      title: t('quotations.show.ARCHIVED')
    }
  ];

  const defineActiveStatus = () => {
    switch (true) {
      case quotation?.status === 'QUOTATION_BEING_CREATED':
        return setCurrent(0);
      case quotation?.status === 'KANBAN_STATUS' &&
        quotation?.kanban_status?.title === 'DEVIS FINAUX':
        return setCurrent(1);
      case quotation?.status === 'KANBAN_STATUS' &&
        quotation?.kanban_status?.title === 'DEVIS REMIS':
        return setCurrent(2);
      case quotation?.status === 'KANBAN_STATUS' &&
        quotation?.kanban_status?.title === 'DEVIS SIGNÉS':
        return setCurrent(3);
      case quotation?.status === 'KANBAN_STATUS' &&
        quotation?.kanban_status?.title === 'DEVIS REFUSÉS':
        return setCurrent(4);
      default:
        return setCurrent(5);
    }
  };

  const handleStepIndex = async (stepIndex) => {
    let stringParam;
    if (current === 3 && stepIndex !== 5) return message('quotation_signed');
    switch (stepIndex) {
      case 0:
        stringParam = 'QUOTATION_BEING_CREATED';
        break;
      case 1:
        stringParam = 'DEVIS FINAUX';

        break;
      case 2:
        stringParam = 'DEVIS REMIS';
        break;
      case 3:
        stringParam = 'DEVIS SIGNÉS';
        break;
      case 4:
        stringParam = 'DEVIS REFUSÉS';
        break;
      default:
        stringParam = 'ARCHIVED';
    }

    await patchQuotationStatus(stringParam);
    return true;
  };

  useEffect(() => {
    defineActiveStatus();
  }, [quotation]);

  const loadingDot = (dot) => {
    if (loading) return <LoadingOutlined style={{ fontSize: 10 }} />;
    return dot;
  };

  return (
    <Steps
      items={items}
      status="process"
      progressDot={loadingDot}
      size="small"
      current={current}
      labelPlacement="vertical"
      onChange={(stepIndex) => handleStepIndex(stepIndex)}
    />
  );
};

QuotationsSteps.propTypes = {
  quotation: PropTypes.shape({
    status: PropTypes.string,
    kanban_status: PropTypes.shape({
      title: PropTypes.string
    })
  }),
  kanbanStatuses: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string })
  ),
  patchQuotationStatus: PropTypes.func
};

QuotationsSteps.defaultProps = {
  quotation: null,
  kanbanStatuses: null,
  patchQuotationStatus: null
};
