import React from 'react';
import PropTypes from 'prop-types';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { ExtraSupplierForm } from './extraSupplierForm';

const CreateUpdateSupplier = ({ purpose }) => {
  const { basicFields, isFieldsLoading } = useFields();

  return (
    <CreateUpdateContainer
      fields={basicFields}
      loadingFields={isFieldsLoading}
      formExtra={<ExtraSupplierForm />}
      purpose={purpose}
      baseUrl="suppliers"
      resource="suppliers"
    />
  );
};

CreateUpdateSupplier.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateSupplier;
