import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Divider, Tag, Card, Col, Row, Space } from 'antd';
import { useDrag } from 'react-dnd';
import { EyeOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';

const iconSize = 18;

/**
 * KanbanCardQuote component represents a card for displaying quotation details in the kanban view.
 *
 * @component
 * @param {Object} data - Quotation data.
 * @param {boolean} showAction - Flag to indicate whether to show the "View" action.
 * @param {boolean} editAction - Flag to indicate whether to show the "Edit" action.
 * @returns {JSX.Element} Rendered JSX element representing the KanbanCardQuote component.
 */
export const KanbanCardQuote = ({ data, showAction, editAction }) => {
  const { t } = useTranslation();
  const { _id, number, creation_date, customer, project } = data;
  const [, drag] = useDrag({
    item: {
      type: 'card',
      id: _id
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  return (
    <Card
      ref={drag}
      style={{ marginBottom: 10 }}
      bodyStyle={{
        width: '100%',
        minHeight: 240
      }}
      hoverable
    >
      <Col>
        <Row>
          <Tag color="default">{number}</Tag>
        </Row>
        <Row>{project?.denomination || '-'}</Row>
        <Row>{t('quotations.kanbanview.card.project_type')}</Row>
        <Row>{project?.project_type.title}</Row>
        <Row>
          {t('quotations.kanbanview.card.date')}{' '}
          {moment(creation_date).format('DD/MM/YYYY')}
        </Row>
        <Row align="middle" justify="space-between">
          <Col span={16}>
            <Space>
              <UserOutlined style={{ fontSize: iconSize }} />
              {`${customer?.first_name} ${customer?.last_name}`}
            </Space>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            {showAction && (
              <Link
                to={{
                  pathname: `/quotations/show/${_id}`
                }}
              >
                <EyeOutlined style={{ fontSize: iconSize }} />
              </Link>
            )}
            {editAction && (
              <>
                <Divider type="vertical" />
                <Link
                  to={{
                    pathname: `/quotations/edit/${_id}`
                  }}
                >
                  <EditOutlined style={{ fontSize: iconSize }} />
                </Link>
              </>
            )}
          </Col>
        </Row>
      </Col>
    </Card>
  );
};

KanbanCardQuote.propTypes = {
  showAction: PropTypes.bool,
  editAction: PropTypes.bool,
  data: PropTypes.shape({
    _id: PropTypes.string,
    number: PropTypes.string,
    creation_date: PropTypes.string,
    project: PropTypes.shape({
      denomination: PropTypes.string,
      project_type: PropTypes.shape({
        title: PropTypes.string
      })
    }),
    customer: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string
    })
  }).isRequired
};

KanbanCardQuote.defaultProps = {
  showAction: true,
  editAction: true
};
