import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Form, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;

export const ExtraSupplierForm = () => {
  const { t } = useTranslation();

  return (
    <Form.List name="contacts">
      {(fields, { add, remove }, { errors }) => (
        <>
          <Collapse expandIconPosition="end">
            {fields.map((field) => (
              <Panel
                key={`Contact n° ${field.name}`}
                header={
                  <Row>
                    <Col>{`Contact n° ${field.name + 1}`}</Col>
                    <Col>
                      <Button onClick={() => remove(field.name)} type="text">
                        <CloseOutlined />
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <Row style={{ display: 'flex', flexDirection: 'column' }}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'first_name']}
                    label={t('suppliers.form.contact.first_name')}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, 'last_name']}
                    label={t('suppliers.form.contact.first_name')}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, 'role']}
                    label={t('suppliers.form.contact.role')}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label={t('suppliers.form.contact.phone_number')}
                  >
                    <Input.Group compact>
                      <Form.Item
                        noStyle
                        name={[field.name, 'phone_number', 'country_code']}
                        initialValue="+33"
                      >
                        <Select style={{ width: '25%' }}>
                          <Select.Option value="+33">+33</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        noStyle
                        name={[field.name, 'phone_number', 'number']}
                      >
                        <Input style={{ width: '75%' }} />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, 'email']}
                    label={t('suppliers.form.contact.email')}
                  >
                    <Input />
                  </Form.Item>
                </Row>
              </Panel>
            ))}
          </Collapse>

          <Button
            type="dashed"
            style={{ width: '100%' }}
            onClick={() => add(undefined, fields.length + 1)}
          >
            {t('suppliers.form.add')}
          </Button>
          <Form.ErrorList errors={errors} />
        </>
      )}
    </Form.List>
  );
};
