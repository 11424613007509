import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Orders } from '../routes/orders/Orders';
import { ShowOrder } from '../routes/orders/Show/ShowOrder';
import { OrderContextProvider } from '../contexts/OrderContext';

const OrderRouter = () => (
  <OrderContextProvider>
    <Routes>
      <Route path="/show/:id" element={<ShowOrder />} />
      <Route index element={<Orders />} />
    </Routes>
  </OrderContextProvider>
);

export default OrderRouter;
