import { formatNumberWithSpaces } from '../../../utils/formatters/formatNumberWithSpaces';
import { calculateAccessoriesTotal } from './calculateAccessoriesTotal';
import { calculateMaterialsTotal } from './calculateMaterialsTotal';
import { calculateServicesTotal } from './calculateServicesTotal';

/**
 * Calculates the total values for accessories, materials, services, and discounts
 * and updates the provided form fields with the calculated values.
 *
 * @function
 * @param {Object} fields - An object containing various field values.
 * @param {Array} accessories - An array of accessory objects.
 * @param {Array} rates - An array of rate objects.
 * @param {Object} form - The form instance to update the fields.
 * @param {Array} services - An array of service objects.
 * @param {Array} materials - An array of material objects.
 * @param {Array} shapings - An array of shaping objects.
 * @param {boolean} materialProvidedByCustomer - Indicates if material is provided by the customer.
 * @param {number} customerCoefficient - The customer's coefficient value.
 * @param {Object} initialValues - Initial Form values to be used when purpose is edit because we need values when panels are closed.
 * @returns {void}
 */
export const quotationCalculations = (
  fields,
  accessories,
  rates,
  form,
  services,
  materials,
  shapings,
  materialProvidedByCustomer,
  customerCoefficient,
  initialValues
) => {
  const global_commission =
    fields.global_commission || initialValues?.global_commission || 0;
  const accessoriesFields =
    fields.accessories || initialValues?.accessories || null;
  const servicesFields = fields.services || initialValues?.services || null;
  const materialsFields = fields.materials || initialValues?.materials || null;
  const discountFields =
    fields?.summary?.price_conditions ||
    initialValues.summary.price_conditions ||
    null;

  let materialsTotal = null;
  let servicesTotal = null;
  let accessoriesTotal = null;

  const base = {
    materials_total: 0,
    shapings_total: 0,
    services_total: 0,
    accessories_total: 0,
    unprocessed_values_total: 0,
    customer_coefficient_total: 0,
    commission_total: 0,
    facial_values_total: 0,
    discounted_total: 0,
    total_excluding_VAT: 0,
    VAT_total: 0,
    all_included_total: 0
  };
  const option = {
    ...base
  };

  if (materialsFields) {
    materialsTotal = calculateMaterialsTotal(
      materialsFields,
      rates,
      materials,
      shapings,
      materialProvidedByCustomer,
      customerCoefficient,
      form
    );
    base.materials_total += materialsTotal.base.materialsPriceWithCommission;
    base.shapings_total += materialsTotal.base.shapingsTotalWithoutVAT;
    base.customer_coefficient_total +=
      materialsTotal.base.materialsCustomerCoefficientTotal +
      materialsTotal.base.shapingCoefficientTotal;
    base.unprocessed_values_total +=
      materialsTotal.base.materialsPriceWithoutCustomerCoefficient +
      materialsTotal.base.shapingsWithoutCustomerCoefficient;
    base.facial_values_total +=
      materialsTotal.base.materialsPriceWithCommission +
      materialsTotal.base.shapingsTotalWithoutVAT;
    base.commission_total += materialsTotal.base.materialsAndShapingsCommission;
    base.total_excluding_VAT +=
      materialsTotal.base.materialsPriceWithCommission +
      materialsTotal.base.shapingsTotalWithoutVAT;
    base.VAT_total += materialsTotal.base.materialsAndShapingsVATAmount;
    base.all_included_total +=
      materialsTotal.base.materialsPriceWithCommission +
      materialsTotal.base.shapingsTotalWithoutVAT +
      materialsTotal.base.materialsAndShapingsVATAmount;

    option.materials_total +=
      materialsTotal.option.materialsPriceWithCommission;
    option.shapings_total += materialsTotal.option.shapingsTotalWithoutVAT;
    option.customer_coefficient_total +=
      materialsTotal.option.materialsCustomerCoefficientTotal +
      materialsTotal.option.shapingCoefficientTotal;
    option.unprocessed_values_total +=
      materialsTotal.option.materialsPriceWithoutCustomerCoefficient +
      materialsTotal.option.shapingsWithoutCustomerCoefficient;
    option.facial_values_total +=
      materialsTotal.option.materialsPriceWithCommission;
    option.commission_total +=
      materialsTotal.option.materialsAndShapingsCommission;
    option.total_excluding_VAT +=
      materialsTotal.option.materialsPriceWithCommission +
      materialsTotal.option.shapingsTotalWithoutVAT;
    option.VAT_total += materialsTotal.option.materialsAndShapingsVATAmount;
    option.all_included_total +=
      materialsTotal.option.materialsPriceWithCommission +
      materialsTotal.option.shapingsTotalWithoutVAT +
      materialsTotal.option.materialsAndShapingsVATAmount;
  }

  if (servicesFields) {
    servicesTotal = calculateServicesTotal(
      servicesFields,
      services,
      rates,
      customerCoefficient,
      form
    );
    base.services_total += servicesTotal.base.totalWithCommission;
    base.customer_coefficient_total +=
      servicesTotal.base.customerCoefficientTotal;
    base.commission_total += servicesTotal.base.commissionTotal;
    base.unprocessed_values_total += servicesTotal.base.rawTotal;
    base.facial_values_total += servicesTotal.base.totalWithCommission;
    base.total_excluding_VAT += servicesTotal.base.totalWithCommission;
    base.VAT_total += servicesTotal.base.VATtotal;
    base.all_included_total += servicesTotal.base.totalWithVAT;

    option.services_total += servicesTotal.option.totalWithCommission;
    option.customer_coefficient_total +=
      servicesTotal.option.customerCoefficientTotal;
    option.commission_total += servicesTotal.option.commissionTotal;
    option.unprocessed_values_total += servicesTotal.option.rawTotal;
    option.facial_values_total += servicesTotal.option.totalWithCommission;
    option.total_excluding_VAT += servicesTotal.option.totalWithCommission;
    option.VAT_total += servicesTotal.option.VATtotal;
    option.all_included_total += servicesTotal.option.totalWithVAT;
  }

  if (accessoriesFields) {
    accessoriesTotal = calculateAccessoriesTotal(
      accessoriesFields,
      accessories,
      rates,
      form
    );
    base.accessories_total += accessoriesTotal.base.total;
    base.unprocessed_values_total += accessoriesTotal.base.total;
    base.facial_values_total += accessoriesTotal.base.total;
    base.total_excluding_VAT += accessoriesTotal.base.total;
    base.VAT_total += accessoriesTotal.base.VATtotal;
    base.all_included_total += accessoriesTotal.base.totalWithVAT;

    option.accessories_total += accessoriesTotal.option.total;
    option.unprocessed_values_total += accessoriesTotal.option.total;
    option.facial_values_total += accessoriesTotal.option.total;
    option.total_excluding_VAT += accessoriesTotal.option.total;
    option.VAT_total += accessoriesTotal.option.VATtotal;
    option.all_included_total += accessoriesTotal.option.totalWithVAT;
  }

  // Create a new object to hold the merged values
  const baseVatSumsArray = [
    materialsTotal?.baseVatSums || {},
    servicesTotal?.baseVatSums || {},
    accessoriesTotal?.baseVatSums || {}
  ];

  const optionVatSumsArray = [
    materialsTotal?.optionVatSums || {},
    servicesTotal?.optionVatSums || {},
    accessoriesTotal?.optionVatSums || {}
  ];

  // Combine all vatSums objects into a single mergedVatSums object
  const baseMergedVatSums = baseVatSumsArray.reduce((result, vatSums) => {
    const merged = { ...result };
    Object.keys(vatSums).forEach((percentage) => {
      const value = vatSums[percentage];
      merged[percentage] = (merged[percentage] || 0) + value;
    });
    return merged;
  }, {});

  const optionMergedVatSums = optionVatSumsArray.reduce((result, vatSums) => {
    const merged = { ...result };
    Object.keys(vatSums).forEach((percentage) => {
      const value = vatSums[percentage];
      merged[percentage] = (merged[percentage] || 0) + value;
    });
    return merged;
  }, {});

  if (discountFields) {
    const updatedVATTotal = (updatedValues) => {
      let VATTotal = 0;
      for (let i = 0; i < updatedValues.length; i += 1) {
        VATTotal += Number(updatedValues[i].updatedValue);
      }
      return Number(VATTotal);
    };

    const { type, amount } = discountFields.discount;

    if (type && amount) {
      if (base.total_excluding_VAT > 0) {
        const baseTotal = base.total_excluding_VAT;
        const baseUpdatedMonetaryTotal = baseTotal - Number(amount);
        const basePercentageTotal = (Number(amount) / 100) * baseTotal;
        const baseUpdatedPercentageTotal = baseTotal - basePercentageTotal;

        let baseUpdatedAITotal = 0;
        let baseUpdatedVATTotal = 0;
        let baseUpdatedHTTotal = 0;

        if (type === 'monetary') {
          const baseUpdatedValues = Object.entries(baseMergedVatSums).map(
            ([percentage, sum]) => {
              const updatedValue = (baseUpdatedMonetaryTotal * sum) / baseTotal;
              return {
                VAT_rate: parseInt(percentage, 10),
                updatedValue: updatedValue.toFixed(2)
              };
            }
          );
          baseUpdatedAITotal =
            baseUpdatedMonetaryTotal + updatedVATTotal(baseUpdatedValues);
          baseUpdatedVATTotal = updatedVATTotal(baseUpdatedValues);
          baseUpdatedHTTotal = baseUpdatedMonetaryTotal;
        }

        if (type === 'percentage') {
          const baseUpdatedValues = Object.entries(baseMergedVatSums).map(
            ([percentage, sum]) => {
              const updatedValue =
                (baseUpdatedPercentageTotal * sum) / baseTotal;
              return {
                VAT_rate: parseInt(percentage, 10),
                updatedValue: updatedValue.toFixed(2)
              };
            }
          );

          baseUpdatedAITotal =
            baseUpdatedPercentageTotal + updatedVATTotal(baseUpdatedValues);
          baseUpdatedVATTotal = updatedVATTotal(baseUpdatedValues);
          baseUpdatedHTTotal = baseUpdatedPercentageTotal;
        }

        switch (type) {
          case 'monetary':
            base.discounted_total += Number(amount);
            base.total_excluding_VAT = baseUpdatedHTTotal;
            base.VAT_total = baseUpdatedVATTotal;
            base.all_included_total = baseUpdatedAITotal;
            break;
          case 'percentage':
            base.discounted_total += basePercentageTotal;
            base.total_excluding_VAT = baseUpdatedHTTotal;
            base.VAT_total = baseUpdatedVATTotal;
            base.all_included_total = baseUpdatedAITotal;
            break;
          default:
            break;
        }
      }

      if (option.total_excluding_VAT > 0) {
        const optionTotal = option.total_excluding_VAT;
        const optionUpdatedMonetaryTotal = optionTotal - Number(amount);
        const optionPercentageTotal = (Number(amount) / 100) * optionTotal;
        const optionUpdatedPercentageTotal =
          optionTotal - optionPercentageTotal;

        let optionUpdatedAITotal = 0;
        let optionUpdatedVATTotal = 0;
        let optionUpdatedHTTotal = 0;

        if (type === 'monetary') {
          const optionUpdatedValues = Object.entries(optionMergedVatSums).map(
            ([percentage, sum]) => {
              const updatedValue =
                (optionUpdatedMonetaryTotal * sum) / optionTotal;
              return {
                VAT_rate: parseInt(percentage, 10),
                updatedValue: updatedValue.toFixed(2)
              };
            }
          );

          optionUpdatedAITotal =
            optionUpdatedMonetaryTotal + updatedVATTotal(optionUpdatedValues);
          optionUpdatedVATTotal = updatedVATTotal(optionUpdatedValues);
          optionUpdatedHTTotal = optionUpdatedMonetaryTotal;
        }

        if (type === '%') {
          const optionUpdatedValues = Object.entries(optionMergedVatSums).map(
            ([percentage, sum]) => {
              const updatedValue =
                (optionUpdatedPercentageTotal * sum) / optionTotal;
              return {
                VAT_rate: parseInt(percentage, 10),
                updatedValue: updatedValue.toFixed(2)
              };
            }
          );
          optionUpdatedAITotal =
            optionUpdatedPercentageTotal + updatedVATTotal(optionUpdatedValues);
          optionUpdatedVATTotal = updatedVATTotal(optionUpdatedValues);
          optionUpdatedHTTotal = optionUpdatedPercentageTotal;
        }

        switch (type) {
          case 'monetary':
            option.discounted_total += Number(amount);
            option.total_excluding_VAT = optionUpdatedHTTotal;
            option.VAT_total = optionUpdatedVATTotal;
            option.all_included_total = optionUpdatedAITotal;
            break;
          case '%':
            option.discounted_total += optionPercentageTotal;
            option.total_excluding_VAT = optionUpdatedHTTotal;
            option.VAT_total = optionUpdatedVATTotal;
            option.all_included_total = optionUpdatedAITotal;
            break;
          default:
            break;
        }
      }
    }
  }

  form.setFieldsValue({
    global_commission: formatNumberWithSpaces(Number(global_commission)),
    summary: {
      base_total: {
        materials_total: formatNumberWithSpaces(
          Number(Number(base.materials_total).toFixed(2))
        ),
        shapings_total: formatNumberWithSpaces(
          Number(Number(base.shapings_total).toFixed(2))
        ),
        services_total: formatNumberWithSpaces(
          Number(Number(base.services_total).toFixed(2))
        ),
        accessories_total: formatNumberWithSpaces(
          Number(Number(base.accessories_total).toFixed(2))
        ),
        unprocessed_values_total: formatNumberWithSpaces(
          Number(Number(base.unprocessed_values_total).toFixed(2))
        ),
        customer_coefficient_total: formatNumberWithSpaces(
          Number(Number(base.customer_coefficient_total).toFixed(2))
        ),
        commission_total: formatNumberWithSpaces(
          Number(Number(base.commission_total).toFixed(2))
        ),
        facial_values_total: formatNumberWithSpaces(
          Number(Number(base.facial_values_total).toFixed(2))
        ),
        discounted_total: formatNumberWithSpaces(
          Number(Number(base.discounted_total).toFixed(2))
        ),
        total_excluding_VAT: formatNumberWithSpaces(
          Number(Number(base.total_excluding_VAT).toFixed(2))
        ),
        VAT_total: formatNumberWithSpaces(Number(base.VAT_total.toFixed(2))),
        all_included_total: formatNumberWithSpaces(
          Number(Number(base.all_included_total).toFixed(2))
        )
      },
      option_total: {
        materials_total: formatNumberWithSpaces(
          Number(Number(option.materials_total).toFixed(2))
        ),
        shapings_total: formatNumberWithSpaces(
          Number(Number(option.shapings_total).toFixed(2))
        ),
        services_total: formatNumberWithSpaces(
          Number(Number(option.services_total).toFixed(2))
        ),
        accessories_total: formatNumberWithSpaces(
          Number(Number(option.accessories_total).toFixed(2))
        ),
        unprocessed_values_total: formatNumberWithSpaces(
          Number(Number(option.unprocessed_values_total).toFixed(2))
        ),
        customer_coefficient_total: formatNumberWithSpaces(
          Number(Number(option.customer_coefficient_total).toFixed(2))
        ),
        commission_total: formatNumberWithSpaces(
          Number(Number(option.commission_total).toFixed(2))
        ),
        facial_values_total: formatNumberWithSpaces(
          Number(Number(option.facial_values_total).toFixed(2))
        ),
        discounted_total: formatNumberWithSpaces(
          Number(Number(option.discounted_total).toFixed(2))
        ),
        total_excluding_VAT: formatNumberWithSpaces(
          Number(Number(option.total_excluding_VAT).toFixed(2))
        ),
        VAT_total: formatNumberWithSpaces(Number(option.VAT_total.toFixed(2))),
        all_included_total: formatNumberWithSpaces(
          Number(Number(option.all_included_total).toFixed(2))
        )
      }
    }
  });
};
