import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from 'antd';

/**
 * PageHeaderCustom component renders a page header with a conditional back button.
 * The back button appears only when params is not an empty string.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {string} props.title - The title of the page header. This is a required prop.
 * @param {React.Element} [props.extra=null] - Extra elements to display on the right of the header. It's optional and defaults to null.
 *
 * @returns {React.Element} The PageHeader component with specified props.
 */
export const PageHeaderCustom = ({ title, extra }) => {
  const navigate = useNavigate();
  const { '*': params } = useParams();

  return (
    <PageHeader
      title={title}
      onBack={params !== '' ? () => navigate(-1) : null}
      ghost={false}
      extra={extra}
    />
  );
};

PageHeaderCustom.propTypes = {
  title: PropTypes.string.isRequired,
  extra: PropTypes.element
};

PageHeaderCustom.defaultProps = {
  extra: null
};
