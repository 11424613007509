import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Avatar, Card, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  ContainerOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { routes, subRoutes } from '../../utils/constants/adminRoutes';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useListContent } from './listContent';

import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';

export const ShowUser = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState();
  const listContent = useListContent(user);

  const iconSize = 18;

  const getUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${id}`
      });
      setUser(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getUser();
    })();
  }, [getUser]);

  const deleteUser = async () => {
    try {
      await dispatchAPI('DELETE', {
        url: `/users/${id}`
      });

      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('users.show.title')}
        extra={
          <>
            <Link
              to={{
                pathname: `${routes.PARAMETERS}${subRoutes.PARAMETERS.USERS}/edit/${id}`
              }}
            >
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.archive.title')}
              okText={t('datatable.column.action.archive.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.archive.cancel')}
              onConfirm={deleteUser}
              icon={<WarningOutlined />}
            >
              <Button danger>
                <ContainerOutlined
                  style={{ color: 'var(--errorColor)', fontSize: iconSize }}
                />
                {t('buttons.archive')}
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Card>
          <Row gutter={[0, 24]} align="middle">
            <Col
              style={{ display: 'flex' }}
              xs={24}
              md={8}
              lg={6}
              xl={4}
              xxl={3}
            >
              <Skeleton
                loading={isLoading}
                title={0}
                paragraph={0}
                avatar={{ size: 64 }}
                active
              >
                {/* <Avatar size={64} src={user ? user.photo : ''}/> */}
                <Avatar size={64} src={user && user.photo ? user.photo : ``} />
              </Skeleton>
            </Col>
            <Col xs={24} md={16} lg={18} xl={20} xxl={21}>
              <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                <DescriptionList data={listContent} translate />
              </Skeleton>
            </Col>
          </Row>
        </Card>
      </ContentCustom>
    </>
  );
};
