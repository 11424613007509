/**
 * An array of objects representing table headers for quotation data.
 * @array
 *
 * @type {Array<Object>}
 */
export const headers = [
  {
    label: 'status',
    key: 'status',
    display: true,
    required: true
  },
  {
    label: 'number',
    key: 'number',
    display: true,
    required: true
  },
  {
    label: 'creation_date',
    key: 'creation_date',
    display: true,
    required: true
  },
  {
    label: 'customer_arr',
    key: 'full_name',
    display: true,
    required: true
  },
  {
    label: 'amount',
    key: 'summary.base_total.all_included_total',
    display: true,
    required: true
  },
  {
    label: 'validity_date_arr',
    key: 'validity_date',
    display: true,
    required: true
  },
  {
    label: 'previsional_works_start_date_arr',
    key: 'previsional_works_start_date',
    display: true,
    required: true
  }
];
