import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Col, Table } from 'antd';
import useColumns from '../../columns';

export const SlicesTable = ({
  forceRefresh,
  slices,
  setIsSliceManagement,
  onEdit
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { slicesManagementColumns, pricesManagementColumns } = useColumns(
    id,
    forceRefresh,
    onEdit
  );

  const expandedRowRender = (record) => {
    const dataSource = record.prices_management.map((price) => {
      const newPrice = price;
      newPrice.sliceInfo = {
        _id: record?._id,
        nomenclature: record?.nomenclature
      };
      return newPrice;
    });

    return (
      <>
        <Table
          size="small"
          columns={pricesManagementColumns}
          dataSource={dataSource}
          pagination={false}
        />
        <Button
          type="dashed"
          style={{ width: '100%' }}
          onClick={() => {
            onEdit('prices', record);
            setIsSliceManagement(false);
          }}
        >
          {t('materials.form.add_price')}
        </Button>
      </>
    );
  };
  if (slices)
    return (
      <Col span={24}>
        <Table
          columns={slicesManagementColumns}
          dataSource={slices.map((slice) => ({
            ...slice,
            key: slice._id,
            sliceInfo: { _id: slices?._id, nomenclature: slices?.nomenclature }
          }))}
          expandable={{
            expandedRowRender
          }}
          pagination={false}
          scroll={{ x: 1000 }}
        />
      </Col>
    );

  return null;
};

SlicesTable.propTypes = {
  forceRefresh: PropTypes.func,
  slices: PropTypes.arrayOf({
    _id: PropTypes.string,
    nomenclature: PropTypes.string
  }).isRequired,
  setIsSliceManagement: PropTypes.func,
  onEdit: PropTypes.func
};

SlicesTable.defaultProps = {
  forceRefresh: () => {},
  setIsSliceManagement: () => {},
  onEdit: () => {}
};
