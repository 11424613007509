import React from 'react';
import PropTypes from 'prop-types';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';

/**
 * @component
 * Component for creating or updating a shaping resource.
 * @param {Object} props - Component props.
 * @param {string} props.purpose - Purpose of the form ('create' or 'update').
 * @returns {JSX.Element} A form container for creating or updating a shaping.
 */
const CreateUpdateShaping = ({ purpose }) => {
  const { basicFields, isFieldsLoading } = useFields();

  return (
    <CreateUpdateContainer
      fields={basicFields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="shapings"
      resource="shapings"
    />
  );
};

CreateUpdateShaping.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateShaping;
