import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import * as XLSX from 'xlsx';
import { ExportSheet } from 'react-xlsx-sheet';
import { formatterFile } from '../../utils/formatters/importFile';

export const ExportXLSX = ({
  datas,
  dataName,
  headers,
  fetchData,
  setVisible
}) => {
  const { t } = useTranslation();
  const { flattenObject } = formatterFile;
  const [isLoading, setIsLoading] = useState(false);

  const xlsxHeader = (headers || []).map(({ label, key }) => ({
    title: t(`${dataName}.form.${label}`),
    dataIndex: key || label
  }));

  const flattenData =
    dataName === 'quotations' ? [] : datas.map((data) => flattenObject(data));

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <ExportSheet
      fileName={dataName}
      dataSource={flattenData}
      header={xlsxHeader}
      xlsx={XLSX}
    >
      <Button
        type="link"
        loading={isLoading}
        style={{ margin: 0 }}
        icon={<DownloadOutlined />}
        onClick={async (e) => {
          e.persist();
          e.preventDefault();

          if (!datas.length) {
            setVisible(true);
            setIsLoading(true);

            try {
              await fetchData();
              setVisible(false);
              setIsLoading(false);
            } catch (error) {
              setVisible(false);
              setIsLoading(false);
            }
          }
        }}
      >
        {t(`buttons.export.xlsx`)}
      </Button>
    </ExportSheet>
  );
};

ExportXLSX.propTypes = {
  dataName: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  datas: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  fetchData: PropTypes.func.isRequired,
  setVisible: PropTypes.func
};

ExportXLSX.defaultProps = {
  setVisible: () => {}
};
