import { Button, Card, Col, Form, Input, Row, Select, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useEditionFields } from './editionFields';
import { useGenerateFormItem } from '../../../utils/generateFormItem';
import { tailFormItemLayout } from '../../../utils/constants/formLayout';
import { routes } from '../../../utils/constants/adminRoutes';
import { calculateCreditTotals } from './utils/calculateCreditTotals';

const { Option } = Select;
const { TextArea } = Input;

/**
 * A component for editing or creating credit notes.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {boolean} props.creditEdition - Flag indicating if in edit mode.
 * @param {function} props.setCreditEdition - Function to set edit mode.
 * @param {Object} props.datas - Data for the credit note.
 * @param {boolean} props.refresh - Flag to trigger refresh.
 * @param {function} props.setRefresh - Function to trigger refresh.
 * @returns {JSX.Element} CreditEdition component.
 */
export const CreditEdition = ({
  creditEdition,
  setCreditEdition,
  datas,
  refresh,
  setRefresh
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();
  const [VATRates, setVATRates] = useState();
  const generateFormItem = useGenerateFormItem();
  const [paymentChoices, setPaymentChoices] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const fields = useEditionFields(VATRates);

  const getVATRates = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'tvas'
      });
      setVATRates(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      setIsFieldsLoading(true);
      await getVATRates();
      setIsFieldsLoading(false);
    })();
  }, []);

  const postCreditNote = async (body) => {
    setIsSubmitting(true);
    try {
      const { data } = await dispatchAPI('POST', {
        url: 'invoices',
        body: {
          ...body,
          order: datas.order,
          customer: datas.customer,
          invoice_type: 'CREDIT_NOTE',
          body_infos: {
            ...body.body_infos,
            creator: 'Marbrerie Provençale',
            emission_date: moment()
          },
          totals: {
            ...body.totals,
            due: body.totals.all_included_total,
            paid: 0
          }
        }
      });
      navigate(`${routes.INVOICES}/show/${data._id}`);
      setRefresh(!refresh);
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  const getPaymentChoices = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'invoices/enums'
      });
      setPaymentChoices(data.payment_choice);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getPaymentChoices();
    })();
  }, []);

  return (
    <Form
      form={form}
      onFinish={postCreditNote}
      layout="vertical"
      onValuesChange={(_, allValues) =>
        calculateCreditTotals(allValues, VATRates, form)
      }
    >
      <Skeleton loading={isFieldsLoading}>
        <Card>
          <Row>
            <Col>
              <InfoCircleOutlined />
            </Col>
            <Col>
              <Row style={{ flexDirection: 'column' }}>
                <Col>{t('invoices.show.credit_note_creation')}</Col>
                <Col>{t('invoices.show.credit_note_description')}</Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <Row>{fields.map((field) => generateFormItem('invoices', field))}</Row>
        <Row style={{ flexDirection: 'column' }}>
          <Form.Item
            label={t('invoices.form.body_infos.payment_choice')}
            name={['body_infos', 'payment_choice']}
          >
            <Select>
              {(paymentChoices || []).map((paymentChoice) => (
                <Option key={paymentChoice} value={paymentChoice}>
                  {t(`invoices.form.${paymentChoice}`)}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={t('invoices.form.remarks')} name={['remarks']}>
            <TextArea />
          </Form.Item>
        </Row>
        <Form.Item {...tailFormItemLayout}>
          <Row justify="end">
            <Button
              style={{ margin: '0 10px' }}
              type="link"
              danger
              onClick={() => setCreditEdition(!creditEdition)}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button type="add" htmlType="submit" loading={isSubmitting}>
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Skeleton>
    </Form>
  );
};

CreditEdition.propTypes = {
  creditEdition: PropTypes.bool,
  setCreditEdition: PropTypes.func,
  datas: PropTypes.shape({
    order: PropTypes.string,
    customer: PropTypes.string
  }),
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func
};

CreditEdition.defaultProps = {
  creditEdition: true,
  setCreditEdition: null,
  datas: null,
  refresh: undefined,
  setRefresh: null
};
