import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Profile from './Profile';
import UpdateProfile from './UpdateProfile';
import UpdatePwd from './UpdatePwd';

export const ProfileRouter = () => (
  <Routes>
    <Route path="/edit" element={<UpdateProfile />} />
    <Route path="/change-pwd" element={<UpdatePwd />} />
    <Route index element={<Profile />} />
  </Routes>
);
