import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';

const IconSize = 25;
const ScrollStep = 750;

/**
 * Component to render scroll buttons for the Gantt chart.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.currGantt - The Gantt chart instance to scroll.
 * @returns {JSX.Element} The rendered scroll buttons.
 */
export const ScrollButtons = ({ currGantt }) => {
  const showNextMonth = (curr) => {
    const currentPosX = curr.getScrollState().x;
    curr.scrollTo(currentPosX + ScrollStep, null);
  };

  const showPreviousMonth = (curr) => {
    const currentPosX = curr.getScrollState().x;
    curr.scrollTo(currentPosX - ScrollStep, null);
  };

  return (
    <Col span={24}>
      <Row justify="space-between">
        <Button
          type="link"
          onClick={() => showPreviousMonth(currGantt)}
          icon={<LeftCircleFilled style={{ fontSize: IconSize }} />}
        />
        <Button
          type="link"
          onClick={() => showNextMonth(currGantt)}
          icon={<RightCircleFilled style={{ fontSize: IconSize }} />}
        />
      </Row>
    </Col>
  );
};

ScrollButtons.propTypes = {
  currGantt: PropTypes.shape({}).isRequired
};
