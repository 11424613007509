import React, { useState, useEffect } from 'react';
import { Divider, Popconfirm, Row, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import { Link, useParams } from 'react-router-dom';
import {
  ContainerOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { projectsStatus } from '../../utils/constants/tagColors';
import { routes } from '../../utils/constants/adminRoutes';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const iconSize = 18;

/**
 * Hook: useColumns
 *
 * A custom hook that provides configurations for columns in a projects table within an admin interface.
 * It prepares columns for displaying various project details (project type, address, dwelling type, supervisors, and status)
 * and includes action buttons (view, edit, delete/archive) for each project row.
 *
 * The hook integrates with `useAuthContext` for dispatching API requests, `useTranslation` for internationalization,
 * and `useParams` for accessing URL parameters. It manages enumerations, supervisors, and dwelling types data for filters
 * and handles project deletion logic.
 *
 * @hook
 *
 * @param {boolean} forceRefresh - A state variable to re-render the component when true.
 * @param {Function} setForceRefresh - Function to update the forceRefresh state.
 *
 * @returns {Object} An object containing configurations for the table columns.
 * - basicColumns (Array): Configurations for main columns, displaying core project information.
 * - actionColumn (Array): Configurations for action columns, including buttons for various project operations.
 * - forceRefresh (boolean): A state variable indicating if the component/table should be refreshed.
 * - setForceRefresh (Function): Setter function to toggle forceRefresh, triggering a component/table re-render.
 */
const useColumns = (forceRefresh, setForceRefresh) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [dwellingTypes, setDwellingTypes] = useState([]);

  const deleteProject = async (projectID) => {
    setForceRefresh(!forceRefresh);
    try {
      await dispatchAPI('PATCH', {
        url: `projects/${projectID}`,
        body: { status: 'ARCHIVED' }
      });
    } catch (e) {
      message(e);
    }
  };

  const getCustomerFilters = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `projects?customer=${id}&populate=MP_supervisor`
      });
      const supervisorsArray = data
        .map((project) => project.MP_supervisor)
        .filter(Boolean);
      const dwellingTypesArray = data
        .map((project) => project.dwelling_type)
        .filter(Boolean);
      const statusArray = data.map((project) => project.status).filter(Boolean);
      setSupervisors(
        Array.from(new Set(supervisorsArray.map(JSON.stringify))).map(
          JSON.parse
        )
      );
      setDwellingTypes([...new Set(dwellingTypesArray)]);
      setEnums(
        [...new Set(statusArray)].filter((status) => status !== 'ARCHIVED')
      );
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getCustomerFilters();
    })();
  }, [forceRefresh]);

  const actionColumn = [
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `${routes.PROJECTS}/show/${record._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          {record.status !== 'ARCHIVED' && (
            <>
              <Divider type="vertical" />
              <Link
                to={{
                  pathname: `${routes.PROJECTS}/edit/${record._id}`
                }}
              >
                <EditOutlined style={{ fontSize: iconSize }} />
              </Link>
              <Divider type="vertical" />
              <Popconfirm
                title={t('datatable.column.action.archive.title')}
                okText={t('datatable.column.action.archive.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.archive.cancel')}
                onConfirm={() => deleteProject(record?._id)}
                icon={<WarningOutlined />}
              >
                <ContainerOutlined
                  style={{ color: '#b51010', fontSize: iconSize }}
                  type="delete"
                />
              </Popconfirm>
            </>
          )}
        </>
      )
    }
  ];

  const basicColumns = [
    {
      title: t('projects.table.project_type'),
      dataIndex: 'project_type',
      index: 'project_type',
      key: 'project_type',
      sorter: true,
      render: (type) => type && type.title
    },
    {
      title: t('projects.table.address'),
      dataIndex: 'site_address',
      key: 'site_address',
      render: (address) =>
        address && (
          <Row style={{ display: 'flex', flexDirection: 'column' }}>
            <Row>{address.street}</Row>
            <Row>{`${address.postal_code} ${address.city}`}</Row>
            {address.additional ? <Row> {address.additional}</Row> : null}
          </Row>
        )
    },
    {
      title: t('projects.table.dwelling_type'),
      dataIndex: 'dwelling_type',
      key: 'dwelling_type',
      sorter: (a, b) => a?.type.localeCompare(b?.type),
      filters: dwellingTypes?.map((type) => ({
        text: type,
        value: type
      }))
    },
    {
      title: t('projects.table.MP_supervisor'),
      dataIndex: 'MP_supervisor',
      key: 'MP_supervisor',
      render: (supervisor) =>
        `${supervisor?.first_name || ''} ${supervisor?.last_name || ''}`,
      filters: supervisors.map((supervisor) => ({
        text: `${supervisor?.first_name} ${supervisor?.last_name}`,
        value: supervisor?._id
      })),
      sorter: (a, b) => a?.last_name?.localeCompare(b?.last_name)
    },
    {
      title: t('projects.table.status'),
      dataIndex: 'status',
      key: 'status',
      filters: enums?.map((status) => ({
        text: t(`customers.form.${status}`),
        value: status
      })),
      render: (type) =>
        (type && (
          <Tag color={projectsStatus[type]}>{t(`projects.form.${type}`)}</Tag>
        )) ||
        t('errors.form.not_specified'),
      sorter: (a, b) => a?.status.localeCompare(b?.status)
    }
  ];

  return {
    basicColumns,
    actionColumn
  };
};

export default useColumns;
