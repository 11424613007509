import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { Tag, Typography, Col } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const { Text } = Typography;

/**
 * Render a row cell for the user information in the Gantt chart.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered user row cell.
 */
const UserRowCell = ({ fullName, project, id, order, parent }) =>
  !parent && (
    <Col>
      <a href={`/orders/show/${id}`}>
        <Tag
          style={{
            color: 'var(--primaryColor)'
          }}
        >
          {order}
        </Tag>
      </a>
      <UserOutlined /> {fullName} - <Text strong>{project}</Text>
    </Col>
  );

/**
 * Generate an HTML representation of the order template.
 *
 * @component
 * @param {Object} data - The order data for the template.
 * @returns {string} The HTML representation of the order template.
 */
export const OrderTemplate = (
  { full_name, project_type, id, order, parent },
  data
) => {
  const component = (
    <UserRowCell
      fullName={full_name}
      project={project_type}
      id={id}
      order={order}
      parent={parent}
      data={data}
    />
  );

  const htmlString = ReactDOMServer.renderToString(component);
  return htmlString;
};
UserRowCell.propTypes = {
  id: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  parent: PropTypes.string,
  data: PropTypes.shape({})
};

UserRowCell.defaultProps = {
  parent: null,
  data: null
};
