import React from 'react';
import useColumns from './columns';
import { ListResource } from '../../../components/ListResource/ListResource';
import { headers } from './formatter/headers';
import { useSupplierContext } from '../../../contexts/SupplierContext';
import { DownloadFileButtons } from '../../../components/DownloadFileButton/DownloadFileButton';

export const Suppliers = () => {
  const { basicColumns } = useColumns();
  const { importTemplate } = useSupplierContext();

  return (
    <ListResource
      columns={basicColumns}
      extraQuery="status=ACTIVE"
      populate="type"
      resourceName="suppliers"
      resourceModelName="Supplier"
      headers={headers}
      extraMenu={
        importTemplate[0]?.file && [
          {
            key: 'template',
            label: importTemplate[0]?.file && (
              <DownloadFileButtons file={importTemplate[0]?.file} />
            )
          }
        ]
      }
    />
  );
};
