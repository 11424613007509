import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CreateUpdateShaping from '../../routes/catalog/shapings/CreateUpdateShaping';
import ShowShaping from '../../routes/catalog/shapings/ShowShaping';
import { Shapings } from '../../routes/catalog/shapings/Shapings';
import { ShapingContextProvider } from '../../contexts/ShapingContext';

const ShapingRouter = () => (
  <ShapingContextProvider>
    <Routes>
      <Route
        path="/create"
        element={<CreateUpdateShaping purpose="create" />}
      />
      <Route
        path="/edit/:id"
        element={<CreateUpdateShaping purpose="edit" />}
      />
      <Route path="/show/:id" element={<ShowShaping />} />
      <Route index element={<Shapings />} />
    </Routes>
  </ShapingContextProvider>
);

export default ShapingRouter;
