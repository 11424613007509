import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Switch, Row, Col, Input, Button, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const { Search } = Input;

export const HeaderFilter = ({
  archiveFilter,
  setArchiveFilter,
  extraQuery,
  children,
  resourceName,
  refresh,
  populate,
  setRefresh,
  extraFilters,
  extraButtons,
  withCreateButton,
  withArchiveButton,
  withSearchButton,
  resizeExtra
}) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const [searchValue, setSearchValue] = useState();
  const [datas, setDatas] = useState([]);

  const getResources = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `${resourceName}?populate=${populate}&${
          !archiveFilter ? 'status!=ARCHIVED' : null
        }&${extraQuery || ''}`
      });
      setDatas(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    getResources();
  }, [refresh, archiveFilter, extraQuery]);

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  return (
    <ContentCustom>
      <Row
        justify="space-between"
        gutter={[8, 16]}
        style={{ flexWrap: 'nowrap' }}
      >
        {withSearchButton && (
          <Col lg={3} xl={3} xxl={4}>
            <Search
              allowClear
              placeholder={t('placeholder.search')}
              defaultValue={searchValue}
            />
          </Col>
        )}
        <Col
          span={resizeExtra || null}
          lg={!resizeExtra ? 15 : null}
          xl={!resizeExtra ? 14 : null}
          xxl={!resizeExtra ? 11 : null}
        >
          {extraFilters}
        </Col>
        <Col lg={6} xl={6} xxl={5}>
          <Row justify="space-between" align="middle">
            {withArchiveButton && (
              <Col>
                <Space>
                  <Switch
                    onChange={() => {
                      setArchiveFilter(!archiveFilter);
                      setRefresh(!refresh);
                    }}
                    checkedChildren={t('buttons.archived')}
                    unCheckedChildren={t('buttons.not_archived')}
                  />
                </Space>
              </Col>
            )}
            {withCreateButton && (
              <Col>
                <Row align="middle">
                  {extraButtons}
                  <Link to={withCreateButton?.path || `${pathname}/create`}>
                    <Button type="add">
                      {withCreateButton?.buttonText || `${t('buttons.create')}`}
                      &nbsp;
                      {withCreateButton?.buttonIcon || <PlusOutlined />}
                    </Button>
                  </Link>
                </Row>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <ContentCustom>{React.cloneElement(children, { datas })}</ContentCustom>
    </ContentCustom>
  );
};

HeaderFilter.propTypes = {
  children: PropTypes.element.isRequired,
  extraQuery: PropTypes.string,
  resizeExtra: PropTypes.number,
  populate: PropTypes.string,
  resourceName: PropTypes.string.isRequired,
  extraFilters: PropTypes.element,
  refresh: PropTypes.bool.isRequired,
  archiveFilter: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
  setArchiveFilter: PropTypes.func.isRequired,
  extraButtons: PropTypes.element,
  withArchiveButton: PropTypes.bool,
  withSearchButton: PropTypes.bool,
  withCreateButton: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      path: PropTypes.string,
      buttonText: PropTypes.string,
      buttonIcon: PropTypes.element
    })
  ])
};

HeaderFilter.defaultProps = {
  extraFilters: null,
  extraQuery: null,
  extraButtons: null,
  resizeExtra: null,
  populate: '',
  withArchiveButton: false,
  withCreateButton: true,
  withSearchButton: true
};
