import { useTranslation } from 'react-i18next';

/**
 * A custom hook that provides a column configuration for displaying percentages.
 *
 * @hook
 * @returns {Array} An array containing a column configuration for displaying percentages.
 */
export const usePercentageColumn = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('tvas.form.percentage'),
      dataIndex: ['percentage'],
      editable: true,
      width: '10%',
      render: (percentage) => `${percentage} %`
    }
  ];
};
